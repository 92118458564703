import { Container, Header, SpaceBetween, TextContent } from '@amzn/awsui-components-react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Markdown } from '../../../common/Markdown';
import { ChallengePropAction, useCreateChallenge } from '../../../../store/create-challenge.context';
import { Challenge, ChallengeReviewableSection } from '../../../../types/Challenge';
import { i18nKeys } from '../../../../utils/i18n.utils';
import { ChallengeReviewPopover } from '../../challengesCommon/ChallengeReviewPopover';
import './Wiki.scss';
import MarkDownPreviewEditor from '@/src/components/common/MarkdownPreviewEditor/MarkDownPreviewEditor';

interface ChallengeWikiDetailProps {
  challenge: Challenge;
}

const ChallengeWikiDetail: React.FC<ChallengeWikiDetailProps> = ({ challenge }) => {
  const { t } = useTranslation();
  const { editMode, handleUpdateChallengeProp } = useCreateChallenge();

  return (
    <Container
      header={
        <Header variant="h2" actions={<ChallengeReviewPopover section={ChallengeReviewableSection.WIKI} />}>
          {t(i18nKeys.challenges.challengeDetails.headings.wiki)}
        </Header>
      }>
      <TextContent>
        {!editMode && challenge.props.wiki && (
          <Markdown content={challenge.props.wiki} />
        )}
        {!editMode && !challenge.props.wiki && t(i18nKeys.challenges.challengeDetails.field.wiki.emptyState)}
        {editMode && (
          <div className="challenge-wiki">
            <SpaceBetween size="s">
              <TextContent>
                {t(i18nKeys.challenges.challengeDetails.field.wiki.disclaimer)}
              </TextContent>
              <h3>{t(i18nKeys.challenges.challengeDetails.field.markdownPreferred)}</h3>
              <MarkDownPreviewEditor
                text={challenge?.props?.wiki || ""}
                onTextChanged={(text) => handleUpdateChallengeProp(ChallengePropAction.WIKI, text)}
              />
            </SpaceBetween>
          </div>
        )}
      </TextContent>
    </Container>
  );
};

export default ChallengeWikiDetail;
