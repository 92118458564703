import { useApi } from "@/src/store/api.context";
import { i18nKeys } from "@/src/utils/i18n.utils";
import { preProdLogger } from "@/src/utils/log.utils";
import { Box, Button, Header, Modal, Select, SelectProps, SpaceBetween } from "@amzn/awsui-components-react";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";

const AssignChallengeIssueModal = ({visible, issueId, currentAssignee, onDismiss, triggerReload, assigneeOptions }: {visible: boolean; issueId: string; onDismiss: () => void; triggerReload: () => void; currentAssignee: SelectProps.Option; assigneeOptions: SelectProps.Options}) => {
  const { t } = useTranslation();
  const [assignee, assigneeSet] = useState(currentAssignee);
  const [loading, loadingSet] = useState(false);
  const { challengeIssuesApi } = useApi();
  const submitHandler = useCallback(async () => {
    loadingSet(true);
    try {
      if (currentAssignee.value !== assignee.value) {
        await challengeIssuesApi.assignIssueToUser(issueId, assignee.value || "", true);
        triggerReload();
      }
      onDismiss();
    } catch (err) {
      preProdLogger(err);
    } finally {
      loadingSet(false); 
    }
  }, [assignee, challengeIssuesApi, triggerReload, onDismiss]);

  return <Modal
    header={<Header>{t(i18nKeys.challenges.issues.buttons.assignIssue)}</Header>}
    visible={visible}
    onDismiss={onDismiss}
    data-testid="challenge-issues__assign-modal"
    footer={
      <Box float="right">
        <SpaceBetween size="s" direction="horizontal">
          <Button
            key="challenge-issues__assign-modal-cancel-btn"
            data-testid="challenge-issues__assign-modal-cancel-btn"
            onClick={onDismiss}>{t(i18nKeys.general.cancel)}</Button>
          <Button
            key="challenge-issues__assign-modal-confirm-btn"
            data-testid="challenge-issues__assign-modal-confirm-btn"
            loading={loading}
            onClick={submitHandler}>{t(i18nKeys.general.submit)}</Button>
        </SpaceBetween>
      </Box>
    }
    >
      <Box padding={{bottom: 'm'}}>{t(i18nKeys.challenges.issues.assignIssueModalContent)}</Box>
      <Select
        selectedOption={assignee}
        onChange={({ detail }) => assigneeSet(detail.selectedOption)}
        options={assigneeOptions} />
    </Modal>
};

export default AssignChallengeIssueModal;