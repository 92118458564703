
import * as React from 'react';
import { Badge, Box, Button, CollectionPreferences, ContentLayout, Header, Modal, Pagination, SpaceBetween, Table ,TextFilter} from '@amzn/awsui-components-react';
import { useTranslation } from 'react-i18next';
import { i18nKeys } from '@/src/utils/i18n.utils';
import { useChallenges } from '@/src/store/challenge.context';
import { useEffect, useLayoutEffect, useState } from 'react';
import { ChallengeSnippet } from '@/src/types/ChallengeSnippets';
import { useCollection } from '@amzn/awsui-collection-hooks';
import { callIgnoringExceptionsAsync } from '@/src/utils/call-ignoring-exceptions';
import { ChallengeCFN, Snippets } from '@/src/types/ChallengeSet';
import { useApi } from '@/src/store/api.context';
import JamCodeEditor from '../../challengesCommon/JamCodeEditor';
import { preProdLogger } from '@/src/utils/log.utils';

const EmptyState = ({ title, subtitle, action }: { title: string; subtitle: string; action: React.ReactNode }) => {
    return (
      <Box textAlign="center" color="inherit">
        <Box variant="strong" textAlign="center" color="inherit">
          {title}
        </Box>
        <Box variant="p" padding={{ bottom: 's' }} color="inherit">
          {subtitle}
        </Box>
        {action}
      </Box>
    );
  };
  interface SmartTextI {
    text: string;
    length: number;
  }

const ChallengeSnippets: React.FC = () => {
    const { challengeCFN } = useChallenges();
    const [challengeSnippts, setChallengeSnippts] = useState<ChallengeSnippet[]>([]);
    const [privewVisible, setPrivewVisible] = useState<boolean>(false);
    const [snipptsMarkdown, setSnipptsMarkdown] = useState<string>('');
    const [originalTableData, setOriginalTableData] = useState<ChallengeSnippet[]>([]);
    const [filterText, setFilterText] = useState('');
    const { challengesApi } = useApi();
    const { t } = useTranslation();

      useLayoutEffect(() => {
        if (!challengeCFN) {
          void getALLCNF();
        }
      }, []);
    
      const getALLCNF = async () => {
        await challengesApi.getAllCFN().then(res => {
          const snippts = res.map(item => item.sharableSnippet as unknown as ChallengeSnippet);
          if (snippts) {
            setOriginalTableData(snippts)
            setChallengeSnippts(snippts);
          }
        });
      }


    useEffect(() => {
        const snippts = challengeCFN?.map(item => item.sharableSnippet as unknown as ChallengeSnippet);
        if(snippts){
          setOriginalTableData(snippts)
            setChallengeSnippts(snippts);
        }
    },[]);

      const { items, actions, collectionProps } =
      useCollection(challengeSnippts, {
        filtering: {
          empty: (
            <EmptyState
              title={t(i18nKeys.eventTemplates.customTable.empty.noTitle, t(i18nKeys.eventTemplates.title))}
              subtitle={t(i18nKeys.eventTemplates.customTable.empty.noSubTitle, t(i18nKeys.eventTemplates.title))}
              action={<Button id='challenge-snippet-empty-table-btn' data-testid='challenge-snippet-empty-table-btn'>{t(i18nKeys.eventTemplates.customTable.empty.createTitle, t(i18nKeys.eventTemplates.title))}</Button>}
            />
          ),
          noMatch: (
            <EmptyState
              title={t(i18nKeys.eventTemplates.customTable.noMatch.title)}
              subtitle={t(i18nKeys.eventTemplates.customTable.noMatch.subTitle)}
              action={
                <Button id='challenge-snippet-clear-filter' data-testid='challenge-snippet-clear-filter' onClick={() => actions.setFiltering('')}>
                  {t(i18nKeys.eventTemplates.customTable.noMatch.clearFilter)}
                </Button>
              }
            />
          ),
        },
        pagination: { pageSize: 20 },
        sorting: {},
        selection: {}
      });

      const filterDataFromTable = (value: string) => {
        setFilterText(value);
        const data = originalTableData.filter((item: ChallengeSnippet) => item?.title?.toLocaleLowerCase().includes(value.toLocaleLowerCase()));
        setChallengeSnippts(data)
      };

      const SmartText: React.FC<SmartTextI> = ({ text, length = 20 }) => {
        const [showLess, setShowLess] = React.useState(true);
    
        if (text?.length < length) {
          return <p>{text}</p>;
        }
    
        return (
          <div>
            <p>{showLess ? `${text?.slice(0, length)}` : text}</p>
            <Button variant='inline-link' onClick={() => setShowLess(!showLess)}>
              {showLess
                ? t(i18nKeys.challenges.subSections.cfnTemplate.challengeSnippts.showDescription)
                : t(i18nKeys.challenges.subSections.cfnTemplate.challengeSnippts.hideDescription)}
            </Button>
          </div>
        );
      };

  const getDescriptions = (item: ChallengeSnippet) => {
    if (item.description && item?.description.length > 1) {
      return <SmartText text={item.description} length={0} />;
    }
  }

    const getSnippt = (id: string) => {
     void callIgnoringExceptionsAsync(async () => {
              return await challengesApi.getSnippets(id);
          }).then((challengeCFNResponse: ChallengeCFN | undefined) => {
              if (challengeCFNResponse) {
                setSnipptsMarkdown((challengeCFNResponse.sharableSnippet as unknown as Snippets).snippet);
                setPrivewVisible(true);
              }
          });
      }

      const onPreviw = (item: ChallengeSnippet) => {
         getSnippt(item.id || ''); 
      }

    return (
        <ContentLayout header={
            <Header variant="h1">
                {t(i18nKeys.challenges.subSections.cfnTemplate.snippetTitle, {count: challengeSnippts.length})}
            </Header>
        }>
            <Box>
                <Table
                    {...collectionProps}
                    ariaLabels={{
                        selectionGroupLabel: t(i18nKeys.challenges.selectionGroupLabel),
                        allItemsSelectionLabel: ({ selectedItems }) =>
                          `${selectedItems.length} ${
                            selectedItems.length === 1 ? "item" : "items"
                          } selected`,
                        itemSelectionLabel: ({ }, item: ChallengeSnippet) =>
                          item.title || ''
                      }}
                    columnDefinitions={[
                        {
                            id: "title",
                            header: t(i18nKeys.challenges.subSections.cfnTemplate.challengeSnippts.title),
                            cell: item => <SpaceBetween direction="horizontal" size={'s'}><Button iconName="copy" variant="inline-icon" />{item.title}</SpaceBetween>,
                            sortingField: "title",
                            isRowHeader: true
                        },
                        {
                            id: "preview",
                            header: t(i18nKeys.challenges.subSections.cfnTemplate.challengeSnippts.preview),
                            cell: (item => <Button id='challenge-snippet-preview' data-testid='challenge-snippet-preview' variant="link" onClick={() => onPreviw(item)}>{t(i18nKeys.challenges.subSections.cfnTemplate.challengeSnippts.preview)}</Button>),
                            sortingField: "preview"
                        },
                        {
                            id: "tags",
                            header: t(i18nKeys.challenges.subSections.cfnTemplate.challengeSnippts.tags),
                            cell: item => (<SpaceBetween direction="horizontal" size="xxs">{item.tags?.map((tag, index) => <Badge key={index}>{tag}</Badge>)}{''}</SpaceBetween>)
                        },
                        {
                            id: "description",
                            header: t(i18nKeys.challenges.subSections.cfnTemplate.challengeSnippts.description),
                            cell: item => getDescriptions(item),
                            sortingField: "description"
                        },
                        {
                            id: "rating",
                            header: t(i18nKeys.challenges.subSections.cfnTemplate.challengeSnippts.rating),
                            cell: item => <>{item.positiveFeedbackNum} {t(i18nKeys.challenges.subSections.cfnTemplate.challengeSnippts.vote)}</>,
                            sortingField: "rating"
                        }
                    ]}
                    columnDisplay={[
                        { id: "title", visible: true },
                        { id: "preview", visible: true },
                        { id: "tags", visible: true },
                        { id: "description", visible: true },
                        { id: "rating", visible: true }
                    ]}
                    items={items}
                    loadingText="Loading resources"
                    trackBy="title"
                    resizableColumns
                    empty={
                        <Box
                            margin={{ vertical: "xs" }}
                            textAlign="center"
                            color="inherit"
                        >
                            <SpaceBetween size="m">
                                <b>{t(i18nKeys.eventTemplates.customTable.empty.noTitle)}</b>
                            </SpaceBetween>
                        </Box>
                    }
                    pagination={
                        <Pagination currentPageIndex={1} pagesCount={2} />
                    }
                    filter={            
                          <Box margin={{ top: 'xs', bottom: 'xs' }}>
                             <TextFilter
                                filteringText={filterText}
                                onChange={({ detail }) => void filterDataFromTable(detail.filteringText)}
                                disabled={originalTableData?.length === 0}
                              />
                          </Box>
                       }
                       preferences={
                        <CollectionPreferences
                            title={t(i18nKeys.events.fields.preferences.title)}
                            confirmLabel="Confirm"
                            cancelLabel="Cancel"
                            preferences={{
                                pageSize: 10,
                                contentDisplay: [
                                    { id: "title", visible: true },
                                    { id: "preview", visible: true },
                                    { id: "tags", visible: true },
                                    { id: "description", visible: true },
                                    { id: "rating", visible: true }
                                ]
                            }}
                            pageSizePreference={{
                                title: t(i18nKeys.campaigns.labels.preferences.pageSize.label),
                                options: [
                                    { value: 10, label: t(i18nKeys.campaigns.labels.preferences.pageSize.value, { count: 10 }) },
                                    { value: 20, label: t(i18nKeys.campaigns.labels.preferences.pageSize.value, { count: 20 }) },
                                ]
                            }}
                            wrapLinesPreference={{}}
                            stripedRowsPreference={{}}
                            contentDensityPreference={{}}
                            contentDisplayPreference={{
                                options: [
                                    {
                                        id: "title",
                                        label: t(i18nKeys.challenges.subSections.cfnTemplate.challengeSnippts.title),
                                        alwaysVisible: true
                                    },
                                    { id: "preview", label: t(i18nKeys.challenges.subSections.cfnTemplate.challengeSnippts.preview) },
                                    { id: "tags", label: t(i18nKeys.challenges.subSections.cfnTemplate.challengeSnippts.tags) },
                                    { id: "description", label: t(i18nKeys.challenges.subSections.cfnTemplate.challengeSnippts.description) },
                                    { id: "rating", label: t(i18nKeys.challenges.subSections.cfnTemplate.challengeSnippts.rating) },
                                ]
                            }}
                        />
                    }
                    stickyColumns={{ first: 0, last: 1 }}
                />
                <Modal 
                visible={privewVisible}
                onDismiss={()=> setPrivewVisible(false)}
                >
                    <div>
                    <JamCodeEditor
                        language="yaml"
                        value={snipptsMarkdown}
                        setValue={() => {
                            preProdLogger('none');
                        }}
                        />
                    </div>
                </Modal>
            </Box>
        </ContentLayout>
    );
};

export default ChallengeSnippets;
