import { i18nKeys, pluralize } from '@/src/utils/i18n.utils';
import { DateRangePickerProps } from '@amzn/awsui-components-react';
import moment from 'moment';
import { TFunction } from 'react-i18next';

const formatRelativeRangeUnit = (unit: DateRangePickerProps.TimeUnit, amount: number): string => {
  switch (unit) {
    case 'day':
      return pluralize(i18nKeys.time.formatted.day, i18nKeys.time.formatted.days, amount);
    case 'hour':
      return pluralize(i18nKeys.time.formatted.hour, i18nKeys.time.formatted.hours, amount);
    case 'minute':
      return pluralize(i18nKeys.time.formatted.minute, i18nKeys.time.formatted.minutes, amount);
    case 'month':
      return pluralize(i18nKeys.time.formatted.month, i18nKeys.time.formatted.months, amount);
    case 'second':
      return pluralize(i18nKeys.time.formatted.second, i18nKeys.time.formatted.seconds, amount);
    case 'week':
      return pluralize(i18nKeys.time.formatted.week, i18nKeys.time.formatted.weeks, amount);
    case 'year':
      return pluralize(i18nKeys.time.formatted.year, i18nKeys.time.formatted.years, amount);
  }
};

export const formatRelativeRange = (unit: DateRangePickerProps.TimeUnit, amount: number, t: TFunction) => {
  const formattedUnit = formatRelativeRangeUnit(unit, amount);

  return t(formattedUnit, { amount });
};

export const createDateRangePickerI18nStrings = (t: TFunction): DateRangePickerProps.I18nStrings => ({
  ariaLabel: t(i18nKeys.dateRangePicker.ariaLabel),
  modeSelectionLabel: t(i18nKeys.dateRangePicker.modeSelectionLabel),
  relativeModeTitle: t(i18nKeys.dateRangePicker.relativeModeTitle),
  absoluteModeTitle: t(i18nKeys.dateRangePicker.absoluteModeTitle),
  relativeRangeSelectionHeading: t(i18nKeys.dateRangePicker.relativeRangeSelectionHeading),
  relativeRangeSelectionMonthlyDescription: t(i18nKeys.dateRangePicker.relativeRangeSelectionMonthlyDescription),
  cancelButtonLabel: t(i18nKeys.dateRangePicker.cancelButtonLabel),
  clearButtonLabel: t(i18nKeys.dateRangePicker.clearButtonLabel),
  applyButtonLabel: t(i18nKeys.dateRangePicker.applyButtonLabel),
  formatRelativeRange: ({ unit, amount }) =>
    t(i18nKeys.dateRangePicker.formatRelativeRange, { range: formatRelativeRange(unit, amount, t) }),
  formatUnit: (unit, amount) => t(i18nKeys.dateRangePicker.formatUnit, { range: formatRelativeRange(unit, amount, t) }),
  customRelativeRangeOptionLabel: t(i18nKeys.dateRangePicker.customRelativeRangeOptionLabel),
  customRelativeRangeOptionDescription: t(i18nKeys.dateRangePicker.customRelativeRangeOptionDescription),
  customRelativeRangeDurationLabel: t(i18nKeys.dateRangePicker.customRelativeRangeDurationLabel),
  customRelativeRangeDurationPlaceholder: t(i18nKeys.dateRangePicker.customRelativeRangeDurationPlaceholder),
  customRelativeRangeUnitLabel: t(i18nKeys.dateRangePicker.customRelativeRangeUnitLabel),
  startMonthLabel: t(i18nKeys.dateRangePicker.startMonthLabel),
  startDateLabel: t(i18nKeys.dateRangePicker.startDateLabel),
  startTimeLabel: t(i18nKeys.dateRangePicker.startTimeLabel),
  endMonthLabel: t(i18nKeys.dateRangePicker.endMonthLabel),
  endDateLabel: t(i18nKeys.dateRangePicker.endDateLabel),
  endTimeLabel: t(i18nKeys.dateRangePicker.endTimeLabel),
  dateConstraintText: t(i18nKeys.dateRangePicker.dateConstraintText),
  dateTimeConstraintText: t(i18nKeys.dateRangePicker.dateTimeConstraintText),
  monthConstraintText: t(i18nKeys.dateRangePicker.monthConstraintText),
  errorIconAriaLabel: t(i18nKeys.dateRangePicker.errorIconAriaLabel),
  renderSelectedAbsoluteRangeAriaLive: (start, end) =>
    t(i18nKeys.dateRangePicker.renderSelectedAbsoluteRangeAriaLive, { start, end }),
  todayAriaLabel: t(i18nKeys.dateRangePicker.todayAriaLabel),
  nextMonthAriaLabel: t(i18nKeys.dateRangePicker.nextMonthAriaLabel),
  previousMonthAriaLabel: t(i18nKeys.dateRangePicker.previousMonthAriaLabel),
  currentMonthAriaLabel: t(i18nKeys.dateRangePicker.currentMonthAriaLabel),
  nextYearAriaLabel: t(i18nKeys.dateRangePicker.nextYearAriaLabel),
  previousYearAriaLabel: t(i18nKeys.dateRangePicker.previousYearAriaLabel),
});

export const getDateRange = (value: DateRangePickerProps.Value) => {
  if (value.type === 'absolute') {
    return { startDate: value.startDate, endDate: value.startDate };
  }

  const endDate = moment(moment.now());
  const startDate = moment(moment.now()).subtract(value.amount, value.unit);

  return { startDate: startDate.format('YYYY-MM-DD'), endDate: endDate.format('YYYY-MM-DD') };
};

export const isValidRange = (
  range: DateRangePickerProps.Value | null,
  t: TFunction
): DateRangePickerProps.ValidationResult => {
  if (range?.type === 'absolute') {
    const [startDateWithoutTime] = range.startDate.split('T');
    const [endDateWithoutTime] = range.endDate.split('T');

    if (!startDateWithoutTime || !endDateWithoutTime) {
      return {
        valid: false,
        errorMessage: t(i18nKeys.eventReportContainer.incompleteDateRange),
      };
    }

    if (new Date(range.startDate).getTime() - new Date(range.endDate).getTime() > 0) {
      return {
        valid: false,
        errorMessage: t(i18nKeys.eventReportContainer.invalidDateRange),
      };
    }
  }

  return { valid: true };
};
