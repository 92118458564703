import React from 'react';
import { Box, Button } from '@amzn/awsui-components-react';
import './FeedbackStars.scss';
import { useTranslation } from 'react-i18next';
import { i18nKeys } from '@/src/utils/i18n.utils';

interface FeedbackStarsProps {
  maxRating?: number;
  name: string;
  rating: number;
  setRating: (name: string, value: number) => void;
}

const FeedbackStars: React.FC<FeedbackStarsProps> = ({ maxRating = 5, name, rating, setRating }) => {
  const { t } = useTranslation();
  const stars = [];

  for (let i = 1; i <= maxRating; i++) {
    stars.push(
      <Button
        key={i}
        data-testid={`feedback-star-${i}-${i <= rating ? 'filled' : 'unfilled'}`}
        variant="icon"
        className={`feedback-rating ${i <= rating ? 'filled' : 'unfilled'} cursor-pointer`}
        iconSvg={<svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          stroke={i <= rating ? 'orange' : 'currentColor'}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="feather feather-star">
          <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2" />
        </svg>}
        onClick={() => setRating(name, i)} />
    );
  }

  return (
    <div className="feedback-rating-container">
      <Box className="review-stars" margin={{ right: 'm' }}>
        {stars}
      </Box>
      {rating > 0 && (
        <Button variant="inline-link" onClick={() => setRating(name, 0)}>
          {t(i18nKeys.myJams.feedback.label.clear)}
        </Button>
      )}
    </div>
  );
};

export default FeedbackStars;
