import { HybridConsolePrefix } from '../utils/hybrid-console/index';
import { config } from '../config/app-config';

export const isDev = () => config.stageName.toLowerCase().trim() === 'beta' || isLocal();

export const isPreProd = () => !isProd() || isLocal();

export const isProd = () => config.stageName.toLowerCase().trim() === 'prod';

export const isCustomReporting = () => true;

export const isLocal = () => matchesDomain('localhost');

export const isGamma = () => config.stageName.toLowerCase().trim() === 'gamma';

export const matchesDomain = (domain: string) => window.location.hostname === domain;
export const domainIncludes = (fragment: string) => window.location.hostname.includes(fragment);

// todo convert this to a method, every time this file is imported a new config is generated
export const BASE_PATH_PREFIX: string = config.useHybridExperience ? HybridConsolePrefix.Polaris : '';
