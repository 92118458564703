export const EventAudienceType = {
  GENERAL_PUBLIC: 'GENERAL_PUBLIC',
  AWS_PRIVATE_ENGAGEMENT: 'AWS_PRIVATE_ENGAGEMENT',
  AWS_BOOTCAMP: 'AWS_BOOTCAMP',
  AWS_INTERNAL: 'AWS_INTERNAL',
  CUSTOMER_INTERNAL: 'CUSTOMER_INTERNAL',
  TESTERS: 'TESTERS',
  INDIVIDUAL: 'INDIVIDUAL',
  SKILL_BUILDER_EVENT: 'SKILL_BUILDER_EVENT',
  AWS_CLASSROOMS_BUILDER_LABS: 'AWS_CLASSROOMS_BUILDER_LABS',
  INTERNAL: 'INTERNAL',
  PUBLIC: 'PUBLIC',
  PAID_ALA_CARTE: 'PAID_ALA_CARTE',
  GLOBAL_PARTNERS: 'GLOBAL_PARTNERS',
  AWS_EDUCATION: 'AWS_EDUCATION',
  RECRUITING: 'RECRUITING',
  JAM_JOURNEY: 'JAM_JOURNEY'
};

export const AudienceTypeBasedOnUserGroup = {
  SB_ADMIN: {[EventAudienceType.SKILL_BUILDER_EVENT]: true},
  CLASSROOM_ILT: {
    [EventAudienceType.AWS_CLASSROOMS_BUILDER_LABS]: true, // Handled in the backend. This should case should never be hit
  },
  FEDERATE_USER: {
    [EventAudienceType.SKILL_BUILDER_EVENT]: false,
    [EventAudienceType.INTERNAL]: true,
    [EventAudienceType.GLOBAL_PARTNERS]: false,
  },
  JAM_ADMIN_USER: {
    [EventAudienceType.SKILL_BUILDER_EVENT]: false,
    [EventAudienceType.AWS_CLASSROOMS_BUILDER_LABS]: false,
    [EventAudienceType.INTERNAL]: true,
    [EventAudienceType.PUBLIC]: false,
    [EventAudienceType.GLOBAL_PARTNERS]: false,
    [EventAudienceType.AWS_EDUCATION]: false,
    [EventAudienceType.RECRUITING]: false,
    [EventAudienceType.JAM_JOURNEY]: false,
  },
  ALA_CARTE_USER: {
    [EventAudienceType.PAID_ALA_CARTE]: true,
  }
}

export const EventAudienceTypeDescriptions = {
  GENERAL_PUBLIC: 'Open to the general public',
  AWS_PRIVATE_ENGAGEMENT: 'Private engagement facilitated by AWS Professional Services',
  AWS_BOOTCAMP: 'Instructor lead session included with an AWS Bootcamp',
  AWS_INTERNAL: 'Only accessible to AWS internal staff',
  CUSTOMER_INTERNAL: 'Facilitated by a customer without engagement from AWS',
  TESTERS: 'Only accessible to allowlisted users for testing',
  INDIVIDUAL: 'A single participant event, no teams',
    // TODO: Add descriptions for new channels
  SKILL_BUILDER_EVENT: '',
  AWS_CLASSROOMS_BUILDER_LABS: '',
  INTERNAL: '',
  PUBLIC: '',
  PAID_ALA_CARTE: '',
  GLOBAL_PARTNERS: '',
  AWS_EDUCATION: '',
  RECRUITING: '',
  JAM_JOURNEY: ''
};