import { ConfirmModal } from '@/src/components/common/ConfirmModal';
import { useEventsApi } from '@/src/hooks/api/useEventsApi';
import { useFlashbars } from '@/src/store/flashbar.context';
import { downloadObjectAsCsv } from '@/src/utils/download.utils';
import { i18nKeys } from '@/src/utils/i18n.utils';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { useTranslation } from 'react-i18next';

export enum DownloadType {
  All = 'ALL',
  SolvedOnly = 'SOLVED_ONLY',
}

export interface PiiWarningModalProps {
  eventName: string;
  downloadType: DownloadType;
}

export const PiiWarningModal = NiceModal.create((props: PiiWarningModalProps) => {
  const { t } = useTranslation();
  const modal = useModal();
  const { addErrorFlashbar } = useFlashbars();
  const eventsApi = useEventsApi();

  const exportData = async () => {
    if (!props.eventName) {
      addErrorFlashbar(t(i18nKeys.participants.warnings.selectEvent));
      return;
    }

    if (props.downloadType === DownloadType.All) {
      const response = await eventsApi.getAllParticipantsExport(props.eventName);

      if (response.length) {
        downloadObjectAsCsv(response, `participant-all-export-${props.eventName}`);
      } else {
        addErrorFlashbar(t(i18nKeys.participants.warnings.noUsersExported));
      }
    }

    if (props.downloadType === DownloadType.SolvedOnly) {
      const response = await eventsApi.getParticipantSolvedExport(props.eventName);

      if (response.length) {
        downloadObjectAsCsv(response, `participant-all-export-${props.eventName}`);
      } else {
        addErrorFlashbar(t(i18nKeys.participants.warnings.noUsersExported));
      }
    }

    NiceModal.hide(PiiWarningModal);
  };

  return (
    <ConfirmModal
      onCancel={() => NiceModal.hide(PiiWarningModal)}
      onConfirm={exportData}
      confirmBtnLabel="Yes, Continue"
      title="PII Data Access Warning"
      visible={modal.visible}
      message={
        <>
          <div>{t(i18nKeys.participants.messages.piiDataWarningDiv1)}</div>
          <div>
            {t(i18nKeys.participants.messages.piiDataWarningDiv2)}
            <br /> {t(i18nKeys.participants.messages.piiDataWarningDiv3)} <br />{' '}
            {t(i18nKeys.participants.messages.piiDataWarningDiv4)}
          </div>
          <div>{t(i18nKeys.participants.messages.piiDataWarningDiv5)}</div>
          <div>{t(i18nKeys.participants.messages.piiDataWarningDiv6)}</div>
        </>
      }
    />
  );
});
