/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-call */
import { useCollection } from '@amzn/awsui-collection-hooks';
import { Button, CollectionPreferencesProps, Pagination, SpaceBetween, Table } from '@amzn/awsui-components-react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useComponentDidMountEffect } from '../../../../../hooks/useComponentDidMountEffect';
import { useApi } from '../../../../../store/api.context';
import {
  Challenge,
  ChallengeLearningOutcome,
  ChallengeProps,
  ChallengeReviewStatus,
  ChallengeSettings,
  ChallengeStatus,
  ChallengeTask,
  Clue,
} from '../../../../../types/Challenge';
import { i18nKeys } from '../../../../../utils/i18n.utils';
import { TableHeader } from '../../../../common/TableHeader';
import { COLUMN_DEFINITIONS } from '../revision-list-config';
import { useChallenges } from '../../../../../store/challenge.context';
import { preProdLogger } from '../../../../../utils/log.utils';
import { OptionDefinition } from '@amzn/awsui-components-react/polaris/internal/components/option/interfaces';
import { DiffChange } from '../../../../../types/Diff';
import RevisionHistoryDetails from '../challengeRevisionHistoryDetails/RevisionHistoryDetails';
import { Nullable } from '@/src/types/common';

export interface selectedRevisionDataProps {
  status: Nullable<ChallengeReviewStatus> | Nullable<ChallengeStatus> | undefined;
  createdAt: number;
  owner: string;
}

const RevisionHistory: React.FC = () => {
  const { t } = useTranslation();
  const { challengesApi } = useApi();
  const { challenge } = useChallenges();

  const [challengeHistory, setChallengeHistory] = useState<Challenge[]>();
  const [selectRevisionOptions, setSelectRevisionOptions] = useState<OptionDefinition[]>([]);

  // Revision selction for option definition and for diff comparison
  const [rightRevision, setRightRevision] = useState<Challenge>();
  const [leftRevision, setLeftRevision] = useState<Challenge | null>(null);
  const [selectedLeftRevision, setSelectedLeftRevision] = useState<OptionDefinition | null>(null);
  const [selectedRightRevision, setSelectedRightRevision] = useState<OptionDefinition | null>(null);
  const [selectedLeftRevisionData, setSelectedLeftRevisionData] = useState<selectedRevisionDataProps>({
    status: undefined,
    createdAt: 0,
    owner: '',
  });
  const [selectedRightRevisionData, setSelectedRightRevisionData] = useState<selectedRevisionDataProps>({
    status: undefined,
    createdAt: 0,
    owner: '',
  });

  const [containsDiff, setContainsDiff] = useState(false);
  const [overviewDetailDiffs, setOverviewDetailDiffs] = useState<DiffChange[]>([]);
  const [settingsDiffs, setSettingDiffs] = useState<DiffChange[]>([]);
  const [learningOutcomeDiffs, setLearningOutcomeDiffs] = useState<DiffChange[]>([]);
  const [tasksDiffs, setTasksDiffs] = useState<DiffChange[][]>([]);
  const [cfnTemplateDiffs, setCfnTemplateDiffs] = useState<DiffChange[]>([]);
  const [iamPolicyDiffs, setIamPolicyDiffs] = useState<DiffChange[]>([]);
  const [wikiDiffs, setWikiDiffs] = useState<DiffChange[]>([]);
  const [enableDetailView, setEnableDetailView] = useState(false);

  useComponentDidMountEffect(async () => {
    const history: Challenge[] = await challengesApi.getChallengeVersionHistory(challenge?.challengeId || '');

    // challenge history will come in oldest to newest, we reverse it for the revision list
    history.reverse();

    setChallengeHistory(history);
  });

  useEffect(() => {
    if (challenge) {
      setRightRevision(challenge);
    }
  }, [challenge]);

  useEffect(() => {
    if (challengeHistory) {
      getRevisionOptions();
    }
  }, [challengeHistory]);

  useEffect(() => {
    if (leftRevision && rightRevision) {
      getChallengeOverviewDetailDiff();
      getChallengeSettingsDiff();
      getChallengeLearningOutcomeDiff();
      getChallengeTasksDiff();
      getChallengeCFNTemplateDiff();
      getChallengeIAMPolicyDiff();
      getChallengeWikiDiff();
    }
  }, [leftRevision, rightRevision]);

  const [preferences] = useState<CollectionPreferencesProps.Preferences>({
    pageSize: 10,
  });

  const { items, collectionProps, paginationProps  } = useCollection(challengeHistory || [], {
    pagination: { pageSize: preferences.pageSize },
    sorting: {},
  });

  const handleRevisionSelection = (challengeRevision: Challenge) => {
    setContainsDiff(false);
    setEnableDetailView(true);
    loadVersion(challengeRevision)
      .then((c: Challenge | null) => {
        if (c) {
          setLeftRevision(c);

          // Handle Right Revision
          setSelectedRightRevision({
            label: `v${rightRevision?.majorVersion || 0}.${rightRevision?.minorVersion || 0}`,
            value: `${rightRevision?.version}`,
          });
          setSelectedRightRevisionData({
            status: rightRevision?.status ?? undefined,
            owner: rightRevision?.props.owner ?? '',
            createdAt: rightRevision?.createdDate ?? 0,
          });

          // Handle Left Revision
          setSelectedLeftRevision({
            label: `v${c.majorVersion || 0}.${c.minorVersion || 0}`,
            value: `${c.version}` });
          setSelectedLeftRevisionData({
            status: c.status ?? undefined,
            owner: c.props.owner ?? '',
            createdAt: c.createdDate ?? 0,
          });
        }
      })
      .catch((err) => preProdLogger(err));
  };

  const handleLeftRevisionSelection = (detail: any) => {
    setContainsDiff(false);
    if (challengeHistory) {
      setSelectedLeftRevision(detail.selectedOption);
      const leftChallenge = challengeHistory.find((c) => `${c.version}` === detail.selectedOption.value);
      if (leftChallenge) {
        loadVersion(leftChallenge)
          .then((leftVersion: Challenge | null) => {
            if (leftVersion) {
              setLeftRevision(leftVersion);
              // Add these lines to update the revision data
              setSelectedLeftRevisionData({
                status: leftVersion.status ?? undefined,
                owner: leftVersion.props.owner ?? '',
                createdAt: leftVersion.createdDate ?? 0,
              });
            }
          })
          .catch((err) => preProdLogger(err));
      }
    }
  };

  const handleRightRevisionSelection = (detail: any) => {
    setContainsDiff(false);
    if (challengeHistory) {
      setSelectedRightRevision(detail.selectedOption);
      const rightChallenge = challengeHistory.find((c) => `${c.version}` === detail.selectedOption.value);
      if (rightChallenge) {
        loadVersion(rightChallenge)
          .then((rightVersion: Challenge | null) => {
            if (rightVersion) {
              setRightRevision(rightVersion);
              // Add these lines to update the revision data
              setSelectedRightRevisionData({
                status: rightVersion.status ?? undefined,
                owner: rightVersion.props.owner ?? '',
                createdAt: rightVersion.createdDate ?? 0,
              });
            }
          })
          .catch((err) => preProdLogger(err));
      }
    }
  };

  const getRevisionOptions = () => {
    if (challengeHistory) {
      const options: OptionDefinition[] = [];

      challengeHistory.forEach((c: Challenge) => {
        options.push({ label: `v${c.majorVersion || 0}.${c.minorVersion || 0}`, value: `${c.version}` });
      });

      setSelectRevisionOptions(options);
    }
  };

  const loadVersion = async (challengeRevision: Challenge): Promise<Challenge | null> => {
    const majorVersion = challengeRevision.majorVersion;
    const minorVersion = challengeRevision.minorVersion;

    if (majorVersion != null && minorVersion != null) {
      const match = (challengeHistory || []).find((c: Challenge) => {
        return c.minorVersion === minorVersion && c.majorVersion === majorVersion;
      });
      if (match) {
        return await challengesApi.getChallengeVersion(challenge?.challengeId || '', match.version || 0);
      }
    }
    return null;
  };

  const getChallengeOverviewDetailDiff = () => {
    if (rightRevision && leftRevision) {
      const titleDiff: DiffChange[] = ChallengeProps.diffTitle(leftRevision.props, rightRevision.props);
      const descriptionDiff: DiffChange[] = ChallengeProps.diffDescription(leftRevision.props, rightRevision.props);

      const diffs: DiffChange[] = titleDiff.concat(descriptionDiff);

      setOverviewDetailDiffs(diffs);
      checkContainsDiff(diffs);
    }
  };

  const getChallengeSettingsDiff = () => {
    if (rightRevision && leftRevision) {
      const diffs: DiffChange[] = ChallengeSettings.diff(
        leftRevision.props.settings as ChallengeSettings,
        rightRevision.props.settings as ChallengeSettings
      );
      setSettingDiffs(diffs);
      checkContainsDiff(diffs);
    }
  };

  const getChallengeLearningOutcomeDiff = () => {
    if (rightRevision && leftRevision) {
      const diffs: DiffChange[] = ChallengeLearningOutcome.diff(
        leftRevision.props.learningOutcome,
        rightRevision.props.learningOutcome
      );
      setLearningOutcomeDiffs(diffs);
      checkContainsDiff(diffs);
    }
  };

  const getChallengeTasksDiff = () => {
    const tasksDiff: DiffChange[][] = [];
    if (rightRevision && leftRevision) {
      const currentTasks = rightRevision.props.tasks;
      const selectedTasks = leftRevision.props.tasks;

      if (currentTasks) {
        for (let i = 0; i < currentTasks.length; i++) {
          let diffs: DiffChange[] = [];
          const currentTask = currentTasks[i] || new ChallengeTask();
          const selectedTask = selectedTasks.length > i ? selectedTasks[i] : new ChallengeTask();
          const taskDiffs = ChallengeTask.diff(currentTask, selectedTask);
          taskDiffs.forEach((diff) => {
            if (diff.property === 'Clues') {
              const currentClues = currentTask.clues || [];
              const selectedClues = selectedTask.clues || [];
              for (let j = 0; j < currentClues.length; j++) {
                const currentClue: Clue = currentClues[j] || new Clue();
                const selectedClue: Clue = selectedClues.length > j ? selectedClues[j] : new Clue();
                const clueDiff = Clue.diff(currentClue, selectedClue);
                clueDiff.forEach((cd) => (cd.property = `Clue ${j + 1} - ${cd.property}`));
                diffs = diffs.concat(clueDiff);
              }
            } else {
              diffs.push(diff);
            }
          });
          tasksDiff.push(diffs);
          checkContainsDiff(diffs);
        }
      }
    }
    setTasksDiffs(tasksDiff);
  };

  const getChallengeCFNTemplateDiff = () => {
    if (rightRevision && leftRevision) {
      const diffs: DiffChange[] = ChallengeProps.diffCfnTemplate(leftRevision.props, rightRevision.props);
      setCfnTemplateDiffs(diffs);
      checkContainsDiff(diffs);
    }
  };

  const getChallengeIAMPolicyDiff = () => {
    if (rightRevision && leftRevision) {
      const diffs: DiffChange[] = ChallengeProps.diffStudentPolicy(leftRevision.props, rightRevision.props);
      setIamPolicyDiffs(diffs);
      checkContainsDiff(diffs);
    }
  };

  const getChallengeWikiDiff = () => {
    if (rightRevision && leftRevision) {
      const diffs: DiffChange[] = ChallengeProps.diffWiki(leftRevision.props, rightRevision.props);
      setWikiDiffs(diffs);
      checkContainsDiff(diffs);
    }
  };

  const checkContainsDiff = (diffs: DiffChange[]) => {
    if (diffs.length > 0) {
      setContainsDiff(true);
    }
  };

  return (
    <SpaceBetween direction="vertical" size="s">
      {!enableDetailView ? (
        <Table
          {...collectionProps}
          pagination={<Pagination {...paginationProps} />}
          resizableColumns
          header={<TableHeader title={t(i18nKeys.challenges.challengeDetails.headings.revision)} />}
          columnDefinitions={COLUMN_DEFINITIONS(handleRevisionSelection)}
          items={items}
        />
      ) : (
        <RevisionHistoryDetails
          navigationElement={
            <Button
              onClick={() => setEnableDetailView(false)}
              variant="link"
              iconName="arrow-left"
            >
              {t(i18nKeys.revisions.revisionDetails.labels.goBack)}
            </Button>
          }
          overviewDetailDiffs={overviewDetailDiffs}
          settingsDiffs={settingsDiffs}
          learningOutcomeDiffs={learningOutcomeDiffs}
          tasksDiffs={tasksDiffs}
          cfnTemplateDiffs={cfnTemplateDiffs}
          iamPolicyDiffs={iamPolicyDiffs}
          wikiDiffs={wikiDiffs}
          leftRevision={leftRevision}
          containsDiff={containsDiff}
          selectedLeftRevision={selectedLeftRevision}
          selectedRightRevision={selectedRightRevision}
          selectRevisionOptions={selectRevisionOptions}
          handleLeftRevisionSelection={handleLeftRevisionSelection}
          handleRightRevisionSelection={handleRightRevisionSelection}
          selectedLeftRevisionData={selectedLeftRevisionData}
          selectedRightRevisionData={selectedRightRevisionData}
        />
      )}
    </SpaceBetween>
  );
};

export default RevisionHistory;
