import { TFunction } from 'i18next';
import { ApiClient } from './ApiClient';
import { Challenge, ChallengeIssue, ChallengeIssueUpdate } from '../types/Challenge';
import { ICreateNewIssue } from '@/src/components/challenges/challengeDetailSections/challengeIssues/NewIssue';
import { i18nKeys } from '../utils/i18n.utils';
import { fromPlainObject } from '../utils/mapper.utils';
import { asList } from '../utils/list.utils';

export class ChallengeIssuesAPI {
  constructor(private apiClient: ApiClient, private t: TFunction) {
    // do nothing
  }

  // Challenge Issue APIs

  public async getChallengeIssuesByChallengeId(
    challengeId: string,
    includeResolved = false,
    minify = false,
    silent = false
  ): Promise<ChallengeIssue[]> {
    return (await this.apiClient.get({
      path: `/admin/challenge-issues/challenge/${challengeId}`,
      params: { includeResolved, minify },
      failMessage: this.t(i18nKeys.errors.requestFailed.challenges.getChallengeIssuesByChallengeId),
      responseMapper: asList((object) => fromPlainObject(object, ChallengeIssue)),
      silent,
    })) as Promise<ChallengeIssue[]>;
  }

  async updateChallengeIssueCommentById(
    id: string,
    commentValue: string,
    minify = false,
    silent = false
  ): Promise<ChallengeIssue> {
    return await this.apiClient.post({
      path: `/admin/challenge-issues/${id}/comments/create`,
      params: { commentValue, minify },
      successMessage: 'Comment added successfully',
      failMessage: 'Failed to add comment',
      silent,
    });
  }

  async getChallengeIssueById(id: string, minify = false, silent = false): Promise<ChallengeIssue> {
    return await this.apiClient.get({
      path: `/admin/challenge-issues/${id}`,
      params: { minify },
      failMessage: 'Failed to load challenge issues',
      silent,
    });
  }
  
  public async createChallengeIssue(body: ICreateNewIssue, silent = false): Promise<void> {
    (await this.apiClient.post({
      path: `/admin/challenge-issues?`,
      body,
      successMessage: 'success',
      failMessage: 'error',
      silent,
    })) as Promise<void>;
  }

  async updateChallengeIssueDetails(body: ChallengeIssueUpdate, silent?: false): Promise<ChallengeIssue> {
    const {
      severity,
      challengeVersion,
      challengeMajorVersion,
      challengeMinorVersion,
      challengeVersionStatus,
      eventName,
      assignee,
      status,
      isPrivate,
    } = body;
    return await this.apiClient.post({
      path: `/admin/challenge-issues/${body.id}/details`,
      params: {
        severity,
        challengeVersion,
        challengeMajorVersion,
        challengeMinorVersion,
        challengeVersionStatus,
        eventName,
        isPrivate: isPrivate || false,
        minify: false,
        status,
        ...(!!assignee ? { assignee } : {}),
      },
      successMessage: 'Updated Challenge Issue Details!',
      failMessage: 'Failed to Update Challenge Issue',
      silent,
    });
  }

  public assignIssueToUser(issueId: string, assignee?: string | null, minify = false): Promise<Challenge> {
    return this.apiClient.post({
      path: `/admin/challenge-issues/${issueId}/assignee`,
      params: {
        ...(!!assignee ? { assignee } : {}),
        minify,
      },
    });
  }

  public updateChallengeDescription(issueId: string, description?: string | null, minify = false): Promise<Challenge> {
    return this.apiClient.post({
      path: `/admin/challenge-issues/${issueId}/description`,
      params: {
        ...(!!description ? { description } : {}),
        minify,
      },
    });
  }

  public updateChallengeIssueStatus(
    issueId: string,
    body: { status: 'RESOLVED' | 'IN_PROGRESS' | 'OPEN'; resolutionComment: string | null },
    minify = false
  ) {
    return this.apiClient.post({
      path: `/admin/challenge-issues/${issueId}/status`,
      params: {
        minify,
      },
      body,
    });
  }
}
