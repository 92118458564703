import React, { useEffect } from 'react';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';
import { NoMatch } from '../common/NoMatch/NoMatch';
import routes, { CHALLENGE_DETAILS_ROUTES } from '../../routes';
import RestrictedRoute from './RestrictedRoute';
import { RoutePath } from '@/src/RoutePath';
import HomePage from '@/src/components/event-templates-catalog/HomePage/HomePage';
import { config } from '@/src/config/app-config';
import AdvancedFiltering from '@/src/components/event-templates-catalog/Catalog/Catalog';
import CatalogDetails from '@/src/components/event-templates-catalog/catalogDetails/CatalogDetails';
import { useFlashbars } from '@/src/store/flashbar.context';
import { JamProfileCheckGuard } from '../game/Account/JamProfileCheckGuard';
import PublicEvents from '../events/publicEvents/PublicEvents';
import FAQ from '../faq/FAQ';
import { useToolPanel } from '@/src/store/tool-panel.context';
import ChallengeSnippets from '../challenges/challengeDetailSections/challengeCFNTemplate/ChallengeSnippets';
import ChallengeResources from '../challenges/challengeDetailSections/challengeCFNTemplate/ChallengeResources';
import SSHAccess from '../challenges/challengeDetailSections/challengeCFNTemplate/SSHAccess';
import ChallengeShowIAMPolicyDetail from '../challenges/challengeDetailSections/challengeIAMPolicy/ShowIAMPolicy';
import { OAuthRedirectPage } from '@/src/pages/oauth2';

/* Main content to show the user if they are authenticated. */
const AllJamRoutes: React.FC = () => {
  const location = useLocation();
  const { clearFlashbars } = useFlashbars();
  const { toggleToolPanel } = useToolPanel();

  useEffect(() => {
    toggleToolPanel(false);
    clearFlashbars();
  }, [location]);

  return (
    <JamProfileCheckGuard>
      <Switch>
        {/* Unauthenticated Routes */}
        <Route exact path={RoutePath.ROOT} component={HomePage} />
        <Route exact path={RoutePath.SIGNOUT}>
          <Redirect to={RoutePath.ROOT} />
        </Route>
        {config.isEventTemplateEnabled} && <Route exact path={RoutePath.CATALOG} component={AdvancedFiltering} />
        {config.isEventTemplateEnabled} && <Route exact path={RoutePath.CATALOG_DETAILS} component={CatalogDetails} />
        {config.isEventTemplateEnabled} && <Route exact path={RoutePath.PUBLIC_EVENTS} component={PublicEvents} />
        <Route exact path={RoutePath.OAUTH2} component={OAuthRedirectPage} />
        <Route exact path={RoutePath.FAQ} component={FAQ} />
        <Route exact path={RoutePath.CHALLENGE_SNIPPETS} component={ChallengeSnippets} />
        <Route exact path={RoutePath.CHALLENGES_RESOURCES} component={ChallengeResources} />
        <Route
          exact
          path={CHALLENGE_DETAILS_ROUTES.ShowIamPolicy.wildcard()}
          component={ChallengeShowIAMPolicyDetail}
        />
        <Route exact path={RoutePath.SSH_ACCESS} component={SSHAccess} />
        <Route exact path={RoutePath.JAM_403}>
          <NoMatch path={RoutePath.JAM_403} />
        </Route>
        <Route exact path={RoutePath.JAM_404}>
          <NoMatch path={RoutePath.JAM_404} />
        </Route>
        <Route exact path={RoutePath.JAM_500}>
          <NoMatch path={RoutePath.JAM_500} />
        </Route>
        {/* Authenticated Routes */}
        {routes.map(({ path, exact, Component, requiredGroups }, key) => {
          return (
            <RestrictedRoute
              exact={exact}
              path={path}
              key={key}
              requiredGroups={requiredGroups}
              component={Component}
            />
          );
        })}
        <Route path="*">
          <Redirect to={{ pathname: RoutePath.JAM_404 }} />
        </Route>
      </Switch>
    </JamProfileCheckGuard>
  );
};

export default AllJamRoutes;
