import {
  Badge,
  Box,
  Button,
  ColumnLayout,
  Container,
  FormField,
  Header,
  Link,
  RadioGroup,
  Select,
  SpaceBetween,
  Textarea,
} from '@amzn/awsui-components-react';
import { useParams } from 'react-router-dom';

import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useApi } from '../../../../store/api.context';
import { Challenge, ChallengeIssue, ChallengeIssueStatus, ChallengeIssueUpdate, ChallengeStatus } from '../../../../types/Challenge';
import { Columns } from '@/src/components/common/Columns';
import { Column } from '@/src/components/common/Column';
import { KeyValue } from '@/src/components/common/KeyValue';
import { Markdown } from '@/src/components/common/Markdown';
import moment from 'moment';
import JamSpinner from '@/src/components/common/JamSpinner';
import { ChallengeIssueSeverity, ChallengeIssueSeverityDescriptions, labelData, inputWidth } from './NewIssue';
import { i18nKeys } from '@/src/utils/i18n.utils';
import MarkDownPreviewEditor from '@/src/components/common/MarkdownPreviewEditor/MarkDownPreviewEditor';
import { safeString } from '@/src/utils/string.utils';
import { TinyEvent } from '@/src/types/Event';
import ResolveChallengeIssueModal from '@/src/components/challenges/challengeDetailSections/challengeIssues/ResolveChallengeIssueModal';
import AssignChallengeIssueModal from '@/src/components/challenges/challengeDetailSections/challengeIssues/AssignChallengeIssueModal';
import { useUser } from '@/src/store/user.context';
import { SelectProps } from '@amzn/awsui-components-react/uxdg';
import { uniq } from 'lodash';
import { preProdLogger } from '@/src/utils/log.utils';
import { EVENT_DETAILS_ROUTES } from '@/src/routes';

export interface IUpdateIssue {
  status?: string;
  eventName: string | null;
  eventId: string | null;
  assignee: string;
  description: string;
  severity: string;
  isPrivate: boolean;
}
interface ChallengeIssuesDetailProps {
  challenge: Challenge;
}
const ChallengeIssuesDetail: React.FC<ChallengeIssuesDetailProps> = ({ challenge }) => {

  const { t } = useTranslation();
  const { challengesApi, challengeIssuesApi } = useApi();
  const { user } = useUser();
  const params: { 0: string; 1: string; 2: string } = useParams();
  const { 1: id } = params;
  const [challengeIssues, setChallengeIssues] = useState<ChallengeIssue | undefined>();
  const [comment, setComment] = useState('');
  const [loading, setLoading] = useState(false);
  const [showAssignIssueModal, showAssignIssueModalSet] = useState(false);
  const [showResolveIssueModal, showResolveIssueModalSet]= useState(false);
  const [editIssueMode, editIssueModeSet] = useState(false);
  const [editCommentMode, editCommentModeSet] = useState(false);
  const [submittingComment, submittingCommentSet] = useState(false);
  const [assignIssueInProgress, assignIssueInProgressSet] = useState(false);
  const issueStatus = useRef<ChallengeIssueStatus | null>();

  const initialData = {
    id,
    challengeVersion: 0,
    challengeVersionStatus: challenge.status || ChallengeStatus.DRAFT,
    challengeId: challenge.challengeId,
    challengeMajorVersion: 0,
    challengeMinorVersion: 0,
    status: ChallengeIssueStatus.OPEN,
    eventName: '',
    assignee: '',
    description: '',
    severity: ChallengeIssueSeverity.LOW,
    isPrivate: false,
  };
  const [formData, setFormData] = useState<ChallengeIssueUpdate>(initialData);
  interface Iselect {
    label: string;
    value: string;
  }
  const [associateEvent, setAssociateEvent] = useState<Iselect[] | []>([]);

  const getSingleIssue = async () => {
    setLoading(true);
    const res = await challengeIssuesApi.getChallengeIssueById(id);
    setChallengeIssues(res);
    const eventRes = await challengesApi.getRecentEventsChallengeUsedIn(
      safeString(challenge.challengeId),
      false,
      false
    );
    if (eventRes && eventRes.length > 0) {
      const newData = eventRes.map((event: TinyEvent) => {
        if (event.name === formData.eventName) res.eventName = event.name;
        return { label: event.title, value: event.name };
      });
      setAssociateEvent(newData ? (newData as unknown as Iselect[]) : []);
    }
    issueStatus.current = res.status;
    setFormData(res);
    setLoading(false);
  };

  useEffect(() => {
    void getSingleIssue();
  }, []);

  const onCommentUpdateSubmit = async () => {
    await challengeIssuesApi.updateChallengeIssueCommentById(id, comment);
    await getSingleIssue();
    setComment(''); // empty the comment box
  };

  const updateEditorData = (text: string) => {
    setFormData({ ...formData, description: text });
  };

  const emptyOption = { value: null, label: t(i18nKeys.general.unassigned) };

  const assigneeOptions = useMemo(() => {
    const entries = uniq([
      ...challenge.props.maintainers,
      challenge.props.owner,
      challengeIssues?.assignee,
      user?.email,
    ])
      .filter(entry => !!entry)
      .map((entry) => ({ value: entry, label: entry }));
    entries.push(emptyOption);
    return entries
  }, [challenge.props.maintainers, challenge.props.owner, challengeIssues?.assignee]);

  const issueUpdateSubmitHandler = useCallback(async () => {
    setLoading(true);
    try {
      const apiOperations = [];
      apiOperations.push(challengeIssuesApi.updateChallengeIssueDetails(formData));
      if (!!issueStatus.current && issueStatus.current !== formData.status) {
        apiOperations.push(challengeIssuesApi.updateChallengeIssueStatus(id, { status: formData.status, resolutionComment: null }));
      }
      await Promise.all(apiOperations);
      editIssueModeSet(false);
      await getSingleIssue(); 
    } catch (err) {
      preProdLogger(err);
      setLoading(false);
    }
  }, [formData, challengeIssuesApi, id]);

  const setIssueInProgressHandler = useCallback(async () => {
    assignIssueInProgressSet(true);
    try {
      await challengeIssuesApi.updateChallengeIssueStatus(id, { status: "IN_PROGRESS", resolutionComment: null });
      assignIssueInProgressSet(false);
      await getSingleIssue();
    } finally {
      assignIssueInProgressSet(false);
    }
  }, [challengeIssuesApi, id]);

  const updateIssueCommentSubmitHandler = useCallback(async () => {
    submittingCommentSet(true);
    try {
      await challengeIssuesApi.updateChallengeDescription(id, formData.description, true);
      submittingCommentSet(false);
      editCommentModeSet(false);
      await getSingleIssue();
    } finally {
      submittingCommentSet(false);
    }
  }, [challengeIssuesApi, id, formData]);

  if (loading) {
    return <JamSpinner />;
  }

  if (editIssueMode) {
    return (
      <SpaceBetween size="xl">
        <Container header={<>
            <Box float="left">
              <Header variant="h3">{t(i18nKeys.general.details)}</Header>
            </Box>
            <Box float="right">
              <SpaceBetween size='xxs' direction='horizontal'>
                <Button
                  onClick={() => editIssueModeSet(false)}
                  data-testid="challenge-issue__edit-issue-details-cancel-btn"
                >
                  {t(i18nKeys.general.cancel)}
                </Button>
                <Button
                  data-testid="challenge-issue__edit-issue-details-submit-btn"
                  onClick={issueUpdateSubmitHandler}
                >
                  {t(i18nKeys.general.save)}
                </Button>
              </SpaceBetween>
            </Box>
          </>}>
          <ColumnLayout borders="none" columns={2}>
            <Column size="s">
              <div style={{ width: inputWidth }}>
                <SpaceBetween size="m">
                  <FormField label={t(i18nKeys.challenges.challengeDetails.titles.status)}>
                    <Select
                      selectedOption={{
                        value: formData?.status,
                        label: formData?.status,
                      }}
                      data-testid="challenge-issue__status-select"
                      onChange={({ detail }) => setFormData({ ...formData, status: detail.selectedOption.value as ChallengeIssueStatus || '' })}
                      options={[
                        {
                          value: 'OPEN',
                          label: t(i18nKeys.facilitator.issues.status.OPEN),
                        },
                        {
                          value: "IN_PROGRESS",
                          label: t(i18nKeys.facilitator.issues.status.IN_PROGRESS),
                        },
                        {
                          value: 'RESOLVED',
                          label: t(i18nKeys.facilitator.issues.status.RESOLVED),
                        },
                      ]}
                    />
                  </FormField>
                  <FormField label={t(i18nKeys.challenges.challengeDetails.table.issue.severityLabel)}>
                    <Select
                      data-testid="challenge-issue__severity-select"
                      selectedOption={{ label: labelData(formData.severity), value: formData.severity }}
                      onChange={({ detail }) =>
                        setFormData({ ...formData, severity: detail.selectedOption.value as ChallengeIssueSeverity || '' })
                      }
                      options={[
                        {
                          value: ChallengeIssueSeverity.CRITICAL,
                          label: ChallengeIssueSeverityDescriptions.CRITICAL,
                        },
                        { value: ChallengeIssueSeverity.HIGH, label: ChallengeIssueSeverityDescriptions.HIGH },
                        { value: ChallengeIssueSeverity.MEDIUM, label: ChallengeIssueSeverityDescriptions.MEDIUM },
                        { value: ChallengeIssueSeverity.LOW, label: ChallengeIssueSeverityDescriptions.LOW },
                      ]}
                    />
                  </FormField>
                  <FormField label={t(i18nKeys.campaigns.labels.groups.associatedEvents)}>
                    <Select
                      data-testid="challenge-issue__associated-event-select"
                      selectedOption={{
                        label: formData?.eventName || '',
                        value: formData?.eventName || '',
                      }}
                      onChange={({ detail }) =>
                        setFormData({ ...formData, eventName: detail.selectedOption.value || '' })
                      }
                      options={associateEvent || []}
                      filteringType="auto"
                    />
                  </FormField>
                  <FormField label="Assigned to">
                    <Select
                      data-testid="challenge-issue__assignee-select"
                      selectedOption={{
                        value: formData.assignee || '',
                        label: formData.assignee || t(i18nKeys.general.unassigned),
                      }}
                      onChange={({ detail }) =>
                        setFormData({ ...formData, assignee: detail.selectedOption.value || '' })
                      }
                      options={assigneeOptions as SelectProps.Options}
                    />
                  </FormField>
                  <FormField label={t(i18nKeys.challenges.issues.privateIssueInputLabel)}>
                    <RadioGroup
                      onChange={({ detail }) =>
                        setFormData({ ...formData, isPrivate: detail.value === 'true' ? true : false })
                      }
                      value={formData.isPrivate === true ? 'true' : 'false'}
                      items={[
                        { value: 'true', label: `${t(i18nKeys.challenges.challengeDetails.labels.private.yes)}` },
                        { value: 'false', label: `${t(i18nKeys.challenges.challengeDetails.labels.private.no)}` },
                      ]}
                    />
                  </FormField>
                </SpaceBetween>
              </div>
            </Column>
            <Column size="s">
              <KeyValue className="secondary-text" label={t(i18nKeys.challenges.issues.challengeVersionLabel)}>
                {`${challengeIssues?.challengeMajorVersion}.${
                  (challengeIssues?.challengeMajorVersion && challengeIssues?.challengeMinorVersion) || 0
                }`}{' '}
                - {t(i18nKeys.general.approved)}
              </KeyValue>
            </Column>
          </ColumnLayout>
        </Container>
      </SpaceBetween>
    );
  }

  return (
    <SpaceBetween size="xl">
      <Container header={
        <>
          <Box float="left">
            <Header variant="h3">{t(i18nKeys.general.details)}</Header>
          </Box>
          <Box float='right'>
            <SpaceBetween size='xxs' direction='horizontal'>
              <Button
                key="challenge-issue__assign-btn"
                data-testid="challenge-issue__assign-btn"
                onClick={() => showAssignIssueModalSet(true)}>{t(i18nKeys.challenges.issues.buttons.assignIssue)}</Button>
              {challengeIssues?.status === "OPEN" && (
                <Button
                  key="challenge-issue__start-working-btn"
                  data-testid="challenge-issue__start-working-btn"
                  loading={assignIssueInProgress}
                  onClick={setIssueInProgressHandler}
                >
                  {t(i18nKeys.challenges.issues.buttons.startWorkingIssue)}
                </Button>
              )}
              {challengeIssues?.status !== "RESOLVED" &&  
                <Button
                  key="challenge-issue__resolve-btn"
                  data-testid="challenge-issue__resolve-btn"
                  onClick={() => showResolveIssueModalSet(true)}
                >
                  {t(i18nKeys.challenges.issues.buttons.resolveIssue)}
                </Button>}
              {challengeIssues?.status === "RESOLVED" &&
                <Button
                  key="challenge-issue__reopen-btn"
                  data-testid="challenge-issue__reopen-btn"
                  onClick={async () => {
                    await challengeIssuesApi.updateChallengeIssueStatus(id, { status: "OPEN", resolutionComment: null});
                    await getSingleIssue();
                  }}
                >{t(i18nKeys.challenges.issues.buttons.reopenIssue)}</Button>}
              {!editIssueMode &&
                <Button
                  key="challenge-issue__edit-btn"
                  variant="link"
                  data-testid="challenge-issue__edit-btn"
                  onClick={() => editIssueModeSet(true)}
                  iconName='edit'
                  iconAlign='right'
                >
                  {t(i18nKeys.general.edit)}
                </Button>
              }
            </SpaceBetween>
          </Box>
        </>
        }>
        <Columns columns={4}>
          <Column size="s">
            <KeyValue className="secondary-text" label={t(i18nKeys.challenges.challengeDetails.titles.status)}>
              {challengeIssues?.status}
            </KeyValue>
            <KeyValue className="secondary-text" label={t(i18nKeys.challenges.issues.assignedToLabel)}>
              {challengeIssues?.assignee}
            </KeyValue>
          </Column>
          <Column size="s">
            <KeyValue className="secondary-text" label={t(i18nKeys.challenges.challengeDetails.table.issue.severityLabel)}>
              <Badge color="red">{challengeIssues?.severity}</Badge>
            </KeyValue>
            <KeyValue className="secondary-text" label={t(i18nKeys.challenges.issues.privateIssueLabel)}>
              {challengeIssues?.isPrivate ? t(i18nKeys.general.private) : t(i18nKeys.general.public)}
            </KeyValue>
          </Column>
          <Column size="s">
            <KeyValue className="secondary-text" label={t(i18nKeys.campaigns.labels.groups.associatedEvents)}>
              {!!challengeIssues?.eventName &&
                <Link href={EVENT_DETAILS_ROUTES.Summary.resolve(challengeIssues?.eventName)} external>
                  {challengeIssues?.eventName}
                </Link>
              }
            </KeyValue>
          </Column>
          <Column size="s">
            <KeyValue className="secondary-text" label={t(i18nKeys.challenges.issues.challengeVersionLabel)}>
              {`${challengeIssues?.challengeMajorVersion}.${
                (challengeIssues?.challengeMajorVersion && challengeIssues?.challengeMinorVersion) || 0
              }`}{' '}
              - {t(i18nKeys.general.approved)}
            </KeyValue>
          </Column>
        </Columns>
      </Container>
      {!editCommentMode &&
        <Container header={
          <>
          <Box float="left">
            <Header variant="h3">{t(i18nKeys.general.description)}</Header>
          </Box>
          <Box float="right">
            <Button
              variant='link'
              iconName='edit'
              iconAlign='right'
              data-testid="challenge-issue__edit-comment-btn"
              onClick={() => editCommentModeSet(true)}
            >
              {t(i18nKeys.general.edit)}
            </Button>
          </Box>
          </>
        }>
          <Markdown content={challengeIssues?.description || ''} />
        </Container>
      }
      {editCommentMode &&
        <Container header={
          <>
          <Box float="left">
            <Header variant="h3">{t(i18nKeys.general.description)}</Header>
          </Box>
          <Box float="right">
            <SpaceBetween size='xs' direction='horizontal'>
              <Button
                onClick={() => editCommentModeSet(false)}
                data-testid="challenge-issue__cancel-comment-update-btn"
              >{t(i18nKeys.general.cancel)}</Button>
              <Button
                loading={submittingComment}
                onClick={updateIssueCommentSubmitHandler}
                data-testid="challenge-issue__submit-comment-btn"
              >{t(i18nKeys.general.save)}</Button>
            </SpaceBetween>
          </Box>
          </>
        }>
          <MarkDownPreviewEditor
            data-testid='challenge-issue__comment-markdown-editor'
            editorContentHeight={150}
            text={formData.description}
            onTextChanged={(e) => updateEditorData(e)}
          />
        </Container>
      }
      <Container header={<Header variant="h3">{t(i18nKeys.challenges.issues.activityFeed)}</Header>}>
        <ColumnLayout borders="horizontal" columns={1}>
          {!!challengeIssues?.openedBy && <Column size="s">
            <KeyValue
              className="secondary-text"
              label={moment(challengeIssues?.createdDate).format('MMM Do YYYY, h:mm:ss A')}
            >
              {t(i18nKeys.challenges.issues.issueOpenedBy, { openedBy: challengeIssues?.openedBy})}
            </KeyValue>
          </Column>}
          {!!challengeIssues?.assignee && <Column size="s">
            <KeyValue
              className="secondary-text"
              label={moment(challengeIssues?.lastUpdatedDate).format('MMM Do YYYY, h:mm:ss A')}>
                {t(i18nKeys.challenges.issues.assignUpdateTo, { assignedTo: challengeIssues?.assignee})}
            </KeyValue>
          </Column>}
          <Column size="s">
            <KeyValue
              className="secondary-text"
              label={moment(challengeIssues?.lastUpdatedDate).format('MMM Do YYYY, h:mm:ss A')}>
              {t(i18nKeys.challenges.issues.assignUpdateTo)} {' '}
              <Badge color={challengeIssues?.status.toLocaleLowerCase() === 'open' ? 'green' : 'blue'}>
                {challengeIssues?.status}
              </Badge>
            </KeyValue>
          </Column>
          {challengeIssues?.comments?.map((item, index) => (
            <Column size="s" key={index}>
              <KeyValue
                className="secondary-text"
                label={`${item?.author}: ${moment(item?.createdAt).format('MMM Do YYYY, h:mm:ss A')}`}>
                  <Markdown content={item?.value || ''} />
              </KeyValue>
            </Column>
          ))}
          <Column size="s">
            <FormField label={t(i18nKeys.challenges.issues.commentFieldLabel)}>
              <SpaceBetween size="s">
                <Textarea
                  onChange={({ detail }) => setComment(detail.value)}
                  value={comment}
                  placeholder={t(i18nKeys.challenges.issues.enterCommentPlaceholder)}
                />
                <Box float="right">
                  <Button id='challenge-issue-view-single-issue' data-testid='challenge-issue-view-single-issue' variant="primary" onClick={onCommentUpdateSubmit} disabled={comment?.length === 0}>
                    {t(i18nKeys.challenges.issues.buttons.submitComment)}
                  </Button>
                </Box>
              </SpaceBetween>
            </FormField>
          </Column>
        </ColumnLayout>
      </Container>
      <ResolveChallengeIssueModal
        visible={showResolveIssueModal}
        issueId={id}
        triggerReload={() => getSingleIssue()}
        onDismiss={() => showResolveIssueModalSet(false)}/>
      <AssignChallengeIssueModal
        visible={showAssignIssueModal}
        currentAssignee={(assigneeOptions.find(a => a.value && a.value === challengeIssues?.assignee) as SelectProps.Option) || emptyOption}
        onDismiss={() => showAssignIssueModalSet(false)}
        triggerReload={() => getSingleIssue()}
        issueId={id} assigneeOptions={assigneeOptions as SelectProps.Options} />
    </SpaceBetween>
  );
};

export default ChallengeIssuesDetail;
