import { Container, Link } from '@amzn/awsui-components-react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { i18nKeys } from '../../utils/i18n.utils';
import { DocumentationLink } from './Documentation';

interface DocumentationCardProps {
  title: string;
  documentationLinks: DocumentationLink[];
  videoLinks?: DocumentationLink[];
}

const DocumentationCard: React.FC<DocumentationCardProps> = ({ title, documentationLinks, videoLinks }) => {
  const { t } = useTranslation();

  return (
    <Container className="documentation-card">
      <h3>{title}</h3>
      <ul>
        <h4>{t(i18nKeys.documentation.headers.documentation)}</h4>
        {documentationLinks.map((documentationLink, i) => {
          return (
            documentationLink.canView && (
              <li key={`doc-link-${i}`} className="mb-8">
                <Link external href={documentationLink.href}>
                  {documentationLink.label}
                </Link>
              </li>
            )
          );
        })}
        {videoLinks && videoLinks.length > 0 && (
          <React.Fragment>
            <h4>{t(i18nKeys.documentation.headers.videos)}</h4>
            {videoLinks.map((videoLink, i) => {
              return (
                <li key={`video-link-${i}`}>
                  <Link href={videoLink.href} external>{videoLink?.label}</Link>
                </li>
              );
            })}
          </React.Fragment>
        )}
      </ul>
    </Container>
  );
};

export default DocumentationCard;
