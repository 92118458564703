import routes from '@/src/routes';
import { matchPath } from 'react-router';
import { Match, replaceParamsInPath } from '@/src/utils/route.utils';

/**
 * Based on the current path, return a list of breadcrumb items, resolving any entity names and replacing
 * any path parameters.
 *
 * @param path
 */
export const getCurrentBreadCrumbs = (path: string) => {
  return routes
    .map((routeItem) => {
      const matchedPath = matchPath(path, {
        path: routeItem.path,
        exact: false,
        strict: false,
      }) as Match;
      return { routeItem, match: matchedPath };
    })
    .filter(({ match }) => match != null)
    .map(({ routeItem, match }) => {
      return {
        text: routeItem.name,
        match,
        href: replaceParamsInPath(match, routeItem.path),
        routeItem,
      };
    });
};
