import { LanguageCodes } from '../../../../constants/shared/language-codes';
import {
  Box,
  Button,
  ColumnLayout,
  Container,
  Header,
  Icon,
  Link,
  Select,
  SpaceBetween,
} from '@amzn/awsui-components-react';
import { OptionDefinition } from '@amzn/awsui-components-react/polaris/internal/components/option/interfaces';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Challenge,
  ChallengeAttributes,
  TranslatedChallengeWrapper,
  dividerStart,
  formattedData,
} from '../../../../types/Challenge';
import { i18nKeys } from '../../../../utils/i18n.utils';
import ChallengeTranslationDetail from './Translation';
import { Column } from '@/src/components/common/Column';
import './index.scss';
import { useEffect, useState } from 'react';
import { useApi } from '../../../../store/api.context';
import JamSpinner from '@/src/components/common/JamSpinner';
import { TranslatedAttribute } from '@/src/types/common';
import { ChallengePropAction, useCreateChallenge } from '../../../../store/create-challenge.context';
interface ChallengeTranslationDetailsProps {
  challenge: Challenge;
}

const ChallengeTranslationDetails: React.FC<ChallengeTranslationDetailsProps> = ({ challenge }) => {
  const { t } = useTranslation();
  const hiddenFileInput = React.createRef<HTMLInputElement>();
  const { editMode, toggleChallengeEditMode, handleUpdateChallengeProp } = useCreateChallenge();
  const [langCode, setLangCode] = useState('');
  const { challengesApi } = useApi();
  const [translatedChallenge, setTranslatedChallenge] = useState<TranslatedChallengeWrapper>();
  const [loading, setLoading] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState<OptionDefinition>({
    label: 'Select language',
    value: '',
  });
  const [formData, setFormData] = useState<TranslatedAttribute[]>([]);

  const getData = async (languageCode: string) => {
    setLoading(true);
    const res = await challengesApi.getTranslatedChallenge(
      challenge.id ? challenge.id : 'jwsuh-test-spl-13',
      languageCode,
      false
    );
    setTranslatedChallenge(res);
    setLoading(false);
  };

  const formUpdate = ({ attributeName, originalText, translatedText }: TranslatedAttribute) => {
    if (!editMode) toggleChallengeEditMode(null); // enable edit mode only once
    let tempData = [...formData];
    if (tempData.length > 0) {
      const found = tempData.some((obj, index) => {
        if (obj.attributeName === attributeName) {
          tempData[index] = { attributeName, originalText, translatedText };
          return true;
        }
      });
      if (!found) {
        tempData = [...tempData, { attributeName, originalText, translatedText }];
      }
    } else {
      tempData = [...tempData, { attributeName, originalText, translatedText }];
    }
    setFormData(tempData);
  };

  const withoutEnglish: { label: string; value: string }[] = LanguageCodes.list
    .filter((i) => i.value !== LanguageCodes.map.ENGLISH)
    .map((languageCode) => {
      return {
        label: languageCode.label,
        value: languageCode.value,
      };
    });
  const onLanguageChange = (language: OptionDefinition) => {
    setLangCode(language.value || '');
    if (!editMode) toggleChallengeEditMode(null); // enable edit mode only once
    void getData(language.value || '');
    setSelectedLanguage(language);
  };

  useEffect(() => {
    handleUpdateChallengeProp(ChallengePropAction.TRANSLATION, {
      id: challenge.challengeId || '',
      languageCode: langCode,
      translatedAttributes: formData,
      latest: false,
    });
  }, [formData]);

  const clickAnchorWithAttributes = (attributes: { [key: string]: string }): void => {
    const downloadAnchorNode = document.createElement('a');

    for (const [attrName, attrValue] of Object.entries(attributes)) {
      downloadAnchorNode.setAttribute(attrName, attrValue);
    }

    document.body.appendChild(downloadAnchorNode);

    downloadAnchorNode.click();
    downloadAnchorNode.remove();
  };

  const downloadAs = (content: string | number | boolean, filename: string, mimetype: string) => {
    clickAnchorWithAttributes({
      href: `data:${mimetype};charset=utf-8,${encodeURIComponent(content)}`,
      download: filename,
    });
  };

  const getContent = () => {
    let content = '';
    content += dividerStart(`VERSION: ${challenge.majorVersion || 0}.${challenge.minorVersion || 0}`);
    content += formattedData(ChallengeAttributes.TITLE, translatedChallenge?.original?.props.title);
    content += formattedData(ChallengeAttributes.CATEGORY, translatedChallenge?.original?.props.category);
    content += formattedData(ChallengeAttributes.DESCRIPTION, translatedChallenge?.original?.props.description);

    (challenge.props.tasks ?? []).forEach((task, taskIndex) => {
      content += formattedData(
        ChallengeAttributes.TASK,
        formattedData(ChallengeAttributes.TASK_TITLE, `${task.title} + ${taskIndex}`) +
          formattedData(ChallengeAttributes.TASK_TITLE, `${task.content} + ${taskIndex}`)
      );
      task.clues.forEach((clue, clueIndex) => {
        content += formattedData(
          ChallengeAttributes.CLUE,
          formattedData(ChallengeAttributes.CLUE_TITLE, `${clue.title} + ${clueIndex}`) +
            formattedData(ChallengeAttributes.CLUE_DESCRIPTION, `${clue.description} + ${clueIndex}`)
        );
      });
    });

    if (challenge.props.learningOutcome) {
      content += formattedData(
        ChallengeAttributes.LEARNING_OUTCOME,
        formattedData(ChallengeAttributes.LEARNING_OUTCOME_SUMMARY, challenge.props.learningOutcome.summary) +
          formattedData(ChallengeAttributes.LEARNING_OUTCOME_CONTENT, challenge?.props?.learningOutcome?.content)
      );
    }

    return content;
  };

  const toVersionStr = (item: Challenge) => {
    return `${item.majorVersion}.${item.minorVersion}`;
  };

  const onClickExport = () => {
    downloadAs(getContent(), `translation-${challenge.challengeId}-${toVersionStr(challenge)}-en.md`, 'text/html');
  };

  if (loading) {
    return <JamSpinner />;
  }
  return (
    <div className="challenge-translation">
      <SpaceBetween direction="vertical" size="m">
        {!translatedChallenge ? (
          <Container header={<Header variant="h2">{t(i18nKeys.challenges.subNavigation.translation)}</Header>}>
            <SpaceBetween size="xs">
              <strong>{t(i18nKeys.challenges.challengeDetails.titles.translation)}</strong>
              <div style={{ width: '300px' }}>
                <Select
                  selectedOption={selectedLanguage}
                  onChange={({ detail }) => onLanguageChange(detail.selectedOption)}
                  options={withoutEnglish}
                  filteringType="auto"
                />
              </div>
            </SpaceBetween>
          </Container>
        ) : (
          <>
            <Container header={<Header variant="h2">{t(i18nKeys.challenges.subNavigation.translation)}</Header>}>
              <p
                style={{
                  textAlign: 'center',
                  borderRadius: '4px',
                  background: 'var(--grey-200, #E9EBED)',
                  padding: '14px',
                }}>
                <strong>{`${t(i18nKeys.challenges.challengeDetails.titles.translatingVersion)} ${
                  challenge.majorVersion || 0
                }.${challenge.minorVersion || 0}`}</strong>
              </p>
              <strong>{t(i18nKeys.challenges.challengeDetails.titles.translation)}</strong>
              <ColumnLayout columns={2}>
                <Column size="s">
                  <SpaceBetween size="xs">
                    <div style={{ width: '300px' }}>
                      <Select
                        selectedOption={selectedLanguage}
                        onChange={({ detail }) => onLanguageChange(detail.selectedOption)}
                        options={withoutEnglish}
                        filteringType="auto"
                      />
                    </div>
                  </SpaceBetween>
                </Column>
                <Column size="s">
                  <Box float="right">
                    <SpaceBetween direction="horizontal" size="s">
                      <Button
                        id="challenge-translation-export"
                        data-testid="challenge-translation-export"
                        onClick={() => void onClickExport()}>
                        <Icon name="upload" /> {t(i18nKeys.challenges.challengeDetails.buttons.download)}
                      </Button>
                      <Button
                        id="challenge-translation-retranslate"
                        data-testid="challenge-translation-retranslate"
                        onClick={() => void getData(langCode)}>
                        <Icon name="refresh" /> {t(i18nKeys.challenges.challengeDetails.buttons.retranslate)}
                      </Button>
                    </SpaceBetween>
                  </Box>
                </Column>
              </ColumnLayout>
              <br />
              <div className="resources-dropzone">
                <Box padding={'l'} textAlign="center">
                  <Button
                    id="challenge-translation-upload"
                    data-testid="challenge-translation-upload"
                    iconName="upload"
                    variant="link"
                    formAction="none"
                    onClick={() => {
                      // @ts-expect-error something
                      hiddenFileInput.current.value = null; // allow for re-choosing the same file name
                      // @ts-expect-error something
                      hiddenFileInput.current.click();
                    }}
                  />

                  <input ref={hiddenFileInput} id="chooseFileInput" type="file" hidden multiple accept="any" />
                  <p>
                    {t(i18nKeys.challenges.subSections.assetsResources.dropFiles)}{' '}
                    <Link
                      onFollow={() => {
                        // @ts-expect-error something
                        hiddenFileInput.current.value = null; // allow for re-choosing the same file name
                        // @ts-expect-error something
                        hiddenFileInput.current.click();
                      }}>
                      {t(i18nKeys.challenges.subSections.assetsResources.browseFiles)}
                    </Link>
                  </p>
                </Box>
              </div>
            </Container>
            <ChallengeTranslationDetail
              challenge={challenge}
              attributeName="title"
              title={{ label: 'Title', value: translatedChallenge?.translated?.props.title || '' }}
              originalTitle={{ label: 'Title', value: translatedChallenge?.original?.props.title || '' }}
              formUpdate={formUpdate}
            />
            <ChallengeTranslationDetail
              attributeName="category"
              challenge={challenge}
              title={{ label: 'Category', value: translatedChallenge?.translated?.props.category || '' }}
              originalTitle={{ label: 'Category', value: translatedChallenge?.original?.props.category || '' }}
              formUpdate={formUpdate}
            />
            <ChallengeTranslationDetail
              attributeName="description"
              challenge={challenge}
              heading="Description"
              originalDescription={{
                label: 'Description',
                value: translatedChallenge?.original?.props.description || '',
              }}
              description={{ label: 'Description', value: translatedChallenge?.translated?.props.description || '' }}
              editor
              formUpdate={formUpdate}
            />
            {translatedChallenge?.translated?.props?.tasks &&
              translatedChallenge?.translated?.props?.tasks.map((task, index) => (
                <div key={index}>
                  <ChallengeTranslationDetail
                    attributeName={`task.${task.id}`}
                    challenge={challenge}
                    title={{ label: 'Task', value: task.title || '' }}
                    originalTitle={{
                      label: 'Title',
                      value: translatedChallenge?.original?.props?.tasks[index].title || '',
                    }}
                    description={{ label: 'Content', value: task.content || '' }}
                    originalDescription={{
                      label: 'Content',
                      value: translatedChallenge?.original?.props?.tasks[index].content || '',
                    }}
                    formUpdate={formUpdate}
                    editor
                  />
                </div>
              ))}
          </>
        )}
      </SpaceBetween>
    </div>
  );
};

export default ChallengeTranslationDetails;
