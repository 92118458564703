import { REDIRECT_AFTER_PROFILE_CREATE } from '@/src/constants/common';
import { COUNTRIES } from '@/src/constants/countries';
import { useDebounce } from '@/src/hooks/useDebounce';
import { useUser } from '@/src/store/user.context';
import { CreateJamProfileFields } from '@/src/types/CreateJamProfile';
import { createJamProfileValidator } from '@/src/utils/create-jam-profile.validation.utils';
import SessionStorage from '@/src/utils/session-storage';
import { i18nKeys } from '@/src/utils/i18n.utils';
import { localLogger } from '@/src/utils/log.utils';
import { OptionDefinition } from '@amzn/awsui-components-react/polaris/internal/components/option/interfaces';
import { delay } from '@/src/utils/delay';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Button,
  Checkbox,
  Container,
  ContentLayout,
  FormField,
  Header,
  Input,
  Link,
  Select,
  SpaceBetween,
} from '@amzn/awsui-components-react';
import { learnerTermsAndConditionsLink, privacyNoticeLink } from '../../Profile/JamProfileCreate/jam-profile.config';
import { LANGUAGE_SELECT_OPTIONS } from '@/src/utils/locale.utils';
import i18n from '@/src/i18n';
import JamSpinner from '../../common/JamSpinner';
import { useApi } from '@/src/store/api.context';
import { useHistory } from 'react-router-dom';
import { customEventTrigger } from '../../analytics/createEventTrigger';

const JamProfileCreate = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { user, onProfileChange } = useUser();
  const { jamProfileAPI } = useApi();
  const [language, setLanguage] = useState<OptionDefinition | null>(null);
  const [email, setEmail] = useState<string>(user?.email || '');
  const [displayName, setDisplayName] = useState<string>('');
  const [country, setCountry] = useState<OptionDefinition | null>(null);
  const displayNameSearch = useDebounce<string>(displayName, 300);
  const countries = COUNTRIES.map((countryEl) => ({ value: countryEl.code, label: t(countryEl.name) }));

  const [emailError, setEmailError] = useState('');
  const [languageError, setLanguageError] = useState('');
  const [displayNameError, setDisplayNameError] = useState('');
  const [displayNameUniqueError, setDisplayNameUniqueError] = useState('');
  const [countryError, setCountryError] = useState('');
  const [loading, setLoading] = useState<boolean>(false);
  const [promotionNewsOptIn, setPromotionNewsOptIn] = useState<boolean>(false);

  const validator = useMemo(
    () =>
      createJamProfileValidator(
        email,
        displayName,
        country?.value,
        language?.value,
        t(i18nKeys.createJamProfile.form.validation.emailError),
        t(i18nKeys.createJamProfile.form.validation.displayNameError),
        t(i18nKeys.createJamProfile.form.validation.countryError),
        t(i18nKeys.createJamProfile.form.validation.languageError),
        new Map<CreateJamProfileFields, (error: string) => void>([
          [CreateJamProfileFields.EMAIL, (error: string) => setEmailError(error)],
          [CreateJamProfileFields.DISPLAY_NAME, (error: string) => setDisplayNameError(error)],
          [CreateJamProfileFields.COUNTRY, (error: string) => setCountryError(error)],
          [CreateJamProfileFields.LANGUAGE, (error: string) => setLanguageError(error)],
        ])
      ),
    [email, country, displayName, language]
  );

  const checkDisplayNameUnique = async () => {
    await delay(300);
    if (displayNameSearch.length <= 3) {
      setDisplayNameUniqueError(t(i18nKeys.createJamProfile.form.validation.displayNameShortError));
      return;
    } else {
      setDisplayNameUniqueError('');
    }
  };

  const onSubmit = async () => {
    customEventTrigger('submit', 'Create User Profile', window.location.href, 'Create User Profile', {});
    const isValid = validator.isValidSection(true);
    if (!isValid && !displayNameUniqueError) {
      localLogger('valid', isValid);
      return;
    }
    localLogger('invalid', isValid);

    const body = {
      email,
      nickname: displayName,
      country: country?.value,
      promotionNewsOptIn,
    };
    void i18n.changeLanguage(language?.value);

    setLoading(true);
    const p1 = jamProfileAPI.updateLanguage(language?.value);
    const p2 = jamProfileAPI.updateProfile(body);
    try {
      await Promise.all([p1, p2]);
      const userProfile = {
        email,
        nickname: displayName,
        country: country?.value,
        promotionNewsOptIn,
      };
      onProfileChange(userProfile);

      const redirectAfterProfileCreate = SessionStorage.get(REDIRECT_AFTER_PROFILE_CREATE) as Location;

      if (redirectAfterProfileCreate) {
        history.replace(redirectAfterProfileCreate);
      } else {
        history.replace('/');
      }
    } catch (error) {
      localLogger('error', error);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (!displayNameSearch) {
      return;
    }
    void checkDisplayNameUnique();
  }, [displayNameSearch]);

  if (loading) {
    return <JamSpinner />;
  }

  return (
    <ContentLayout
      header={<Header description={t(i18nKeys.createJamProfile.caption)}>{t(i18nKeys.createJamProfile.title)}</Header>}>
      <div className="jam-profile-create-container">
        <Container header={<Header variant="h3">{t(i18nKeys.createJamProfile.profileDetails)}</Header>}>
          <form onSubmit={(e) => e.preventDefault()}>
            <SpaceBetween size="l">
              <FormField
                label={`${t(i18nKeys.createJamProfile.form.preferredLanguage)}*`}
                description={`${t(i18nKeys.createJamProfile.form.preferredLanguageDesc)}`}
                i18nStrings={{ errorIconAriaLabel: t(i18nKeys.general.error) }}
                errorText={languageError}>
                <Select
                  id="preferredLanguageSelectBox"
                  placeholder={t(i18nKeys.createJamProfile.form.select)}
                  selectedOption={language}
                  options={LANGUAGE_SELECT_OPTIONS}
                  onChange={({ detail }) => setLanguage(detail.selectedOption)}
                  onBlur={() => validator.isValidField(CreateJamProfileFields.LANGUAGE)}
                />
              </FormField>
              <FormField
                label={`${t(i18nKeys.createJamProfile.form.email)}`}
                i18nStrings={{ errorIconAriaLabel: t(i18nKeys.general.error) }}
                errorText={emailError}>
                <Input
                  value={email}
                  onChange={({ detail }) => setEmail(detail.value)}
                  placeholder={t(i18nKeys.createJamProfile.form.email)}
                  onBlur={() => validator.isValidField(CreateJamProfileFields.EMAIL)}
                  disabled
                />
              </FormField>
              <FormField
                label={`${t(i18nKeys.createJamProfile.form.displayName)}*`}
                description={t(i18nKeys.createJamProfile.form.displayNameDesc)}
                i18nStrings={{ errorIconAriaLabel: t(i18nKeys.general.error) }}
                errorText={displayNameUniqueError || displayNameError}>
                <Input
                  id="displayNameTextBox"
                  value={displayName}
                  onChange={({ detail }) => setDisplayName(detail.value)}
                  placeholder={t(i18nKeys.createJamProfile.form.displayNamePlaceholder)}
                  onBlur={() => validator.isValidField(CreateJamProfileFields.DISPLAY_NAME)}
                />
              </FormField>
              <FormField
                label={`${t(i18nKeys.createJamProfile.form.country)}*`}
                i18nStrings={{ errorIconAriaLabel: t(i18nKeys.general.error) }}
                errorText={countryError}>
                <Select
                  id="countrySelectBox"
                  placeholder={t(i18nKeys.createJamProfile.form.select)}
                  selectedOption={country}
                  onChange={({ detail }) => setCountry(detail.selectedOption)}
                  options={countries}
                  onBlur={() => validator.isValidField(CreateJamProfileFields.COUNTRY)}
                />
              </FormField>
              <FormField>
                <Checkbox checked={promotionNewsOptIn} onChange={() => setPromotionNewsOptIn(!promotionNewsOptIn)}>
                  <Box color="text-label">{t(i18nKeys.createJamProfile.form.subscribeDesc)}</Box>
                </Checkbox>
              </FormField>
            </SpaceBetween>
          </form>
        </Container>
        <div className="final-note">
          <Box variant="p" fontSize="body-s" margin={{ top: 'l', bottom: 'm' }}>
            {t(i18nKeys.createJamProfile.termsDesc)}
            <Link fontSize="inherit" href={learnerTermsAndConditionsLink} target="blank">
              {t(i18nKeys.createJamProfile.termLink)}
            </Link>
            .
            <br />
            {t(i18nKeys.createJamProfile.privacyDesc)}
            <Link fontSize="inherit" href={privacyNoticeLink} target="blank">
              {t(i18nKeys.createJamProfile.privacyLink)}
            </Link>
            .
          </Box>
          <Box float="right">
            <Button variant="primary" id="createJamProfileButton" data-testid="createJamProfileButton" onClick={() => void onSubmit()}>{t(i18nKeys.createJamProfile.form.submitButton)}</Button>
          </Box>
        </div>
      </div>
    </ContentLayout>
  );
};

export default JamProfileCreate;
