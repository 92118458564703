/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-return */
import { Box, Button, ExpandableSection, SpaceBetween } from '@amzn/awsui-components-react';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useChallenges } from '../../../../store/challenge.context';
import { ChallengePropAction, useCreateChallenge } from '../../../../store/create-challenge.context';
import { ChallengeTask, Clue } from '../../../../types/Challenge';
import { i18nKeys } from '../../../../utils/i18n.utils';
import { preProdLogger } from '../../../../utils/log.utils';
import ChallengeClueDetail from './Clue';

interface ChallengeCluesDetailProps {
  taskId: string;
  taskNumber: number;
}

const ChallengeCluesDetail: React.FC<ChallengeCluesDetailProps> = ({ taskId, taskNumber }) => {
  const { t } = useTranslation();
  const { challenge } = useChallenges();
  const { editMode, editedChallenge, handleUpdateChallengeTask } = useCreateChallenge();
  const [task, setTask] = useState<ChallengeTask>();

  useEffect(() => {
    if (!editMode && challenge) {
      setTask(challenge.props.tasks[taskNumber - 1]);
    }
  }, [challenge]);

  useEffect(() => {
    if (editedChallenge) {
      setTask(editedChallenge.props.tasks[taskNumber - 1]);
    }
  }, [editedChallenge]);

  const addClue = () => {
    const currentClues = task?.clues || [];
    const newClue: Clue = Clue.defaultClue();
    newClue.order = currentClues.length + 1;
    currentClues.push(newClue);

    handleUpdateChallengeTask(ChallengePropAction.TASK_CLUES, taskNumber, currentClues);
  };

  const removeClue = (clueNumber: number) => {
    const currentClues = task?.clues || [];
    if (clueNumber <= 1) {
      return;
    }

    const newClues = currentClues
      .filter((clue) => clue.order !== clueNumber)
      .map((clue, index) => {
        clue.order = index + 1;
        return clue;
      });
    preProdLogger('Removed clue', clueNumber, newClues);
    handleUpdateChallengeTask(ChallengePropAction.TASK_CLUES, taskNumber, newClues);
  };

  return (
    <Box margin={{ top: 'l' }}>
      <ExpandableSection
        variant="container"
        headerText={t(i18nKeys.challenges.challengeDetails.headings.clues)}>
        <SpaceBetween size="s" direction="vertical">
          {task && task.clues.length > 0 ? (
            task.clues.map((clue, i) => {
              const id = `${taskId} clue-${i + 1}`;
              return (
                <ChallengeClueDetail
                  key={id}
                  taskNumber={taskNumber}
                  onRemove={removeClue}
                  clue={clue}
                  clueNumber={clue.order || 1}
                />
              );
            })
          ) : (
            <div>{t(i18nKeys.challenges.challengeDetails.text.challengeCluesEmptyState)}</div>
          )}
          {editMode && (
            <Button
              id="challenge-clues-add-clue"
              data-testid="challenge-clues-add-clue"
              onClick={addClue}
              iconName="add-plus">
              {t(i18nKeys.challenges.subSections.tasks.subSections.fields.clues.addClue)}
            </Button>
          )}
        </SpaceBetween>
      </ExpandableSection>
    </Box>
  );
};

export default ChallengeCluesDetail;
