/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { TFunction } from 'i18next';
import { AddErrorFlashbar, AddSuccessFlashbar } from '../store/flashbar.context';
import { Nullable, NullableString } from '../types/common';
import { UserRow } from '../types/UserRow';
import { i18nKeys } from '../utils/i18n.utils';
import { ApiClient } from './ApiClient';

export class TeamAPI {
  constructor(private apiClient: ApiClient, private addSuccessFlashbar: AddSuccessFlashbar, private addErrorFlashbar: AddErrorFlashbar, private t: TFunction) {
    // do nothing
  }

  public async changeTeamAlias(eventName: string, teamName: string, alias: string, silent = false): Promise<any> {
    return this.apiClient.post({
      path: `/admin/events/${eventName}/teams/alias`,
      body: { teamName, alias },
      successMessage: this.t(i18nKeys.success.requestSucceeded.team.changeAlias, {
        alias,
        teamName,
      }),
      failMessage: this.t(i18nKeys.errors.requestFailed.team.changeAlias, { alias, teamName }),
      silent,
    });
  }

  public async disableTeam(eventName: string, teamName: string, silent = false): Promise<any> {
    return this.apiClient.post({
      path: `/admin/events/${eventName}/teams/disable`,
      body: { teamName },
      successMessage: this.t(i18nKeys.success.requestSucceeded.team.disable, { teamName }),
      failMessage: this.t(i18nKeys.errors.requestFailed.team.disableTeam, { teamName }),
      silent,
    });
  }

  public async deleteTeam(eventName: string, teamName: string, silent = false): Promise<any> {
    return this.apiClient.post({
      path: `/admin/events/${eventName}/teams/delete`,
      body: { teamName },
      successMessage: this.t(i18nKeys.success.requestSucceeded.team.delete, { teamName }),
      failMessage: this.t(i18nKeys.errors.requestFailed.team.delete, { teamName }),
      silent,
    });
  }

  public async createTeam(
    eventName: string,
    teamName: string,
    password: NullableString,
    owner: Nullable<UserRow>,
    silent = false
  ): Promise<any> {
    return this.apiClient.post({
      path: `/admin/events/${eventName}/teams/create`,
      body: {
        teamName,
        password,
        owner: owner ? owner.userId : null,
      },
      successMessage: this.t(i18nKeys.success.requestSucceeded.team.create, { teamName }),
      failMessage: this.t(i18nKeys.errors.requestFailed.team.create, { teamName }),
      silent,
    });
  }

  public async bulkCreateTeam(eventName: string, num: number, prefix: string, silent = false): Promise<void> {
    let numCreated = 0;

    for (let x = 0; x < num; x++) {
      const teamName = x > 8 ? `${prefix} ${x + 1}` : `${prefix} 0${x + 1}`;

      await this.apiClient.post({
        path: `/admin/events/${eventName}/teams/create`,
        body: {
          teamName,
          password: null,
          owner: null,
        },
        failMessage: this.t(i18nKeys.errors.requestFailed.team.create, { teamName }),
        silent,
      });

      numCreated++;
    }

    if (numCreated > 0) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      this.addSuccessFlashbar(this.t(i18nKeys.success.requestSucceeded.team.bulkCreate, { numCreated }));
    } else {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      this.addErrorFlashbar(this.t(i18nKeys.errors.requestFailed.team.bulkCreate));
    }
  }
}
