import {
  Alert,
  Button,
  Container,
  DatePicker,
  FormField,
  Grid,
  Header,
  Icon,
  Input,
  Select,
  SpaceBetween,
  TimeInput,
  Toggle,
} from '@amzn/awsui-components-react';
import { OptionDefinition } from '@amzn/awsui-components-react/polaris/internal/components/option/interfaces';
import { kebabCase } from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import { MeridiemOptions } from '../../../../constants/DateTimeConstants';
import { CAMPAIGN_DETAILS_ROUTES } from '../../../../routes';
import { useCampaigns } from '../../../../store/campaigns.context';
import { Campaign, CampaignGroup } from '../../../../types/Campaign';
import {
  getBrowserTimezoneName,
  getTimeForPolarisTimePicker,
  getTimeInBrowserLocalTime,
  getTimeInEventLocalTime,
  getTimezonesWithUTCOffsetAsOptionList,
  getUTCOffset,
  polarisDateTimeToMoment,
} from '../../../../utils/event-time.utils';
import { i18nKeys } from '../../../../utils/i18n.utils';
import { getLanguageCodeSafe } from '../../../../utils/locale.utils';
import { preProdLogger } from '../../../../utils/log.utils';
import { fromPlainObject } from '../../../../utils/mapper.utils';
import { ConfirmModal } from '../../../common/ConfirmModal';
import { HorizontalRule } from '../../../common/HorizontalRule';

interface NewGroupDetailsProps {
  campaign: Campaign;
  editedCampaignGroup?: CampaignGroup;
  handleEditedCampaignChange?: (newCampaignGroup: CampaignGroup) => void;
  editMode?: boolean;
}

const NewGroupDetails: React.FC<NewGroupDetailsProps> = ({
  campaign,
  editedCampaignGroup,
  handleEditedCampaignChange,
  editMode = false,
}) => {
  const timezoneOptions = getTimezonesWithUTCOffsetAsOptionList();
  const defaultTimezone = getBrowserTimezoneName();
  const defaultSelectedTimezone = timezoneOptions.find((e) => e.value === defaultTimezone) || null;
  const { i18n, t } = useTranslation();
  const languageCode: string = getLanguageCodeSafe(i18n.language);
  const [title, setTitle] = useState('');
  const [minTotalParticipants, setMinTotalParticipants] = useState('');
  const [maxTotalParticipants, setMaxTotalParticipants] = useState('');
  const [startDate, setStartDate] = useState('');
  const [startTime, setStartTime] = useState('');
  const [startTimeMeridiem, setStartTimeMeridiem] = useState<OptionDefinition>(MeridiemOptions.AM);
  const [startDateTime, setStartDateTime] = useState('');
  const [endDate, setEndDate] = useState('');
  const [endTime, setEndTime] = useState('');
  const [endTimeMeridiem, setEndTimeMeridiem] = useState<OptionDefinition>(MeridiemOptions.AM);
  const [endDateTime, setEndDateTime] = useState('');
  const [selectedTimezoneOption, setSelectedTimezoneOption] = useState<OptionDefinition | null>(
    defaultSelectedTimezone
  );
  const [confirmOpenRegistrationModalVisible, setConfirmOpenRegistrationModalVisible] = useState(false);
  const [confirmCloseRegistrationModalVisible, setConfirmCloseRegistrationModalVisible] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const { createCampaignGroup } = useCampaigns();
  const history = useHistory();

  const errorMessage = (error: string) => (
    <span className="warning">
      <Icon name="status-warning" /> {error}
    </span>
  );

  const formIsValid = () => {
    return (
      titleIsValid() &&
      startDateTimeIsValid() &&
      endDateTimeIsValid() &&
      endDateTimeIsAfterStartDateTime() &&
      expectedParticipantsValid()
    );
  };

  const titleIsValid = () => {
    return title.length > 0;
  };

  const startDateTimeIsValid = () => {
    return startDateTime.length > 0;
  };

  const endDateTimeIsValid = () => {
    return endDateTime.length > 0;
  };

  const endDateTimeIsAfterStartDateTime = () => {
    return moment(endDateTime) > moment(startDateTime);
  };

  const expectedParticipantsPopulated = () => {
    return minTotalParticipants.length > 0 && maxTotalParticipants.length > 0;
  };

  const expectedParticipantsValid = () => {
    return expectedParticipantsPopulated() && Number(minTotalParticipants) <= Number(maxTotalParticipants);
  };

  const handleCreateCampaignGroup = () => {
    if (formIsValid()) {
      setLoading(true);
      const newCampaignGroup = fromPlainObject(
        {
          title,
          campaignId: campaign.id,
          startDate: startDateTime,
          endDate: endDateTime,
          timezone: selectedTimezoneOption?.value,
          minExpectedParticipants: Number(minTotalParticipants),
          maxExpectedParticipants: Number(maxTotalParticipants),
        },
        CampaignGroup
      ) as CampaignGroup;
      createCampaignGroup(newCampaignGroup)
        .then(() => {
          setLoading(false);
          history.push(CAMPAIGN_DETAILS_ROUTES.Groups.resolve(campaign.id || ''));
        })
        .catch((err) => {
          setLoading(false);
          preProdLogger('Error creating campaign group', err.message);
        });
    }
  };

  /** Watches date values and creates dateTimes when they change */
  useEffect(() => {
    let newStartDateTime = '';
    let newEndDateTime = '';
    if (startDate && startTime && startTimeMeridiem && selectedTimezoneOption) {
      newStartDateTime = polarisDateTimeToMoment(
        startDate,
        `${startTime} ${startTimeMeridiem.value}`,
        selectedTimezoneOption?.value || ''
      ).format();
      setStartDateTime(newStartDateTime);
      handleSetDateTime(newStartDateTime, 'startDate');
    }
    if (endDate && endTime && endTimeMeridiem && selectedTimezoneOption) {
      newEndDateTime = polarisDateTimeToMoment(
        endDate,
        `${endTime} ${endTimeMeridiem.value}`,
        selectedTimezoneOption.value || ''
      ).format();
      setEndDateTime(newEndDateTime);
      handleSetDateTime(newEndDateTime, 'endDate');
    }
  }, [startDate, endDate, startTime, endTime, startTimeMeridiem, endTimeMeridiem, selectedTimezoneOption]);

  const splitUpAndSetDateTimes = (key: 'start' | 'end') => {
    const propertyToCheck = key === 'start' ? 'startDate' : 'endDate';
    const setDate = propertyToCheck === 'startDate' ? setStartDate : setEndDate;
    const setTime = propertyToCheck === 'startDate' ? setStartTime : setEndTime;
    const setMeridiem = propertyToCheck === 'startDate' ? setStartTimeMeridiem : setEndTimeMeridiem;
    if (editedCampaignGroup?.[propertyToCheck]) {
      // Polaris DatePicker component does not properly parse full startDate with time
      // Spliting here fixes default selection for DatePicker
      setDate(editedCampaignGroup?.[propertyToCheck]?.split('T')[0] || '');
      const startTimeFromEvent = getTimeInBrowserLocalTime(editedCampaignGroup?.[propertyToCheck] || '', {
        includeDate: false,
        includeTime: true,
      });
      const dateSelectorTime = getTimeForPolarisTimePicker(editedCampaignGroup?.[propertyToCheck] || '');
      if (startTimeFromEvent && dateSelectorTime) {
        if (startTimeFromEvent.includes('PM')) {
          setMeridiem(MeridiemOptions.PM);
        } else {
          setMeridiem(MeridiemOptions.AM);
        }
        setTime(dateSelectorTime);
      }
    }
  };

  const handleSetDateTime = (dateTimeValue: string, property: 'startDate' | 'endDate') => {
    if (editMode && editedCampaignGroup && handleEditedCampaignChange) {
      editedCampaignGroup[property] = dateTimeValue;
      handleEditedCampaignChange(editedCampaignGroup);
    }
  };

  const handleSetTimezone = (timezone: OptionDefinition) => {
    if (editMode && editedCampaignGroup && handleEditedCampaignChange) {
      editedCampaignGroup.timezone = timezone.value || null;
      handleEditedCampaignChange(editedCampaignGroup);
    }
    setSelectedTimezoneOption(timezone);
  };

  const handleSetTitle = (newTitle: string) => {
    if (editMode && editedCampaignGroup && handleEditedCampaignChange) {
      editedCampaignGroup.title = newTitle;
      handleEditedCampaignChange(editedCampaignGroup);
    }
    setTitle(newTitle);
  };

  const handleSetMinTotalParticipants = (newMin: string) => {
    setMinTotalParticipants(newMin);
    if (editMode && editedCampaignGroup && handleEditedCampaignChange) {
      editedCampaignGroup.minExpectedParticipants = Number(newMin);
      handleEditedCampaignChange(editedCampaignGroup);
    }
  };

  const handleSetMaxTotalParticipants = (newMax: string) => {
    setMaxTotalParticipants(newMax);
    if (editMode && editedCampaignGroup && handleEditedCampaignChange) {
      editedCampaignGroup.maxExpectedParticipants = Number(newMax);
      handleEditedCampaignChange(editedCampaignGroup);
    }
  };

  const handleOpenRegistrationChange = () => {
    if (editMode && editedCampaignGroup && handleEditedCampaignChange) {
      editedCampaignGroup.closedForNewRegistrations = !editedCampaignGroup.closedForNewRegistrations;
      handleEditedCampaignChange(editedCampaignGroup);
      setIsOpen(!editedCampaignGroup?.closedForNewRegistrations && !editedCampaignGroup?.isAfterEnd);
    }
  };

  const verifyRegistrationChange = () => {
    if (editedCampaignGroup?.closedForNewRegistrations) {
      setConfirmOpenRegistrationModalVisible(true);
    } else {
      setConfirmCloseRegistrationModalVisible(true);
    }
  };

  useEffect(() => {
    if (editedCampaignGroup) {
      setTitle(editedCampaignGroup.title || '');
      splitUpAndSetDateTimes('start');
      splitUpAndSetDateTimes('end');
      const newSelectedTimezone = timezoneOptions.find((timezone) => timezone.value === editedCampaignGroup.timezone);
      setSelectedTimezoneOption(newSelectedTimezone || null);
      setMinTotalParticipants(editedCampaignGroup.minExpectedParticipants?.toString() || '');
      setMaxTotalParticipants(editedCampaignGroup.maxExpectedParticipants?.toString() || '');
      setIsOpen(!editedCampaignGroup?.closedForNewRegistrations && !editedCampaignGroup?.isAfterEnd);
    }
  }, [editedCampaignGroup]);

  return (
    <React.Fragment>
      <ConfirmModal
        visible={confirmOpenRegistrationModalVisible}
        title={t(i18nKeys.campaigns.modals.registrationStatus.title)}
        message={t(i18nKeys.campaigns.modals.registrationStatus.open)}
        onCancel={() => setConfirmOpenRegistrationModalVisible(false)}
        onConfirm={() => {
          setConfirmOpenRegistrationModalVisible(false);
          handleOpenRegistrationChange();
        }}
      />
      <ConfirmModal
        visible={confirmCloseRegistrationModalVisible}
        title={t(i18nKeys.campaigns.modals.registrationStatus.title)}
        message={t(i18nKeys.campaigns.modals.registrationStatus.lock)}
        onConfirm={() => {
          setConfirmCloseRegistrationModalVisible(false);
          handleOpenRegistrationChange();
        }}
        onCancel={() => setConfirmCloseRegistrationModalVisible(false)}
      />
      <Header
        variant="h2"
        actions={[
          <React.Fragment key="back-to-groups">
            {!editMode && (
              <Link to={CAMPAIGN_DETAILS_ROUTES.Groups.resolve(campaign?.id || '')}>
                {t(i18nKeys.campaigns.labels.groups.goBackToGroups)}
              </Link>
            )}
          </React.Fragment>,
        ]}>
        {t(i18nKeys.campaigns.buttons.createNewGroup)}
      </Header>
      <HorizontalRule />
      <SpaceBetween direction="vertical" size="l">
        {!editMode && (
          <Alert type="info" visible>
            {t(i18nKeys.campaigns.messages.groups.alert)}
          </Alert>
        )}
        {editMode && (
          <Alert type="warning">{t(i18nKeys.campaigns.messages.groups.endDateCannotBeEarlierThanCurrent)}</Alert>
        )}
        <SpaceBetween direction="vertical" size="l">
          <FormField
            label={t(i18nKeys.campaigns.labels.groups.title)}
            description={`${t(i18nKeys.campaigns.headers.groups.descriptions.title)} ${t(i18nKeys.general.avoidPIIWarning)}`}>
            <SpaceBetween direction="vertical" size="s">
              <div className="slug">
                <strong>{title ? `/${kebabCase(title)}` : '/example-title'}</strong>
              </div>
              <Input value={title} onChange={({ detail }) => handleSetTitle(detail.value)} className="text-input" />
              {!titleIsValid() && errorMessage(t(i18nKeys.campaigns.errors.emptyTitle))}
            </SpaceBetween>
          </FormField>
          <FormField
            label={t(i18nKeys.campaigns.labels.groups.startTime)}
            description={t(i18nKeys.campaigns.headers.groups.descriptions.startTime)}>
            <SpaceBetween direction="vertical" size="s">
              <SpaceBetween direction="horizontal" size="s">
                <DatePicker
                  className="inline"
                  onChange={({ detail }) => setStartDate(detail.value)}
                  value={startDate}
                  openCalendarAriaLabel={(selectedDate) =>
                    `${t(i18nKeys.general.chooseDate)} ${
                      selectedDate ? t(i18nKeys.general.selectedDate, { selectedDate }) : ''
                    }`
                  }
                  placeholder="YYYY/MM/DD"
                  locale={languageCode}
                  previousMonthAriaLabel={t(i18nKeys.events.fields.filters.labels.previousMonth)}
                  nextMonthAriaLabel={t(i18nKeys.events.fields.filters.labels.nextMonth)}
                  todayAriaLabel={t(i18nKeys.events.fields.filters.labels.today)}
                />
                <TimeInput
                  className="time-input inline ml-5"
                  value={startTime}
                  placeholder="hh:mm"
                  format="hh:mm"
                  onChange={({ detail }) => setStartTime(detail.value)}
                />
                <Select
                  className="inline-select"
                  options={[MeridiemOptions.AM, MeridiemOptions.PM]}
                  onChange={({ detail }) => setStartTimeMeridiem(detail.selectedOption)}
                  selectedOption={startTimeMeridiem}
                />
              </SpaceBetween>
              {!startDateTimeIsValid() && errorMessage(t(i18nKeys.campaigns.errors.emptyStartTime))}
            </SpaceBetween>
          </FormField>
          <FormField
            label={t(i18nKeys.campaigns.labels.groups.endTime)}
            description={t(i18nKeys.campaigns.headers.groups.descriptions.endTime)}>
            <SpaceBetween direction="vertical" size="s">
              <SpaceBetween direction="horizontal" size="s">
                <DatePicker
                  className="inline"
                  onChange={({ detail }) => setEndDate(detail.value)}
                  value={endDate}
                  openCalendarAriaLabel={(selectedDate) =>
                    `${t(i18nKeys.general.chooseDate)} ${
                      selectedDate ? t(i18nKeys.general.selectedDate, { selectedDate }) : ''
                    }`
                  }
                  placeholder="YYYY/MM/DD"
                  locale={languageCode}
                  previousMonthAriaLabel={t(i18nKeys.events.fields.filters.labels.previousMonth)}
                  nextMonthAriaLabel={t(i18nKeys.events.fields.filters.labels.nextMonth)}
                  todayAriaLabel={t(i18nKeys.events.fields.filters.labels.today)}
                />
                <TimeInput
                  className="time-input inline ml-5"
                  value={endTime}
                  placeholder="hh:mm"
                  format="hh:mm"
                  onChange={({ detail }) => setEndTime(detail.value)}
                />
                <Select
                  className="inline-select"
                  options={[MeridiemOptions.AM, MeridiemOptions.PM]}
                  onChange={({ detail }) => setEndTimeMeridiem(detail.selectedOption)}
                  selectedOption={endTimeMeridiem}
                />
              </SpaceBetween>
              {!endDateTimeIsValid() && errorMessage(t(i18nKeys.campaigns.errors.emptyEndTime))}
              {endDateTimeIsValid() &&
                startDateTimeIsValid() &&
                !endDateTimeIsAfterStartDateTime() &&
                errorMessage(t(i18nKeys.campaigns.errors.startTimeMustBeBeforeEndTime))}
            </SpaceBetween>
          </FormField>
          <FormField
            label={t(i18nKeys.campaigns.labels.groups.timezone)}
            description={t(i18nKeys.campaigns.headers.groups.descriptions.timezone)}>
            <SpaceBetween direction="vertical" size="s">
              <Select
                className="long-select-input"
                selectedOption={selectedTimezoneOption}
                options={timezoneOptions}
                onChange={({ detail }) => handleSetTimezone(detail.selectedOption)}
              />
            </SpaceBetween>
          </FormField>
          <FormField
            label={t(i18nKeys.campaigns.labels.groups.numberOfExpectedParticipants)}
            description={t(i18nKeys.campaigns.headers.groups.descriptions.numberOfExpectedParticipants)}>
            <SpaceBetween direction="vertical" size="s">
              <div>
                {t(i18nKeys.campaigns.labels.groups.numberOfParticipantsMessage.expectingAtLeast)}
                <Input
                  type="number"
                  className="numeric-input inline"
                  value={minTotalParticipants}
                  onChange={({ detail }) => handleSetMinTotalParticipants(detail.value)}
                />
                {t(i18nKeys.campaigns.labels.groups.numberOfParticipantsMessage.butNoMoreThan)}
                <Input
                  type="number"
                  className="numeric-input inline"
                  value={maxTotalParticipants}
                  onChange={({ detail }) => handleSetMaxTotalParticipants(detail.value)}
                />
                {t(i18nKeys.campaigns.labels.groups.numberOfParticipantsMessage.totalParticipants)}
              </div>
              {!expectedParticipantsPopulated() &&
                errorMessage(t(i18nKeys.campaigns.errors.emptyNumberOfExpectedParticipants))}
              {(minTotalParticipants.length > 0 || maxTotalParticipants.length > 0) &&
                !expectedParticipantsValid() &&
                errorMessage(t(i18nKeys.campaigns.errors.minNumberOfParticipantsMustBeLessThanMax))}
            </SpaceBetween>
          </FormField>
          {editMode && (
            <FormField label={t(i18nKeys.campaigns.labels.groups.registrationStatus)}>
              <Toggle
                checked={isOpen}
                disabled={editedCampaignGroup?.isAfterEnd}
                onChange={() => verifyRegistrationChange()}>
                {editedCampaignGroup?.closedForNewRegistrations || editedCampaignGroup?.isAfterEnd
                  ? t(i18nKeys.general.locked)
                  : t(i18nKeys.general.open)}
              </Toggle>
            </FormField>
          )}
          {startDateTimeIsValid() && endDateTimeIsValid() && endDateTimeIsAfterStartDateTime() && !editMode && (
            <React.Fragment>
              <Header variant="h3">{t(i18nKeys.campaigns.headers.groups.review)}</Header>
              <Container>
                <div className="container-table-header">
                  <Grid gridDefinition={[{ colspan: 2 }, { colspan: 4 }, { colspan: 4 }]}>
                    <div className="table-divider" />
                    <div>
                      <strong>{t(i18nKeys.events.eventDetails.labels.eventLocalTime)}</strong>
                    </div>
                    <div className="table-divider">
                      <strong>{t(i18nKeys.events.eventDetails.labels.yourLocalTime)}</strong>
                    </div>
                  </Grid>
                </div>
                <div style={{ paddingTop: '10px', marginBottom: '-20px' }}>
                  <Grid gridDefinition={[{ colspan: 2 }, { colspan: 4 }, { colspan: 4 }]}>
                    <div style={{ lineHeight: '40px' }}>{t(i18nKeys.events.eventDetails.labels.startTime)}</div>
                    <div>
                      <strong>
                        {getTimeInEventLocalTime(startDateTime, selectedTimezoneOption?.value || null, {
                          includeDate: true,
                          includeTime: true,
                        })}
                      </strong>
                      <div>
                        <strong>{`${selectedTimezoneOption?.value} ${getUTCOffset(
                          startDateTime,
                          selectedTimezoneOption?.value
                        )}`}</strong>
                      </div>
                    </div>
                    <div>
                      <strong>
                        {getTimeInBrowserLocalTime(startDateTime, {
                          includeDate: true,
                          includeTime: true,
                        })}
                      </strong>
                      <div>
                        <strong>{`${defaultTimezone} ${getUTCOffset(startDateTime, defaultTimezone)}`}</strong>
                      </div>
                    </div>
                  </Grid>
                </div>
                <div className="grey-section-divider-top">
                  <Grid gridDefinition={[{ colspan: 2 }, { colspan: 4 }, { colspan: 4 }]}>
                    <div style={{ lineHeight: '40px' }}>{t(i18nKeys.events.eventDetails.labels.endTime)}</div>
                    <div>
                      <strong>
                        {getTimeInEventLocalTime(endDateTime, selectedTimezoneOption?.value || null, {
                          includeDate: true,
                          includeTime: true,
                        })}
                      </strong>
                      <div>
                        <strong>{`${selectedTimezoneOption?.value} ${getUTCOffset(
                          endDateTime,
                          selectedTimezoneOption?.value
                        )}`}</strong>
                      </div>
                    </div>
                    <div>
                      <strong>
                        {getTimeInBrowserLocalTime(endDateTime, {
                          includeDate: true,
                          includeTime: true,
                        })}
                      </strong>
                      <div>
                        <strong>{`${defaultTimezone} ${getUTCOffset(endDateTime, defaultTimezone)}`}</strong>
                      </div>
                    </div>
                  </Grid>
                </div>
              </Container>
            </React.Fragment>
          )}
          {!editMode && (
            <Button
              loading={loading}
              variant="primary"
              disabled={!formIsValid()}
              className="button-right"
              onClick={() => handleCreateCampaignGroup()}>
              {t(i18nKeys.campaigns.buttons.createGroup)}
            </Button>
          )}
        </SpaceBetween>
      </SpaceBetween>
    </React.Fragment>
  );
};
export default NewGroupDetails;
