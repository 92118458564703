import { useLoading } from '@/src/hooks/store/useLoading';
import { useApi } from '@/src/store/api.context';
import { LabProviderAuditRecord } from '@/src/types/LabModels';
import { i18nKeys } from '@/src/utils/i18n.utils';
import {
  Badge,
  BadgeProps,
  Box,
  Button,
  FormField,
  Header,
  Icon,
  Input,
  Modal,
  SpaceBetween,
  Table,
  Toggle,
} from '@amzn/awsui-components-react';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface AuditTrailProps {
  auditFor: string | undefined;
  onClose: () => void;
  eventName: string;
}

const AuditTrail: React.FC<AuditTrailProps> = ({ auditFor, onClose, eventName }) => {
  const { t } = useTranslation();
  const [auditRecords, setAuditRecords] = useState<LabProviderAuditRecord[]>([]);
  const [query, setQuery] = useState('');
  const [duration, setDuration] = useState('0');
  const [errorsOnly, setErrorsOnly] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState<LabProviderAuditRecord | undefined>();
  const { eventsApi } = useApi();
  const { setLoading } = useLoading();
  const rowText = useMemo(
    () => auditRecords.map((auditRecord) => JSON.stringify(auditRecord).toLowerCase()),
    [auditRecords]
  );

  const getDuration = (auditRecord: LabProviderAuditRecord): number => {
    if (!auditRecord.lastUpdated || !auditRecord.time) return 0;
    return auditRecord.lastUpdated - auditRecord.time;
  };

  const filteredRecords = useMemo(() => {
    let records = [...auditRecords];
    if (errorsOnly) {
      records = records.filter(({ successful }) => !successful);
    }
    if (duration) {
      records = records.filter((record) => getDuration(record) >= parseInt(duration, 10));
    }
    if (query.trim()) {
      const term = query.toLowerCase();

      records = records.filter((auditRecord: LabProviderAuditRecord, index: number) => {
        return rowText[index].indexOf(term) > -1;
      });
    }
    return records;
  }, [errorsOnly, query, duration, auditRecords]);

  const getEventLabAuditTrail = async () => {
    const records: LabProviderAuditRecord[] = await eventsApi.getEventLabAuditTrail(eventName);
    setAuditRecords((records || []).reverse());
    setLoading(false);
  };
  const getChallengeLabAuditTrail = async () => {
    const records: LabProviderAuditRecord[] = await eventsApi.getChallengeLabAuditTrail(eventName, auditFor as string);
    setAuditRecords((records || []).reverse());
    setLoading(false);
  };

  const truncate = (str: string) => {
    if (str && str.length > 50) {
      return str.substr(0, 50) + '...';
    }
    return str;
  };

  useEffect(() => {
    setAuditRecords([]);
    if (!auditFor) {
      return;
    }
    setLoading(true);
    if (auditFor === 'ALL') {
      void getEventLabAuditTrail();
    } else {
      void getChallengeLabAuditTrail();
    }
  }, [auditFor]);

  if (!auditFor) {
    return null;
  }

  return (
    <Modal
      size="max"
      onDismiss={onClose}
      visible
      header={<Header>{t(i18nKeys.eventLabs.auditTrail.labProviderAuditTrail)}</Header>}
      footer={
        <Box float="right">
          <Button onClick={onClose}>{t(i18nKeys.general.close)}</Button>
        </Box>
      }
    >
      <Box margin={{ top: 'l' }}>
        {selectedRecord ? (
          <Box>
            <Button variant="primary" iconName="angle-left" onClick={() => setSelectedRecord(undefined)}>
              {t(i18nKeys.eventLabs.auditTrail.backToList)}
            </Button>
            <div style={{ wordWrap: 'break-word', wordBreak: 'break-all', whiteSpace: 'pre-wrap' }}>
              {JSON.stringify(selectedRecord, null, 4)}
            </div>
          </Box>
        ) : (
          <Table
            filter={
              <SpaceBetween direction="horizontal" size="m" alignItems="center">
                <FormField label={t(i18nKeys.eventLabs.auditTrail.containsText)}>
                  <Input value={query} onChange={({ detail }) => setQuery(detail.value)} />
                </FormField>
                <FormField label={t(i18nKeys.eventLabs.auditTrail.duration)}>
                  <Input
                    value={duration}
                    onChange={({ detail }) => setDuration(detail.value)}
                    type="number"
                    step={100}
                  />
                </FormField>
                <Toggle checked={errorsOnly} onChange={({ detail }) => setErrorsOnly(detail.checked)}>
                  {t(i18nKeys.eventLabs.auditTrail.showOnlyErrors)}
                </Toggle>
              </SpaceBetween>
            }
            items={filteredRecords}
            variant="embedded"
            columnDefinitions={[
              {
                id: 'challengeId',
                cell: ({ challengeId }) => challengeId,
                header: t(i18nKeys.eventLabs.auditTrail.table.headers.challengeId),
              },
              {
                id: 'action',
                cell: ({ action }) => action,
                header: t(i18nKeys.eventLabs.auditTrail.table.headers.action),
              },
              {
                id: 'timeCompleted',
                cell: ({ lastUpdated }) => (
                  <Box>
                    <Box>{moment(lastUpdated).format('MMM D HH:mm:ss.SSS')}</Box>
                    <Box color="text-body-secondary">{moment(lastUpdated).fromNow()}</Box>
                  </Box>
                ),
                header: t(i18nKeys.eventLabs.auditTrail.table.headers.timeCompleted),
              },
              {
                id: 'duration',
                cell: ({ lastUpdated, time }) => {
                  const diff = !lastUpdated || !time ? 0 : lastUpdated - time;
                  let color: BadgeProps['color'] = 'red';
                  if (diff < 200) color = 'green';
                  else if (diff >= 200 && diff < 1000) color = 'blue';
                  return <Badge color={color}>{diff}</Badge>;
                },
                header: t(i18nKeys.eventLabs.auditTrail.table.headers.duration),
              },
              {
                id: 'responseCode',
                cell: (record) => {
                  const { responseCode, successful, errorMessage, debugMessage } = record;
                  return (
                    <Box>
                      <Badge color={successful ? 'green' : 'red'}>{responseCode}</Badge>
                      <Box>
                        <Button variant="inline-link" onClick={() => setSelectedRecord(record)}>
                          {t(i18nKeys.eventLabs.auditTrail.table.action)}
                        </Button>
                      </Box>
                      {!!errorMessage && (
                        <Box color="text-status-error">
                          <Icon name="status-warning" />
                          {truncate(errorMessage)}
                        </Box>
                      )}
                      {!!debugMessage && (
                        <Box color="text-status-warning">
                          <Icon name="status-warning" />
                          {truncate(debugMessage)}
                        </Box>
                      )}
                    </Box>
                  );
                },
                header: t(i18nKeys.eventLabs.auditTrail.table.headers.responseCode),
              },
            ]}
          />
        )}
      </Box>
    </Modal>
  );
};

export default AuditTrail;
