import React from 'react';
import {JamEventStatusForSidebar} from '@/src/types/JamChallengeDetails';
import {Box, Icon} from '@amzn/awsui-components-react';
import {JamEventStatus} from '@/src/types/JamCommon';

interface TaskStatusProps {
  taskCompleted?: boolean;
  taskLocked?: boolean;
  name?: JamEventStatusForSidebar;
  status?: JamEventStatus | undefined;
  challengeStatus?: boolean | undefined;
}

const TaskStatus: React.FC<TaskStatusProps> = ({ taskCompleted, taskLocked, name, status, challengeStatus }) => {
  const className = (name === JamEventStatusForSidebar.LOCKED || taskLocked || !challengeStatus) ? 'status-INCOMPLETE' :
    ((name === JamEventStatusForSidebar.SOLVED || taskCompleted) ? 'status-COMPLETE' : '');
  return <Box variant="span" className={className}>
    {taskCompleted || name === JamEventStatusForSidebar.SOLVED ? (
      <Icon name="status-positive"/>
    ) : taskLocked ||
    name === JamEventStatusForSidebar.LOCKED ||
    status === JamEventStatus.ENDED ||
    !challengeStatus ? (
      <Icon name="lock-private"/>
    ) : (
      ''
    )}
  </Box>
};

export default TaskStatus;
