import S3, { PutObjectRequest } from 'aws-sdk/clients/s3';
import { Credentials } from 'aws-sdk';
import { NullableString } from '../types/common';
import mime from 'mime';

export const uploadFile = async (credentials: Credentials, bucketName: string, file: File, folder: NullableString = null) => {
  const Key = folder ? `${folder}/${file.name}` : file.name;

  const params = {
    Body: file,
    Bucket: bucketName,
    Key,
    ContentType: mime.getType(file.name),
  } as PutObjectRequest;

  await new S3({
    credentials,
    region: 'us-west-2', // TODO: put the region in the environment
  })
    .upload(params)
    .promise();

  return `https://${bucketName}.s3.amazonaws.com/${Key}`;
};
