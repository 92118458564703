import _ from 'lodash';
import React, { useContext, useState } from 'react';
import { GetEventByNameResponse } from '../api/EventsAPI';
import { DetailedEventStatisticsReport } from '../types/DetailedEventStatisticsReport';
import {
  Event,
  EventChallengeFeedback,
  EventConfiguration,
  EventFeedback,
  EventFilterOptions,
  TinyEvent,
} from '../types/Event';
import { GetPoolsResponse, Pool } from '../types/Pools';
import { Team } from '../types/Team';
import { DateRangeFilter } from '../types/common';
import { callIgnoringExceptionsAsync } from '../utils/call-ignoring-exceptions';
import { LoggingService } from '../utils/logging-service.utils';
import { useApi } from './api.context';

export interface UserEmail {
  email: string;
  isVerified: boolean;
}

export type LoadEventsByDateRange = (filter: DateRangeFilter) => void;
export type LoadTinyEvents = (startDate: string, endDate: string) => void;
export type GetEventByName = (eventName: string) => void;
export type LoadEventFeedback = (eventName: string) => void;
export type LoadEventReport = (eventName: string) => void;
export type LoadEventChallengeFeedback = (eventName: string) => void;
export type LoadConfig = () => void;
export type UnlockChallengesForEvent = (eventName: string) => Promise<void>;
export type LockChallengesForEvent = (eventName: string) => Promise<void>;
export type CancelEvent = (eventName: string, comment: string) => Promise<void>;
export type DeleteEvent = (eventName: string) => Promise<void>;
export type AddEventComment = (commentValue: string) => Promise<void>;
export type UpdateEventComment = (commentId: string, commentValue: string) => Promise<void>;
export type DeleteEventComment = (commentId: string) => Promise<void>;
export type LoadEventConfiguration = () => Promise<void>;
export type ReevaluateRequest = (eventName: string) => Promise<void>;
export type LoadPools = () => Promise<void>;
export type FetchUserEmail = (userId: string) => Promise<UserEmail | undefined>;
export type ApproveJamEventRequest = (eventName: string, eventId: string, comment?: string) => Promise<void>;
export type DenyJamEventRequest = (eventName: string, comment?: string) => Promise<void>;
export type CancelJamEventRequest = (eventId: string, eventName: string, comment?: string) => Promise<void>;
export type ApproveJamEventChangeRequest = (eventName: string, comment?: string) => Promise<void>;
export type DenyJamEventChangeRequest = (eventName: string, comment?: string) => Promise<void>;
export type CancelJamEventChangeRequest = (eventName: string, comment?: string) => Promise<void>;
export type ResetEvent = (eventName: string) => Promise<void>;
export type ResetEventTeams = (eventName: string) => Promise<void>;
export type CleanUpEvent = () => void;
export type ToggleInviteParticipantModal = (state: boolean) => void;
export type CachedEmail = { email: string; isVerified: boolean };

export interface EventsContentValue {
  events: Event[] | undefined;
  event: Event | undefined;
  eventFeedback: EventFeedback[] | undefined;
  challengeFeedback: EventChallengeFeedback | undefined;
  tinyEvents: TinyEvent[] | undefined;
  teams: Team[] | undefined;
  pools: Pool[] | undefined;
  emailCache: Record<string, CachedEmail>;
  eventReport: DetailedEventStatisticsReport | undefined;
  isLoadEventsByDateRangeInProgress: boolean;
  loadEventsByDateRange: LoadEventsByDateRange;
  loadTinyEvents: LoadTinyEvents;
  getEventByName: GetEventByName;
  loadEventFeedback: LoadEventFeedback;
  loadEventReport: LoadEventReport;
  loadEventChallengeFeedback: LoadEventChallengeFeedback;
  loadConfig: LoadConfig;
  eventConfig: EventConfiguration | undefined;
  unlockChallengesForEvent: UnlockChallengesForEvent;
  lockChallengesForEvent: LockChallengesForEvent;
  cancelEvent: CancelEvent;
  deleteEvent: DeleteEvent;
  addEventComment: AddEventComment;
  updateEventComment: UpdateEventComment;
  deleteEventComment: DeleteEventComment;
  reevaluateRequest: ReevaluateRequest;
  loadPools: LoadPools;
  fetchUserEmail: FetchUserEmail;
  approveJamEventRequest: ApproveJamEventRequest;
  denyJamEventRequest: DenyJamEventRequest;
  cancelJamEventRequest: CancelJamEventRequest;
  approveJamEventChangeRequest: ApproveJamEventChangeRequest;
  denyJamEventChangeRequest: DenyJamEventChangeRequest;
  cancelJamEventChangeRequest: CancelJamEventChangeRequest;
  resetEvent: ResetEvent;
  resetEventTeams: ResetEventTeams;
  cleanUpEvent: CleanUpEvent;
  inviteParticipantModal: boolean;
  toggleInviteParticipantModal: ToggleInviteParticipantModal;
  pageCount: number;
}

/**
 * This context is intended to be a centralized store for caching events which have been fetched from the server.
 */
const EventsContent = React.createContext<EventsContentValue>({
  events: undefined,
  event: undefined,
  eventFeedback: undefined,
  challengeFeedback: undefined,
  tinyEvents: undefined,
  teams: undefined,
  pools: undefined,
  eventConfig: undefined,
  emailCache: {},
  eventReport: undefined,
  isLoadEventsByDateRangeInProgress: false,
  loadEventsByDateRange: (_filter: DateRangeFilter) => {
    // do nothing
  },
  loadTinyEvents: (_startDate: string, _endDate: string) => {
    // do nothing
  },
  getEventByName: (_eventName: string) => {
    // do nothing
  },
  loadEventFeedback: (_eventName: string) => {
    // do nothing
  },
  loadEventReport: (_eventName: string) => {
    // do nothing
  },
  loadEventChallengeFeedback: (_eventName: string) => {
    // do nothing
  },
  loadConfig: () => {
    // do nothing
  },
  unlockChallengesForEvent: (_eventName: string) =>
    new Promise(() => {
      // do nothing
    }),
  lockChallengesForEvent: (_eventName: string) =>
    new Promise(() => {
      // do nothing
    }),
  cancelEvent: (_eventName: string, _comment: string) =>
    new Promise(() => {
      // do nothing
    }),
  deleteEvent: (_eventName: string) =>
    new Promise(() => {
      // do nothing
    }),
  addEventComment: (_commentValue: string) =>
    new Promise(() => {
      // do nothing
    }),
  updateEventComment: (_commentId: string, _commentValue: string) =>
    new Promise(() => {
      // do nothing
    }),
  deleteEventComment: (_commentId: string) =>
    new Promise(() => {
      // do nothing
    }),
  reevaluateRequest: (_eventName: string) =>
    new Promise(() => {
      // do nothing
    }),
  loadPools: () =>
    new Promise(() => {
      // do nothing
    }),
  fetchUserEmail: (_userId: string) => Promise.resolve(undefined),
  approveJamEventRequest: (_eventName: string, _eventId: string, _comment?: string) =>
    new Promise(() => {
      // do nothing
    }),
  denyJamEventRequest: (_eventName: string, _comment?: string) =>
    new Promise(() => {
      // do nothing
    }),
  cancelJamEventRequest: (_eventId: string, _eventName: string, _comment?: string) =>
    new Promise(() => {
      // do nothing
    }),
  approveJamEventChangeRequest: (_eventName: string, _comment?: string) =>
    new Promise(() => {
      // do nothing
    }),
  denyJamEventChangeRequest: (_eventName: string, _comment?: string) =>
    new Promise(() => {
      // do nothing
    }),
  cancelJamEventChangeRequest: (_eventName: string, _comment?: string) =>
    new Promise(() => {
      // do nothing
    }),
  resetEvent: (_eventName: string) =>
    new Promise(() => {
      // do nothing
    }),
  resetEventTeams: (_eventName: string) =>
    new Promise(() => {
      // do nothing
    }),
  cleanUpEvent: () => {
    // do nothing
  },
  inviteParticipantModal: false,
  toggleInviteParticipantModal: (_state: boolean): void => {
    // do nothing
  },
  pageCount: 1,
});

const EventsProvider: React.FC = ({ children }) => {
  const [events, setEvents] = useState<Event[] | undefined>(undefined);
  const [event, setEvent] = useState<Event | undefined>(undefined);
  const [eventFeedback, setEventFeedback] = useState<EventFeedback[] | undefined>(undefined);
  const [challengeFeedback, setChallengeFeedback] = useState<EventChallengeFeedback | undefined>(undefined);
  const [teams, setTeams] = useState<Team[] | undefined>(undefined);
  const [eventConfig, setEventConfig] = useState<EventConfiguration | undefined>(undefined);
  const [pools, setPools] = useState<Pool[]>([]);
  const [tinyEvents, setTinyEvents] = useState<TinyEvent[] | undefined>(undefined);
  const [emailCache, setEmailCache] = useState<{ [userId: string]: { email: string; isVerified: boolean } }>({});
  const [eventReport, setEventReport] = useState<DetailedEventStatisticsReport | undefined>(undefined);
  const { eventsApi, userApi, reportsApi } = useApi();
  const [isLoadEventsByDateRangeInProgress, setIsLoadEventsByDateRangeInProgress] = useState<boolean>(false);
  const [inviteParticipantModal, setInviteParticipantModal] = useState<boolean>(false);
  const [pageCount, setPageCount] = useState<number>(1);

  const toggleInviteParticipantModal = (state: boolean) => {
    setInviteParticipantModal(state);
  };

  const loadEventsByDateRange = (filter: DateRangeFilter): void => {
    setIsLoadEventsByDateRangeInProgress(true);
    void callIgnoringExceptionsAsync(async () => {
      return await eventsApi.getEventsByDateRange(filter, false, setEvents, setPageCount);
    })
      .then((eventsByDateRange: Event[] | undefined) => {
        setIsLoadEventsByDateRangeInProgress(false);
        if (eventsByDateRange) {
          LoggingService.debug(`loadEventsByDateRange finished; total events loaded: ${eventsByDateRange.length}`);
          setEvents(eventsByDateRange);
        }
      })
      .catch(() => setIsLoadEventsByDateRangeInProgress(false));
  };

  const loadTinyEvents = (startDate: string, endDate: string) => {
    const queryParams: EventFilterOptions = {
      dateRangeStart: startDate,
      dateRangeEnd: endDate ? endDate : undefined,
    };
    void callIgnoringExceptionsAsync(async () => {
      return await eventsApi.getTinyEvents(queryParams);
    }).then((tinyEventsResponse: TinyEvent[] | undefined) => {
      if (tinyEventsResponse) {
        setTinyEvents(tinyEventsResponse);
      }
    });
  };

  const getEventByName = (eventName: string) => {
    void callIgnoringExceptionsAsync(async () => {
      return await eventsApi.getEvent(eventName);
    }).then((eventResponse: GetEventByNameResponse | undefined) => {
      if (eventResponse?.event) {
        if (eventResponse.unassignedParticipants) {
          eventResponse.event.unassignedParticipants = eventResponse.unassignedParticipants;
        }
        setEvent(eventResponse.event);
      }
      if (eventResponse?.teams) {
        setTeams(eventResponse.teams);
      }
    });
  };

  const loadConfig = () => {
    void callIgnoringExceptionsAsync(async () => {
      return await eventsApi.getEventConfig();
    }).then((eventConfigResponse: EventConfiguration | undefined) => {
      if (eventConfigResponse) {
        setEventConfig(eventConfigResponse);
      }
    });
  };

  const loadEventReport = (eventName: string) => {
    void callIgnoringExceptionsAsync(async () => {
      return await reportsApi.getEventReport(eventName);
    }).then((response: DetailedEventStatisticsReport | undefined) => {
      if (response) {
        setEventReport(response);
      }
    });
  };

  const cleanUpEvent = () => {
    setEvent(undefined);
  };

  const loadEventFeedback = (eventName: string) => {
    void callIgnoringExceptionsAsync(async () => {
      return await eventsApi.getEventFeedback(eventName);
    }).then((response: EventFeedback[] | undefined) => {
      if (response) {
        setEventFeedback(response);
      }
    });
  };

  const loadEventChallengeFeedback = (eventName: string) => {
    void callIgnoringExceptionsAsync(async () => {
      return await eventsApi.getEventChallengeFeedback(eventName);
    }).then((response: EventChallengeFeedback | undefined) => {
      if (response) {
        setChallengeFeedback(response);
      }
    });
  };

  const unlockChallengesForEvent = async (eventName: string) => {
    await eventsApi.unlockChallenges(eventName).then(() => {
      getEventByName(eventName);
    });
  };

  const lockChallengesForEvent = async (eventName: string) => {
    await eventsApi.lockChallenges(eventName).then(() => {
      getEventByName(eventName);
    });
  };

  const cancelEvent = async (eventName: string, comment: string) => {
    await eventsApi.cancelEvent(eventName, comment).then(() => {
      getEventByName(eventName);
    });
  };

  const deleteEvent = async (eventName: string) => {
    await eventsApi.deleteEvent(eventName);
  };

  const approveJamEventRequest = async (eventName: string, eventId: string, comment?: string) => {
    await eventsApi.approveJamEventRequest(eventName, eventId, comment || '').then((res: Event) => {
      setEvent(res);
    });
  };

  const denyJamEventRequest = async (eventName: string, comment?: string) => {
    await eventsApi.denyJamEventRequest(eventName, comment || '').then((res: Event) => {
      setEvent(res);
    });
  };

  const cancelJamEventRequest = async (eventId: string, eventName: string, comment?: string) => {
    await eventsApi.cancelJamEventRequest(eventId, eventName, comment || '').then((res: Event) => {
      setEvent(res);
    });
  };

  const approveJamEventChangeRequest = async (eventName: string, comment?: string) => {
    await eventsApi.approveJamEventChangeRequest(eventName, comment || '').then((res: Event) => {
      setEvent(res);
    });
  };

  const denyJamEventChangeRequest = async (eventName: string, comment?: string) => {
    await eventsApi.denyJamEventChangeRequest(eventName, comment || '').then((res: Event) => {
      setEvent(res);
    });
  };

  const cancelJamEventChangeRequest = async (eventName: string, comment?: string) => {
    await eventsApi.cancelJamEventChangeRequest(eventName, comment || '').then((res: Event) => {
      setEvent(res);
    });
  };

  const resetEvent = async (eventName: string) => {
    await eventsApi.resetEvent(eventName).then(() => {
      getEventByName(eventName);
    });
  };

  const resetEventTeams = async (eventName: string) => {
    await eventsApi.resetEventTeams(eventName).then(() => {
      getEventByName(eventName);
    });
  };

  const addEventComment = async (commentValue: string) => {
    if (event) {
      await eventsApi.addComment(event.name, commentValue).then(() => {
        getEventByName(event.name);
      });
    }
  };

  const updateEventComment = async (commentId: string, commentValue: string) => {
    if (event) {
      await eventsApi.updateComment(event.name, commentId, commentValue).then(() => {
        getEventByName(event?.name);
      });
    }
  };

  const deleteEventComment = async (commentId: string) => {
    if (event) {
      await eventsApi.deleteComment(event.name, commentId).then(() => {
        getEventByName(event.name);
      });
    }
  };

  const reevaluateRequest = async (eventName: string) => {
    await eventsApi.reevaluateWithPlan(eventName).then(() => {
      getEventByName(eventName);
    });
  };

  const loadPools = async () => {
    await eventsApi.getPools().then((res: GetPoolsResponse) => {
      if (res.pools) {
        let newPools: Pool[] = res.pools;
        newPools = newPools.filter((pool) => !pool.test); // ignore any preprod/test pools
        const orderByProperties = [
          { property: 'numReservedChallenges', order: 'asc' },
          { property: 'numCurrentChallenges', order: 'asc' },
          { property: 'numAvailable', order: 'desc' },
          { property: 'maxPoolSize', order: 'desc' },
          { property: 'name', order: 'asc' },
        ];

        newPools = _.orderBy(newPools, [
          orderByProperties.map((item) => item.property),
          orderByProperties.map((item) => item.order),
        ]) as Pool[];
        setPools(newPools);
      }
    });
  };

  const fetchUserEmail = async (userId: string) => {
    if (!event) {
      return undefined;
    }

    const response = await userApi.fetchUserEmail(event?.name, userId);
    const currentCachedEmails = emailCache || {};

    if (response) {
      currentCachedEmails[userId] = response;

      setEmailCache(currentCachedEmails);
    }

    return currentCachedEmails[userId] || { email: null, isVerified: false };
  };

  const data: EventsContentValue = {
    events,
    eventFeedback,
    eventReport,
    challengeFeedback,
    tinyEvents,
    isLoadEventsByDateRangeInProgress,
    loadEventsByDateRange,
    loadTinyEvents,
    event,
    teams,
    pools,
    emailCache,
    getEventByName,
    loadEventFeedback,
    loadEventReport,
    loadEventChallengeFeedback,
    loadConfig,
    eventConfig,
    unlockChallengesForEvent,
    lockChallengesForEvent,
    cancelEvent,
    deleteEvent,
    addEventComment,
    updateEventComment,
    deleteEventComment,
    reevaluateRequest,
    loadPools,
    fetchUserEmail,
    approveJamEventRequest,
    denyJamEventRequest,
    cancelJamEventRequest,
    approveJamEventChangeRequest,
    denyJamEventChangeRequest,
    cancelJamEventChangeRequest,
    resetEvent,
    resetEventTeams,
    cleanUpEvent,
    inviteParticipantModal,
    toggleInviteParticipantModal,
    pageCount,
  };

  return <EventsContent.Provider value={data}>{children}</EventsContent.Provider>;
};

const useEvents = () => {
  const context = useContext(EventsContent);
  if (context === undefined) {
    throw new Error('useEvents can only be used inside EventsProvider');
  }
  return context;
};

export { EventsProvider, useEvents };
