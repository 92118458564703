import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, Form, FormField, Grid, Input, RadioGroup, SpaceBetween } from '@amzn/awsui-components-react';
import { i18nKeys } from '@/src/utils/i18n.utils';
import { createTeamValidator } from '@/src/utils/create-team.validation.utils';
import { CreateTeamFields } from '@/src/types/CreateTeam';
import { JamEventTeamGoal } from '@/src/types/JamCommon';
import { JoinOrCreateTeamProps } from '@/src/types/JamJoinTeam';

const CreateTeamForm: React.FC<Omit<JoinOrCreateTeamProps, 'joinTeam'>> = ({ createTeam, saving }) => {
  const { t } = useTranslation();
  const [teamName, setTeamName] = useState('');
  const [teamNameError, setTeamNameError] = useState('');
  const [password, setPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [confirmPasswordError, setConfirmPasswordError] = useState('');
  const [teamGoal, setTeamGoal] = useState<JamEventTeamGoal>();
  const [teamGoalError, setTeamGoalError] = useState('');
  const [privacySettings, setPrivacySettings] = useState('');
  const [privacySettingsError, setPrivacySettingsError] = useState('');

  const validator = useMemo(
    () =>
      createTeamValidator(
        { teamName, password, confirmPassword, teamGoal, privacySettings },
        {
          teamName: t(i18nKeys.joinTeam.form.errors.teamName),
          password: t(i18nKeys.joinTeam.form.errors.password),
          confirmPassword: t(i18nKeys.joinTeam.form.errors.confirmPassword),
          teamGoal: t(i18nKeys.joinTeam.form.errors.teamGoal),
          privacySettings: t(i18nKeys.joinTeam.form.errors.privacySettings),
        },
        new Map<CreateTeamFields, (error: string) => void>([
          [CreateTeamFields.TEAM_NAME, (error: string) => setTeamNameError(error)],
          [CreateTeamFields.TEAM_GOAL, (error: string) => setTeamGoalError(error)],
          [CreateTeamFields.PRIVACY_SETTINGS, (error: string) => setPrivacySettingsError(error)],
          [CreateTeamFields.PASSWORD, (error: string) => setPasswordError(error)],
          [CreateTeamFields.CONFIRM_PASSWORD, (error: string) => setConfirmPasswordError(error)],
        ])
      ),
    [teamName, password, confirmPassword, teamGoal, privacySettings]
  );

  const onSubmit = async () => {
    const isValid = validator.isValidSection(true);
    if (!isValid) {
      return;
    }
    await createTeam(teamName, teamGoal as JamEventTeamGoal, password || null);
  };

  return (
    <form onSubmit={(e) => e.preventDefault()}>
      <Form>
        <SpaceBetween direction="vertical" size="l">
          <Box variant="h3">{t(i18nKeys.joinTeam.createTeam.formHeader)}</Box>
          <FormField
            label={t(i18nKeys.joinTeam.form.commonFields.teamName) + '*'}
            i18nStrings={{ errorIconAriaLabel: t(i18nKeys.general.error) }}
            errorText={teamNameError}>
            <Input id="teamNameTextBox" value={teamName} onChange={({ detail }) => setTeamName(detail.value)} />
          </FormField>
          <FormField
            label={t(i18nKeys.joinTeam.form.commonFields.teamGoal) + '*'}
            i18nStrings={{ errorIconAriaLabel: t(i18nKeys.general.error) }}
            errorText={teamGoalError}>
            <RadioGroup
              onChange={({ detail }) => setTeamGoal(detail.value as JamEventTeamGoal)}
              value={teamGoal as JamEventTeamGoal}
              items={[
                {
                  controlId: 'teamGoalRadio1',
                  value: JamEventTeamGoal.PLAY_TO_WIN,
                  label: t(i18nKeys.joinTeam.form.commonFields.playToWin),
                  description: t(i18nKeys.joinTeam.form.commonFields.playToWinDesc),
                },
                {
                  controlId: 'teamGoalRadio2',
                  value: JamEventTeamGoal.PLAY_TO_LEARN,
                  label: t(i18nKeys.joinTeam.form.commonFields.playToLearn),
                  description: t(i18nKeys.joinTeam.form.commonFields.playToLearnDesc),
                },
              ]}
            />
          </FormField>
          <FormField
            label={t(i18nKeys.joinTeam.createTeam.fields.privacySettings)}
            i18nStrings={{ errorIconAriaLabel: t(i18nKeys.general.error) }}
            errorText={privacySettingsError}>
            <RadioGroup
              onChange={({ detail }) => setPrivacySettings(detail.value)}
              value={privacySettings}
              items={[
                {
                  controlId: 'privacySettingRadio1',
                  value: 'public',
                  label: t(i18nKeys.general.public),
                  description: t(i18nKeys.joinTeam.createTeam.fields.publicDesc),
                },
                {
                  controlId: 'privacySettingRadio2',
                  value: 'private',
                  label: t(i18nKeys.general.private),
                  description: t(i18nKeys.joinTeam.createTeam.fields.privateDesc),
                },
              ]}
            />
          </FormField>
          {privacySettings === 'private' && (
            <>
              <FormField
                label={t(i18nKeys.joinTeam.form.commonFields.password) + '*'}
                description={t(i18nKeys.joinTeam.createTeam.fields.passwordCaption)}
                i18nStrings={{ errorIconAriaLabel: t(i18nKeys.general.error) }}
                errorText={passwordError}>
                <Input
                  id="passwordTextBox"
                  type="password"
                  value={password}
                  onChange={({ detail }) => setPassword(detail.value)}
                />
              </FormField>
              <FormField
                label={t(i18nKeys.joinTeam.createTeam.fields.confirmPassword) + '*'}
                errorText={confirmPasswordError}>
                <Input
                  id="passwordConfirmTextBox"
                  type="password"
                  value={confirmPassword}
                  onChange={({ detail }) => setConfirmPassword(detail.value)}
                />
              </FormField>
            </>
          )}

          <Box>
            <Grid gridDefinition={[{ colspan: 8 }, { colspan: 8 }]}>
              <div className="divider" />
              <Box float="right">
                <Button
                  loading={saving}
                  variant="primary"
                  id="createTeamButton"
                  data-testid="createTeamButton"
                  onClick={() => void onSubmit()}
                >
                  {t(i18nKeys.joinTeam.createTeam.submitBtn)}
                </Button>
              </Box>
            </Grid>
          </Box>
        </SpaceBetween>
      </Form>
    </form>
  );
};

export default CreateTeamForm;
