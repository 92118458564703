import { ApiClient } from '@/src/api';
import { config } from '@/src/config/app-config';
import { useAuth } from '@/src/store/auth.context';
import { useFlashbars } from '@/src/store/flashbar.context';
import { useUser } from '@/src/store/user.context';
import { useHistory } from 'react-router-dom';

export const useApiClient = () => {
  const { addFlashbar, addSuccessFlashbar, addErrorFlashbar } = useFlashbars();
  const { authClient } = useAuth();
  const { user } = useUser();

  return new ApiClient(
    config.api.origin,
    config.api.originCheckout,
    authClient,
    user,
    addFlashbar,
    addSuccessFlashbar,
    addErrorFlashbar,
    useHistory()
  );
};
