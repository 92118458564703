import React, { useEffect, useState } from 'react';
import { ColumnLayout, Container,  Header, SpaceBetween } from '@amzn/awsui-components-react';
import { useTranslation } from 'react-i18next';
import TimeAgo from 'timeago-react';
import { useChallenges } from '../../../../store/challenge.context';
import {
  Challenge,
  ChallengeDeploymentStatistics,
  ChallengeGlobalStatistics,
  ChallengeListItem,
} from '../../../../types/Challenge';
import { Nullable } from '../../../../types/common';
import { i18nKeys } from '../../../../utils/i18n.utils';
import { roundFloat } from '../../../../utils/number.utils';
import { safeString } from '../../../../utils/string.utils';
import { getDuration } from '../../../../utils/time.utils';
import CircleRating from '../../../common/CircleRating';
import { KeyValue } from '../../../common/KeyValue';
import StarRating from '../../../common/StarRating';
import styles from './StatisticsSummary.module.scss';


interface DeployTimeStatsProps {
  title: string;
  avgInMs?: number;
  mostRecent?: number | null;
  latestTime?: number | null;
}

const DeployTimeStats = ({ title, avgInMs, mostRecent, latestTime }: DeployTimeStatsProps) => {
  const { t } = useTranslation();
  const averageDurationFormatted = getDuration(Math.floor((avgInMs || 0) / 1000));
  return (
    <KeyValue className="primary-text"  label={<strong>{title}</strong>}>
      <>
        <div>
          <strong className={styles.challengeStatsLabel}>{t(i18nKeys.report.labels.minMaxMean.mean)}</strong>
            {!!avgInMs && avgInMs > 0 ? averageDurationFormatted : '--'}
        </div>
        <div>
          <strong className={styles.challengeStatsLabel}>
            {t(i18nKeys.challenges.challengeDetails.titles.mostRecent)}:
          </strong>{' '}
            {mostRecent ? getDuration(Math.floor(mostRecent / 1000)) : '--'}
            { !!latestTime && <>{' '}(<TimeAgo datetime={latestTime} />)</>}
        </div>
      </>
    </KeyValue>
  );
};

interface ChallengeStatisticSummaryDetailProps {
  challenge: Challenge;
  challengeDeploymentStatistics?: ChallengeDeploymentStatistics;
}

const ChallengeStatisticSummarysDetail: React.FC<ChallengeStatisticSummaryDetailProps> = ({
  challenge,
  challengeDeploymentStatistics,
}) => {
  const { t } = useTranslation();
  const { getChallengeListItemFromChallengeId } = useChallenges();

  const [globalStats, setGlobalStats] = useState<ChallengeGlobalStatistics>();

  useEffect(() => {
    const challengeListItem: Nullable<ChallengeListItem> = getChallengeListItemFromChallengeId(
      safeString(challenge.challengeId)
    );

    if (challengeListItem) {
      setGlobalStats(challengeListItem.globalStatistics);
    }
  }, [challenge.challengeId]);

  return (
    <Container
      header={<Header variant="h2">{t(i18nKeys.challenges.challengeDetails.headings.statisticsSummary)}</Header>}>
        <ColumnLayout columns={4} variant='text-grid'>
          <SpaceBetween direction="vertical" size="xs">
            <KeyValue className="primary-text" label={<strong>{t(i18nKeys.challenges.challengeDetails.titles.rating)}</strong>}>
              <div>
                {globalStats && <StarRating value={globalStats.rating} valueCount={globalStats.ratingCount} />}
              </div>
              <div>
                <strong className={styles.challengeStatsLabel}>{t(i18nKeys.report.labels.minMaxMean.mean)}</strong>
                {globalStats && globalStats.rating ? roundFloat(globalStats.rating, 2).toFixed(2): '--'} {' '}
                ({globalStats?.ratingCount})
              </div>
            </KeyValue>
            <KeyValue className="primary-text" label={<strong>{t(i18nKeys.challenges.challengeDetails.titles.numbersOfJamUsed)}</strong>}>
              {globalStats?.jamsUsed}
            </KeyValue>
            <KeyValue className="primary-text" label={<strong>{t(i18nKeys.challenges.challengeDetails.titles.incorrectAnswers)}</strong>}>
              {globalStats?.totalIncorrect}
            </KeyValue>
            <KeyValue className="primary-text" label={<strong>{t(i18nKeys.challenges.challengeDetails.titles.averageSolveTime)}</strong>}>
              <div>
                <strong className={styles.challengeStatsLabel}>{t(i18nKeys.report.labels.minMaxMean.mean)}</strong>
                {globalStats && globalStats.solveTimes.trimmedAvgSeconds
                  ? getDuration(globalStats.solveTimes.trimmedAvgSeconds)
                  : '--'}
              </div>
              <div>
                <strong className={styles.challengeStatsLabel}>{t(i18nKeys.report.labels.minMaxMean.min)}</strong>
                {globalStats && globalStats.solveTimes.lowestSolveTime
                  ? getDuration(globalStats.solveTimes.lowestSolveTime.numSeconds)
                  : '--'}
              </div>
              <div>
                <strong className={styles.challengeStatsLabel}>{t(i18nKeys.report.labels.minMaxMean.max)}</strong>
                {globalStats && globalStats.solveTimes.highestSolveTime
                  ? getDuration(globalStats.solveTimes.highestSolveTime.numSeconds)
                  : '--'}
              </div>
              <div>
                  ({ globalStats?.solveTimes?.recentSolveTimes?.length} { !!globalStats?.solveTimes?.recentSolveTimes && t(i18nKeys.challenges.challengeDetails.titles.dataPoints) })
              </div>
            </KeyValue>
          </SpaceBetween>
          <SpaceBetween direction="vertical" size="xs">
            <KeyValue className="primary-text" label={<strong>{t(i18nKeys.challenges.challengeDetails.titles.difficultyRating)}</strong>}>
              <div>
                {globalStats && <CircleRating value={globalStats.difficultyRating} />}
              </div>
              <div>
                <strong className={styles.challengeStatsLabel}>{t(i18nKeys.report.labels.minMaxMean.mean)}</strong>
                {globalStats && globalStats.difficultyRating ? roundFloat(globalStats.difficultyRating, 2).toFixed(2)
                  : '--'} {' '} ({globalStats?.ratingCount})
              </div>
            </KeyValue>
            <KeyValue className="primary-text" label={<strong>{t(i18nKeys.challenges.challengeDetails.titles.firstUsed)}</strong>}>
              {globalStats && globalStats.firstUsed ? <TimeAgo datetime={globalStats.firstUsed} /> : '--'}
            </KeyValue>
            <KeyValue className="primary-text" label={<strong>{t(i18nKeys.challenges.challengeDetails.titles.solveRate)}</strong>}>
              {globalStats ? `${roundFloat(globalStats.passRate * 100 || 0, 0)}%` : '--'}
            </KeyValue>
            <KeyValue className="primary-text" label={<strong>{t(i18nKeys.challenges.challengeDetails.titles.stability)}</strong>}>
              {!!challengeDeploymentStatistics?.stability ? `${Math.floor(challengeDeploymentStatistics.stability * 100)}%` : '--'}
            </KeyValue>
          </SpaceBetween>
          <SpaceBetween direction="vertical" size="xs">
            <DeployTimeStats
              title={t(i18nKeys.challenges.challengeDetails.titles.stackDeployTime)}
              avgInMs={challenge.avgStackDeployTime}
              mostRecent={challenge?.lastSuccessfulDeployment?.stackDuration}
              latestTime={challenge?.avgDeployTimesLastUpdated}
            />
            <KeyValue className="primary-text" label={<strong>{t(i18nKeys.challenges.challengeDetails.titles.lastUsed)}</strong>}>
              {globalStats && globalStats.lastUsed ? <TimeAgo datetime={globalStats.lastUsed} /> : '--'}
            </KeyValue>
            <KeyValue className="primary-text" label={<strong>{t(i18nKeys.challenges.challengeDetails.titles.cluesRequested)}</strong>}>
              {globalStats?.totalRequestedClues}
            </KeyValue>
          </SpaceBetween>
          <SpaceBetween direction="vertical" size="xs">
            <DeployTimeStats
              title={t(i18nKeys.challenges.challengeDetails.titles.labVendTime)}
              avgInMs={challenge?.avgDeployResolveTime}
              mostRecent={challenge?.lastSuccessfulDeployment?.deploymentResolutionDuration}
              latestTime={challenge.lastSuccessfulDeployment.deploymentResolveTime}
            />
            <KeyValue className="primary-text" label={<strong>{t(i18nKeys.challenges.challengeDetails.titles.completedTasks)}</strong>}>
              {!!globalStats && globalStats.totalCorrect}
            </KeyValue>
            <KeyValue
              className="primary-text"
              label={<strong>{t(i18nKeys.challenges.challengeDetails.titles.cluePerCompletedTask)}</strong>}>
              {globalStats && globalStats.totalCorrect > 0 ? (
                roundFloat((globalStats.totalRequestedClues || 0) / globalStats.totalCorrect, 2).toFixed(2)
              ) : (
                <div>--</div>
              )}
            </KeyValue>
            {!!globalStats?.averageCostPerHour && <KeyValue className="primary-text" label={<strong>{t(i18nKeys.challenges.challengeDetails.titles.avgCostPerHour)}</strong>}>
              {`$${roundFloat(globalStats.averageCostPerHour || 0, 4).toFixed(4)}`}
            </KeyValue>}
          </SpaceBetween>
        </ColumnLayout>
    </Container>
  );
};

export default ChallengeStatisticSummarysDetail;
