/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { TFunction } from 'i18next';
import {
  Challenge,
  ChallengeConfiguration,
  ChallengeFeedback,
  ChallengeFeedbackSortOptions,
  ChallengeFeedbackSummary,
  ChallengeFeedbackTrend,
  ChallengeReview,
  ChallengeReviewableSection,
  ChallengeReviewStatus,
  ChallengeWarningResponse,
  ChallengeWrapper,
  GetChallengeDeploymentStatisticsResponse,
  IamPolicyValidationResponse,
  MarkChallengeDefectiveRequest,
  TemplateScannerRequest,
  TemplateScannerResponse,
  TranslatedChallengeWrapper,
} from '../types/Challenge';
import { ChallengeCFN, ChallengeSet } from '../types/ChallengeSet';
import { IChallengeRequestOptions, NullableNumber, NullableString, updateTranslatedAttribute } from '../types/common';
import { TinyEvent } from '../types/Event';
import { AccountCredentials } from '../types/LabModels';
import { S3Object } from '../types/s3-object';
import { i18nKeys } from '../utils/i18n.utils';
import { asList } from '../utils/list.utils';
import { fromPlainObject } from '../utils/mapper.utils';
import { ApiClient } from './ApiClient';
import { QueryParams } from './types';
import { ICreateNewIssue } from '@/src/components/challenges/challengeDetailSections/challengeIssues/NewIssue';

export class ChallengesAPI {
  challengeConfig: ChallengeConfiguration | undefined;
  challengeWrappers: ChallengeWrapper[] | undefined;
  challengeWrapperMap: { [id: string]: ChallengeWrapper } = {};
  templateChallengeAcknowledged = false;

  constructor(private apiClient: ApiClient, private t: TFunction) {
    // do nothing
  }

  // Load Challenge/Challenges APIs

  public async getFullChallenge(id: string, silent = false): Promise<ChallengeWrapper> {
    return (await this.apiClient.get({
      path: `/admin/challenges/${id}`,
      responseMapper: (object) => fromPlainObject(object, ChallengeWrapper),
      failMessage: this.t(i18nKeys.errors.requestFailed.challenges.getFullChallenge),
      silent,
    })) as Promise<ChallengeWrapper>;
  }

  public async loadChallenges(silent = false, includeArchived = false): Promise<ChallengeWrapper[]> {
    const limit = 400;
    const path = '/admin/challenges';

    const firstCall: { challenges: Challenge[]; count: number } = await this.apiClient.get({
      path,
      params: { offset: 0, limit, includeArchived },
      silent,
    });

    const numberOfCalls = Math.ceil(firstCall.count / limit) - 1;
    const apiRequests: Promise<Challenge[]>[] = [];
    for (let i = 0; i < numberOfCalls; i++) {
      apiRequests.push(
        this.apiClient
          .get({
            path,
            params: {
              offset: limit * (i + 1),
              limit,
              includeArchived,
            },
            silent,
          })
          .then((res: { challenges: Challenge[]; count: number }) => res.challenges)
      );
    }

    const challengeObjects = await Promise.all(apiRequests);

    challengeObjects.unshift(firstCall.challenges);
    return challengeObjects.reduce((accum, chunk) => {
      const convertedToWrapper = chunk.map((c) => fromPlainObject(c, ChallengeWrapper) as ChallengeWrapper);
      return accum.concat(convertedToWrapper);
    }, [] as ChallengeWrapper[]);
  }

  public async loadChallengesById(
    silent = false,
    _includeArchived = false,
    ids: string[]
  ): Promise<ChallengeWrapper[]> {
    const response: { challenges: object[]; count: number } = await this.apiClient.get({
      path: '/admin/challenges',
      params: { challengeIds: ids },
      silent,
    });
    const convertedChallenges: ChallengeWrapper[] = response.challenges
      ? (response.challenges.map((challenge: object) =>
          fromPlainObject(challenge, ChallengeWrapper)
        ) as ChallengeWrapper[])
      : [];

    return convertedChallenges;
  }

  public async loadChallengesOpenSearch(
    includeArchived: boolean,
    challengeRequestOptions: IChallengeRequestOptions,
    silent: boolean,
    editMode: boolean
  ): Promise<{ challenges: ChallengeWrapper[]; count: number }> {
    const params: QueryParams = {};

    params.offset = challengeRequestOptions.offset;
    params.limit = challengeRequestOptions.limit;

    if (challengeRequestOptions.title) params.title = challengeRequestOptions.title;
    if (challengeRequestOptions.containTitles) params.containTitles = challengeRequestOptions.containTitles;
    if (challengeRequestOptions.status) params.status = challengeRequestOptions.status;
    if (challengeRequestOptions.containStatuses) params.containStatuses = challengeRequestOptions.containStatuses;
    if (challengeRequestOptions.owner) params.owner = challengeRequestOptions.owner;
    if (challengeRequestOptions.containOwners) params.containOwners = challengeRequestOptions.containOwners;
    if (challengeRequestOptions.category) params.category = challengeRequestOptions.category;
    if (challengeRequestOptions.containCategories) params.containCategories = challengeRequestOptions.containCategories;
    if (challengeRequestOptions.difficulty) params.difficulty = challengeRequestOptions.difficulty;
    if (challengeRequestOptions.awsServices) params.awsServices = challengeRequestOptions.awsServices;
    if (challengeRequestOptions.containAwsServices)
      params.containAwsServices = challengeRequestOptions.containAwsServices;
    if (challengeRequestOptions.tags) params.tags = challengeRequestOptions.tags;
    if (challengeRequestOptions.containTags) params.containTags = challengeRequestOptions.containTags;
    if (challengeRequestOptions.stability && !editMode) params.stability = true;
    if (includeArchived) params.includeArchived = true;

    const response: { challenges: ChallengeWrapper[]; count: number } = await this.apiClient.get({
      path: '/admin/challenges',
      params,
      silent,
    });

    response.challenges = response.challenges.map((challenge: object) =>
      fromPlainObject(challenge, ChallengeWrapper)
    ) as ChallengeWrapper[];

    return response;
  }

  public async getChallengeResources(challengeId: string, silent = false): Promise<S3Object[]> {
    return (await this.apiClient.get({
      path: `/admin/challenges/${challengeId}/resources`,
      failMessage: this.t(i18nKeys.errors.requestFailed.challenges.getChallengeResources),
      silent,
    })) as Promise<S3Object[]>;
  }

  public async deleteChallengeResource(challengeId: string, key: string, silent = false): Promise<void> {
    await this.apiClient.delete({
      path: `/admin/challenges/${challengeId}/resources`,
      params: {
        path: key,
      },
      failMessage: this.t(i18nKeys.errors.requestFailed.challenges.deleteChallengeResource),
      silent,
    });
  }

  public async getChallengeVersionHistory(challengeId: string, silent = false): Promise<Challenge[]> {
    return (await this.apiClient.get({
      path: `/admin/challenges/${challengeId}/history`,
      responseMapper: asList((obj) => fromPlainObject(obj, Challenge)),
      failMessage: this.t(i18nKeys.errors.requestFailed.challenges.getChallengeVersionHistory),
      silent,
    })) as Promise<Challenge[]>;
  }

  async getChallengeVersion(challengeId: string, version: number, silent = false): Promise<Challenge> {
    return (await this.apiClient.get({
      path: `/admin/challenges/${challengeId}/history/${version}`,
      responseMapper: (obj) => fromPlainObject(obj, Challenge),
      failMessage: this.t(i18nKeys.errors.requestFailed.challenges.getChallengeVersion),
      silent,
    })) as Promise<Challenge>;
  }

  public async updatePublicStatus(challengeId: string, isPublic: boolean, silent = false): Promise<ChallengeWrapper> {
    return (await this.apiClient.post({
      path: '/admin/challenges/public',
      body: { challengeId, flagValue: isPublic },
      responseMapper: (object) => fromPlainObject(object, ChallengeWrapper),
      successMessage: this.t(i18nKeys.success.requestSucceeded.challenges.updatePublicStatus, { challengeId }),
      failMessage: this.t(i18nKeys.errors.requestFailed.challenges.updatePublicStatus, { challengeId }),
      silent,
    })) as Promise<ChallengeWrapper>;
  }

  public async archiveChallenge(challengeId: string, silent = false): Promise<ChallengeWrapper> {
    return (await this.apiClient.post({
      path: `/admin/challenges/${challengeId}/archive`,
      responseMapper: (object) => fromPlainObject(object, ChallengeWrapper),
      successMessage: this.t(i18nKeys.success.requestSucceeded.challenges.archiveChallenge, { challengeId }),
      failMessage: this.t(i18nKeys.errors.requestFailed.challenges.archiveChallenge, { challengeId }),
      silent,
    })) as Promise<ChallengeWrapper>;
  }

  public async unarchiveChallenge(challengeId: string, silent = false): Promise<ChallengeWrapper> {
    return (await this.apiClient.post({
      path: `/admin/challenges/${challengeId}/unarchive`,
      responseMapper: (object) => fromPlainObject(object, ChallengeWrapper),
      successMessage: this.t(i18nKeys.success.requestSucceeded.challenges.unarchiveChallenge, { challengeId }),
      failMessage: this.t(i18nKeys.errors.requestFailed.challenges.unarchiveChallenge, { challengeId }),
      silent,
    })) as Promise<ChallengeWrapper>;
  }

  public async updateDemoStatus(challengeId: string, isDemo: boolean, silent = false): Promise<ChallengeWrapper> {
    return (await this.apiClient.post({
      path: '/admin/challenges/demo',
      body: { challengeId, flagValue: isDemo },
      responseMapper: (object) => fromPlainObject(object, ChallengeWrapper),
      successMessage: this.t(i18nKeys.success.requestSucceeded.challenges.updateDemoStatus, { challengeId }),
      failMessage: this.t(i18nKeys.errors.requestFailed.challenges.updateDemoStatus, { challengeId }),
      silent,
    })) as Promise<ChallengeWrapper>;
  }

  public async deleteChallenge(challengeId: string, silent = false): Promise<ChallengeWrapper> {
    return this.apiClient.delete({
      path: `/admin/challenges/${challengeId}`,
      successMessage: this.t(i18nKeys.success.requestSucceeded.challenges.deleteChallenge, { challengeId }),
      failMessage: this.t(i18nKeys.errors.requestFailed.challenges.deleteChallenge, { challengeId }),
      silent,
    }) as Promise<ChallengeWrapper>;
  }

  public async updateOwner(
    challengeId: NullableString,
    lastKnownVersion: NullableNumber,
    owner: NullableString,
    silent = false
  ): Promise<ChallengeWrapper> {
    return (await this.apiClient.post({
      path: '/admin/challenges/owner',
      body: { challengeId, lastKnownVersion, owner },
      responseMapper: (object) => fromPlainObject(object, ChallengeWrapper),
      successMessage: this.t(i18nKeys.success.requestSucceeded.challenges.updateOwner, { challengeId }),
      failMessage: this.t(i18nKeys.errors.requestFailed.challenges.updateOwner, { challengeId }),
      silent,
    })) as Promise<ChallengeWrapper>;
  }

  public async updateMaintainers(
    challengeId: NullableString,
    lastKnownVersion: NullableNumber,
    maintainers: string[],
    silent = false
  ): Promise<ChallengeWrapper> {
    return (await this.apiClient.post({
      path: '/admin/challenges/maintainers',
      body: { challengeId, lastKnownVersion, maintainers },
      responseMapper: (object) => fromPlainObject(object, ChallengeWrapper),
      successMessage: this.t(i18nKeys.success.requestSucceeded.challenges.updateMaintainers, { challengeId }),
      failMessage: this.t(i18nKeys.errors.requestFailed.challenges.updateMaintainers, { challengeId }),
      silent,
    })) as Promise<ChallengeWrapper>;
  }

  // Challenge Action Button APIs

  public async updateDefectiveStatus(
    challengeId: string,
    request: MarkChallengeDefectiveRequest,
    silent = false
  ): Promise<ChallengeWrapper> {
    return (await this.apiClient.post({
      path: '/admin/challenges/defective',
      body: request,
      successMessage: this.t(i18nKeys.success.requestSucceeded.challenges.updateDefectiveStatus, {
        challengeId,
      }),
      failMessage: this.t(i18nKeys.errors.requestFailed.challenges.updateDefectiveStatus, { challengeId }),
      silent,
    })) as Promise<ChallengeWrapper>;
  }

  /**
   * Gets a list of warnings, if any, from the backend that the end user should know about before they
   * mark the challenge defective as well as a token used to make the second markDefective API call.
   *
   * @param challengeId The challenge ID of the challenge the user intends to mark as defective.
   * @returns ChallengeDefectiveWarning, which gives the user a list of warnings
   */
  public async getMarkDefectiveWarnings(challengeId: string, silent = false): Promise<ChallengeWarningResponse> {
    return this.apiClient.get({
      path: `/admin/challenges/${challengeId}/defective/warning`,
      failMessage: this.t(i18nKeys.errors.requestFailed.challenges.getMarkDefectiveWarnings, { challengeId }),
      silent,
    }) as Promise<ChallengeWarningResponse>;
  }

  /**
   * Gets a list of warnings, if any, from the backend that the end user should know about before they
   * proceed with the challenge approval as well as an approvalToken needed to make the final
   * approveChallenge call.
   *
   * @param challenge The challenge the user intends to approve.
   * @returns ChallengeApprovalWarning, which gives the user a list of warnings
   */
  public async getApprovalWarnings(challenge: Challenge, silent = false): Promise<ChallengeWarningResponse> {
    const { challengeId } = challenge;

    return this.apiClient.get({
      path: `/admin/challenges/${challengeId}/review/approve`,
      failMessage: this.t(i18nKeys.errors.requestFailed.challenges.getApprovalWarnings, { challengeId }),
      silent,
    }) as Promise<ChallengeWarningResponse>;
  }

  public async patchChallenge(
    path: string,
    challengeId: NullableString,
    lastKnownVersion: NullableNumber,
    body: object,
    silent = false
  ): Promise<ChallengeWrapper> {
    return (await this.apiClient.patch({
      path: `/admin/challenges/${challengeId}${path}`,
      body: { lastKnownVersion, props: body },
      responseMapper: (object) => fromPlainObject(object, ChallengeWrapper),
      successMessage: this.t(i18nKeys.success.requestSucceeded.challenges.patchChallenge),
      failMessage: this.t(i18nKeys.errors.requestFailed.challenges.patchChallenge),
      silent,
    })) as Promise<ChallengeWrapper>;
  }

  public async updateTags(
    challengeId: NullableString,
    lastKnownVersion: NullableNumber,
    tags: string[],
    silent = false
  ): Promise<ChallengeWrapper> {
    tags = tags || [];

    return (await this.apiClient.post({
      path: `/admin/challenges/${challengeId}/tags`,
      body: { tags },
      failMessage: this.t(i18nKeys.errors.requestFailed.challenges.updateTags),
      silent,
    })) as Promise<ChallengeWrapper>;
  }

  public async createChallengeTask(
    challengeId: NullableString,
    lastKnownVersion: NullableNumber,
    silent = false
  ): Promise<ChallengeWrapper> {
    return (await this.apiClient.post({
      path: `/admin/challenges/${challengeId}/tasks/create`,
      body: { lastKnownVersion },
      responseMapper: (object) => fromPlainObject(object, ChallengeWrapper),
      successMessage: this.t(i18nKeys.success.requestSucceeded.challenges.createChallengeTask),
      failMessage: this.t(i18nKeys.errors.requestFailed.challenges.createChallengeTask),
      silent,
    })) as Promise<ChallengeWrapper>;
  }

  public async removeChallengeTask(
    challengeId: NullableString,
    lastKnownVersion: NullableNumber,
    taskId: string,
    silent = false
  ): Promise<ChallengeWrapper> {
    return (await this.apiClient.post({
      path: `/admin/challenges/${challengeId}/tasks/${taskId}/remove`,
      body: { lastKnownVersion },
      responseMapper: (object) => fromPlainObject(object, ChallengeWrapper),
      successMessage: this.t(i18nKeys.success.requestSucceeded.challenges.removeChallengeTask),
      failMessage: this.t(i18nKeys.errors.requestFailed.challenges.removeChallengeTask),
      silent,
    })) as Promise<ChallengeWrapper>;
  }

  // Create Challenge APIs

  public async createChallenge(challengeId: string, body: object, silent = false): Promise<ChallengeWrapper> {
    return (await this.apiClient.post({
      path: `/admin/challenges/${challengeId}`,
      body: { props: body },
      responseMapper: (object) => fromPlainObject(object, ChallengeWrapper),
      successMessage: this.t(i18nKeys.success.requestSucceeded.challenges.createChallenge),
      failMessage: this.t(i18nKeys.errors.requestFailed.challenges.createChallenge),
      silent,
    })) as Promise<ChallengeWrapper>;
  }

  /**
   * Load the challenge config.
   *
   * @param silent
   */
  public async getChallengeConfig(silent = false): Promise<ChallengeConfiguration> {
    const response: Promise<ChallengeConfiguration> = await this.apiClient.get({
      path: '/admin/challenges/config',
      failMessage: this.t(i18nKeys.errors.requestFailed.challenges.getChallengeConfig),
      responseMapper: (object) => fromPlainObject(object, ChallengeConfiguration),
      silent,
    });
    return response;
  }

  // Challenge Comment APIs

  public async addComment(challenge: Challenge, commentValue: string, silent = false): Promise<void> {
    (await this.apiClient.post({
      path: `/admin/challenges/${challenge.challengeId}/comments/create`,
      body: { value: commentValue },
      successMessage: this.t(i18nKeys.success.requestSucceeded.comments.addComment),
      failMessage: this.t(i18nKeys.errors.requestFailed.comments.addComment),
      silent,
    })) as Promise<void>;
  }

  public async updateComment(
    challenge: Challenge,
    commentId: string,
    commentValue: string,
    silent = false
  ): Promise<void> {
    (await this.apiClient.post({
      path: `/admin/challenges/${challenge.challengeId}/comments/update`,
      body: { id: commentId, value: commentValue },
      successMessage: this.t(i18nKeys.success.requestSucceeded.comments.updateComment),
      failMessage: this.t(i18nKeys.errors.requestFailed.comments.updateComment),
      silent,
    })) as Promise<void>;
  }

  public async deleteComment(challenge: Challenge, commentId: string, silent = false): Promise<void> {
    (await this.apiClient.post({
      path: `admin/challenges/${challenge.challengeId}/comments/delete`,
      body: { id: commentId },
      successMessage: this.t(i18nKeys.success.requestSucceeded.comments.deleteComment),
      failMessage: this.t(i18nKeys.errors.requestFailed.comments.deleteComment),
      silent,
    })) as Promise<void>;
  }

  async getTranslatedChallenge(
    id: string,
    languageCode: string,
    latest: boolean,
    silent?: false
  ): Promise<TranslatedChallengeWrapper> {
    return await this.apiClient.get({
      path: `/admin/challenges/${id}/translate/${languageCode}`,
      params: { latest },
      failMessage: 'Failed to load translated challenge',
      silent,
    });
  }

  async updateTranslatedChallenge(body: updateTranslatedAttribute): Promise<void> {
    const { id, languageCode, translatedAttributes, latest } = body;
    await this.apiClient.post({
      path: `/admin/challenges/${id}/translate/${languageCode}`,
      body: { translatedAttributes },
      params: { latest },
      failMessage: 'Failed to update translated challenge',
      silent: false,
    });
  }

  // Challenge Feedback APIs

  public async getChallengeFeedbackTrend(challengeId: string, silent = false): Promise<ChallengeFeedbackTrend[]> {
    return (await this.apiClient.get({
      path: `/admin/challenges/${challengeId}/feedback/trend`,
      responseMapper: asList((object) => fromPlainObject(object, ChallengeFeedbackTrend)),
      failMessage: this.t(i18nKeys.errors.requestFailed.challenges.getChallengeFeedbackTrend),
      silent,
    })) as Promise<ChallengeFeedbackTrend[]>;
  }

  public async getChallengeFeedbackTrendAll(challengeId: string, silent = false): Promise<ChallengeFeedbackTrend[]> {
    return (await this.apiClient.get({
      path: `/admin/challenges/${challengeId}/feedback/trend/all`,
      responseMapper: asList((object) => fromPlainObject(object, ChallengeFeedbackTrend)),
      failMessage: this.t(i18nKeys.errors.requestFailed.challenges.getChallengeFeedbackTrendAll),
      silent,
    })) as Promise<ChallengeFeedbackTrend[]>;
  }

  public async getChallengeFeedbackSummary(challengeId: string, silent = false): Promise<ChallengeFeedbackSummary> {
    return (await this.apiClient.get({
      path: `/admin/challenges/${challengeId}/feedback/summary`,
      failMessage: this.t(i18nKeys.errors.requestFailed.challenges.getChallengeFeedbackSummary),
      silent,
    })) as Promise<ChallengeFeedbackSummary>;
  }

  public async getChallengeFeedback(
    challengeId: string,
    pageSize: number,
    sort: ChallengeFeedbackSortOptions,
    lastReceivedFeedback: ChallengeFeedback | null,
    silent = false
  ): Promise<ChallengeFeedback[]> {
    const sortOptionsPayload = {
      sortType: sort.sortType,
      ascending: sort.ascending,
    };
    return this.apiClient.post({
      path: `/admin/challenges/${challengeId}/feedback#silent`,
      body: {
        pageSize,
        sortOptions: sortOptionsPayload,
        lastReceivedFeedback,
      },
      failMessage: this.t(i18nKeys.errors.requestFailed.challenges.getChallengeFeedback),
      silent,
    }) as Promise<ChallengeFeedback[]>;
  }

  public async findAllChallengeSets(silent = false): Promise<ChallengeSet[]> {
    return this.apiClient.get({
      path: '/admin/challenge-sets',
      failMessage: this.t(i18nKeys.errors.requestFailed.challengeSets.getChallengeSets),
      silent,
      responseMapper: asList((object: unknown) => fromPlainObject(object, ChallengeSet)),
    }) as Promise<ChallengeSet[]>;
  }

  public async getAllCFN(silent = false): Promise<ChallengeCFN[]> {
    return this.apiClient.get({
      path: '/admin/snippets/type/CFN?',
      failMessage: this.t(i18nKeys.errors.requestFailed.challengeSnippets.getChallengeCFN),
      silent,
      responseMapper: asList((object: unknown) => object as ChallengeCFN),
    }) as Promise<ChallengeCFN[]>;
  }
  public async findByType(type: string, silent = false): Promise<ChallengeCFN[]> {
    return this.apiClient.get({
      path: `/admin/snippets/type/${type}`,
      failMessage: 'Failed to load challenge snippets',
      silent,
      responseMapper: asList((object: unknown) => object as ChallengeCFN),
    }) as Promise<ChallengeCFN[]>;
  }

  public async getSnippets(id: string, silent = false): Promise<ChallengeCFN> {
    return this.apiClient.get({
      path: `/admin/snippets/${id}?`,
      failMessage: this.t(i18nKeys.errors.requestFailed.challengeSnippets.getChallengeCFN),
      silent,
      responseMapper: (object: unknown) => object as ChallengeCFN,
    }) as Promise<ChallengeCFN>;
  }

  // Challenge One-Click-Test-Event APIs

  public async deployTestEvent(
    challengeId: string,
    labProvider: string,
    pinnedRegion: NullableString = null,
    silent = false
  ): Promise<ChallengeWrapper> {
    return (await this.apiClient.post({
      path: `/admin/challenges/${challengeId}/test/deploy`,
      body: {
        labProvider,
        pinnedRegion,
      },
      responseMapper: (object) => fromPlainObject(object, ChallengeWrapper),
      successMessage: this.t(i18nKeys.success.requestSucceeded.challenges.deployTestEvent),
      failMessage: this.t(i18nKeys.errors.requestFailed.challenges.deployTestEvent),
      silent,
    })) as Promise<ChallengeWrapper>;
  }

  public async redeployTestEvent(challengeId: string, silent = false): Promise<ChallengeWrapper> {
    return (await this.apiClient.post({
      path: `/admin/challenges/${challengeId}/test/redeploy`,
      responseMapper: (object) => fromPlainObject(object, ChallengeWrapper),
      successMessage: this.t(i18nKeys.success.requestSucceeded.challenges.redeployTestEvent),
      failMessage: this.t(i18nKeys.errors.requestFailed.challenges.redeployTestEvent),
      silent,
    })) as Promise<ChallengeWrapper>;
  }

  public async updateOneClickEventTesters(
    challengeId: string,
    testers: string[],
    silent = false
  ): Promise<ChallengeWrapper> {
    return (await this.apiClient.post({
      path: `/admin/challenges/${challengeId}/test/testers`,
      body: { testers },
      responseMapper: (object) => fromPlainObject(object, ChallengeWrapper),
      successMessage: this.t(i18nKeys.success.requestSucceeded.challenges.updateOneClickEventTesters),
      failMessage: this.t(i18nKeys.errors.requestFailed.challenges.updateOneClickEventTesters),
      silent,
    })) as Promise<ChallengeWrapper>;
  }

  public async terminateTestEvent(challengeId: string, silent = false): Promise<ChallengeWrapper> {
    return (await this.apiClient.post({
      path: `/admin/challenges/${challengeId}/test/terminate`,
      responseMapper: (object) => fromPlainObject(object, ChallengeWrapper),
      successMessage: this.t(i18nKeys.success.requestSucceeded.challenges.terminateTestEvent),
      failMessage: this.t(i18nKeys.errors.requestFailed.challenges.terminateTestEvent),
      silent,
    })) as Promise<ChallengeWrapper>;
  }

  public async extendTestEvent(challengeId: string, hours: number, silent = false): Promise<ChallengeWrapper> {
    return (await this.apiClient.post({
      path: `/admin/challenges/${challengeId}/test/extend`,
      body: { hours },
      responseMapper: (object) => fromPlainObject(object, ChallengeWrapper),
      successMessage: this.t(i18nKeys.success.requestSucceeded.challenges.extendTestEvent),
      failMessage: this.t(i18nKeys.errors.requestFailed.challenges.extendTestEvent),
      silent,
    })) as Promise<ChallengeWrapper>;
  }

  // Challenge Deployment APIs

  public async getChallengeDeploymentStatistics(
    challengeId: string,
    silent = false
  ): Promise<GetChallengeDeploymentStatisticsResponse> {
    return this.apiClient.get({
      path: `/admin/challenges/${challengeId}/statistics`,
      responseMapper: (object) => fromPlainObject(object, GetChallengeDeploymentStatisticsResponse),
      failMessage: this.t(i18nKeys.errors.requestFailed.challenges.getChallengeDeploymentStatistics),
      silent,
    }) as Promise<GetChallengeDeploymentStatisticsResponse>;
  }

  public async getRecentEventsChallengeUsedIn(
    challengeId: string,
    silent = false,
    excludeTest = true
  ): Promise<TinyEvent[]> {
    return this.apiClient.get({
      path: `/admin/challenges/${challengeId}/eventsUsed/recent`,
      params: { excludeTest },
      responseMapper: asList((object) => fromPlainObject(object, TinyEvent)),
      failMessage: this.t(i18nKeys.errors.requestFailed.challenges.getRecentEventsChallengeUsedIn),
      silent,
    }) as Promise<TinyEvent[]>;
  }

  // Challenge Review APIs

  public async readyForReview(challenge: Challenge, comment: string, silent = false): Promise<ChallengeWrapper> {
    const { challengeId, version: lastKnownVersion } = challenge;

    return (await this.apiClient.post({
      path: '/admin/challenges/review/request',
      body: { challengeId, lastKnownVersion, comment },
      successMessage: this.t(i18nKeys.success.requestSucceeded.challenges.readyForReview),
      failMessage: this.t(i18nKeys.errors.requestFailed.challenges.readyForReview),
      silent,
    })) as Promise<ChallengeWrapper>;
  }

  public async beginReview(challenge: Challenge, silent = true): Promise<ChallengeWrapper> {
    const { challengeId, version: lastKnownVersion } = challenge;

    return (await this.apiClient.post({
      path: '/admin/challenges/review/start',
      body: { challengeId, lastKnownVersion },
      responseMapper: (object) => fromPlainObject(object, ChallengeWrapper),
      successMessage: this.t(i18nKeys.success.requestSucceeded.challenges.beginReview),
      failMessage: this.t(i18nKeys.errors.requestFailed.challenges.beginReview),
      silent,
    })) as Promise<ChallengeWrapper>;
  }

  public async approveReview(
    challenge: Challenge,
    warningToken: string,
    comment: string,
    silent = false
  ): Promise<ChallengeWrapper> {
    const { challengeId, version: lastKnownVersion } = challenge;

    return (await this.apiClient.post({
      path: '/admin/challenges/review/approve',
      body: { challengeId, lastKnownVersion, warningToken, comment },
      responseMapper: (object) => fromPlainObject(object, ChallengeWrapper),
      successMessage: this.t(i18nKeys.success.requestSucceeded.challenges.approveReview),
      failMessage: this.t(i18nKeys.errors.requestFailed.challenges.approveReview),
      silent,
    })) as Promise<ChallengeWrapper>;
  }

  public async rejectReview(challenge: Challenge, suggestChanges: boolean, silent = false): Promise<ChallengeWrapper> {
    const { challengeId, props, version: lastKnownVersion } = challenge;

    const payload: any = { challengeId, lastKnownVersion };

    if (suggestChanges === true) {
      payload.props = props;
    }

    return (await this.apiClient.post({
      path: '/admin/challenges/review/reject',
      body: payload,
      responseMapper: (object) => fromPlainObject(object, ChallengeWrapper),
      successMessage: this.t(i18nKeys.success.requestSucceeded.challenges.rejectReview),
      failMessage: this.t(i18nKeys.errors.requestFailed.challenges.rejectReview),
      silent,
    })) as Promise<ChallengeWrapper>;
  }

  public async cancelReview(challenge: Challenge, silent = true): Promise<ChallengeWrapper> {
    const { challengeId, version: lastKnownVersion } = challenge;

    return (await this.apiClient.post({
      path: '/admin/challenges/review/cancel',
      body: { challengeId, lastKnownVersion },
      responseMapper: (object) => fromPlainObject(object, ChallengeWrapper),
      successMessage: this.t(i18nKeys.success.requestSucceeded.challenges.cancelReview),
      failMessage: this.t(i18nKeys.errors.requestFailed.challenges.cancelReview),
      silent,
    })) as Promise<ChallengeWrapper>;
  }

  // polaris review apis
  public async getReview(challenge: Challenge, silent = true): Promise<ChallengeReview> {
    const { challengeId, majorVersion } = challenge;
    const params: { majorVersion: number } = { majorVersion: majorVersion || 0 };
    // NOTE: response mapper should NOT be used here
    return (await this.apiClient.get({
      path: `/admin/challenge/${challengeId}/polaris-review`,
      params,
      failMessage: this.t(i18nKeys.errors.requestFailed.challenges.getReview),
      silent,
    })) as Promise<ChallengeReview>;
  }

  public async getLatestReviews(challengeId: string, silent = false): Promise<{ reviews: ChallengeReview[] }> {
    return (await this.apiClient.get({
      path: `/admin/challenge/${challengeId}/polaris-reviews/latest`,
      failMessage: this.t(i18nKeys.errors.requestFailed.challenges.getLatestReviews),
      silent,
    })) as Promise<{ reviews: ChallengeReview[] }>;
  }

  public async updateReview(
    challenge: Challenge,
    comment: string,
    section: ChallengeReviewableSection,
    reviewStatus: ChallengeReviewStatus,
    silent = true
  ): Promise<ChallengeReview> {
    const { challengeId, majorVersion } = challenge;
    const payload: {
      challengeId: string;
      majorVersion: number;
      comment: string;
      section: string;
      reviewStatus: string;
    } = {
      challengeId: challengeId || '',
      majorVersion: majorVersion || 0,
      comment,
      section,
      reviewStatus,
    };
    return (await this.apiClient.post({
      path: `/admin/challenge/${challengeId}/polaris-review`,
      body: payload,
      failMessage: this.t(i18nKeys.errors.requestFailed.challenges.updateReview),
      silent,
    })) as Promise<ChallengeReview>;
  }

  public async submitReview(challenge: Challenge, silent = true): Promise<void> {
    const { challengeId } = challenge;
    return (await this.apiClient.post({
      path: `/admin/challenge/${challengeId}/polaris-reviews/submit`,
      failMessage: this.t(i18nKeys.errors.requestFailed.challenges.submitReview),
      silent,
    })) as Promise<void>;
  }

  public async validateCfnTemplate(templateBody: string, silent = false): Promise<TemplateScannerResponse> {
    return this.validateTemplate({ cfnTemplate: templateBody }, silent);
  }

  private async validateTemplate(body: TemplateScannerRequest, silent = false): Promise<TemplateScannerResponse> {
    return this.apiClient.post({
      path: '/admin/challenges/validateChallengeTemplate',
      body,
      successMessage: this.t(i18nKeys.success.requestSucceeded.challenges.validateTemplate),
      failMessage: this.t(i18nKeys.errors.requestFailed.challenges.validateTemplate),
      silent,
    }) as Promise<TemplateScannerResponse>;
  }

  public async validateIamPolicy(iamPolicy: string, silent = false): Promise<IamPolicyValidationResponse> {
    return this.apiClient.post({
      path: '/admin/challenges/validateChallengeIamPolicy',
      body: { iamPolicy },
      successMessage: this.t(i18nKeys.success.requestSucceeded.challenges.validateIamPolicy),
      failMessage: this.t(i18nKeys.errors.requestFailed.challenges.validateIamPolicy),
      silent,
    }) as Promise<IamPolicyValidationResponse>;
  }

  public async validateS3Template(challengeId: string, silent = false): Promise<TemplateScannerResponse> {
    return this.validateTemplate({ challengeId }, silent);
  }

  public async getCredentialsToUploadChallengeResources(
    challengeId: string,
    silent = false
  ): Promise<AccountCredentials> {
    return this.apiClient.get({
      path: `/admin/challenges/${challengeId}/resources/credentials`,
      responseMapper: (obj) => fromPlainObject(obj, AccountCredentials),
      failMessage: this.t(i18nKeys.errors.requestFailed.challenges.getCredentialsToUploadChallengeResources),
      silent,
    }) as Promise<AccountCredentials>;
  }
}
