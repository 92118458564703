import { i18nKeys } from '@/src/utils/i18n.utils';
import { Button, Container, FormField, Header, SpaceBetween } from '@amzn/awsui-components-react';
import Dropzone from '@/src/components/common/Dropzone/Dropzone';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface IImageSectionProps {
  imageUrl?: string;
  onImageChange: (image?: File) => void;
}

const ImageSection: React.FC<IImageSectionProps> = ({ imageUrl, onImageChange }) => {
  const { t } = useTranslation();

  const handleImageChange = (value: File) => {
    onImageChange(value);
  };
  const removeImageChange = () => {
    onImageChange(undefined);
  };

  return (
    <div className="image-container">
      <Container
        header={
          <Header variant="h2" description={t(i18nKeys.eventTemplates.step4.image.description)}>
            {t(i18nKeys.eventTemplates.step4.image.label)}
          </Header>
        }>
        <SpaceBetween direction="vertical" size="l">
          <FormField label={t(i18nKeys.eventTemplates.step4.formField.label)}>
            {imageUrl ? (
              <div className="upload-image" id="upload-image" data-testid="upload-image">
                <img width={50} src={imageUrl} alt={t(i18nKeys.eventTemplates.step4.image.altEvent)} />
                  <Button variant="link" id="remove-image" data-testid="remove-image" onClick={() => removeImageChange()}>
                    {t(i18nKeys.eventTemplates.step4.removeButton)}
                  </Button>
              </div>
            ) : (
              <Dropzone uploadedFileCb={handleImageChange} />
            )}
          </FormField>
        </SpaceBetween>
        <h3>{t(i18nKeys.eventTemplates.step4.preview)}:</h3>
        <div className="image-preview">
          <div className="details-page">
            <div className="image-parent-block" style={{ position: 'relative', top: 0, left: 0 }}>
              <img
                id="preview-image"
                data-testid="preview-image"
                className="default"
                width={400}
                src={'/assets/event-template-details-page-image.png'}
                alt={t(i18nKeys.eventTemplates.step4.image.altEvent)}
                style={{ position: 'relative', top: 0, left: 0 }}
              />
              {imageUrl && (
                <img
                  className="custom"
                  width={400}
                  src={imageUrl}
                  alt={t(i18nKeys.eventTemplates.step4.image.altEvent)}
                  style={{ position: 'absolute', top: '8px', left: 0, height: '105px', objectFit: 'cover' }}
                />
              )}
            </div>
            <label style={{ fontWeight: 'normal' }}>{t(i18nKeys.eventTemplates.step4.challengeSetDetailsPage)}</label>
          </div>
          <div className="thumbnail">
            <div>
              {imageUrl ? (
                <img
                  id="thumbnail-image"
                  data-testid="thumbnail-image"
                  width={300}
                  src={imageUrl}
                  alt={t(i18nKeys.eventTemplates.step4.image.altEvent)}
                />
              ) : (
                <img
                  id="default-thumbnail-image"
                  data-testid="default-thumbnail-image"
                  width={300}
                  src={'/assets/event-template-details-image-thumbnail.png'}
                  alt={t(i18nKeys.eventTemplates.step4.image.altThumbnail)}
                />
              )}
            </div>
            <label style={{ fontWeight: 'normal' }}>{t(i18nKeys.eventTemplates.step4.challengeSetThumbnail)}</label>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default ImageSection;
