import Input from '@amzn/awsui-components-react/polaris/input';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import React from 'react';
import styles from './InputWithIcon.module.scss';

interface InputWithIconProps {
  value: string;
  icon: JSX.Element;
  type?: any;
  onChange: (value: string) => void;
  disabled: boolean;
  direction?: 'horizontal' | 'vertical';
}

export const InputWithIcon: React.FC<InputWithIconProps> = ({ value, icon, type, onChange, disabled, direction }) => {
  return (
    <SpaceBetween direction={direction} size="xs">
      <Input
        className="inline numeric-input"
        value={value}
        type={type}
        onChange={({ detail }) => onChange(detail.value)}
        disabled={disabled}
      />
      <div className={styles.iconContainer}>
        <div className={styles.inputIcon}>{icon}</div>
      </div>
    </SpaceBetween>
  );
};
