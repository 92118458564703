import { Box, Button, Modal } from '@amzn/awsui-components-react';
import React, { useState } from 'react';
import JamSkillProfileForm from '../JamSkillProfileForm/JamSkillProfileForm';
import { useTranslation } from 'react-i18next';
import { i18nKeys } from '@/src/utils/i18n.utils';
import { useApi } from '@/src/store/api.context';
import { useJamEventDetails } from '@/src/store/jam-event-details.context';

const JamSkillProfileFormModal = () => {
  const { t } = useTranslation();
  const [value, setValue] = useState('');
  const [loading, setLoading] = useState<boolean>(false);
  const { jamProfileAPI } = useApi();
  const { eventName, loadEventDetails } = useJamEventDetails();

  const onSubmit = async () => {
    setLoading(true);
    try {
      const body = {
        AWS: parseInt(value, 10),
      };
      await jamProfileAPI.updateSkillProfile(body);
      await loadEventDetails(eventName);
    } catch (e) {
      // Error handle in API
    }
    setLoading(false);
  };

  return (
    <Modal
      visible
      size="medium"
      footer={
        <Box float="right">
          <Button
            loading={loading}
            variant="primary"
            id="proficiencyRatingSubmitButton"
            data-testid="proficiencyRatingSubmitButton"
            onClick={() => void onSubmit()}
          >
            {t(i18nKeys.general.submit)}
          </Button>
        </Box>
      }>
      <JamSkillProfileForm isModal value={value} setValue={setValue} />
    </Modal>
  );
};

export default JamSkillProfileFormModal;
