/* eslint-disable @typescript-eslint/no-unsafe-argument */
import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { i18nKeys, withI18NPrefix } from './utils/i18n.utils';
import { RoutePath } from './RoutePath';
import { AuthRole } from './constants/shared/auth-roles';
import { isArray } from 'lodash';
import { config } from '@/src/config/app-config';
import { MY_JAM_PREFIX } from './constants/api';
import { UpcomingEventsPage } from './pages/upcoming-events';
import { PastEventsPage } from './pages/past-events';
import { ActiveEventsPage } from './pages/active-events';
import { OAuthRedirectPage } from './pages/oauth2';
import { HomePage } from './pages';
import { MyEventsPage } from './pages/my-events';
import { NewEventPage } from './pages/events/new';
import { EventDetailsPage } from './pages/events/$eventName';
import { CatalogDetailsPage } from './pages/events/details/$catalogId';
import { NewCampaignPage } from './pages/campaigns/new';
import { CampaignsPage } from './pages/campaigns';
import { CampaignDetailsPage } from './pages/campaigns/$campaignId';
import { CampaignGroupDetailsPage } from './pages/campaigns/$campaignId/groups/$eventName';
import { ChallengesPage } from './pages/challenges';
import { ChallengeSetsPage } from './pages/challenge-set';
import { ChallengeSetDetailsPage } from './pages/challenge-set/$challengeSetId';
import { NewChallengePage } from './pages/challenges/new';
import { NewChallengeSetPage } from './pages/challenge-set/new';
import { ReviewChallengesPage } from './pages/review-challenges';
import { ChallengeDetailsPage } from './pages/challenges/$challengeId';
import { UsagePlansPage } from './pages/usage-plans';
import { CreateUsagePlanPage } from './pages/usage-plans/create';
import { UsagePlanDetailsPage } from './pages/usage-plans/$planId';
import { DocumentationPage } from './pages/documentation';
import { PrizesPage } from './pages/prizes';
import { SettingsPage } from './pages/settings';
import { EventsPage } from './pages/events';
import { EventCatalogTemplatesPage } from './pages/event-catalog-templates';
import { ChallengesSnippetsPage } from './pages/challenges-snippets';
import { ChallengeResourcesPage } from './pages/challenges-resources/$challengeId';
import { ChallengesSshAccessPage } from './pages/challenges-ssh-access';
import { EventTemplateCreatePage } from './pages/event-catalog-templates/create';
import { EventTemplateDetailsPage } from './pages/event-catalog-templates/$eventTemplateId';
import { CreateJamProfilePage } from './pages/create-jam-profile';
import { AdminPage } from './pages/admin';
import { FaqPage } from './pages/faq';
import { FaqSectionPage } from './pages/faq/$section';
import { MyJamsPage } from './pages/jam';
import { ProfilePage } from './pages/profile';
import { ProfileDetailsPage } from './pages/profile/detail';
import { CreateProfilePage } from './pages/profile/create';
import { ProfileSkillDetailsPage } from './pages/profile/skill-detail';
import { ProfilePrizesPage } from './pages/profile/prizes';
import { DataAndPrivacyPage } from './pages/profile/data-and-privacy';
import { JoinAnEventPage } from './pages/join-an-event';
import { JamEventPage } from './pages/$eventId';
import { JamEventOnboardingPage } from './pages/$eventId/onboarding';
import { JamEventTeamJoinPage as JamEventJoinTeamPage } from './pages/$eventId/onboarding/$joinOption';
import { JamEventTeamPage } from './pages/$eventId/team';
import { JamEventFacilitatorMessagingPage } from './pages/$eventId/facilitator/messaging';
import { JamEventSupportChatsPage } from './pages/$eventId/facilitator/support-chats';
import { JamEventSettingsPage } from './pages/$eventId/facilitator/settings';
import { JamEventNotificationsPage } from './pages/$eventId/facilitator/notifications';
import { JamEventFacilitatorParticipantsPage } from './pages/$eventId/facilitator/participants';
import { JamEventMessagesPage } from './pages/$eventId/messages';
import { ReportsPage } from './pages/reports';
import { LabsPage } from './pages/labs';
import { PublicEventsPage } from './pages/public-events';

export interface RouteItem {
  path: string;
  name: string;
  requiredGroups?: AuthRole[];
  Component: React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any>;
  exact?: boolean;
  entityType?:
    | 'event'
    | 'challenge'
    | 'challengeSet'
    | 'usagePlan'
    | 'campaign'
    | 'group'
    | 'eventTemplate'
    | 'catalog'
    | 'faq'
    | 'jam-event-onboarding'
    | 'jam-event-onboarding-options'
    | 'jam-challenges';
}

export class ResolvableRoute {
  public static readonly TOKEN = '{token}';

  public constructor(private readonly path: string) {
    if (!path.includes(ResolvableRoute.TOKEN)) {
      throw new Error(`Path '${path}' is non-resolvable`);
    }
  }

  replaceTokens = (tokens: string[]) => {
    let newPath = this.path;
    tokens.forEach((token) => {
      newPath = newPath.replace(ResolvableRoute.TOKEN, token);
    });
    return newPath;
  };

  public resolve(tokenParams: string | string[]): string {
    if (isArray(tokenParams)) {
      return this.replaceTokens(tokenParams);
    } else {
      return this.path.replaceAll(ResolvableRoute.TOKEN, tokenParams);
    }
  }

  public wildcard(): string {
    return this.resolve('*');
  }
}

export const CHALLENGE_DETAILS_ROUTES = {
  Summary: new ResolvableRoute(`${RoutePath.CHALLENGES}/${ResolvableRoute.TOKEN}`),
  LearningOutcome: new ResolvableRoute(`${RoutePath.CHALLENGES}/${ResolvableRoute.TOKEN}/learning-outcome`),
  Settings: new ResolvableRoute(`${RoutePath.CHALLENGES}/${ResolvableRoute.TOKEN}/settings`),
  Tasks: new ResolvableRoute(`${RoutePath.CHALLENGES}/${ResolvableRoute.TOKEN}/tasks`),
  Assets: new ResolvableRoute(`${RoutePath.CHALLENGES}/${ResolvableRoute.TOKEN}/assets`),
  IamPolicy: new ResolvableRoute(`${RoutePath.CHALLENGES}/${ResolvableRoute.TOKEN}/iam`),
  ShowIamPolicy: new ResolvableRoute(`${RoutePath.CHALLENGES}/${ResolvableRoute.TOKEN}/show-i-am`),
  CfnTemplate: new ResolvableRoute(`${RoutePath.CHALLENGES}/${ResolvableRoute.TOKEN}/cfn`),
  NextSteps: new ResolvableRoute(`${RoutePath.CHALLENGES}/${ResolvableRoute.TOKEN}/next-steps`),
  Wiki: new ResolvableRoute(`${RoutePath.CHALLENGES}/${ResolvableRoute.TOKEN}/wiki`),
  Collaborators: new ResolvableRoute(`${RoutePath.CHALLENGES}/${ResolvableRoute.TOKEN}/collaborators`),
  Issues: new ResolvableRoute(`${RoutePath.CHALLENGES}/${ResolvableRoute.TOKEN}/issues`),
  IssuesSingle: new ResolvableRoute(`${RoutePath.CHALLENGES}/${ResolvableRoute.TOKEN}/issues/${ResolvableRoute.TOKEN}`),
  Comments: new ResolvableRoute(`${RoutePath.CHALLENGES}/${ResolvableRoute.TOKEN}/comments`),
  Testing: new ResolvableRoute(`${RoutePath.CHALLENGES}/${ResolvableRoute.TOKEN}/testing`),
  Feedback: new ResolvableRoute(`${RoutePath.CHALLENGES}/${ResolvableRoute.TOKEN}/feedback`),
  Stats: new ResolvableRoute(`${RoutePath.CHALLENGES}/${ResolvableRoute.TOKEN}/stats`),
  Revisions: new ResolvableRoute(`${RoutePath.CHALLENGES}/${ResolvableRoute.TOKEN}/revisions`),
  Reviews: new ResolvableRoute(`${RoutePath.CHALLENGES}/${ResolvableRoute.TOKEN}/review/`),
  Translation: new ResolvableRoute(`${RoutePath.CHALLENGES}/${ResolvableRoute.TOKEN}/translation`),
  FacilitatorNotes: new ResolvableRoute(`${RoutePath.CHALLENGES}/${ResolvableRoute.TOKEN}/facilitator-notes`),
  Help: new ResolvableRoute(`${RoutePath.CHALLENGES}/${ResolvableRoute.TOKEN}/help`),
} as const;

export const EVENT_DETAILS_ROUTES = {
  Summary: new ResolvableRoute(`/events/${ResolvableRoute.TOKEN}`),
  Challenges: new ResolvableRoute(`/events/${ResolvableRoute.TOKEN}/challenges`),
  Settings: new ResolvableRoute(`/events/${ResolvableRoute.TOKEN}/settings`),
  Comments: new ResolvableRoute(`/events/${ResolvableRoute.TOKEN}/comments`),
  Participants: new ResolvableRoute(`/events/${ResolvableRoute.TOKEN}/participants`),
  ParticipantInvites: new ResolvableRoute(`/events/${ResolvableRoute.TOKEN}/participant-invites`),
  ParticipantsV1: new ResolvableRoute(`/v1/events/${ResolvableRoute.TOKEN}/participants`),
  OwnerAndPermission: new ResolvableRoute(`/events/${ResolvableRoute.TOKEN}/owner-and-permissions`),
  Labs: new ResolvableRoute(`/events/${ResolvableRoute.TOKEN}/labs`),
  Feedback: new ResolvableRoute(`/events/${ResolvableRoute.TOKEN}/feedback`),
  Revisions: new ResolvableRoute(`/events/${ResolvableRoute.TOKEN}/revisions`),
  Report: new ResolvableRoute(`/events/${ResolvableRoute.TOKEN}/report`),
} as const;

export const CHALLENGE_SETS_ROUTES = {
  Summary: new ResolvableRoute(`/challenge-set/${ResolvableRoute.TOKEN}`),
};

export const CAMPAIGN_DETAILS_ROUTES = {
  Summary: new ResolvableRoute(`/campaigns/${ResolvableRoute.TOKEN}`),
  Challenges: new ResolvableRoute(`/campaigns/${ResolvableRoute.TOKEN}/challenges`),
  Settings: new ResolvableRoute(`/campaigns/${ResolvableRoute.TOKEN}/settings`),
  AdvancedSettings: new ResolvableRoute(`/campaigns/${ResolvableRoute.TOKEN}/advanced-settings`),
  Groups: new ResolvableRoute(`/campaigns/${ResolvableRoute.TOKEN}/groups`),
  NewGroup: new ResolvableRoute(`/campaigns/${ResolvableRoute.TOKEN}/groups/new`),
  GroupDetails: new ResolvableRoute(`/campaigns/${ResolvableRoute.TOKEN}/groups/${ResolvableRoute.TOKEN}`),
  Comments: new ResolvableRoute(`/campaigns/${ResolvableRoute.TOKEN}/comments`),
};

export const EVENT_CATALOG_TEMPLATE_DETAILS_ROUTES = {
  Details: new ResolvableRoute(`${RoutePath.EVENT_CATALOG_TEMPLATES}/${ResolvableRoute.TOKEN}`),
  GeneralEdit: new ResolvableRoute(`${RoutePath.EVENT_CATALOG_TEMPLATES}/${ResolvableRoute.TOKEN}/edit/general`),
  ChallengeEdit: new ResolvableRoute(`${RoutePath.EVENT_CATALOG_TEMPLATES}/${ResolvableRoute.TOKEN}/edit/challenges`),
  Edit: new ResolvableRoute(`${RoutePath.EVENT_CATALOG_TEMPLATES}/${ResolvableRoute.TOKEN}/edit`),
  Challenges: new ResolvableRoute(`${RoutePath.EVENT_CATALOG_TEMPLATES}/${ResolvableRoute.TOKEN}/challenges`),
  Feedback: new ResolvableRoute(`${RoutePath.EVENT_CATALOG_TEMPLATES}/${ResolvableRoute.TOKEN}/feedback`),
  Reporting: new ResolvableRoute(`${RoutePath.EVENT_CATALOG_TEMPLATES}/${ResolvableRoute.TOKEN}/reporting`),
} as const;

export const JAM_EVENT_DETAILS_ROUTES = {
  Onboarding: new ResolvableRoute(`${MY_JAM_PREFIX}/${ResolvableRoute.TOKEN}/onboarding`),
  JoinTeam: new ResolvableRoute(`${MY_JAM_PREFIX}/${ResolvableRoute.TOKEN}/onboarding/${ResolvableRoute.TOKEN}`),
  Dashboard: new ResolvableRoute(`${MY_JAM_PREFIX}/${ResolvableRoute.TOKEN}/dashboard`),
  LeaderBoard: new ResolvableRoute(`${MY_JAM_PREFIX}/${ResolvableRoute.TOKEN}/leaderboard`),
  Challenges: new ResolvableRoute(`${MY_JAM_PREFIX}/${ResolvableRoute.TOKEN}/challenges`),
  Team: new ResolvableRoute(`${MY_JAM_PREFIX}/${ResolvableRoute.TOKEN}/team`),
  Feedback: new ResolvableRoute(`${MY_JAM_PREFIX}/${ResolvableRoute.TOKEN}/feedback`),
  Report: new ResolvableRoute(`${MY_JAM_PREFIX}/${ResolvableRoute.TOKEN}/report`),
  Messages: new ResolvableRoute(`${MY_JAM_PREFIX}/${ResolvableRoute.TOKEN}/messages`),
  Meetings: new ResolvableRoute(`${MY_JAM_PREFIX}/${ResolvableRoute.TOKEN}/meetings`),
  Facilitator: new ResolvableRoute(`${MY_JAM_PREFIX}/${ResolvableRoute.TOKEN}/facilitator`),
  TeamChat: new ResolvableRoute(`${MY_JAM_PREFIX}/${ResolvableRoute.TOKEN}/team/chat`),
  TeamInfo: new ResolvableRoute(`${MY_JAM_PREFIX}/${ResolvableRoute.TOKEN}/team/${ResolvableRoute.TOKEN}`),
} as const;

export const JAM_CHALLENGE_DETAILS_ROUTES = {
  Overview: new ResolvableRoute(
    `${MY_JAM_PREFIX}/${ResolvableRoute.TOKEN}/challenges/${ResolvableRoute.TOKEN}/overview`
  ),
  RecentFeedback: new ResolvableRoute(
    `${MY_JAM_PREFIX}/${ResolvableRoute.TOKEN}/challenges/${ResolvableRoute.TOKEN}/recent-feedback`
  ),
  Issue: new ResolvableRoute(`${MY_JAM_PREFIX}/${ResolvableRoute.TOKEN}/challenges/${ResolvableRoute.TOKEN}/issue`),
  FacilitatorNotes: new ResolvableRoute(
    `${MY_JAM_PREFIX}/${ResolvableRoute.TOKEN}/challenges/${ResolvableRoute.TOKEN}/facilitator-notes`
  ),
  Wiki: new ResolvableRoute(`${MY_JAM_PREFIX}/${ResolvableRoute.TOKEN}/challenges/${ResolvableRoute.TOKEN}/wiki`),
  OutputProperties: new ResolvableRoute(
    `${MY_JAM_PREFIX}/${ResolvableRoute.TOKEN}/challenges/${ResolvableRoute.TOKEN}/output-properties`
  ),
} as const;

export const JAM_FACILITATOR_DETAILS_ROUTES = {
  Participants: new ResolvableRoute(`${MY_JAM_PREFIX}/${ResolvableRoute.TOKEN}/facilitator/participants`),
  Messaging: new ResolvableRoute(`${MY_JAM_PREFIX}/${ResolvableRoute.TOKEN}/facilitator/messaging`),
  SupportChats: new ResolvableRoute(`${MY_JAM_PREFIX}/${ResolvableRoute.TOKEN}/facilitator/support-chats`),
  JamSettings: new ResolvableRoute(`${MY_JAM_PREFIX}/${ResolvableRoute.TOKEN}/facilitator/settings`),
  Notifications: new ResolvableRoute(`${MY_JAM_PREFIX}/${ResolvableRoute.TOKEN}/facilitator/notifications`),
  Support: new ResolvableRoute(`${MY_JAM_PREFIX}/${ResolvableRoute.TOKEN}/facilitator/support`),
};

export const JAM_CHALLENGE_ROUTES = new ResolvableRoute(
  `${MY_JAM_PREFIX}/${ResolvableRoute.TOKEN}/challenges/${ResolvableRoute.TOKEN}/overview`
);

export const JAM_CHALLENGE_TASK_ROUTES = new ResolvableRoute(
  `${MY_JAM_PREFIX}/${ResolvableRoute.TOKEN}/challenges/${ResolvableRoute.TOKEN}/tasks/${ResolvableRoute.TOKEN}/details`
);

export interface ParameterizedRouteItem extends RouteItem {
  resolvePath: (...params: string[]) => string;
}

export const homeRoute: RouteItem = {
  path: RoutePath.ROOT,
  name: withI18NPrefix(i18nKeys.home.title),
  exact: true,
  Component: HomePage,
};

export const oauth2Route: RouteItem = {
  path: RoutePath.OAUTH2,
  name: 'Oauth',
  exact: true,
  Component: OAuthRedirectPage,
};

export const eventsRoute: RouteItem = {
  path: RoutePath.EVENTS,
  name: withI18NPrefix(i18nKeys.events.title),
  exact: true,
  Component: MyEventsPage,
};

export const newEventRoute: RouteItem = {
  path: RoutePath.NEW_EVENT,
  name: withI18NPrefix(i18nKeys.newEvent.title),
  exact: true,
  entityType: 'event',
  Component: NewEventPage,
  requiredGroups: [AuthRole.SUBSCRIBED_USER],
};

export const eventRoute: ParameterizedRouteItem = {
  path: RoutePath.EVENT_DETAILS,
  name: withI18NPrefix(i18nKeys.events.event),
  exact: false,
  Component: EventDetailsPage,
  entityType: 'event',
  resolvePath: (...params) => `/events/${params[0] || ''}*`,
};

export const eventTemplateCatalogDetailsRoute: ParameterizedRouteItem = {
  path: RoutePath.CATALOG_DETAILS,
  name: withI18NPrefix(i18nKeys.eventTemplates.catalogDetails.title),
  exact: false,
  Component: CatalogDetailsPage,
  entityType: 'catalog',
  resolvePath: (...params) => `/events/details/${params[0] || ''}`,
};

export const newCampaignRoute: RouteItem = {
  path: RoutePath.NEW_CAMPAIGN,
  name: withI18NPrefix(i18nKeys.campaigns.newCampaign.title),
  exact: true,
  Component: NewCampaignPage,
  requiredGroups: [AuthRole.SUBSCRIBED_USER],
};

export const campaignsRoute: RouteItem = {
  path: RoutePath.CAMPAIGNS,
  name: withI18NPrefix(i18nKeys.campaigns.title),
  exact: true,
  Component: CampaignsPage,
};

export const campaignRoute: ParameterizedRouteItem = {
  path: RoutePath.CAMPAIGN_DETAILS,
  name: withI18NPrefix(i18nKeys.campaigns.campaignDetails.title),
  exact: false,
  entityType: 'campaign',
  resolvePath: (...params) => `/campaigns/${params[0] || ''}*`,
  Component: CampaignDetailsPage,
};

export const groupRoute: ParameterizedRouteItem = {
  path: RoutePath.CAMPAIGN_GROUP_DETAILS,
  name: withI18NPrefix(i18nKeys.campaigns.headers.groups.group),
  exact: false,
  entityType: 'group',
  resolvePath: (...params) => `/campaigns/${params[0] || ''}/groups/${params[1] || ''}`,
  Component: CampaignGroupDetailsPage,
};

export const challengesRoute: RouteItem = {
  path: RoutePath.CHALLENGES,
  name: withI18NPrefix(i18nKeys.challenges.title),
  exact: true,
  Component: ChallengesPage,
  requiredGroups: [
    AuthRole.JAM_ADMIN,
    AuthRole.CHALLENGE_SUPPORT,
    AuthRole.CHALLENGE_REVIEWER,
    AuthRole.CHALLENGE_BUILDER,
    AuthRole.SUBSCRIBED_USER,
  ],
};

export const challengeSetsRoute: RouteItem = {
  path: RoutePath.CHALLENGE_SETS,
  name: withI18NPrefix(i18nKeys.challenges.challengeSets),
  exact: true,
  Component: ChallengeSetsPage,
  requiredGroups: [
    AuthRole.JAM_ADMIN,
    AuthRole.CHALLENGE_SUPPORT,
    AuthRole.CHALLENGE_REVIEWER,
    AuthRole.CHALLENGE_BUILDER,
    AuthRole.SUBSCRIBED_USER,
  ],
};

export const challengeSetRoute: ParameterizedRouteItem = {
  path: RoutePath.CHALLENGE_SET_DETAILS,
  name: withI18NPrefix(i18nKeys.challenges.challengeSets),
  exact: false,
  Component: ChallengeSetDetailsPage,
  entityType: 'challengeSet',
  resolvePath: (...params) => `/challenge-set/${params[0] ?? ''}*`,
  requiredGroups: [
    AuthRole.JAM_ADMIN,
    AuthRole.CHALLENGE_SUPPORT,
    AuthRole.CHALLENGE_REVIEWER,
    AuthRole.CHALLENGE_BUILDER,
    AuthRole.SUBSCRIBED_USER,
  ],
};

export const createChallengeSetRoute: RouteItem = {
  path: RoutePath.NEW_CHALLENGE_SET,
  name: withI18NPrefix(i18nKeys.challenges.challengeSet.header.createChallengeSet),
  exact: true,
  Component: NewChallengeSetPage,
  requiredGroups: [AuthRole.JAM_ADMIN, AuthRole.CHALLENGE_SUPPORT, AuthRole.CHALLENGE_BUILDER],
};

export const reviewChallengesRoute: RouteItem = {
  path: RoutePath.REVIEW_CHALLENGES,
  name: withI18NPrefix(i18nKeys.challenges.reviewChallenges.title),
  exact: true,
  Component: ReviewChallengesPage,
  requiredGroups: [AuthRole.JAM_ADMIN, AuthRole.CHALLENGE_SUPPORT, AuthRole.CHALLENGE_REVIEWER],
};

export const challengeRoute: ParameterizedRouteItem = {
  path: RoutePath.CHALLENGE_DETAILS,
  name: withI18NPrefix(i18nKeys.challenges.challenge),
  exact: false,
  Component: ChallengeDetailsPage,
  entityType: 'challenge',
  resolvePath: (...params) => `/challenges/${params[0] ?? ''}*`,
};

export const createChallengeRoute: RouteItem = {
  path: RoutePath.NEW_CHALLENGE,
  name: withI18NPrefix(i18nKeys.challenges.createChallenge),
  exact: true,
  Component: NewChallengePage,
  requiredGroups: [AuthRole.JAM_ADMIN, AuthRole.CHALLENGE_SUPPORT, AuthRole.CHALLENGE_BUILDER],
};

export const usagePlansRoute: RouteItem = {
  path: RoutePath.USAGE_PLANS,
  name: withI18NPrefix(i18nKeys.usagePlan.title),
  exact: true,
  Component: UsagePlansPage,
};

export const createUsagePlanRoute: RouteItem = {
  path: RoutePath.NEW_USAGE_PLAN,
  name: withI18NPrefix(i18nKeys.usagePlan.createUsagePlan),
  exact: true,
  Component: CreateUsagePlanPage,
};

export const usagePlanDetailsRoute: ParameterizedRouteItem = {
  path: RoutePath.USAGE_PLAN_DETAILS,
  name: withI18NPrefix(i18nKeys.usagePlan.sections.details),
  exact: true,
  Component: UsagePlanDetailsPage,
  entityType: 'usagePlan',
  resolvePath: (...params) => `/usage-plans/${params[0] || ''}`,
};

export const prizeRedemptionRoute: RouteItem = {
  path: RoutePath.PRIZES,
  name: withI18NPrefix(i18nKeys.prizeRedemption.title),
  exact: true,
  Component: PrizesPage,
  requiredGroups: [AuthRole.JAM_ADMIN, AuthRole.EVENT_PRIZES_ADMIN],
};

export const documentationRoute: RouteItem = {
  path: RoutePath.DOCUMENTATION,
  name: withI18NPrefix(i18nKeys.documentation.title),
  exact: true,
  Component: DocumentationPage,
};

export const settingsRoute: RouteItem = {
  path: RoutePath.SETTINGS,
  name: withI18NPrefix(i18nKeys.settings.advancedSettings),
  exact: true,
  Component: SettingsPage,
  requiredGroups: [AuthRole.JAM_ADMIN],
};

export const catalogRoute: RouteItem = {
  path: RoutePath.CATALOG,
  name: withI18NPrefix(i18nKeys.catalog.title),
  exact: true,
  Component: EventsPage,
};

export const eventTemplatesRoute: RouteItem = {
  path: RoutePath.EVENT_CATALOG_TEMPLATES,
  name: withI18NPrefix(i18nKeys.eventTemplates.title),
  exact: true,
  Component: EventCatalogTemplatesPage,
  requiredGroups: [AuthRole.JAM_ADMIN],
};

export const challengeSnippets: RouteItem = {
  path: RoutePath.CHALLENGE_SNIPPETS,
  name: 'Challenges Snippets',
  exact: true,
  Component: ChallengesSnippetsPage,
};

export const challengeResources: ParameterizedRouteItem = {
  path: RoutePath.CHALLENGES_RESOURCES,
  name: 'Challenges Resources',
  exact: false,
  Component: ChallengeResourcesPage,
  resolvePath: (...params) => `/challenges-resources/${params[0] || ''}`,
};

export const challengeSSHAccess: RouteItem = {
  path: RoutePath.SSH_ACCESS,
  name: 'Challenges SSHAccess',
  exact: true,
  Component: ChallengesSshAccessPage,
};

export const createEventTemplateRoute: RouteItem = {
  path: RoutePath.EVENT_CATALOG_TEMPLATE_CREATE,
  name: withI18NPrefix(i18nKeys.eventTemplates.create),
  exact: true,
  Component: EventTemplateCreatePage,
  requiredGroups: [AuthRole.JAM_ADMIN],
};

export const eventTemplateDetailsRoute: ParameterizedRouteItem = {
  path: RoutePath.EVENT_CATALOG_TEMPLATE_DETAILS,
  name: withI18NPrefix(i18nKeys.eventTemplates.details),
  exact: false,
  Component: EventTemplateDetailsPage,
  resolvePath: (...params) => `${RoutePath.EVENT_CATALOG_TEMPLATES}/${params[0] || ''}/*`,
  requiredGroups: [AuthRole.JAM_ADMIN],
};

export const createJamProfileRoute: RouteItem = {
  path: RoutePath.JAM_PROFILE_CREATE,
  name: withI18NPrefix(i18nKeys.createJamProfile.title),
  exact: true,
  Component: CreateJamProfilePage,
};

export const adminRoute: RouteItem = {
  path: RoutePath.ADMIN,
  name: withI18NPrefix(i18nKeys.header.nav.admin.text),
  exact: true,
  Component: AdminPage,
  requiredGroups: [AuthRole.JAM_ADMIN],
};

export const faqRoute: RouteItem = {
  path: RoutePath.FAQ,
  name: withI18NPrefix(i18nKeys.faq.title),
  exact: true,
  Component: FaqPage,
};

export const faqSectionRoute: ParameterizedRouteItem = {
  path: RoutePath.FAQ_SECTION,
  name: withI18NPrefix(i18nKeys.faq.title),
  exact: false,
  Component: FaqSectionPage,
  entityType: 'faq',
  resolvePath: (...params) => `/faq/${params[0] || ''}`,
};

export const myJamsRoute: RouteItem = {
  path: RoutePath.MY_JAMS,
  name: withI18NPrefix(i18nKeys.myJams.title),
  exact: true,
  Component: MyJamsPage,
};

export const myJamsActiveEventsRoute: RouteItem = {
  path: RoutePath.MY_JAMS_ACTIVE,
  name: withI18NPrefix(i18nKeys.myJams.eventTabs.activeEvents),
  exact: true,
  Component: ActiveEventsPage,
};

export const myJamsPastEventsRoute: RouteItem = {
  path: RoutePath.MY_JAMS_PAST,
  name: withI18NPrefix(i18nKeys.myJams.eventTabs.pastEvents),
  exact: true,
  Component: PastEventsPage,
};

export const myJamsUpcomingEventsRoute: RouteItem = {
  path: RoutePath.MY_JAMS_UPCOMING,
  name: withI18NPrefix(i18nKeys.myJams.eventTabs.upcomingEvents),
  exact: true,
  Component: UpcomingEventsPage,
};

export const jamProfile: RouteItem = {
  path: RoutePath.JAM_PROFILE,
  name: withI18NPrefix(i18nKeys.JamProfile.title),
  exact: true,
  Component: ProfilePage,
};
export const jamProfileDetails: RouteItem = {
  path: RoutePath.JAM_PROFILE_DETAILS,
  name: withI18NPrefix(i18nKeys.JamProfile.jamProfileDetails.title),
  exact: true,
  Component: ProfileDetailsPage,
};

export const userProfileCreate: RouteItem = {
  path: RoutePath.USER_PROFILE_CREATE,
  name: withI18NPrefix(i18nKeys.createJamProfile.title),
  exact: true,
  Component: CreateProfilePage,
};

export const jamSkillProfile: RouteItem = {
  path: RoutePath.JAM_SKILL_PROFILE,
  name: withI18NPrefix(i18nKeys.JamProfile.jamSkillProfile.title),
  exact: true,
  Component: ProfileSkillDetailsPage,
};
export const jamPrizes: RouteItem = {
  path: RoutePath.JAM_PRIZES,
  name: withI18NPrefix(i18nKeys.JamProfile.prizes.title),
  exact: true,
  Component: ProfilePrizesPage,
};
export const dataAndPrivacy: RouteItem = {
  path: RoutePath.DATA_AND_PRIVACY,
  name: withI18NPrefix(i18nKeys.JamProfile.DataAndPrivacy.title),
  exact: true,
  Component: DataAndPrivacyPage,
};

export const joinAnEvent: RouteItem = {
  path: RoutePath.JOIN_AN_EVENT,
  name: withI18NPrefix(i18nKeys.myJams.joinAnEvent.title),
  exact: true,
  Component: JoinAnEventPage,
};

export const jamEventRoute: ParameterizedRouteItem = {
  path: RoutePath.JAM_EVENT,
  name: withI18NPrefix(i18nKeys.myJams.title),
  exact: false,
  Component: JamEventPage,
  entityType: 'jam-event-onboarding',
  resolvePath: (...params) => `${MY_JAM_PREFIX}/${params[0] || ''}`,
};

export const jamEventOnboardingRoute: ParameterizedRouteItem = {
  path: RoutePath.JAM_EVENT_ONBOARDING,
  name: withI18NPrefix(i18nKeys.joinTeam.title),
  exact: false,
  Component: JamEventOnboardingPage,
  resolvePath: (...params) => `${MY_JAM_PREFIX}/${params[0] || ''}/onboarding`,
};

export const jamEventOnboardingOptionsRoute: ParameterizedRouteItem = {
  path: RoutePath.JOIN_TEAM,
  name: 'anything',
  exact: false,
  Component: JamEventJoinTeamPage,
  entityType: 'jam-event-onboarding-options',
  resolvePath: (...params) => `${MY_JAM_PREFIX}/${params[0] || ''}/onboarding/${params[1] || ''}`,
};

export const jamEventTeamRoute: ParameterizedRouteItem = {
  path: RoutePath.JAM_EVENT_TEAM,
  name: withI18NPrefix(i18nKeys.jamTeam.title),
  exact: false,
  Component: JamEventTeamPage,
  resolvePath: (...params) => `${MY_JAM_PREFIX}/${params[0] || ''}/team`,
};

export const jamFacilitatorMessagingRoute: ParameterizedRouteItem = {
  path: RoutePath.JAM_FACILITATOR_MESSAGING,
  name: withI18NPrefix(i18nKeys.facilitator.messaging.title),
  exact: false,
  Component: JamEventFacilitatorMessagingPage,
  resolvePath: (...params) => `${MY_JAM_PREFIX}/${params[0] || ''}/facilitator/messaging`,
};

export const jamFacilitatorSupportChatsRoute: ParameterizedRouteItem = {
  path: RoutePath.JAM_FACILITATOR_SUPPORT_CHATS,
  name: withI18NPrefix(i18nKeys.facilitator.supportChats.title),
  exact: false,
  Component: JamEventSupportChatsPage,
  resolvePath: (...params) => `${MY_JAM_PREFIX}/${params[0] || ''}/facilitator/support-chats`,
};

export const jamFacilitatorJamSettingsRoute: ParameterizedRouteItem = {
  path: RoutePath.JAM_FACILITATOR_JAM_SETTINGS,
  name: withI18NPrefix(i18nKeys.facilitator.jamSettings.title),
  exact: false,
  Component: JamEventSettingsPage,
  resolvePath: (...params) => `${MY_JAM_PREFIX}/${params[0] || ''}/facilitator/settings`,
};

export const jamFacilitatorNotificationsRoute: ParameterizedRouteItem = {
  path: RoutePath.JAM_FACILITATOR_NOTIFICATIONS,
  name: withI18NPrefix(i18nKeys.facilitator.notifications.title),
  exact: false,
  Component: JamEventNotificationsPage,
  resolvePath: (...params) => `${MY_JAM_PREFIX}/${params[0] || ''}/facilitator/notifications`,
};

export const jamFacilitatorParticipantsRoute: ParameterizedRouteItem = {
  path: RoutePath.JAM_FACILITATOR_PARTICIPANTS,
  name: withI18NPrefix(i18nKeys.facilitator.participants.title),
  exact: false,
  Component: JamEventFacilitatorParticipantsPage,
  resolvePath: (...params) => `${MY_JAM_PREFIX}/${params[0] || ''}/facilitator/participants`,
};

export const jamMessagesRoute: ParameterizedRouteItem = {
  path: RoutePath.JAM_MESSAGES,
  name: withI18NPrefix(i18nKeys.jamMessages.title),
  exact: false,
  Component: JamEventMessagesPage,
  resolvePath: (...params) => `${MY_JAM_PREFIX}/${params[0] || ''}/messages`,
};

export const reportsRoute: RouteItem = {
  path: RoutePath.REPORTS,
  name: withI18NPrefix(i18nKeys.reports.title),
  exact: true,
  Component: ReportsPage,
};

export const labsRoute: RouteItem = {
  path: RoutePath.LABS,
  name: withI18NPrefix(i18nKeys.labs.title),
  exact: true,
  Component: LabsPage,
};

export const publicEventsRoute: RouteItem = {
  path: RoutePath.PUBLIC_EVENTS,
  name: withI18NPrefix(i18nKeys.publicEvents.title),
  exact: true,
  Component: PublicEventsPage,
  entityType: 'catalog',
};

export const routes: RouteItem[] = [homeRoute];

if (config.isEventTemplateEnabled) {
  routes.push(
    eventTemplatesRoute, // /event-catalog-templates'
    createEventTemplateRoute, // /event-catalog-templates/create
    eventTemplateDetailsRoute, // /event-catalog-templates/:eventTemplateId
    catalogRoute, // /events
    eventTemplateCatalogDetailsRoute // /events/details/:catalogId
  );
}

routes.push(
  oauth2Route, // /oauth2
  publicEventsRoute, // /public-events
  eventsRoute, // my-events'
  newEventRoute, // events/new
  eventRoute, // /events/:eventName'
  newCampaignRoute, // /campaigns/new
  campaignRoute, // /campaigns/:campaignId
  campaignsRoute, // /campaigns'
  createChallengeRoute, // /challenges/new
  challengeRoute, // /challenges/:challengeId
  challengesRoute, // /challenges
  createChallengeSetRoute, // /challenge-set/new
  challengeSetRoute, // /challenge-set/:challengeSetId
  challengeSetsRoute, // /challenge-set
  reviewChallengesRoute, // /review-challenges
  createUsagePlanRoute, // /usage-plans/create
  usagePlanDetailsRoute, // /usage-plans/:planId
  usagePlansRoute, // /usage-plan
  prizeRedemptionRoute, // /prizes
  documentationRoute, // //documentation
  settingsRoute, // /setting
  reportsRoute, // /reports
  labsRoute, // /labs
  userProfileCreate, // /profile/create
  jamProfileDetails, // /profile/detail
  jamSkillProfile, // /profile/skill-detail
  jamPrizes, // /profile/prizes
  dataAndPrivacy, // /profile/data-and-privacy
  jamProfile, // /profile
  createJamProfileRoute, // /create-jam-profile
  adminRoute, // /admin
  faqRoute, // /faq
  faqSectionRoute, // /faq/:section
  myJamsRoute, // /jam
  joinAnEvent, // /join-an-event
  myJamsActiveEventsRoute, // /active-events
  myJamsPastEventsRoute, // /past-events
  myJamsUpcomingEventsRoute, // /upcoming-events
  jamEventRoute, // /:eventId
  challengeSnippets, // /challenges/snippets
  challengeResources, // /challenges-resources
  challengeSSHAccess // /SSH-Access
);

export const rootRoute = routes.find((r) => r.path === '/');
export const resolve404Path = (from: string) => `/404?from=${encodeURIComponent(from)}`;

export default routes;
