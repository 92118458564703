import * as React from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { SideNavigation } from '@amzn/awsui-components-react';
import { SideNavigationProps } from '@amzn/awsui-components-react/polaris/side-navigation/interfaces';
import { useActiveRouteDetails } from '@/src/hooks/useActiveRouteDetails';
import { useSplitPanel } from '@/src/store/split-panel.context';
import { useToolPanel } from '@/src/store/tool-panel.context';
import { useEditEvent } from '@/src/store/edit-event.context';
import { useEditCampaign } from '@/src/store/edit-campaign.context';
import { RoutePath } from '@/src/RoutePath';
import { JamEventStatusForSidebar, JamTaskProgress } from '@/src/types/JamChallengeDetails';
import { i18nKeys } from '@/src/utils/i18n.utils';
import {
  JAM_CHALLENGE_DETAILS_ROUTES,
  JAM_CHALLENGE_ROUTES,
  JAM_CHALLENGE_TASK_ROUTES,
  JAM_EVENT_DETAILS_ROUTES,
  JAM_FACILITATOR_DETAILS_ROUTES
} from '@/src/routes';
import { useJamChallenge } from '@/src/store/jam-challenge.context';
import TaskStatus from '@/src/components/ui/molecules/MyJams/JamChallengeDetails/JamTask/TaskStatus';
import { useApi } from '@/src/store/api.context';
import { useJamChallengeDetails } from '@/src/store/jam-challenge-details.context';
import { useJamEventDetails } from '@/src/store/jam-event-details.context';
import { useJamChat } from '@/src/store/jam-chat.context';
import './MyJamSidebar.scss';

/**
 * Types that allow attaching custom "onClick" behavior to side navigation items.
 */
type FollowDetailWithClick = SideNavigationProps.FollowDetail & { onClick?: () => void };

export const MyJamSidebar: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const { activeRoute } = useActiveRouteDetails();
  const [activeNav, setActiveNav] = useState('');
  const { toggleShowSplitPanel } = useSplitPanel();
  const { toggleShowToolPanel } = useToolPanel();
  const { newEventMode } = useEditEvent();
  const { newCampaignMode } = useEditCampaign();
  const { jamChallengeData } = useJamChallenge();
  const { selectedJamChallenge, jamChallengeId, challengeProgress, outputProperties, challengeSupportDetails } =
    useJamChallengeDetails();
  const { eventSlug, event, eventName, unreadMessageCounts } = useJamEventDetails();
  const { allSupportChats } = useJamChat();
  const { jamChallengeDetailsApi } = useApi();
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const [ jamTasks , setJamTasks] = useState<{[key: string]: SideNavigationProps.Item[]}>({});

  const isAnyChallengeUnlocked = React.useMemo(() => {
    return jamChallengeData?.challenges?.some(({ locked }) => !locked);
  }, [jamChallengeData?.challenges]);

  // If the provided link is empty, do not redirect pages
  const onFollow = (e: CustomEvent<FollowDetailWithClick>) => {
    toggleShowSplitPanel(false);
    toggleShowToolPanel(false);
    e.preventDefault();

    if (e.detail.onClick) {
      e.detail.onClick();
    } else if (e.detail.href) {
      history.push(e.detail.href);
    }
  };

  useEffect(() => {
    if (location.hash) {
      if (newEventMode) setActiveNav(location.hash);
      if (newCampaignMode) setActiveNav(location.hash);
    } else {
      setActiveNav(activeRoute);
    }
  }, [activeRoute, location.pathname]);

  // Update the challenge progress/information for the whole event.
  // This usually happens when the page loads or a websocket message comes in updating the challenge list.
  useEffect(() => {
    jamChallengeData?.challenges?.map(async (challenge) => {
      if (challenge.taskProgress?.length) {
        // Challenge progress contained in event details - use that.
        setJamTasks(prevState => ({
          ...prevState,
          [challenge.id]: getJamTaskNavigationFromTaskProgress(challenge.id, challenge.locked, !!challenge.started, challenge.taskProgress)
        }));
      } else {
        // Challenge progress was not found in challenge data.
        // This usually happens if a challenge was added to the event after the page was loaded.
        // If this happens, fall back to reading the challenge progress details from the API.
        const responseProgress = await jamChallengeDetailsApi.fetchJamChallengeProgress(eventName, challenge.id);
        setJamTasks(prevState => ({
          ...prevState,
          [challenge.id] : getJamTaskNavigationFromTaskProgress(challenge.id, challenge.locked, responseProgress.started, responseProgress.taskProgress)
        }));
      }
    });
  }, [jamChallengeData]);

  // Update the sidebar using progress data from the current challenge being worked on.
  // Since it's updated from the learner's own local actions, it's going to be more
  // up to date than the data in `jamChallengeData`.
  useEffect(() => {
    if (challengeProgress) {
      setJamTasks(prevState => {
        return {
          ...prevState,
          // `challengeProgress` lacks information about whether the challenge is locked.
          // Since the challenge is currently being worked on, it can be assumed to be unlocked.
          // If a facilitator later locks the challenge, jamChallengeData will be updated via websocket, and the previous hook will run to lock the challenges as appropriate.
          [challengeProgress.challengeId]: getJamTaskNavigationFromTaskProgress(challengeProgress.challengeId, false, challengeProgress.started, challengeProgress.taskProgress),
        };
      });
    }
  }, [challengeProgress]);

  const validateTaskLink = (challengeId : string, challengeLocked: boolean, challengeStarted: boolean, task: JamTaskProgress) => {
    if (
      task.locked ||
      // let facilitator navigate without challenge start
      (challengeLocked || !challengeStarted) && !event?.facilitator
    ) {
      return '';
    }
    return JAM_CHALLENGE_TASK_ROUTES.resolve([eventSlug, challengeId, task.taskId]);
  };

  const getJamTaskNavigationFromTaskProgress = (challengeId: string, challengeLocked: boolean, challengeStarted: boolean, taskProgress?: JamTaskProgress[]): SideNavigationProps.Item[] => {
    if (!taskProgress?.length) return [];
    const sorted = [...taskProgress];
    sorted.sort((a, b) => a.taskNumber - b.taskNumber);
    return sorted.map((task) => ({
      type: 'link',
      text: `${t(i18nKeys.myJams.challenges.details.task.noTitle)} ${task?.taskNumber}`,
      href: validateTaskLink(challengeId, challengeLocked, challengeStarted, task),
      info: <TaskStatus taskCompleted={task.completed} taskLocked={task.locked} status={event?.eventStatus} challengeStatus={challengeStarted} />
    }));
  }

  const getJamChallengesDetailRoutes = (challengeId: string): SideNavigationProps.Item[] => {
    return [
      {
        type: 'link',
        text: t(i18nKeys.myJams.challenges.details.overview.title),
        href: JAM_CHALLENGE_DETAILS_ROUTES.Overview.resolve([eventSlug, challengeId]),
      },
      // Only show output properties if the challenge is currently selected.
      // This is a limitation of the `outputProperties` context object, which only holds output properties for
      // the currently selected challenge.
      // TODO - Improve the context implementation to contain output property information for all challenges.
      //    https://sim.amazon.com/issues/JAM-13694
      ...(challengeId === jamChallengeId && !selectedJamChallenge?.locked && outputProperties && outputProperties.length > 0
        ? ([
          {
            type: 'link',
            text: t(i18nKeys.myJams.challenges.details.outputProperties.title),
            href: JAM_CHALLENGE_DETAILS_ROUTES.OutputProperties.resolve([eventSlug, challengeId]),
          },
        ] as SideNavigationProps.Item[])
        : []),
      ...(jamTasks[challengeId] || []),
      ...(event?.facilitator
        ? ([
          {
            type: 'link',
            text: t(i18nKeys.myJams.challenges.details.sidebar.recentFeedback),
            href: JAM_CHALLENGE_DETAILS_ROUTES.RecentFeedback.resolve([eventSlug, challengeId]),
          },
        ] as SideNavigationProps.Item[])
        : []),
      ...(event?.facilitator && event.testEvent
        ? ([
          {
            type: 'link',
            text: t(i18nKeys.myJams.challenges.details.sidebar.issues),
            href: JAM_CHALLENGE_DETAILS_ROUTES.Issue.resolve([eventSlug, challengeId]),
          },
        ] as SideNavigationProps.Item[])
        : []),
      ...(event?.facilitator && challengeSupportDetails?.getChallengeSupportDetails(challengeId)?.wiki?.length
        ? ([
          {
            type: 'link',
            text: t(i18nKeys.myJams.challenges.details.sidebar.wiki),
            href: JAM_CHALLENGE_DETAILS_ROUTES.Wiki.resolve([eventSlug, challengeId]),
          },
        ] as SideNavigationProps.Item[])
        : []),
      ...(event?.facilitator
        ? ([
          {
            type: 'link',
            text: t(i18nKeys.myJams.challenges.details.sidebar.faciliatorNotes),
            href: JAM_CHALLENGE_DETAILS_ROUTES.FacilitatorNotes.resolve([eventSlug, challengeId]),
          },
        ] as SideNavigationProps.Item[])
        : []),
    ];
  };

  const AllJamChallenges: SideNavigationProps.Item[] =
    jamChallengeData?.challenges?.map((challenge) => ({
      type: 'expandable-link-group',
      text: challenge.title ?? challenge.id,
      defaultExpanded: jamChallengeId === challenge.id,
      href: !challenge.locked ? JAM_CHALLENGE_ROUTES.resolve([eventSlug, challenge.id]) : '',
      info: challenge.locked ? (
        <TaskStatus name={JamEventStatusForSidebar.LOCKED} />
      ) : challenge.solved ? (
        <TaskStatus name={JamEventStatusForSidebar.SOLVED} />
      ) : (
        ''
      ),
      items: getJamChallengesDetailRoutes(challenge.id),
    })) ?? [];

  // it is possible that below items will be filtered based on
  // isSupportUser access. right now if user is even support user
  // he will be able to see the facilitator menu
  const FacilitatorMenu: SideNavigationProps.Section = {
    type: 'section',
    text: t(i18nKeys.facilitator.title),
    items: [
      {
        type: 'link',
        text: t(i18nKeys.facilitator.participants.title),
        href: JAM_FACILITATOR_DETAILS_ROUTES.Participants.resolve(eventSlug),
      },
      {
        type: 'link',
        text: t(i18nKeys.facilitator.jamSettings.title),
        href: JAM_FACILITATOR_DETAILS_ROUTES.JamSettings.resolve(eventSlug),
      },
      {
        type: 'link',
        text: t(i18nKeys.facilitator.messaging.title),
        href: JAM_FACILITATOR_DETAILS_ROUTES.Messaging.resolve(eventSlug),
      },
      ...(event?.supportChatEnabled
        ? [
          {
            type: 'link',
            text:
              t(i18nKeys.facilitator.supportChats.title) +
              (allSupportChats.length > 0 ? ` (${allSupportChats.length})` : ''),
            href: JAM_FACILITATOR_DETAILS_ROUTES.SupportChats.resolve(eventSlug),
          } as SideNavigationProps.Item,
        ]
        : []),
      {
        type: 'link',
        text: t(i18nKeys.facilitator.notifications.notification),
        href: JAM_FACILITATOR_DETAILS_ROUTES.Notifications.resolve(eventSlug),
      },
    ],
  };

  return (
    <div className="jam-sidebar">
      <SideNavigation
        activeHref={activeNav}
        header={{
          href: RoutePath.MY_JAMS,
          text: event ? (event.title.length > 40 ? event.title.slice(0, 40) + '...' : event.title) : 'AWS Jam',
        }}
        items={[
          ...(event?.gamified
            ? [
              {
                type: 'link',
                text: t(i18nKeys.jamDashboard.leaderboard.title),
                href: JAM_EVENT_DETAILS_ROUTES.LeaderBoard.resolve(eventSlug),
              } as SideNavigationProps.Item,
            ]
            : []),
          {
            type: isAnyChallengeUnlocked ? 'expandable-link-group' : 'link-group',
            text: t(i18nKeys.challenges.title),
            items: isAnyChallengeUnlocked ? AllJamChallenges : [],
            href: JAM_EVENT_DETAILS_ROUTES.Challenges.resolve(eventSlug),
            defaultExpanded: true,
          },
          ...(!event?.isSingleParticipantEvent && !event?.isSplEvent
            ? [
              {
                type: 'link',
                text: t(i18nKeys.myJams.team.title),
                href: JAM_EVENT_DETAILS_ROUTES.Team.resolve(eventSlug),
              } as SideNavigationProps.Item,
            ]
            : []),
          ...(!event?.facilitator
            ? [
              {
                type: 'link',
                text: t(i18nKeys.myJams.feedback.title),
                href: JAM_EVENT_DETAILS_ROUTES.Feedback.resolve(eventSlug),
              } as SideNavigationProps.Item,
            ]
            : []),
          {
            type: 'link',
            text: `${t(i18nKeys.myJams.messages.title)} ${unreadMessageCounts ? `(${unreadMessageCounts})` : ''}`,
            href: JAM_EVENT_DETAILS_ROUTES.Messages.resolve(eventSlug),
          },
          ...(event?.supportUser || event?.facilitator ? [FacilitatorMenu] : []),
        ]}
        onFollow={onFollow}
      />
      <div className="spacer" />
    </div>
  );
};
