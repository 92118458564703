// todo this should be refactored into not waiting a fixed amount of time, as this could easily end up adding a couple hundred ms to page loads
export const waitForWafIntegration = async (maxAttempts = 10, delayMs = 500) => {
  let attempts = 0;
  while (typeof (window as any).AwsWafIntegration === 'undefined' && attempts < maxAttempts) {
    await new Promise((resolve) => setTimeout(resolve, delayMs));
    attempts++;
  }

  if (typeof (window as any).AwsWafIntegration === 'undefined') {
    throw new Error('AwsWafIntegration failed to load');
  }
};
