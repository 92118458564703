/* eslint-disable @typescript-eslint/no-unsafe-call */
import { Box, Button, Cards, Header } from '@amzn/awsui-components-react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import QrReader, { Result } from 'react-qr-scanner';
import { useApi } from '../../store/api.context';
import { useFlashbars } from '../../store/flashbar.context';
import { PrizeDetails } from '../../types/PrizeDetails';
import { i18nKeys } from '../../utils/i18n.utils';
import { preProdLogger } from '../../utils/log.utils';
import { getFormattedDate } from '../../utils/time.utils';
import { KeyValue } from '../common/KeyValue';
import { LoadingBar } from '../common/LoadingBar';

const PrizeRedemption: React.FC = () => {
  interface PrizeCard {
    description: string;
    status: string;
    createDate: string;
    value: PrizeDetails;
    isClaimed: boolean;
  }
  const { t } = useTranslation();
  const [, setData] = useState('');
  const { userApi } = useApi();
  const [loading, setLoading] = useState(true);
  const [prizeCards, setPrizeCards] = useState<PrizeCard[]>([]);
  const { addSuccessFlashbar, addWarningFlashbar } = useFlashbars();
  const { i18n } = useTranslation();

  const handleResult = (result: Result | null) => {
    if (result && result.text) {
      setData(result.text);
      setLoading(true);
      getPrizesForCode(result.text);
    }
  };

  const getPrizesForCode = (code: string, isReload = false) => {
    userApi
      .getUserPrizesByCode(code)
      .then((res) => {
        setLoading(false);
        generatePrizeCards(res, isReload);
      })
      .catch((err) => {
        preProdLogger('Error getting prizes by code', err.message);
      });
  };

  const handleOnLoad = () => {
    setLoading(false);
  };

  const handleRedeemPrize = (prize: PrizeCard) => {
    userApi
      .markParticipantPrizeAsClaimed(prize.value)
      .then(() => {
        if (prize.value.prizeCode) {
          getPrizesForCode(prize.value.prizeCode, true);
          return;
        }
      })
      .catch((err) => {
        preProdLogger('Error redeeming prize', err.message);
      });
    return;
  };

  const generatePrizeCards = (prizesRes: PrizeDetails[], isReload: boolean) => {
    const newPrizeCards: PrizeCard[] = [];
    if (!isReload) {
      if (prizesRes.length > 0) {
        addSuccessFlashbar(t(i18nKeys.prizeRedemption.alerts.foundPrizes));
      } else {
        addWarningFlashbar(t(i18nKeys.prizeRedemption.alerts.noPrizesFound));
      }
    }
    prizesRes.forEach((prize: PrizeDetails) => {
      newPrizeCards.push({
        description: prize.description || '',
        status: prize.isClaimed
          ? t(i18nKeys.prizeRedemption.labels.claimed)
          : t(i18nKeys.prizeRedemption.labels.available),
        createDate: prize.createDate || '',
        value: prize,
        isClaimed: prize.isClaimed,
      });
    });
    setPrizeCards(newPrizeCards);
  };

  return (
    <React.Fragment>
      <Header variant="h1">{t(i18nKeys.prizeRedemption.title)}</Header>
      {loading && (
        <Box textAlign="center">
          <LoadingBar />
          {t(i18nKeys.prizeRedemption.messages.loading)}
        </Box>
      )}
      <React.Fragment>
        <Cards
          cardDefinition={{
            header: (item: PrizeCard) => (
              <Box textAlign="center">
                <h3>{item.description}</h3>
              </Box>
            ),
            sections: [
              {
                id: 'status',
                content: (item: PrizeCard) => (
                  <Box textAlign="center">
                    <KeyValue label={<strong>{t(i18nKeys.prizeRedemption.labels.status)}</strong>}>
                      <span className={item.isClaimed ? 'warning' : 'aws-positive-green'}>{item.status}</span>
                    </KeyValue>
                  </Box>
                ),
              },
              {
                id: 'awardedDate',
                content: (item: PrizeCard) => (
                  <Box textAlign="center">
                    <KeyValue label={<strong>{t(i18nKeys.prizeRedemption.labels.awardedDate)}</strong>}>
                      {getFormattedDate(new Date(item.createDate), i18n.language)}
                    </KeyValue>
                  </Box>
                ),
              },
              {
                id: 'action',
                content: (item) => (
                  <Box textAlign="center">
                    <Button variant="primary" disabled={loading} onClick={() => handleRedeemPrize(item)}>
                      {t(i18nKeys.prizeRedemption.buttons.iGaveThePrizeToTheParticipant)}
                    </Button>
                  </Box>
                ),
              },
            ],
          }}
          items={prizeCards}
          cardsPerRow={[{ cards: 3 }]}
        />
        <Box textAlign="center">
          <QrReader
            onScan={(result: Result | null) => handleResult(result)}
            onLoad={() => handleOnLoad()}
            onError={() => {
              preProdLogger('error scanning QR code');
            }}
            delay={500}
            facingmode="rear"
          />
        </Box>
      </React.Fragment>
    </React.Fragment>
  );
};

export default PrizeRedemption;
