import { ApprovalStatus } from '@/src/types/common';
import { i18nKeys } from '@/src/utils/i18n.utils';
import { joinClasses } from '@/src/utils/scss.utils';
import { getTimelineFormattedDate } from '@/src/utils/time.utils';
import { Icon } from '@amzn/awsui-components-react/uxdg';
import moment from 'moment';
import React, { ReactNode, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Campaign } from '../../../../types/Campaign';
import { Event, EventStatus } from '../../../../types/Event';
import styles from './TargetTimeline.module.scss';

export interface TargetTimelineProps {
  target?: Event | Campaign;
}

interface TargetTimelineItemProps {
  icon: ReactNode;
  title: string;
  subtitle: string;
  date: string;
  active?: boolean;
  cancelled?: boolean;
}

const getFormattedDate = (dateVal: number | string | null) => {
  if (!dateVal) return '';

  const date = new Date(dateVal);
  return `${getTimelineFormattedDate(date)} (GMT${moment(date).format('Z')})`;
};

const TargetTimelineItem: React.FC<TargetTimelineItemProps> = (props) => {
  const classes = [styles.item];

  if (props.active) {
    classes.push(styles.active);
  }
  if (props.cancelled) {
    classes.push(styles.cancelled);
  }

  return (
    <li className={joinClasses(classes)}>
      <span className={styles.itemIcon}>{props.icon}</span>
      <div className={styles.itemTitle}>
        <h3 className={styles.itemTitleText} data-testid="item-title">
          {props.title}
        </h3>
        <span className={styles.itemHorizontalBar} />
      </div>
      <span className={styles.itemVerticalBar} />
      <div className={styles.itemSubtitle}>
        <div className={styles.itemSubtitleText} data-testid="item-subtitle">
          {props.subtitle}
        </div>
        <div className={styles.itemSubtitleDate} data-testid="item-date">
          {props.date}
        </div>
      </div>
    </li>
  );
};

const TargetTimeline: React.FC<TargetTimelineProps> = ({ target }) => {
  const { t } = useTranslation();
  const event = target as Event;

  const eventApproved = target?.approvalStatus === ApprovalStatus.REQUEST_APPROVED;
  const eventPending = [ApprovalStatus.REQUEST_PENDING, ApprovalStatus.REQUEST_SUBMITTED].includes(
    target?.approvalStatus as ApprovalStatus
  );

  const eventStarted = new Date(event?.startDate || '') < new Date() && event?.status !== EventStatus.NOT_STARTED;
  const eventEnded = new Date(event?.endDate || '') < new Date();

  const circleWithDiv = (num: number): JSX.Element => <div className={styles.circleNew}>{num}</div>;

  const approvalIcon = useMemo(() => {
    switch (target?.approvalStatus) {
      case ApprovalStatus.REQUEST_SUBMITTED:
        return <Icon data-testid="submitted-icon" name="status-in-progress" size="medium" />;
      case ApprovalStatus.REQUEST_PENDING:
        return <Icon data-testid="pending-icon" name="status-pending" size="medium" />;
      case ApprovalStatus.REQUEST_CANCELLED:
      case ApprovalStatus.REQUEST_DENIED:
        return <Icon data-testid="x-icon" name="status-negative" size="medium" variant="error" />;
      case ApprovalStatus.REQUEST_APPROVED:
        return <Icon data-testid="check-icon" name="status-positive" size="medium" variant="success" />;
    }
  }, [target?.approvalStatus]);

  if (
    [ApprovalStatus.REQUEST_SUBMITTED, ApprovalStatus.REQUEST_PENDING, ApprovalStatus.REQUEST_APPROVED].includes(
      target?.approvalStatus as ApprovalStatus
    )
  ) {
    return (
      <ol className={styles.timeline}>
        <TargetTimelineItem
          icon={approvalIcon}
          active={eventApproved}
          title={
            eventPending
              ? t(i18nKeys.events.eventDetails.labels.statuses.requestPending)
              : t(i18nKeys.events.eventDetails.timeline.scheduled)
          }
          subtitle={t(i18nKeys.events.eventDetails.timeline.createdOn)}
          date={getFormattedDate(event?.createdDate)}
        />
        <TargetTimelineItem
          icon={eventStarted ? <Icon name="status-positive" size="medium" variant="success" /> : circleWithDiv(2)}
          active={eventStarted}
          title={t(i18nKeys.events.eventDetails.timeline.start)}
          subtitle={t(i18nKeys.events.eventDetails.timeline.scheduledFor)}
          date={getFormattedDate(event?.startDate)}
        />
        <TargetTimelineItem
          icon={eventEnded ? <Icon name="status-positive" size="medium" variant="success" /> : circleWithDiv(3)}
          active={eventEnded}
          title={t(i18nKeys.events.eventDetails.timeline.end)}
          subtitle={t(i18nKeys.events.eventDetails.timeline.scheduledFor)}
          date={getFormattedDate(event?.endDate)}
        />
      </ol>
    );
  }

  return (
    <ol className={styles.timeline}>
      <TargetTimelineItem
        icon={circleWithDiv(1)}
        title={t(i18nKeys.events.eventDetails.timeline.created)}
        subtitle={t(i18nKeys.events.eventDetails.timeline.createdOn)}
        date={getFormattedDate(event?.createdDate)}
      />
      <TargetTimelineItem
        icon={approvalIcon}
        cancelled
        title={
          target?.approvalStatus === ApprovalStatus.REQUEST_CANCELLED
            ? t(i18nKeys.general.cancelled)
            : t(i18nKeys.general.denied)
        }
        subtitle={
          target?.approvalStatus === ApprovalStatus.REQUEST_CANCELLED
            ? t(i18nKeys.events.eventDetails.timeline.cancelledOn)
            : t(i18nKeys.events.eventDetails.timeline.deniedOn)
        }
        date={getFormattedDate(event?.lastUpdatedDate)}
      />
    </ol>
  );
};
export default TargetTimeline;
