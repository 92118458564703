import { useCollection } from '@amzn/awsui-collection-hooks';
import { ButtonDropdown, Pagination, SpaceBetween, Table, TextFilter } from '@amzn/awsui-components-react';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ChallengeDeploymentStatistics,
  DeploymentStatisticListItem,
  DeploymentStatistics,
  TimeRangeStatistic,
} from '../../../../../types/Challenge';
import { Nullable } from '../../../../../types/common';
import { i18nKeys } from '../../../../../utils/i18n.utils';
import { paginationLabels } from '../../../../../utils/table.utils';
import { TableEmptyState } from '../../../../common/TableEmptyState';
import { TableHeader } from '../../../../common/TableHeader';
import { COLUMN_DEFINITIONS, filteringFunction } from './deployment-statistics-config';

interface ChallengeDeploymentStatisticsDetailProps {
  challengeDeploymentStatistics?: ChallengeDeploymentStatistics;
}

const ChallengeDeploymentStatisticsDetail: React.FC<ChallengeDeploymentStatisticsDetailProps> = ({
  challengeDeploymentStatistics,
}) => {
  const { t } = useTranslation();
  const [deploymentStatisticListItems, setDeploymentStatisticListItems] = useState<DeploymentStatisticListItem[]>();
  const [timeRangeLabel, setTimeRangeLabel] = useState<string>(t(i18nKeys.general.year))
  const [timeFilter, setTimeFilter] = useState<string>('year');

  const [preferences] = useState({
    pageSize: 10,
    visibleColumns: [
      'region',
      'succuessfulLabs',
      'totalLabs',
      'percentLabsSuccessful',
      'stackDeployTime',
      'labVendTime',
    ],
  });

  useEffect(() => {
    const stats: DeploymentStatisticListItem[] = [];

    if (challengeDeploymentStatistics) {
      const globalStats: Nullable<DeploymentStatistics> = challengeDeploymentStatistics.globalStatistics;
      const regionalStats: Nullable<Map<string, DeploymentStatistics>> =
        challengeDeploymentStatistics.regionalStatistics;

      if (globalStats && regionalStats) {
        regionalStats.forEach((value, key) => {
          stats.push(
            new DeploymentStatisticListItem(
              key,
              value.successfulDeployments?.[timeFilter as keyof TimeRangeStatistic] || 0,
              value.totalDeployments?.[timeFilter as keyof TimeRangeStatistic] || 0,
              value.deploymentSuccessRate?.[timeFilter as keyof TimeRangeStatistic] || 0,
              value.avgStackDeployTime?.[timeFilter as keyof TimeRangeStatistic] || 0,
              value.avgVendTime?.[timeFilter as keyof TimeRangeStatistic] || 0
            )
          );
        });

        stats.push(
          new DeploymentStatisticListItem(
            'global',
            globalStats.successfulDeployments?.[timeFilter as keyof TimeRangeStatistic] || 0,
            globalStats.totalDeployments?.[timeFilter as keyof TimeRangeStatistic] || 0,
            globalStats.deploymentSuccessRate?.[timeFilter as keyof TimeRangeStatistic] || 0,
            globalStats.avgStackDeployTime?.[timeFilter as keyof TimeRangeStatistic] || 0,
            globalStats.avgVendTime?.[timeFilter as keyof TimeRangeStatistic] || 0
          )
        );
      }

      setDeploymentStatisticListItems(stats);
    }
  }, [challengeDeploymentStatistics, timeFilter]);

  const { items, actions, filteredItemsCount, collectionProps, filterProps, paginationProps } = useCollection(
    deploymentStatisticListItems || [],
    {
      filtering: {
        filteringFunction,
        empty: (
          <TableEmptyState
            title={t(i18nKeys.challenges.challengeDetails.table.emptyDeploymentStatisticsState)}
          />
        ),
        noMatch: (
          <TableEmptyState
            title={t(i18nKeys.tables.noMatch.title)}
            subtitle={t(i18nKeys.tables.noMatch.subtitle)}
            onClearFilter={() => actions.setFiltering('')}
          />
        ),
      },
      pagination: { pageSize: preferences.pageSize },
      sorting: {},
    }
  );

  const handleActionClick = (clickEvent: any) => {
    const rangeLabels = {
      'all': t(i18nKeys.general.all),
      'year': t(i18nKeys.general.year),
      'month': t(i18nKeys.general.month),
      'week': t(i18nKeys.general.week)
    }

    setTimeRangeLabel(rangeLabels[clickEvent.detail.id as keyof typeof rangeLabels])
    setTimeFilter(clickEvent.detail.id);
  };

  return (
    <Table
      {...collectionProps}
      variant="container"
      resizableColumns
      header={
        <TableHeader
          totalItems={deploymentStatisticListItems ? deploymentStatisticListItems.length : 0}
          title={t(i18nKeys.challenges.challengeDetails.headings.deploymentStatistics)}
          toggleTools={() => alert('challenge list tools not implemented yet')}
          actionButtons={
            <SpaceBetween direction="horizontal" size="s">
              <strong>{`${t(i18nKeys.challenges.challengeDetails.titles.timeRange)}:`}</strong>
              <ButtonDropdown
                data-testid="challenge-deployment-statistics-button"
                variant="primary"
                onItemClick={handleActionClick}
                items={[
                  {
                    text: t(i18nKeys.general.week)!,
                    id: 'week',
                  },
                  {
                    text: t(i18nKeys.general.month)!,
                    id: 'month',
                  },
                  {
                    text: t(i18nKeys.general.year)!,
                    id: 'year',
                  },
                  {
                    text: t(i18nKeys.general.all)!,
                    id: 'all',
                  }
                ]}>
                {timeRangeLabel}
              </ButtonDropdown>
            </SpaceBetween>
          }
        />
      }
      columnDefinitions={COLUMN_DEFINITIONS}
      items={items}
      loadingText={t(i18nKeys.challenges.challengeDetails.table.loadingIssues)}
      pagination={<Pagination {...paginationProps} ariaLabels={paginationLabels(t)} />}
      filter={
        <TextFilter
          {...filterProps}
          countText={t(i18nKeys.tables.matchesCount, { count: filteredItemsCount })}
          filteringAriaLabel={t(i18nKeys.challenges.filteringLabel)}
        />
      }
    />
  );
};

export default ChallengeDeploymentStatisticsDetail;
