/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import {
  Box,
  Button,
  Container,
  Header,
  Link,
  Modal,
  SpaceBetween,
  TextContent,
} from '@amzn/awsui-components-react';
import React, { createRef, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useChallenges } from '../../../../store/challenge.context';
import { ChallengePropAction, useCreateChallenge } from '../../../../store/create-challenge.context';
import { useFlashbars } from '../../../../store/flashbar.context';
import { Challenge, ChallengeReviewableSection, IamPolicyValidationResponse, ValidationFindings } from '../../../../types/Challenge';
import { i18nKeys } from '../../../../utils/i18n.utils';
import { preProdLogger } from '../../../../utils/log.utils';
import JamCodeEditor from '../../challengesCommon/JamCodeEditor';
import { ChallengeReviewPopover } from '../../challengesCommon/ChallengeReviewPopover';
import './IAMPolicy.scss';
import CodeView from '@amzn/awsui-code-view/code-view';
import jsonHighlight from "@amzn/awsui-code-view/highlight/json";
import { ChallengeSnippetsInner } from '@/src/components/challenges/challengeDetailSections/challengeIAMPolicy/ShowIAMPolicy';
import { downloadAs } from '@/src/utils/download.utils';

interface ChallengeIAMPolicyDetailProps {
  challenge: Challenge;
}

const ChallengeIAMPolicyDetail: React.FC<ChallengeIAMPolicyDetailProps> = ({ challenge }) => {
  const { t } = useTranslation();
  const { validateIamPolicy } = useChallenges();
  const { addErrorFlashbar } = useFlashbars();
  const { editMode, editedChallenge, handleUpdateChallengeProp } = useCreateChallenge();
  const [validationResponse, setValidationResponse] = useState<IamPolicyValidationResponse | undefined>();
  const [showIAMSnippets, showIAMSnippetsSet] = useState(false);
  const [validatePolicyLoading, validatePolicyLoadingSet] = useState(false);
  const hiddenFileInput = createRef<HTMLInputElement>();

  const iamPolicyFileName = `aws-jam-${challenge.challengeId}-team-iam-policy`;

  const handleFileUpload = (uploadedFile: File) => {
    // Handle file upload
    uploadedFile
      .text()
      .then((file) => {
        handleUpdateChallengeProp(ChallengePropAction.STUDENT_POLICY, file);
      })
      .catch((err) => preProdLogger(err));
  };

  const handleUpdateIamPolicy = (policy: string) => {
    if (editMode) {
      handleUpdateChallengeProp(ChallengePropAction.STUDENT_POLICY, policy);
    }
  };

  const onValidateIamPolicy = () => {
    const policyToCheck = editMode ? editedChallenge?.props?.studentPolicy : challenge?.props?.studentPolicy;
    if (!policyToCheck || policyToCheck.trim().length < 1) {
      addErrorFlashbar(t(i18nKeys.challenges.challengeDetails.messages.emptyPolicy));
    } else {
      validatePolicyLoadingSet(true);
      validateIamPolicy(policyToCheck)
        .then((response) => setValidationResponse(response))
        .catch((err) => preProdLogger(err))
        .finally(() => validatePolicyLoadingSet(false));
    }
  };

  const ValidationFindingsList = ({ data }: { data: ValidationFindings[]; }) => {
      if (data?.length > 0) {
        return (
          <ul>
            {data.map((result, i) => (
              <li key={i}>
                <b>{result.issueCode}:</b> {result.finding}{' '}
                <a href={result.learnMoreLink}> {t(i18nKeys.general.learnMore)} </a>{' '}
              </li>
            ))}
          </ul>
        );
      } else {
        return <div>{t(i18nKeys.challenges.challengeDetails.messages.emptyPolicy)}</div>;
      }
  };

  const headerRef = useRef(null);

  return (
    <div className="iam-policy">
      <Container
        header={
          <>
            <Box float="left">
              <Header variant="h2" actions={<ChallengeReviewPopover section={ChallengeReviewableSection.IAM_POLICY} />}>
                {t(i18nKeys.challenges.challengeDetails.headings.teamIAMPolicy)}
              </Header>
            </Box>
            <Box float='right'>
              <Button data-testid='challenge-i-am-policy-validate-policy'
                iconName="status-positive"
                loading={validatePolicyLoading}
                onClick={onValidateIamPolicy}>
                {t(i18nKeys.challenges.subSections.iamPolicy.validatePolicy)}
              </Button>
            </Box>
          </>
        }>
        <SpaceBetween size="s">
          {editMode && (
            <TextContent>
              <div style={{ maxWidth: '1024px' }}>{t(i18nKeys.challenges.subSections.iamPolicy.infoFlashbar)}</div>
            </TextContent>
          )}
          {challenge.hasIamPolicy && !editMode && (
            <TextContent>
              <div style={{ maxWidth: '1024px' }}>{t(i18nKeys.challenges.subSections.iamPolicy.infoFlashbar)}</div>
            </TextContent>
          )}
          {editMode && (
            <Box float="right">
              <Button onClick={() => showIAMSnippetsSet(true)}>{t(i18nKeys.challenges.challengeDetails.buttons.showIamSnippets)}</Button>
            </Box>
          )}
          {editMode && (
            <Container
              header={<Header>{t(i18nKeys.challenges.subSections.cfnTemplate.toolsAndResources)}</Header>}>
              <Button variant='inline-link' iconAlign='left' iconName='external' onClick={() => showIAMSnippetsSet(true)}>
                {t(i18nKeys.challenges.subSections.iamPolicy.iamSnippets)}
              </Button>
            </Container>
          )}
          {editMode && (
            <TextContent>
              <strong>{t(i18nKeys.challenges.subSections.cfnTemplate.format.title)}</strong>{' '}
              {t(i18nKeys.challenges.subSections.iamPolicy.jsonOnlyWarning)}
            </TextContent>
          )}
          {editMode && (
            <div className="iam-dropzone">
              <Box padding={'l'} textAlign="center">
                <Button
                  id='challenge-i-am-policy-upload' data-testid='challenge-i-am-policy-upload'
                  variant="link"
                  iconName="upload"
                  formAction="none"
                  onClick={() => {
                    // @ts-ignore
                    hiddenFileInput.current.value = null; // allow for re-choosing the same file name
                    // @ts-ignore
                    hiddenFileInput.current.click();
                  }}
                />

                <input
                  ref={hiddenFileInput}
                  id="chooseFileInput"
                  type="file"
                  hidden
                  multiple={false}
                  accept="application/json"
                  // @ts-ignore
                  onChange={(event) => handleFileUpload(event.target.files[0])}
                />
                <p>
                  {t(i18nKeys.challenges.subSections.iamPolicy.dropJsonFiles)}
                  <Link
                    onFollow={() => {
                      // @ts-ignore
                      hiddenFileInput.current.value = null; // allow for re-choosing the same file name
                      // @ts-ignore
                      hiddenFileInput.current.click();
                    }}>
                    {' '}
                    {t(i18nKeys.challenges.subSections.iamPolicy.browseFiles)}
                  </Link>
                </p>
              </Box>
            </div>
          )}
          {challenge.hasIamPolicy && !editMode && (
            <Button
              variant='link'
              iconAlign='left'
              iconName='file'
              data-testid="challenge-i-am-download--policy"
              onClick={() =>
                downloadAs(challenge.props.studentPolicy || '', iamPolicyFileName + '.json', 'text/json')
              }>
              {iamPolicyFileName}
            </Button>
          )}

          {editMode && (
            <JamCodeEditor
              language="json"
              value={editedChallenge?.props.studentPolicy || ''}
              setValue={(e) => {
                handleUpdateIamPolicy(e);
              }}
            />
          )}

          {!editMode && (
             <CodeView
              content={challenge.props.studentPolicy || ''}
              highlight={jsonHighlight} />
          )}
          {validationResponse && (
            <Container
              header={
                <Header variant="h3">{t(i18nKeys.challenges.challengeDetails.titles.iamSecurityFindings)}</Header>
              }>
              <ValidationFindingsList data={validationResponse.findings } />
            </Container>
          )}
        </SpaceBetween>
      </Container>
      <Modal
        header={<div ref={headerRef} />}
        data-testid="challenges-iam-policy__snippets-modal"
        visible={showIAMSnippets}
        size="large"
        onDismiss={() => showIAMSnippetsSet(false)}>
          <ChallengeSnippetsInner headerRef={headerRef} silent />
      </Modal>
    </div>
  );
};

export default ChallengeIAMPolicyDetail;
