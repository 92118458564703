import React, { useContext, useState } from 'react';
import { useApi } from './api.context';
import { JamChallengesData } from '../types/JamChallenges';
import { noop } from 'lodash';
import { JamEventFeedback, JamFeedbackRes } from '../types/JamFeedback';

export interface JamChallengeContextValue {
  jamChallengeData?: JamChallengesData;
  isFetchingJamChallenge: boolean;
  loadJamChallengeData: (params: { id?: string; showLoader?: boolean }) => Promise<{ id: string }>;
  isFetchingJamChallengeFeedback: boolean;
  loadJamChallengeFeedbackData: (params: { id?: string }) => Promise<void>;
  eventFeedbackData?: JamFeedbackRes;
  updateEventFeedback: (feedback: JamEventFeedback) => void;
}

const JamChallengeContext = React.createContext<JamChallengeContextValue>({
  jamChallengeData: undefined,
  isFetchingJamChallenge: false,
  loadJamChallengeData: () => new Promise(noop),
  isFetchingJamChallengeFeedback: false,
  loadJamChallengeFeedbackData: () => new Promise(noop),
  eventFeedbackData: undefined,
  updateEventFeedback: noop,
});

const JamChallengeProvider: React.FC = ({ children }) => {
  const { jamChallengeFeedbackApi, jamEventDetailsApi } = useApi();
  const [jamChallengeData, setJamChallengeData] = useState<JamChallengesData>();
  const [isFetchingJamChallenge, setIsFetchingJamChallenge] = useState(false);
  const [isFetchingJamChallengeFeedback, setIsFetchingJamChallengeFeedback] = useState(false);
  const [eventFeedbackData, setEventFeedbackData] = useState<JamFeedbackRes>();

  const loadJamChallengeData = async (params: { id?: string; showLoader?: boolean }): Promise<{ id: string }> => {
    const { id, showLoader = true } = params;
    setIsFetchingJamChallenge(showLoader);
    if (!id) {
      setIsFetchingJamChallenge(false);
      throw new Error('id is required.');
    }

    try {
      const response = JamChallengesData.fromPlainObject(await jamEventDetailsApi.getEventDetails(id));
      setJamChallengeData(response);
      setIsFetchingJamChallenge(false);
      return { id: response.id ?? '' };
    } catch (e: any) {
      setIsFetchingJamChallenge(false);
      // if API failed catch here.
      throw e;
    }
  };

  const loadJamChallengeFeedbackData = async (params: { id?: string }): Promise<void> => {
    setIsFetchingJamChallengeFeedback(true);
    const { id } = params;
    if (!id) {
      setIsFetchingJamChallengeFeedback(false);
      throw new Error('id is required.');
    }

    try {
      const eventResponse = await jamChallengeFeedbackApi.getJamEventFeedbackData(id);
      setEventFeedbackData(eventResponse);
      setIsFetchingJamChallengeFeedback(false);
    } catch (e: any) {
      setIsFetchingJamChallengeFeedback(false);
      // if API failed catch here.
      throw e;
    }
  };

  const updateEventFeedback = (feedback: JamEventFeedback) => {
    setEventFeedbackData((prev) => ({
      ...(prev as JamFeedbackRes),
      eventFeedback: feedback,
    }));
  };

  const data: JamChallengeContextValue = {
    jamChallengeData,
    loadJamChallengeData,
    isFetchingJamChallenge,
    isFetchingJamChallengeFeedback,
    loadJamChallengeFeedbackData,
    eventFeedbackData,
    updateEventFeedback,
  };
  return <JamChallengeContext.Provider value={data}>{children}</JamChallengeContext.Provider>;
};

const useJamChallenge = () => {
  const context = useContext(JamChallengeContext);
  if (context === undefined) {
    throw new Error('useJamChallenge can only be used inside JamChallengeProvider');
  }
  return context;
};

export { JamChallengeProvider, useJamChallenge };
