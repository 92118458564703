import { jsonMember, jsonObject } from 'typedjson';
import { i18nKeys } from '../utils/i18n.utils';
import { fromPlainObject } from '../utils/mapper.utils';
import * as common from './common';
import { Team, TeamGoal, TeamMember } from './Team';

@jsonObject
export class UserRow {
  @jsonMember(common.NullableStringValue)
  userId: common.NullableString = null;
  @jsonMember(common.NullableStringValue)
  nickname: common.NullableString = null;
  @jsonMember(common.NullableStringValue)
  email: common.NullableString = null;
  @jsonMember(common.NullableStringValue)
  teamName: common.NullableString = null;
  @jsonMember(common.NullableStringValue)
  teamDisplayName: common.NullableString = null;
  @jsonMember(Boolean)
  facilitator = false;
  @jsonMember(Boolean)
  disabled = false;
  @jsonMember(Boolean)
  support = false;
  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  @jsonMember(String)
  goal: common.Nullable<TeamGoal> = null;

  static ofUserId(userId: string): UserRow {
    return fromPlainObject({ userId }, UserRow) as UserRow;
  }

  static fromUnassignedUser(user: TeamMember): UserRow {
    return UserRow.fromTeamMember(user);
  }

  /**
   * Make sure to run the return value through a translation, no way to translate here without a .tsx file for hooks or providers
   * type casting as i18nKey is type any but returns a string
   */
  get getRole(): string {
    if (this.facilitator) {
      return i18nKeys.participants.labels.facilitator;
    }

    if (this.support) {
      return i18nKeys.participants.labels.support;
    }

    return i18nKeys.participants.labels.participant;
  }

  static fromTeamMember(user: TeamMember, team?: Team): UserRow {
    const userRow: UserRow = fromPlainObject(
      {
        userId: user.userId,
        nickname: user.nickname || user.userId,
        facilitator: user.facilitator,
        support: user.support,
        disabled: user.disabled,
      },
      UserRow
    ) as UserRow;

    // if the user is assigned to a team, populate the team details
    if (team) {
      userRow.teamName = team.name;
      userRow.teamDisplayName = team.teamLabel;
    }

    return userRow;
  }
}
