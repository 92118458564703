import React, { MutableRefObject, useRef, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import {
  Badge,
  Box,
  Button,
  ContentLayout,
  Header,
  Modal,
  Popover,
  SpaceBetween,
  StatusIndicator,
  Table,
  TextFilter,
} from '@amzn/awsui-components-react';
import { useTranslation } from 'react-i18next';
import { i18nKeys } from '@/src/utils/i18n.utils';
import { useChallenges } from '@/src/store/challenge.context';
import { useCollection } from '@amzn/awsui-collection-hooks';
import { callIgnoringExceptionsAsync } from '@/src/utils/call-ignoring-exceptions';
import { ChallengeCFN, ChallengeSnippts, Snippets } from '@/src/types/ChallengeSet';
import { useApi } from '@/src/store/api.context';
import { copyToClipboardJSON } from '@/src/utils/copy-to-clipboard'
import CodeView from '@amzn/awsui-code-view/code-view';
import jsonHighlight from "@amzn/awsui-code-view/highlight/json";

const EmptyState = ({ title, subtitle, action }: { title: string; subtitle: string; action: React.ReactNode }) => {
  return (
    <Box textAlign="center" color="inherit">
      <Box variant="strong" textAlign="center" color="inherit">
        {title}
      </Box>
      <Box variant="p" padding={{ bottom: 's' }} color="inherit">
        {subtitle}
      </Box>
      {action}
    </Box>
  );
};

interface SmartTextI {
  text: string;
  length: number;
}

interface ChallengeSnippetsInnerProps {
  headerRef?: MutableRefObject<HTMLElement | null>;
  silent: boolean;
}

export const ChallengeSnippetsInner: React.FC<ChallengeSnippetsInnerProps> = ({ headerRef, silent }) => {
  const { challengeCFN } = useChallenges();
  const [originalTableData, setOriginalTableData] = useState<ChallengeSnippts[]>([]);
  const [challengeSnippts, setChallengeSnippts] = useState<ChallengeSnippts[]>([]);
  const [previewVisible, previewVisibleSet] = useState<boolean>(false);
  const [snipptsMarkdown, setSnipptsMarkdown] = useState<string>('');
  const [filterText, setFilterText] = useState('');

  const { challengesApi } = useApi();
  const { t } = useTranslation();

  useEffect(() => {
    if (!challengeCFN) {
      void getALLIAM();
    }
  }, []);

  useEffect(() => {
    const snippts = challengeCFN?.map((item) => item.sharableSnippet);
    if (snippts) {
      setOriginalTableData(snippts);
      setChallengeSnippts(snippts);
    }
  }, []);

  const getALLIAM = async () => {
    await challengesApi.findByType('IAM', silent).then((res) => {
      const snippts = res.map((item) => item.sharableSnippet);
      if (snippts) {
        setOriginalTableData(snippts);
        setChallengeSnippts(snippts);
      }
    });
  };

  const { items, actions, collectionProps } = useCollection(challengeSnippts, {
    filtering: {
      empty: (
        <EmptyState
          title={t(i18nKeys.eventTemplates.customTable.empty.noTitle, { headerTitle: t(i18nKeys.eventTemplates.title) })}
          subtitle={t(i18nKeys.eventTemplates.customTable.empty.noSubTitle, { headerTitle: t(i18nKeys.eventTemplates.title) })}
          action={
            <Button id="challenge-i-am-policy-empty-table" data-testid="challenge-i-am-policy-empty-table">
              {t(i18nKeys.eventTemplates.customTable.empty.createTitle, { headerTitle: t(i18nKeys.eventTemplates.title) })}
            </Button>
          }
        />
      ),
      noMatch: (
        <EmptyState
          title={t(i18nKeys.eventTemplates.customTable.noMatch.title)}
          subtitle={t(i18nKeys.eventTemplates.customTable.noMatch.subTitle)}
          action={
            <Button
              id="challenge-i-am-policy-no-result"
              data-testid="challenge-i-am-policy-no-result"
              onClick={() => actions.setFiltering('')}>
              {t(i18nKeys.eventTemplates.customTable.noMatch.clearFilter)}
            </Button>
          }
        />
      ),
    },
    pagination: { pageSize: 20 },
    sorting: {},
    selection: {},
  });

  const SmartText: React.FC<SmartTextI> = ({ text, length = 20 }) => {
    const [showLess, setShowLess] = useState(true);

    if (text?.length < length) {
      return <p>{text}</p>;
    }

    return (
      <div>
        <p>{showLess ? `${text?.slice(0, length)}` : text}</p>
        <Button variant="inline-link" onClick={() => setShowLess(!showLess)}>
          {showLess
            ? t(i18nKeys.challenges.subSections.cfnTemplate.challengeSnippts.showDescription)
            : t(i18nKeys.challenges.subSections.cfnTemplate.challengeSnippts.hideDescription)}
        </Button>
      </div>
    );
  };

  const getDescriptions = (item: ChallengeSnippts) => {
    if (item.description && item?.description.length > 1) {
      return <SmartText text={item.description} length={0} />;
    }
  };

  const getSnippt = (id: string) => {
    void callIgnoringExceptionsAsync(async () => {
      return await challengesApi.getSnippets(id);
    }).then((challengeCFNResponse: ChallengeCFN | undefined) => {
      if (challengeCFNResponse) {
        setSnipptsMarkdown((challengeCFNResponse.sharableSnippet as unknown as Snippets).snippet);
        previewVisibleSet(true);
      }
    });
  };

  const getSnipptAndCopy = (id: string) => {
    void callIgnoringExceptionsAsync(async () => {
      return await challengesApi.getSnippets(id);
    }).then((challengeCFNResponse: ChallengeCFN | undefined) => {
      if (challengeCFNResponse) {
        copyToClipboardJSON((challengeCFNResponse.sharableSnippet as unknown as Snippets).snippet);
      }
    });
  };

  const onPreviw = (item: ChallengeSnippts) => {
    getSnippt(item.id || '');
  };

  const filterDataFromTable = (value: string) => {
    setFilterText(value);
    const data = originalTableData.filter((item: ChallengeSnippts) =>
      item?.title?.toLocaleLowerCase().includes(value.toLocaleLowerCase())
    );
    setChallengeSnippts(data);
  };

  return (
    <>
      { !!headerRef?.current && createPortal(<Header variant="h1">
          {t(i18nKeys.challenges.subSections.iamPolicy.snippetTitle, { count: challengeSnippts.length })}
        </Header>, headerRef.current)
      }
        <Table
          {...collectionProps}
          ariaLabels={{
            selectionGroupLabel: t(i18nKeys.challenges.selectionGroupLabel),
            allItemsSelectionLabel: ({ selectedItems }) =>
              `${selectedItems.length} ${selectedItems.length === 1 ? 'item' : 'items'} selected`,
            itemSelectionLabel: ({}, item: ChallengeSnippts) => item.title || '',
          }}
          columnDefinitions={[
            {
              id: 'title',
              header: t(i18nKeys.challenges.subSections.cfnTemplate.challengeSnippts.title),
              cell: (item) => (
                <SpaceBetween direction="horizontal" size={'s'}>
                  <Popover
                    dismissButton={false}
                    position="top"
                    size="small"
                    triggerType="custom"
                    content={
                      <StatusIndicator type="success">
                        {t(i18nKeys.clipboard.successCopiedToClipboard)}
                      </StatusIndicator>
                    }
                  >
                    <Button
                      id="challenge-i-am-policy-copy"
                      data-testid="challenge-i-am-policy-copy"
                      onClick={() => getSnipptAndCopy(item.id || '')}
                      iconName="copy"
                      variant="inline-link">
                        {item.title}
                    </Button>
                  </Popover>
                </SpaceBetween>
              ),
              sortingField: 'title',
              isRowHeader: true,
            },
            {
              id: 'preview',
              header: t(i18nKeys.challenges.subSections.cfnTemplate.challengeSnippts.preview),
              width: 150,
              cell: (item) => (
                <Button
                  data-testid={`challenge-i-am-policy-preview-${item.id}`}
                  variant="link"
                  onClick={() => onPreviw(item)}>
                  {t(i18nKeys.challenges.subSections.cfnTemplate.challengeSnippts.preview)}
                </Button>
              ),
              sortingField: 'preview',
            },
            {
              id: 'tags',
              header: t(i18nKeys.challenges.subSections.cfnTemplate.challengeSnippts.tags),
              cell: (item) => (
                <SpaceBetween direction="horizontal" size="xxs">
                  {item.tags?.map((tag, index) => (
                    <Badge key={index}>{tag}</Badge>
                  ))}{' '}
                </SpaceBetween>
              ),
            },
            {
              id: 'description',
              header: t(i18nKeys.challenges.subSections.cfnTemplate.challengeSnippts.description),
              cell: (item) => getDescriptions(item),
              sortingField: 'description',
            },
            {
              id: 'rating',
              header: t(i18nKeys.challenges.subSections.cfnTemplate.challengeSnippts.rating),
              width: 150,
              cell: (item) => (
                <>
                  {item.positiveFeedbackNum} {t(i18nKeys.challenges.subSections.cfnTemplate.challengeSnippts.vote)}
                </>
              ),
              sortingField: 'rating',
            },
          ]}
          columnDisplay={[
            { id: 'title', visible: true },
            { id: 'preview', visible: true },
            { id: 'tags', visible: true },
            { id: 'description', visible: true },
            { id: 'rating', visible: true },
          ]}
          items={items}
          loadingText="Loading resources"
          trackBy="title"
          resizableColumns
          empty={
            <Box margin={{ vertical: 'xs' }} textAlign="center" color="inherit">
              <SpaceBetween size="m">
                <b>{t(i18nKeys.eventTemplates.customTable.empty.noTitle, { headerTitle: t(i18nKeys.eventTemplates.title) })}</b>
              </SpaceBetween>
            </Box>
          }
          filter={
            <Box margin={{ top: 'xs', bottom: 'xs' }}>
              <TextFilter
                filteringText={filterText}
                onChange={({ detail }) => void filterDataFromTable(detail.filteringText)}
                disabled={originalTableData?.length === 0}
              />
            </Box>
          }
          stickyColumns={{ first: 0, last: 1 }}
        />
        <Modal
          visible={previewVisible}
          onDismiss={() => previewVisibleSet(false)}
          data-testid="challenge-snippets__preview-modal">
          <CodeView content={snipptsMarkdown} highlight={jsonHighlight} />
        </Modal>
    </>
  );
};

const ChallengeSnippetsView: React.FC = () => {
  const headerRef = useRef(null);
  return (
    <ContentLayout
      header={
        <div ref={headerRef} />
      }>
      <Box>
        <ChallengeSnippetsInner headerRef={headerRef} silent={false} />
      </Box>
    </ContentLayout>
  )
};

export default ChallengeSnippetsView;
