import React from 'react';
import Modal from '@amzn/awsui-components-react/polaris/modal';
import Box from '@amzn/awsui-components-react/polaris/box';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import Button from '@amzn/awsui-components-react/polaris/button';
import { useTranslation } from 'react-i18next';
import { i18nKeys } from '@/src/utils/i18n.utils';
import { Icon } from '@amzn/awsui-components-react';

interface JamTaskStatusModalProps {
  isCompleted: boolean;
  onClose: () => void;
  onDismiss: () => void;
}

const JamTaskStatusModal: React.FC<JamTaskStatusModalProps> = ({ isCompleted, onClose, onDismiss }) => {
  const { t } = useTranslation();
  const { modals } = i18nKeys.myJams.challenges.tabs;

  return (
    <Modal
      onDismiss={onDismiss}
      visible
      footer={
        <Box float="right">
          <SpaceBetween direction="horizontal" size="xs">
              <Button variant="primary" id="taskCompletedCloseButton" data-testid="taskCompletedCloseButton" onClick={onClose}>{t(i18nKeys.general.close)}</Button>
          </SpaceBetween>
        </Box>
      }
      header={isCompleted ? t(modals.completedTask.title) : t(modals.pendingTask.title)}>
      {isCompleted ? (
        <SpaceBetween direction="horizontal" size="xs">
          <Box color="text-status-success">
            <Icon name="status-positive" />
          </Box>
          <Box>{t(modals.completedTask.body)}</Box>
        </SpaceBetween>
      ) : (
        t(modals.pendingTask.body)
      )}
    </Modal>
  );
};

export default JamTaskStatusModal;
