import { waitForWafIntegration } from '@/src/utils/wafIntegration.util';
import { History } from 'history';
import queryString from 'query-string';
import { config } from '../config/app-config';
import { AddErrorFlashbar, AddFlashbar, AddSuccessFlashbar } from '../store/flashbar.context';
import '../styles/captcha.scss';
import { ApiClientUnauthorizedResponse } from '../types/ApiClientUnauthorizedResponse';
import { Nullable } from '../types/common';
import { LocalizedError } from '../types/LocalizedError';
import { User } from '../types/User';
import { getEnvVar } from '../utils/env-var.utils';
import { i18nKeys } from '../utils/i18n.utils';
import { preProdLogger } from '../utils/log.utils';
import { apiErrorFlashbar } from '../utils/notification.utils';
import { safePath } from '../utils/route.utils';
import { AuthClient } from './AuthClient';
import {
  ApiError,
  BaseOptions,
  DeleteOptions,
  GetOptions,
  PatchOptions,
  PostOptions,
  PutOptions,
  QueryParams,
  RequestMethod,
  RequestOptions,
} from './types';

class ErrorWithCode extends Error {
  name: string;
  code: number;

  constructor(message: string, code: number) {
    super(message);
    this.name = 'ErrorWithCode';
    this.code = code;
  }
}

/**
 * Class for constructing a client used to make XHR api calls.
 */
export class ApiClient {
  constructor(
    private apiBaseUrl: string,
    private checkoutSessionApiBaseUrl: string,
    private authClient: AuthClient,
    private user: Nullable<User>,
    private addFlashbar: AddFlashbar,
    private addSuccessFlashbar: AddSuccessFlashbar,
    private addErrorFlashbar: AddErrorFlashbar,
    private history: History
  ) {
    // do nothing
  }

  /**
   * Get query params for a request. Adds the 'silent' param is options.silent === true.
   *
   * @param options
   * @public
   */
  public static getParams(options: Partial<BaseOptions> = {}): QueryParams {
    options.params = options.params || {};
    if (options.silent === true) {
      options.params.silent = 'true';
    }
    return options.params;
  }

  /**
   * Get the query string to use for a request.
   *
   * @param options
   * @public
   */
  public static getQueryString(options: Partial<BaseOptions>): string {
    const queryParams: QueryParams = ApiClient.getParams(options);
    const queryStr: string = queryString.stringify(queryParams);
    return queryStr ? `?${queryStr}` : '';
  }

  private async getAuthToken() {
    return (await this.authClient.getIdToken()) || '';
  }

  /**
   * Get the headers for a new request.
   *
   * @public
   */
  public async getHeaders(isBearer: boolean): Promise<Headers> {
    const authToken: string = await this.getAuthToken();
    /**
     * If the auth token is missing then the user isn't signed in.
     * We should prevent user's from accessing pages which need to make API calls
     * if they aren't signed in yet.
     * Just log an error and let the api call fail from lack of authorization.
     */
    if (!authToken) {
      // eslint-disable-next-line no-console
      console.error('Authorization token missing');
    }
    const headers: Headers = new Headers();
    headers.append('Content-Type', 'application/json; charset=utf-8');
    headers.append('Accept', 'application/json');
    headers.append('Authorization', isBearer ? `Bearer ${authToken}` : authToken);
    return headers;
  }

  /**
   * Attempt to parse a network response as JSON.  Optionally throw if fails to parse.
   *
   * @param res
   * @param throwOnParseError
   * @public
   */
  public static async parseResponseAsJson(res: Response, throwOnParseError = true): Promise<any> {
    let data: Promise<any> | null;
    try {
      data = (await res.json()) as Promise<any>;
    } catch (err) {
      if (throwOnParseError) {
        // eslint-disable-next-line no-console
        console.error('Failed to parse response as JSON', err);
        throw new LocalizedError(i18nKeys.errors.network.malformedResponse);
      }
      data = null;
    }
    return data;
  }

  /**
   * Throw a generic http error message based on the http status code.
   *
   * @param status
   * @public
   */
  public static throwGenericHttpError(status: number): void {
    if (status >= 300 && status < 400) {
      throw new LocalizedError(i18nKeys.errors.network.HTTP3XX);
    }
    if (status === 404) {
      throw new LocalizedError(i18nKeys.errors.network.HTTP404);
    }
    if (status >= 400 && status < 500) {
      throw new LocalizedError(i18nKeys.errors.network.HTTP4XX);
    }
    throw new LocalizedError(i18nKeys.errors.network.HTTP5XX);
  }

  /**
   * Always throws an exception when called.
   *
   * @public
   */
  public static async onError(res: Response): Promise<void> {
    // try to parse the response body, but don't throw on parsing error
    const data: ApiError = (await ApiClient.parseResponseAsJson(res, false)) as ApiError;

    // if this is an ApiError, then the error.errorMessage attribute is the error message from the backend
    const message: string = data?.errorMessage || '';
    const errorCode: number = data?.errorCode || 0;

    // if we were able to parse a message from the error response, then display that raw message to the user
    if (message) {
      // eslint-disable-next-line no-console
      preProdLogger(`API ${res.status} Error:`, message, errorCode);
      throw new ErrorWithCode(message, errorCode);
    }

    // we were not able to parse an error message from the response, so show a generic error message based on the status
    ApiClient.throwGenericHttpError(res.status);
  }

  /**
   * Always throws an exception when called including http information and response
   *
   * @public
   */
  public static async onErrorHandled(res: Response): Promise<void> {
    // try to parse the response body, but don't throw on parsing error
    const data: ApiError = (await ApiClient.parseResponseAsJson(res, false)) as ApiError;

    // if this is an ApiError, then the error.errorMessage attribute is the error message from the backend
    const message: string = data?.errorMessage || '';

    // if we were able to parse a message from the error response, then display that raw message to the user
    if (message) {
      // eslint-disable-next-line no-console
      preProdLogger(`API ${res.status} Error:`, message);
      // eslint-disable-next-line no-throw-literal
      throw Object.assign(res, { responseData: data });
    }

    // we were not able to parse an error message from the response, so show a generic error message based on the status
    ApiClient.throwGenericHttpError(res.status);
  }

  /**
   * Always displays a success flashbar when called.
   *
   * @param message
   * @private
   */
  private onSuccess(message: string): void {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    this.addSuccessFlashbar(message);
  }

  /**
   * Based on the (method, options, body) supplied, produce the api url and fetch options needed to make the request.
   *
   * @param method
   * @param options
   * @param body
   * @private
   */
  private async getFetchOptions(
    method: RequestMethod,
    options: BaseOptions,
    body: any = {}
  ): Promise<{ url: string; fetchOptions: RequestOptions }> {
    const methodSupportsBody = method !== 'GET' && method !== 'DELETE';
    const fetchOptions: RequestOptions = {
      body: methodSupportsBody ? (typeof body === 'string' ? body : JSON.stringify(body || {})) : undefined,
      headers: await this.getHeaders(options.isBearer as boolean),
      method,
    };

    const baseUrl: string = options.isOriginCheckout ? this.checkoutSessionApiBaseUrl : this.apiBaseUrl;
    const path: string = safePath(options.path);
    const query: string = ApiClient.getQueryString(options);

    return {
      url: `${baseUrl}${path}${query}`,
      fetchOptions,
    };
  }

  private async loadData(url: string, fetchOptions: RequestInit) {
    try {
      await waitForWafIntegration();
      const wafIntegration = window.AwsWafIntegration as NonNullable<typeof window.AwsWafIntegration>;
      const result: Response = await wafIntegration.fetch(url, fetchOptions);

      if (result.status === 405) {
        return new Promise<Response>((resolve, reject) => {
          // Create overlay
          const overlay = document.createElement('div');
          overlay.className = 'captcha-overlay';
          document.body.appendChild(overlay);
          // Add active class after a brief delay to ensure proper rendering
          setTimeout(() => overlay.classList.add('active'), 0);

          // Create container for captcha
          const container = document.createElement('div');
          container.id = 'my-captcha-box';
          document.body.appendChild(container);
          // Add active class after a brief delay to ensure proper rendering
          setTimeout(() => container.classList.add('active'), 0);

          if (typeof window.AwsWafCaptcha === 'undefined') {
            throw new Error('AwsWafCaptcha is not loaded');
          }

          try {
            void window.AwsWafCaptcha.renderCaptcha(container, {
              apiKey: getEnvVar('REACT_APP_WAF_API_KEY'),
              onSuccess: async () => {
                // Remove active classes first
                overlay.classList.remove('active');
                container.classList.remove('active');
                setTimeout(() => {
                  overlay.remove();
                  container.remove();
                }, 0);
                try {
                  const newResult = await this.loadData(url, fetchOptions);
                  resolve(newResult);
                } catch (error) {
                  reject(error as Error);
                }
              },
            });
          } catch (error) {
            // Remove active classes first
            overlay.classList.remove('active');
            container.classList.remove('active');
            setTimeout(() => {
              overlay.remove();
              container.remove();
            }, 0);
            reject(error as Error);
          }
        });
      }
      return result;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error in loadData:', error);
      throw error;
    }
  }

  /**
   * Primary logic for executing an XHR.
   *
   * @param method
   * @param options
   * @param body
   */
  private async send(method: RequestMethod, options: BaseOptions, body?: any, multipart?: boolean): Promise<any> {
    let res: Response;
    try {
      const { fetchOptions, url } = await this.getFetchOptions(method, options, body);
      if (multipart) {
        res = await this.loadData(url, {
          method: 'POST',
          body,
          headers: {
            Authorization: fetchOptions.headers?.get('Authorization') || '',
          },
        });
      } else {
        res = await this.loadData(url, fetchOptions);
      }
    } catch (err) {
      /**
       * The Promise returned from fetch() won’t reject on HTTP error status even if the response is an HTTP 404 or 500.
       * Instead, it will resolve normally (with ok status set to false), and it will only reject on network failure
       * or if anything prevented the request from completing.
       * learn more: https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API
       */
      // eslint-disable-next-line no-console
      console.error('API Error:', err);
      throw err;
    }

    try {
      /* Status of 401 means there is an issue with the token.
         The legacy console redirects to login, so we will sign in the user, redirecting to the current location. */
      if (res.status === 401) {
        // sign in with the user's current IDP
        await this.authClient.signIn(this.history.location, this.user?.provider);
      }

      if (res.status === 403 && config.isGandalfOtpRedirectEnabled) {
        preProdLogger('403');
        /* Have to check 403 message to see if email is verified */
        const resFor403 = res.clone();
        const resText = await resFor403.text();
        preProdLogger(`Response Json String: ${resText}`);

        let resJson: ApiClientUnauthorizedResponse | null = null;

        try {
          resJson = JSON.parse(resText);
        } catch {
          /* If invalid json is returned we let the logic fall through to normal non-200 error handling. */
          preProdLogger('Failed to parse 403 response json.');
        }

        if (resJson && 'emailVerified' in resJson && !resJson.emailVerified) {
          preProdLogger('Unverified email, redirecting to email OTP');
          await this.authClient.verifyEmail();
          return null;
        }
      }

      // 204 is a special status that is considered success but has no content
      // learn more: https://developer.mozilla.org/en-US/docs/Web/HTTP/Status/204
      if (res.status === 204) {
        return null;
      }

      // handle any other 2xx as an error
      if (res.status >= 300) {
        // this always throws 100% of the time
        await ApiClient[options.errorHandled ? 'onErrorHandled' : 'onError'](res);
      }

      const successMessage = options.successMessage;
      if (successMessage && !options.silent && options.withErrorFlashbar) {
        this.addErrorFlashbar(successMessage);
      } else if (successMessage && !options.silent) {
        this.onSuccess(successMessage);
      }
      // getting a file from server response as a blob
      if (options.responseType === 'blob') {
        return res.blob();
      }

      if (options.responseType === 'attachment') {
        const blob = await res.blob();
        return blob;
      }

      const jsonResponse: Promise<any> = (await ApiClient.parseResponseAsJson(
        res,
        res.status === 200 ? false : true
      )) as Promise<any>;
      if (res && options.responseMapper) {
        return (await options.responseMapper(jsonResponse)) as Promise<any>;
      } else {
        return jsonResponse;
      }
    } catch (err: any) {
      if (options.failMessage) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        this.addFlashbar(apiErrorFlashbar(err, options.failMessage));
      }
      throw err;
    }
  }

  public async post(options: PostOptions, multipart?: boolean): Promise<any> {
    if (multipart) {
      return (await this.send('POST', options, options.body, true)) as Promise<any>;
    }
    return (await this.send('POST', options, options.body)) as Promise<any>;
  }

  public async put(options: PutOptions): Promise<any> {
    return (await this.send('PUT', options, options.body)) as Promise<any>;
  }

  public async patch(options: PatchOptions): Promise<any> {
    return (await this.send('PATCH', options, options.body)) as Promise<any>;
  }

  public async get(options: GetOptions): Promise<any> {
    return (await this.send('GET', options)) as Promise<any>;
  }

  public async delete(options: DeleteOptions): Promise<any> {
    return (await this.send('DELETE', options)) as Promise<any>;
  }
}
