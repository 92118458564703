import {
  Alert,
  Button,
  ExpandableSection,
  FormField,
  Grid,
  Header,
  Input,
  Modal,
  SpaceBetween,
  Textarea,
} from '@amzn/awsui-components-react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useApi } from '../../../store/api.context';
import { Campaign } from '../../../types/Campaign';
import { i18nKeys } from '../../../utils/i18n.utils';
import { preProdLogger } from '../../../utils/log.utils';
import sanitizeHtml from 'sanitize-html';
import { EditCampaignActions, useEditCampaign } from '../../../store/edit-campaign.context';

interface InvitationEmailProps {
  campaign: Campaign;
}

const InvitationEmail: React.FC<InvitationEmailProps> = ({ campaign }) => {
  const { t } = useTranslation();
  const [invitationEmailModalVisible, setInvitationEmailModalVisible] = useState(false);
  const [inviteEmailPreview, setEmailInvitePreview] = useState('');
  const { campaignEditMode, editedCampaign, handleUpdateEditCampaign } = useEditCampaign();
  const { campaignApi } = useApi();

  const generateInvitationEmailPreview = () => {
    campaignApi
      .getInvitePreview(editedCampaign || campaign)
      .then((res) => {
        // Migrated from legacy
        // auto-correct cases where the end of the <body> and <html> elements are not at the end of the html string.
        if (res.includes('</body>')) {
          res = res.replace('</body>', '');
          res = res + '</body>';
        }
        if (res.includes('</html>')) {
          res = res.replace('</html>', '');
          res = res + '</html>';
        }

        if (res.includes('<body>')) {
          res = res.split('<body>')[1].split('</body>')[0];
        }
        setEmailInvitePreview(res);
      })
      .catch((err) => {
        preProdLogger('Error generating invite email preview', err.message);
      });
  };

  useEffect(() => {
    if (inviteEmailPreview) {
      setInvitationEmailModalVisible(true);
    }
  }, [inviteEmailPreview]);

  /** Legacy application used innerHTML for this html block, will not display without it.
   * Attempted using render-react-markup but no type files available and linter breaks as a result
   * When available, migrate to using one of these modules to avoid dangerouslySetInnerHTML, saniztizing as a necessary precaution
   * */
  return (
    <ExpandableSection
      variant="container"
      header={<Header variant="h2">{t(i18nKeys.campaigns.headers.campaignDetails.invitationEmail)}</Header>}>
      <Modal
        header={t(i18nKeys.campaigns.headers.campaignDetails.invitationEmail)}
        visible={invitationEmailModalVisible}
        onDismiss={() => setInvitationEmailModalVisible(false)}>
        <div dangerouslySetInnerHTML={{ __html: sanitizeHtml(inviteEmailPreview) }} />
      </Modal>
      {!campaignEditMode && (
        <React.Fragment>
          <div className="section-first-row">
            <Grid gridDefinition={[{ colspan: 3 }, { colspan: 6 }]}>
              <div className="secondary-text">{t(i18nKeys.campaigns.labels.campaignDetails.customSubject)}</div>
              <div>{campaign?.campaignSettings.inviteEmailSubject ?? t(i18nKeys.general.default)}</div>
            </Grid>
          </div>
          <div className="grey-section-divider-top">
            <Grid gridDefinition={[{ colspan: 3 }, { colspan: 6 }]}>
              <div className="secondary-text">{t(i18nKeys.campaigns.labels.campaignDetails.customMessage)}</div>
              <div>{campaign?.campaignSettings.inviteEmailMessage ?? t(i18nKeys.general.default)}</div>
            </Grid>
          </div>
        </React.Fragment>
      )}
      {campaignEditMode && (
        <SpaceBetween direction="vertical" size="s">
          <Alert type="info" visible>
            {`${t(i18nKeys.campaigns.messages.campaignDetails.inviteEmailTemplate)} ${t(i18nKeys.general.avoidPIIWarning)}`}
          </Alert>
          <FormField label={t(i18nKeys.campaigns.labels.campaignDetails.customSubject)}>
            <Input
              type="text"
              value={editedCampaign?.campaignSettings.inviteEmailSubject || ''}
              onChange={({ detail }) => handleUpdateEditCampaign(EditCampaignActions.CUSTOM_SUBJECT, detail.value)}
            />
          </FormField>
          <FormField label={t(i18nKeys.campaigns.labels.campaignDetails.customMessage)}>
            <Textarea
              value={editedCampaign?.campaignSettings.inviteEmailMessage || ''}
              onChange={({ detail }) => handleUpdateEditCampaign(EditCampaignActions.CUSTOM_MESSAGE, detail.value)}
            />
          </FormField>
        </SpaceBetween>
      )}
      <div className="grey-section-divider-top">
        <Button variant="primary" onClick={() => generateInvitationEmailPreview()}>
          {t(i18nKeys.campaigns.buttons.previewEmailInvite)}
        </Button>
      </div>
    </ExpandableSection>
  );
};
export default InvitationEmail;
