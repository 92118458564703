import { JamTaskProgress } from './JamChallengeDetails';
import { JamEventType } from './JamEventDetails';

export interface IJamChallengeComment {
  id: string;
  value: string;
}
export interface IJamChallenge {
  id: string;
  title: string;
  topic: string;
  solved: boolean;
  earnedPoints: number;
  maxPoints: number;
  difficulty: number;
  warmup?: boolean;
  sponsor: { value: boolean; name: string; logo: string };
  isSolvedbyOtherTeam?: boolean;
  description: string;
  cluesUsed?: '';
  locked: boolean;
  started?: boolean;
  completed?: boolean;
  taskProgress?: JamTaskProgress[];
}

export class JamChallengesData {
  id?: string;
  challenges: IJamChallenge[];
  eventEndDate: number;
  eventPath: string;
  type: JamEventType;
  eventName: string;

  static fromPlainObject(obj: any): JamChallengesData {
    const data = Object.assign(new JamChallengesData(), obj) as JamChallengesData;
    return data;
  }
}

export class JamClue {
  order: number;
  title: string;
  description?: string | null;

  static fromPlainObject(obj: any): JamClue {
    return Object.assign(new JamClue(), obj) as JamClue;
  }
}

// eslint-disable-next-line no-shadow
export enum JamChallengeTaskAnswerFields {
  ANSWER = 'answer',
}

export interface InputValidationHelper {
  isValid: () => boolean;
  checkErrors: () => string;
}

export type JamChallengeTaskValidationFields = JamChallengeTaskAnswerFields;

export class ChallengeFeedback {
  public challengeRank: number;
  public challengeDifficulty: number;
  public notes: string;
  public didYouLearnSomethingNew: boolean;
  public updatedDate: string;

  static fromPlainObject(obj: any): ChallengeFeedback {
    return Object.assign(new ChallengeFeedback(), obj) as ChallengeFeedback;
  }
}

export class TaskValidationResponse {
  eventName: string;
  challengeId: string;
  taskId: string;
  challengeCompleted: boolean;
  taskCompleted: boolean;
  challengeFeedback: ChallengeFeedback;
  message: string;
  progressPercent: number;
  metadata: { [key: string]: string } = {};
  localizedMessage: string;

  static fromPlainObject(obj: any): TaskValidationResponse {
    const response: TaskValidationResponse = Object.assign(new TaskValidationResponse(), obj) as TaskValidationResponse;
    if (response.challengeFeedback) {
      response.challengeFeedback = ChallengeFeedback.fromPlainObject(response.challengeFeedback);
    }
    return response;
  }
}
