import {
  Box,
  Button,
  Container,
  Form,
  FormField,
  Grid,
  Header,
  Input,
  SpaceBetween,
} from '@amzn/awsui-components-react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useApi } from '../../../store/api.context';
import { i18nKeys } from '../../../utils/i18n.utils';
import { preProdLogger } from '../../../utils/log.utils';
import { isEmailValid } from '../../../utils/string.utils';
import { ConfirmModal } from '../../common/ConfirmModal';

const GDPR: React.FC = () => {
  const [email, setEmail] = useState('');
  const [confirmDeleteModalVisible, setConfirmDeleteModalVisible] = useState(false);
  const { accountApi } = useApi();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  const handleDSARRequest = () => {
    setLoading(true);
    accountApi
      .makeDSARRequest(email)
      .then(() => {
        setLoading(false);
        setEmail('');
      })
      .catch((err) => {
        setLoading(false);
        preProdLogger('Error requesting DSAR', err.message);
      });
  };

  const handleRTFRequest = () => {
    setLoading(true);
    accountApi
      .makeRTFRequest(email)
      .then(() => {
        setLoading(false);
        setEmail('');
        setConfirmDeleteModalVisible(false);
      })
      .catch((err) => {
        setLoading(false);
        preProdLogger('Error requesting RTF', err.message);
        setConfirmDeleteModalVisible(false);
      });
  };

  return (
    <Box>
      <ConfirmModal
        visible={confirmDeleteModalVisible}
        onCancel={() => setConfirmDeleteModalVisible(false)}
        onConfirm={() => handleRTFRequest()}
        message={t(i18nKeys.settings.gdpr.messages.deleteConfirmation, { email })}
      />
      <Container>
        <Form
          variant="embedded"
          header={
            <Header variant="h2" description={t(i18nKeys.settings.gdpr.description)}>
              {t(i18nKeys.settings.gdpr.title)}
            </Header>
          }>
          <FormField label={t(i18nKeys.settings.gdpr.labels.email)}>
            <SpaceBetween direction="vertical" size="m">
              <Input
                id="settings-gdpr-email-input"
                placeholder="user1@example.com"
                value={email}
                onChange={({ detail }) => setEmail(detail.value)}
              />
              {email && !isEmailValid(email) && (
                <div className="warning">{t(i18nKeys.settings.errors.invalidEmailFormat)}</div>
              )}
              <Grid disableGutters gridDefinition={[{ colspan: 4, offset: 2 }, { colspan: 4 }]}>
                <Box textAlign="center">
                  <SpaceBetween direction="vertical" size="xxxs">
                    <Button
                      variant="normal"
                      disabled={!isEmailValid(email)}
                      loading={loading}
                      iconName="close"
                      id="settings-gdpr-request-rtf-button"
                      data-testid="settings-gdpr-request-rtf-button"
                      onClick={() => setConfirmDeleteModalVisible(true)}
                    >
                      {t(i18nKeys.settings.gdpr.buttons.requestRTF)}
                    </Button>
                    <Box variant="small" color="text-status-inactive">
                      {t(i18nKeys.settings.gdpr.labels.requestRTFDesc)}
                    </Box>
                  </SpaceBetween>
                </Box>
                <Box textAlign="center">
                  <SpaceBetween direction="vertical" size="xxxs">
                    
                    <Button
                      variant="primary"
                      disabled={!isEmailValid(email)}
                      loading={loading}
                      iconName="download"
                      id="settings-gdpr-request-dsar-button"
                      data-testid="settings-gdpr-request-dsar-button"
                      onClick={() => handleDSARRequest()}
                    >
                      {t(i18nKeys.settings.gdpr.buttons.requestDSAR)}
                    </Button>
                    <Box variant="small" color="text-status-inactive">
                      {t(i18nKeys.settings.gdpr.labels.requestDSARDesc)}
                    </Box>
                  </SpaceBetween>
                </Box>
              </Grid>
            </SpaceBetween>
          </FormField>
        </Form>
      </Container>
    </Box>
  );
};

export default GDPR;
