import AwsCliModal from '@/src/components/ui/molecules/MyJams/JamChallengeDetails/JamOverview/Modals/AwsCLI/AwsCLIModal';
import { getComplimentaryFontColor, labProviderStatusColors, labStatusColors } from '@/src/constants/lab-status-color';
import { useLoading } from '@/src/hooks/store/useLoading';
import { CHALLENGE_DETAILS_ROUTES } from '@/src/routes';
import { useApi } from '@/src/store/api.context';
import { useFlashbars } from '@/src/store/flashbar.context';
import { useUser } from '@/src/store/user.context';
import { Challenge } from '@/src/types/Challenge';
import { AccountCredentials, Lab, LabCloudFormationDetails, LabSignInDetails } from '@/src/types/LabModels';
import { LAB_PROVIDER_LABELS, LabProvider } from '@/src/types/LabProvider';
import { ResourceDeploymentItem } from '@/src/types/ResourceDeployment';
import { getFailedStackEvents } from '@/src/utils/cfn.utils';
import { i18nKeys } from '@/src/utils/i18n.utils';
import { localizeLabProviderStatus, localizeLabStatus } from '@/src/utils/lab-dashboard-chart.utils';
import { localLogger } from '@/src/utils/log.utils';
import { toTitleCase } from '@/src/utils/string.utils';
import { timeAgo } from '@/src/utils/time.utils';
import {
  Badge,
  Box,
  Button,
  ButtonDropdown,
  Header,
  Input,
  Modal,
  Pagination,
  Select,
  SpaceBetween,
  Table,
} from '@amzn/awsui-components-react';
import { OptionDefinition } from '@amzn/awsui-components-react/polaris/internal/components/option/interfaces';
import { pick } from 'lodash';
import moment from 'moment';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Link from '../../ui/atoms/Link/Link';

interface LabAccountsProps {
  allLabs: Lab[];
  getChallenge: (id: string) => Challenge;
  selectedChallengeFilter: OptionDefinition | null;
  selectedChallengeFilterSet: (args: OptionDefinition | null) => void;
}

const initialFilters = {
  region: '',
  status: null,
  providerStatus: null,
  assignedTeam: null,
};

const LabAccounts: React.FC<LabAccountsProps> = ({
  allLabs,
  getChallenge,
  selectedChallengeFilter,
  selectedChallengeFilterSet,
}) => {
  const { t } = useTranslation();
  const { user } = useUser();
  const { addErrorFlashbar } = useFlashbars();
  const [pageSize] = useState(50);
  const { setLoading } = useLoading();
  const [currentPage, setCurrentPage] = useState(1);
  const [labStatusHistory, setLabStatusHistory] = useState<React.ReactNode | undefined>();
  const [stackDetails, setStackDetails] = useState<LabCloudFormationDetails | undefined>();
  const [selectedLab, setSelectedLab] = useState<Lab | undefined>();
  const [metadata, setMetadata] = useState('');
  const [labErrors, setLabErrors] = useState('');
  const [isCredentialModalOpen, setIsCredentialModalOpen] = useState(false);
  const [modalCredentails, setModalCredentails] = useState<AccountCredentials>();
  const [signInDetails, setSignInDetails] = useState<{
    [labId: string]: {
      teamSignInDetails?: LabSignInDetails;
      adminSignInDetails?: LabSignInDetails;
      masterSignInDetails?: LabSignInDetails;
    };
  }>();
  const [filters, setFilters] = useState<{
    region: string;
    status: OptionDefinition | null;
    providerStatus: OptionDefinition | null;
    assignedTeam: OptionDefinition | null;
  }>(initialFilters);
  const totalPages = Math.ceil(allLabs.length / pageSize);
  const { eventsApi } = useApi();

  const filtered = useMemo(() => {
    return (
      selectedChallengeFilter || filters.assignedTeam || filters.providerStatus || filters.region || filters.status
    );
  }, [filters]);

  const filterDropdowns = useMemo(() => {
    const emptyOption = {
      label: t(i18nKeys.eventLabs.labAccounts.table.anyPlaceholder),
    };
    const allExternalStatuses: OptionDefinition[] = [emptyOption];
    const allInternalStatuses: OptionDefinition[] = [emptyOption];
    const allAssignedTeams: OptionDefinition[] = [emptyOption];
    const allChallengesWithLabs: OptionDefinition[] = [emptyOption];

    const labChallengeIds = allLabs.reduce((acc, lab) => {
      if (lab.challengeId && !acc.includes(lab.challengeId)) {
        acc.push(lab.challengeId);
      }

      return acc;
    }, [] as string[]);

    labChallengeIds.forEach((challengeId) => {
      allChallengesWithLabs.push({
        label: `${getChallenge(challengeId).props.title} (${challengeId})`,
        value: challengeId,
      });
    });

    // aggregate some lists for caching lists to be used for filter dropdowns
    (allLabs || []).forEach((lab) => {
      if (lab.extStatus && allExternalStatuses.findIndex((status) => status.value === lab.extStatus) < 0) {
        allExternalStatuses.push({ label: localizeLabProviderStatus(t, lab.extStatus) || '', value: lab.extStatus });
      }
      if (lab.status && allInternalStatuses.findIndex((status) => status.value === lab.status) < 0) {
        allInternalStatuses.push({ label: localizeLabStatus(t, lab.status) || '', value: lab.status });
      }
      if (lab.onHold && allInternalStatuses.findIndex((status) => status.value === 'ON_HOLD') < 0) {
        allInternalStatuses.push({ label: t(i18nKeys.eventLabs.challengeLab.progressMeter.onHold), value: 'ON_HOLD' });
      }
      if (lab.team?.name && allAssignedTeams.findIndex((team) => team.value === lab.team?.name) < 0) {
        allAssignedTeams.push({
          label: toTitleCase(lab.team?.alias || '') || '',
          value: lab.team?.name || '',
        });
      }
    });
    return { allAssignedTeams, allExternalStatuses, allInternalStatuses, allChallengesWithLabs };
  }, [allLabs]);

  const labs = useMemo(() => {
    let filteredLabs = [...allLabs];
    filteredLabs.sort((a, b) => (a.id || '').localeCompare(b.id || ''));
    const { status, providerStatus, assignedTeam, region } = filters;

    if (selectedChallengeFilter) {
      filteredLabs = filteredLabs.filter((lab) => lab.challengeId === selectedChallengeFilter?.value);
    }
    if (region) {
      filteredLabs = filteredLabs.filter(
        (lab) => lab.regionDescription?.includes(region) || lab.awsAccountNumber?.includes(region)
      );
    }
    if (status) {
      filteredLabs = filteredLabs.filter((lab) => {
        if (lab.onHold) {
          return 'ON_HOLD' === status.value;
        }
        return lab.status === status.value;
      });
    }
    if (providerStatus) {
      filteredLabs = filteredLabs.filter((lab) => lab.extStatus === providerStatus.value);
    }
    if (assignedTeam) {
      filteredLabs = filteredLabs.filter((lab) => lab.team && lab.team.name === assignedTeam.value);
    }
    return filteredLabs.slice((currentPage - 1) * pageSize, currentPage * pageSize);
  }, [allLabs, currentPage, pageSize, filters]);

  const failedEvents = useMemo(() => {
    if (stackDetails) {
      return getFailedStackEvents(stackDetails.stackEvents);
    }
    return [];
  }, [stackDetails]);

  const isLabUsable = (internalStatus: string) => {
    // non terminal states
    // for list of terminal states see CBE: com.amazonaws.awsjam.model.data.LabAccount.Status
    switch (internalStatus) {
      case 'NOT_READY':
      case 'UNASSIGNED':
      case 'ASSIGNED':
      case 'PREPARING_RESOURCES':
        return true;
      default:
        return false;
    }
  };

  const downloadSshKey = async (lab: Lab) => {
    try {
      setLoading(true);
      await eventsApi.getChallengeLabKeyPair(lab);
    } catch (e) {
      //
    }
    setLoading(false);
  };

  const unassignAccount = async (lab: Lab) => {
    const message = t(i18nKeys.eventLabs.labAccounts.messages.unassignAccountSure);
    if (confirm(message)) {
      let reason = prompt(t(i18nKeys.eventLabs.labAccounts.messages.whatIsReasonAccount));
      reason = reason || t(i18nKeys.eventLabs.labAccounts.messages.defaultReasonAccount);
      try {
        setLoading(true);
        await eventsApi.teamRestart(lab, reason || '');
      } catch (e) {
        //
      }
      setLoading(false);
    }
  };

  const terminateLab = async (lab: Lab) => {
    let message = t(i18nKeys.eventLabs.labAccounts.messages.terminateAccountSure);

    if (lab.team) {
      message += t(i18nKeys.eventLabs.labAccounts.messages.terminateTeamSure);
    }

    if (confirm(message)) {
      let reason = prompt(t(i18nKeys.eventLabs.labAccounts.messages.whatIsReasonLab));
      // prompt returns null if user clicks "cancel" and an empty string when the user clicks "ok" with no input
      if (reason !== null) {
        reason = reason || t(i18nKeys.eventLabs.labAccounts.messages.defaultReasonLab);
        try {
          setLoading(true);
          await eventsApi.terminateLab(lab, reason || '');
        } catch (e) {
          //
        }
        setLoading(false);
      }
    }
  };

  const viewStatusHistory = (lab: Lab) => {
    const messages = lab.statusHistory.map((item) => {
      const time = moment(item.time).format('MMM Da hh:mm A');
      return (
        <Box key={time}>
          [{time}] - {item.status} - {item.details}
        </Box>
      );
    });
    setLabStatusHistory(messages);
  };

  const viewResourceDeploymentHistory = async (lab: Lab) => {
    try {
      setLoading(true);
      const items: ResourceDeploymentItem[] = await eventsApi.getLabResourceDeploymentHistory(lab);
      lab.applyResourceTaskHistory(items);
      // TODOSAGAR: show modal check legacy code
    } catch (e) {
      //
    }
    setLoading(false);
  };

  const getAccountStackDetails = async (lab: Lab) => {
    try {
      setLoading(true);
      const details = await eventsApi.getChallengeLabCfnDetails(lab);
      if (details) {
        setStackDetails(details);
      } else {
        addErrorFlashbar(t(i18nKeys.eventLabs.labAccounts.messages.failedStackDetails));
      }
    } catch (e) {
      //
    }
    setLoading(false);
  };

  const getMetadata = async (lab: Lab): Promise<{ [key: string]: string }> => {
    const data = await eventsApi.getLabMetadata(lab);
    return Object.assign({}, data, pick(lab, ['holdUntil', 'masterAccount']));
  };

  const showMetadata = async (lab: Lab) => {
    const note =
      lab.labProvider === LabProvider.EVENT_ENGINE ? t(i18nKeys.eventLabs.labAccounts.messages.metadataNote) : '';
    try {
      setLoading(true);
      const message = note + JSON.stringify(await getMetadata(lab), null, 4);
      setMetadata(message);
    } catch (e) {
      //
    }
    setLoading(false);
  };

  const showErrors = (lab: Lab) => {
    let error = lab.error && lab.error.replace(/\\"/g, '"').replace(/"{/g, '{').replace(/}"/g, '}');

    try {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      error = error ? JSON.parse(error) : {};
      error = JSON.stringify(error, null, 4);
    } catch (e) {
      // ignore
      // console.log(e);
    }

    const message = `AWS Account Number: ${lab.awsAccountNumber}
        Session ID: ${lab.sessionId}
        Lab ID: ${lab.id}
        Error: ${error}
    `;
    setLabErrors(message);
  };

  const fetchSignInDetails = async (item: Lab) => {
    setLoading(true);
    try {
      const teamSignInDetails: LabSignInDetails = await eventsApi.getChallengeLabTeamSignIn(item);
      const adminSignInDetails: LabSignInDetails = await eventsApi.getChallengeLabAdminSignIn(item);

      let masterSignInDetails: LabSignInDetails;
      const challenge = getChallenge(item.challengeId || '');
      if (challenge && challenge.isGameDay) {
        masterSignInDetails = await eventsApi.getChallengeLabMasterSignIn(item);
      }
      setSignInDetails((prev) => {
        if (item.id) {
          return { ...prev, [item.id]: { teamSignInDetails, adminSignInDetails, masterSignInDetails } };
        }
      });
    } catch (e) {
      // e
    }
    setLoading(false);
  };

  const onShowCliCredentials = (labId: string, role: 'team' | 'admin' | 'master') => {
    const creds = signInDetails?.[labId]?.[`${role}SignInDetails`]?.credentials;
    if (!creds) return;
    setModalCredentails(creds);
    setIsCredentialModalOpen(true);
  };

  const onAction = (id: string, lab: Lab) => {
    setSelectedLab(lab);
    switch (id) {
      case 'SSH_KEY':
        void downloadSshKey(lab);
        break;
      case 'UNASSIGN_ACCOUNT':
        void unassignAccount(lab);
        break;
      case 'TERMINATE_LAB':
        void terminateLab(lab);
        break;
      case 'STATUS_HISTORY':
        viewStatusHistory(lab);
        break;
      case 'RESOURCE_DEPLOY_HISTORY':
        void viewResourceDeploymentHistory(lab);
        break;
      case 'STACK_DETAILS':
        void getAccountStackDetails(lab);
        break;
      case 'VIEW_METADATA':
        void showMetadata(lab);
        break;
    }
  };

  const getActionItems = (lab: Lab) => {
    const actions = [];
    const challenge = getChallenge(lab.challengeId || '');
    if (lab.canSignIn && challenge && challenge.props.sshKeyPairRequired) {
      actions.push({ text: t(i18nKeys.eventLabs.labAccounts.actions.sshKey), id: 'SSH_KEY' });
    }
    if (lab.team && lab.status?.toLowerCase() === 'assigned') {
      actions.push({ text: t(i18nKeys.eventLabs.labAccounts.actions.unassignAccount), id: 'UNASSIGN_ACCOUNT' });
    }
    if (user?.isEventAdmin && isLabUsable(lab.status || '')) {
      actions.push({ text: t(i18nKeys.eventLabs.labAccounts.actions.tearminateLab), id: 'TERMINATE_LAB' });
    }
    if (lab.statusHistory) {
      actions.push({ text: t(i18nKeys.eventLabs.labAccounts.actions.statusHistory), id: 'STATUS_HISTORY' });
    }
    if (lab.error) {
      actions.push({ text: t(i18nKeys.eventLabs.labAccounts.actions.cloudFormStackDetails), id: 'STACK_DETAILS' });
    }
    if (user?.isEventAdmin) {
      actions.push({ text: t(i18nKeys.eventLabs.labAccounts.actions.viewMetadata), id: 'VIEW_METADATA' });
    }
    return actions;
  };

  const awsAccountNumber = selectedLab?.awsAccountNumber?.toString();
  localLogger({ signInDetails });
  const nullIfNoValue = (opt: OptionDefinition) => (opt.value ? opt : null);
  return (
    <>
      <Table
        resizableColumns
        columnDefinitions={[
          {
            id: 'challenge',
            header: (
              <Box>
                {t(i18nKeys.eventLabs.labAccounts.table.header.challenge)}
                <Box>
                  <Select
                    placeholder={t(i18nKeys.eventLabs.labAccounts.table.anyPlaceholder)}
                    expandToViewport
                    options={filterDropdowns.allChallengesWithLabs}
                    selectedOption={selectedChallengeFilter}
                    data-testid="lab-accounts__challenge-filter"
                    onChange={({ detail }) => selectedChallengeFilterSet(nullIfNoValue(detail.selectedOption))}
                  />
                </Box>
              </Box>
            ),
            cell: (item) => (
              <Link href={CHALLENGE_DETAILS_ROUTES.Summary.replaceTokens([item.challengeId || ''])}>
                {getChallenge(item.challengeId || '').props.title}
              </Link>
            ),
            sortingField: 'name',
            isRowHeader: true,
          },
          {
            id: 'awsregion_accountid',
            header: (
              <Box>
                {t(i18nKeys.eventLabs.labAccounts.table.header.awsRegion)}
                <Input
                  placeholder="Filter"
                  value={filters.region}
                  onChange={({ detail }) => setFilters((prev) => ({ ...prev, region: detail.value }))}
                />
              </Box>
            ),
            cell: (item) => {
              const details = signInDetails?.[item.id || ''];
              return (
                <Box>
                  {!!item.region && <Box>{item.regionDescription}</Box>}
                  {!!item.awsAccountNumber && <Box>{item.awsAccountNumber}</Box>}
                  {!item.expired && !item.isInFinalStatus && (
                    <Box color="text-body-secondary">
                      Expires: {moment(item.expiration).format('MM/DD/YY, hh:mm A')} (
                      {moment(item.expiration).fromNow()})
                    </Box>
                  )}
                  <Box margin={{ top: 'xxs' }}>
                    {item.canSignIn && (isLabUsable(item.status || '') || item.error) && (
                      <SpaceBetween direction="horizontal" size="s">
                        <Button
                          iconName={details ? 'refresh' : undefined}
                          onClick={() => void fetchSignInDetails(item)}
                          variant={details ? 'inline-icon' : 'inline-link'}
                        >
                          {t(i18nKeys.eventLabs.labAccounts.actions.fetchSignInDetails)}
                        </Button>
                        {!!details && (
                          <>
                            {details.teamSignInDetails && (
                              <div style={{ border: '1px solid #ccc' }}>
                                Team:{' '}
                                <a
                                  href={details.teamSignInDetails?.url || ''}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  Login
                                </a>{' '}
                                <Button
                                  variant="inline-link"
                                  onClick={() => onShowCliCredentials(item.id || '', 'team')}
                                >
                                  CLI
                                </Button>
                              </div>
                            )}
                            {details.adminSignInDetails && (
                              <div style={{ border: '1px solid #ccc' }}>
                                Admin:{' '}
                                <a
                                  href={details.adminSignInDetails?.url || ''}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  Login
                                </a>{' '}
                                <Button
                                  variant="inline-link"
                                  onClick={() => onShowCliCredentials(item.id || '', 'admin')}
                                >
                                  CLI
                                </Button>
                              </div>
                            )}
                            {details.masterSignInDetails && (
                              <div style={{ border: '1px solid #ccc' }}>
                                Master:{' '}
                                <a
                                  href={details.masterSignInDetails?.url || ''}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  Login
                                </a>{' '}
                                <Button
                                  variant="inline-link"
                                  onClick={() => onShowCliCredentials(item.id || '', 'master')}
                                >
                                  CLI
                                </Button>
                              </div>
                            )}
                          </>
                        )}
                      </SpaceBetween>
                    )}
                  </Box>
                </Box>
              );
            },
            sortingField: 'alt',
          },
          {
            id: 'status',
            header: (
              <Box>
                {t(i18nKeys.eventLabs.labAccounts.table.header.status)}
                <Box>
                  <Select
                    placeholder={t(i18nKeys.eventLabs.labAccounts.table.anyPlaceholder)}
                    expandToViewport
                    options={filterDropdowns.allInternalStatuses}
                    selectedOption={filters.status as OptionDefinition}
                    data-testid="lab-accounts__status-filter"
                    onChange={({ detail }) =>
                      setFilters((prev) => ({ ...prev, status: nullIfNoValue(detail.selectedOption) }))
                    }
                  />
                </Box>
              </Box>
            ),
            cell: (item) => (
              <Box>
                {item.onHold && <Box>{t(i18nKeys.eventLabs.labAccounts.onHold)}</Box>}
                {!item.onHold && (
                  <div
                    style={{
                      display: 'inline',
                      padding: '2px 4px',
                      borderRadius: '4px',
                      backgroundColor: labStatusColors[item.status as keyof typeof labStatusColors],
                      color: getComplimentaryFontColor(labStatusColors[item.status as keyof typeof labStatusColors]),
                    }}
                  >
                    {localizeLabStatus(t, item.status ?? '')}
                  </div>
                )}
                {!!(item.status && item.statusHistory) && (
                  <Box>{timeAgo(item.statusHistory[item.statusHistory.length - 1].time || '')}</Box>
                )}
                {item.onHold && (
                  <Box>
                    {t(i18nKeys.eventLabs.labAccounts.table.cell.onHoldUntil)}{' '}
                    {moment(item.holdUntil).format('MM/DD/YYYY hh:mm A')}
                  </Box>
                )}
                {!item.status && 'Unknown'}
                {!!item.error && (
                  <Button onClick={() => showErrors(item)}>
                    {t(i18nKeys.eventLabs.labAccounts.actions.clickToViewError)}
                  </Button>
                )}
                {item.hasResourceHistory && (
                  <Box>
                    {item.hasFailedResource && (
                      <Button>{t(i18nKeys.eventLabs.labAccounts.actions.clickToFailedResources)}</Button>
                    )}
                    {t(i18nKeys.eventLabs.labAccounts.table.cell.deplyingResouces)} {item.resolvedTaskCount + 1} of{' '}
                    {item.totalTaskCount}
                  </Box>
                )}
              </Box>
            ),
          },
          {
            id: 'lab_provider_status',
            header: (
              <Box>
                {t(i18nKeys.lab.table.titles.labProviderStatus)}
                <Box>
                  <Select
                    placeholder={t(i18nKeys.eventLabs.labAccounts.table.anyPlaceholder)}
                    expandToViewport
                    options={filterDropdowns.allExternalStatuses}
                    selectedOption={filters.providerStatus as OptionDefinition}
                    data-testid="lab-accounts__lab-provider-status-filter"
                    onChange={({ detail }) =>
                      setFilters((prev) => ({ ...prev, providerStatus: nullIfNoValue(detail.selectedOption) }))
                    }
                  />
                </Box>
              </Box>
            ),
            cell: (item) => (
              <Box>
                {!!item.extStatus && (
                  <Badge color={labProviderStatusColors[item.extStatus as keyof typeof labProviderStatusColors]}>
                    {localizeLabProviderStatus(t, item.extStatus)}
                  </Badge>
                )}
                {!item.extStatus && 'Unknown'}
                <Box fontWeight="bold">{LAB_PROVIDER_LABELS[item.labProvider || '']}</Box>
              </Box>
            ),
          },
          {
            id: 'assigned_team',
            header: (
              <Box>
                {t(i18nKeys.eventLabs.labAccounts.table.header.assignedTeam)}
                <Box>
                  <Select
                    placeholder={t(i18nKeys.eventLabs.labAccounts.table.anyPlaceholder)}
                    expandToViewport
                    options={filterDropdowns.allAssignedTeams}
                    selectedOption={filters.assignedTeam as OptionDefinition}
                    data-testid="lab-accounts__assigned-team-filter"
                    onChange={({ detail }) =>
                      setFilters((prev) => ({ ...prev, assignedTeam: nullIfNoValue(detail.selectedOption) }))
                    }
                  />
                </Box>
              </Box>
            ),
            cell: (item) =>
              !!item.team && (
                <Box>
                  {item.team.alias === item.team.name ? item.team.alias : `${item.team.alias} (${item.team.name})`}
                </Box>
              ),
          },
          {
            id: 'actions',
            header: t(i18nKeys.eventLabs.labAccounts.table.header.actions),
            cell: (item) => (
              <Box>
                <ButtonDropdown
                  expandToViewport
                  ariaLabel="Control instance"
                  variant="icon"
                  items={getActionItems(item)}
                  onItemClick={({ detail }) => onAction(detail.id, item)}
                />
              </Box>
            ),
          },
        ]}
        items={labs}
        loadingText="Loading resources"
        sortingDisabled
        empty={
          <Box margin={{ vertical: 'xs' }} textAlign="center" color="inherit">
            <SpaceBetween size="m">
              <b>{t(i18nKeys.eventLabs.labAccounts.messages.noLabAccountsFound)}</b>
            </SpaceBetween>
          </Box>
        }
        header={
          <Header
            actions={
              <SpaceBetween direction="horizontal" size="m" alignItems="center">
                {filtered && (
                  <Button iconName="close" onClick={() => setFilters({ ...initialFilters })}>
                    {t(i18nKeys.eventLabs.labAccounts.actions.clearFilters)}
                  </Button>
                )}
                <Pagination
                  onChange={({ detail }) => setCurrentPage(detail.currentPageIndex)}
                  currentPageIndex={currentPage}
                  pagesCount={totalPages}
                />
              </SpaceBetween>
            }
            counter={`(${allLabs.length})`}
          >
            {` ${t(i18nKeys.eventLabs.labAccounts.title)} `}
          </Header>
        }
      />
      <Modal
        onDismiss={() => setLabStatusHistory(undefined)}
        visible={!!labStatusHistory}
        footer={
          <Box float="right">
            <Button variant="primary" onClick={() => setLabStatusHistory(undefined)}>
              {t(i18nKeys.general.ok)}
            </Button>
          </Box>
        }
        header={t(i18nKeys.eventLabs.labAccounts.modal.labStatusHistory)}
      >
        <Box>{labStatusHistory}</Box>
      </Modal>
      <Modal
        onDismiss={() => setLabErrors('')}
        visible={!!labErrors}
        footer={
          <Box float="right">
            <Button variant="primary" onClick={() => setLabErrors('')}>
              {t(i18nKeys.general.ok)}
            </Button>
          </Box>
        }
        header={'Lab Error'}
      >
        <Box>{labErrors}</Box>
      </Modal>
      <Modal
        onDismiss={() => setStackDetails(undefined)}
        visible={!!stackDetails}
        footer={
          <Box float="right">
            <Button variant="primary" onClick={() => setStackDetails(undefined)}>
              {t(i18nKeys.general.ok)}
            </Button>
          </Box>
        }
        header={`${
          awsAccountNumber ? t(i18nKeys.eventLabs.labAccounts.modal.cfnDetails, { awsAccountNumber }) + ' ' : ''
        }${
          getChallenge(selectedLab?.challengeId || '')
            ? `[${t(i18nKeys.eventLabs.labAccounts.table.header.challenge)}: ${selectedLab?.challengeId}]`
            : ''
        }`}
      >
        {failedEvents.length > 0 && (
          <Box>
            <Box variant="h3">{t(i18nKeys.eventLabs.labAccounts.modal.mainFailedEvents)}</Box>
            <pre>{JSON.stringify(stackDetails, null, 2)}</pre>
          </Box>
        )}
        <Box>
          <Box variant="h3">{t(i18nKeys.eventLabs.labAccounts.modal.fullDetails)}</Box>
          <pre>{JSON.stringify(failedEvents, null, 2)}</pre>
        </Box>
      </Modal>
      <Modal
        size="large"
        onDismiss={() => setMetadata('')}
        visible={!!metadata}
        footer={
          <Box float="right">
            <Button variant="primary" onClick={() => setMetadata('')}>
              {t(i18nKeys.general.ok)}
            </Button>
          </Box>
        }
        header={t(i18nKeys.eventLabs.labAccounts.modal.labAccountMetadata)}
      >
        <div style={{ wordWrap: 'break-word', wordBreak: 'break-all', whiteSpace: 'pre-wrap' }}>{metadata}</div>
      </Modal>
      {modalCredentails && (
        <AwsCliModal value={isCredentialModalOpen} setValue={setIsCredentialModalOpen} awsAccount={modalCredentails} />
      )}
    </>
  );
};

export default LabAccounts;
