import * as React from 'react';
import '../styles/landing-page.scss';
import { useAuth } from '../store/auth.context';
import { useUser } from '../store/user.context';
import { useComponentDidMountEffect } from '../hooks/useComponentDidMountEffect';
import { useTranslation } from 'react-i18next';
import { i18nKeys } from '../utils/i18n.utils';

export const OAuthRedirectPage: React.FC = () => {
  const { t } = useTranslation();
  const { isLoggedIn } = useUser();
  const { authClient } = useAuth();

  useComponentDidMountEffect(async () => {
    if (isLoggedIn) {
      authClient.handleOauthSignInRedirect();
    } else {
      void authClient.signIn();
    }

    return Promise.resolve();
  });

  return <p>{isLoggedIn ? t(i18nKeys.oauth.redirecting) : t(i18nKeys.oauth.authenticating)}</p>;
};
