/* eslint-disable @typescript-eslint/no-unsafe-call */
import { UserRole } from '@/src/api';
import { useUser } from '@/src/store/user.context';
import { User } from '@/src/types/User';
import { DownloadType, PiiWarningModal } from '@/src/components/modals/PiiWarningModal';
import { useModalManager } from '@/src/hooks/useModalManager';
import { useApi } from '@/src/store/api.context';
import { CachedEmail, useEvents } from '@/src/store/events.context';
import { useCollection } from '@amzn/awsui-collection-hooks';
import {
  Alert,
  Box,
  Button,
  ButtonDropdown,
  CollectionPreferencesProps,
  ExpandableSection,
  Icon,
  Input,
  Link,
  Modal,
  Pagination,
  Select,
  SpaceBetween,
  Table,
  TextFilter,
} from '@amzn/awsui-components-react';
import { OptionDefinition } from '@amzn/awsui-components-react/polaris/internal/components/option/interfaces';
import NiceModal from '@ebay/nice-modal-react';
import _, { isEqual } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFlashbars } from '../../../store/flashbar.context';
import { Nullable, NullableString } from '../../../types/common';
import { DownloadParticipantActions, Event } from '../../../types/Event';
import { Team } from '../../../types/Team';
import { UserRow } from '../../../types/UserRow';
import { i18nKeys } from '../../../utils/i18n.utils';
import { paginationLabels } from '../../../utils/table.utils';
import { TimezoneFormat } from '../CommonModel';
import { ConfirmModal } from '../ConfirmModal';
import { HorizontalRule } from '../HorizontalRule';
import { KeyValue } from '../KeyValue';
import { TableEmptyState } from '../TableEmptyState';
import { COLUMN_DEFINITIONS, filteringFunction } from './table-users-config';
import { TeamTable } from './TeamTable';

interface ParticipantsProps {
  target: Event;
}

export const hasDownloadPermissions = (user: User | null) => {
  if (!user) return false;
  return user.isSuperAdmin;
};

export const ParticipantManagement: React.FC<ParticipantsProps> = ({ target }) => {
  const { t } = useTranslation();
  const { user } = useUser();
  const TEAM_OPTION_VALUE_NONE = '__NONE__';

  const noTeamOption: OptionDefinition = {
    label: t(i18nKeys.participants.labels.noTeam),
    value: TEAM_OPTION_VALUE_NONE,
  };
  const noOwnerOption: OptionDefinition = {
    label: t(i18nKeys.participants.labels.noOwner),
    value: TEAM_OPTION_VALUE_NONE,
  };

  const roleOptions: Record<UserRole, OptionDefinition> = {
    [UserRole.Facilitator]: { label: t(i18nKeys.participants.labels.facilitator), value: UserRole.Facilitator },
    [UserRole.Support]: { label: t(i18nKeys.participants.labels.support), value: UserRole.Support },
    [UserRole.Participant]: { label: t(i18nKeys.participants.labels.participant), value: UserRole.Participant },
  };

  const roleFilterOptions: OptionDefinition[] = [
    { label: t(i18nKeys.participants.labels.allRoles), value: 'ALL' },
    { label: t(i18nKeys.participants.labels.facilitators), value: 'FACILITATORS' },
    { label: t(i18nKeys.participants.labels.participants), value: 'PARTICIPANTS' },
  ];

  const preferences: CollectionPreferencesProps.Preferences = { pageSize: 10, custom: TimezoneFormat.LOCAL };
  const [users, setUsers] = useState<UserRow[]>();
  const { teams, fetchUserEmail, emailCache, getEventByName } = useEvents();
  const { userApi, teamApi } = useApi();
  const { addErrorFlashbar } = useFlashbars();

  const [confirmDisableUserVisible, setConfirmDisableUserVisible] = useState(false);
  const [confirmEnableUserVisible, setConfirmEnableUserVisible] = useState(false);

  const [confirmAssignTeamVisible, setConfirmAssignTeamVisible] = useState(false);
  const [confirmAssignRoleVisible, setConfirmAssignRoleVisible] = useState(false);

  const [saveTeamAssignmentVisible, setSaveTeamAssignmentVisible] = useState(false);
  const [saveRoleAssignmentVisible, setSaveRoleAssignmentVisible] = useState(false);

  const [userDetailsVisible, setUserDetailsVisible] = useState(false);
  const [teamDetailsVisible, setTeamDetailsVisible] = useState(false);

  const [renameTeamAliasVisible, setRenameTeamAliasVisible] = useState(false);
  const [disableTeamVisible, setDisableTeamVisible] = useState(false);
  const [deleteTeamVisible, setDeleteTeamVisible] = useState(false);
  const [createTeamsVisible, setCreateTeamsVisible] = useState(false);
  const [createTeamVisible, setCreateTeamVisible] = useState(false);
  const [confirmCreateTeamVisible, setConfirmCreateTeamVisible] = useState(false);

  const [numberOfTeamsToCreate, setNumberOfTeamsToCreate] = useState(0);
  const [createTeamsPrefix, setCreateTeamsPrefix] = useState<NullableString>(null);
  const [newTeamAlias, setNewTeamAlias] = useState<NullableString>(null);
  const [selectedTeamOption, setSelectedTeamOption] = useState<OptionDefinition>(noTeamOption);
  const [selectedRoleOption, setSelectedRoleOption] = useState<OptionDefinition>();
  const [selectedUser, setSelectedUser] = useState<Nullable<UserRow>>(null);
  const [selectedTeam, setSelectedTeam] = useState<Nullable<Team>>(null);
  const [teamSelectOptions, setTeamSelectOptions] = useState<OptionDefinition[]>([]);
  const [teamOwnerOptions, setTeamOwnerOptions] = useState<OptionDefinition[]>([]);
  const [teamOwnerOption, setTeamOwnerOption] = useState<OptionDefinition>(noOwnerOption);
  const [newTeamName, setNewTeamName] = useState<NullableString>(null);
  const [showPassword, setShowPassword] = useState(false);
  const [newPassword, setNewPassword] = useState<NullableString>(null);
  const [roleFilter, setRoleFilter] = useState<OptionDefinition>(roleFilterOptions[0]);
  const [allUserRows, setAllUserRows] = useState<UserRow[]>([]);
  const { hideAllModals } = useModalManager();

  const teamList = useMemo(() => {
    return teams || target.teams;
  }, [target, teams]);

  useEffect(() => {
    setTeamsFromEvent();
  }, [teamList]);

  /**
   * Idempotent method for updating the list of teams and users from this.event.
   *
   * If the teams or users did not change, then the lists are not updated.
   */
  const setTeamsFromEvent = (): void => {
    // if there is no event, set teams and users to empty and return
    if (!target) {
      setUsers([]);
      return;
    }

    setupTeamOwnerOptions();
    const userRows: UserRow[] = getUserRowsFromEvent(target);

    if (!isEqual(userRows, users)) {
      setUsers(userRows);
    }

    if (!isEqual(userRows, allUserRows)) {
      setAllUserRows(userRows);
    }
  };

  /**
   * Get a list of all users for an event. Unassigned and assigned users.
   *
   * @param event
   */
  const getUserRowsFromEvent = (event: Event): UserRow[] => {
    return [...getUnassignedUserRows(event), ...getAssignedUserRows()];
  };

  /**
   * Get a list of assigned users for an event.
   *
   * @param event
   */
  const getAssignedUserRows = (): UserRow[] => {
    return _.flatMap(teamList || [], getTeamMemberUserRows);
  };

  /**
   * Get a list of assigned users for a team.
   *
   * @param team
   */
  const getTeamMemberUserRows = (team: Team): UserRow[] => {
    return (team.members || []).map((m) => UserRow.fromTeamMember(m, team));
  };

  /**
   * Get a list of unassigned participants for an event.
   *
   * @param event
   */
  const getUnassignedUserRows = (event: Event): UserRow[] => {
    return (event.unassignedParticipants || []).map((teamMember) => UserRow.fromUnassignedUser(teamMember));
  };

  const getUserById = (userId: string) => {
    return users?.find((userRow: UserRow) => userRow.userId === userId);
  };

  const getTeamOptionByName = (teamName: string, teamOptions: OptionDefinition[]) => {
    return teamOptions.find((teamOption) => teamOption.value === teamName);
  };

  const getTeamByName = (teamName: string) => {
    return teamList?.find((team: Team) => {
      return team.name === teamName || team.teamLabel === teamName;
    });
  };

  const downloadParticipantSolvedExport = () => {
    NiceModal.show(PiiWarningModal, {
      eventName: target.name,
      downloadType: DownloadType.SolvedOnly,
    });
  };

  const downloadAllParticipantEmails = () => {
    NiceModal.show(PiiWarningModal, {
      eventName: target.name,
      downloadType: DownloadType.All,
    });
  };

  const toggleUserDetails = (userRow: UserRow) => {
    setSelectedUser(userRow);
    void checkEmail(userRow, false, true);
    setUserDetailsVisible(true);
  };

  const toggleDisableUser = (userRow: UserRow) => {
    setSelectedUser(userRow);
    void checkEmail(userRow, false);
    setConfirmDisableUserVisible(true);
  };

  const selectTeamName = (teamName: string) => {
    if (teamName) {
      const newSelectedTeam = getTeamByName(teamName);
      if (newSelectedTeam) {
        setSelectedTeam(newSelectedTeam);
      }
    }
  };

  const toggleTeamDetails = (teamName: string) => {
    setTeamDetailsVisible(true);
    selectTeamName(teamName);
  };

  const toggleEnableUser = (userRow: UserRow) => {
    setSelectedUser(userRow);
    void checkEmail(userRow, false);
    setConfirmEnableUserVisible(true);
  };

  const toggleAssignTeam = (userRow: UserRow) => {
    setSelectedUser(userRow);
    setSaveTeamAssignmentVisible(true);
    setupTeamSelectOptions(userRow);
  };

  const setupTeamOwnerOptions = () => {
    if (target) {
      const participants = target.unassignedParticipants?.filter((member) => !member.facilitator);

      const options = participants.map((member) => ({
        label: member.nickname || '',
        value: member.userId || '',
      }));

      sortAndAddDefaultSelection(options, noOwnerOption, setTeamOwnerOptions, teamOwnerOptions);
    }
  };

  const setupTeamSelectOptions = (userRow: UserRow) => {
    if (!target || !teamList) {
      return;
    }

    const newTeamSelectOptions: OptionDefinition[] = teamList.map((team) => {
      const name = team.alias ? team.teamLabel : team.name;

      const remainingSpots = `${Math.max(0, target.maxTeamSize - (team.members || []).length)}/${target.maxTeamSize}${t(
        i18nKeys.participants.labels.spotsAvailable
      )}`;

      return {
        value: team.name as string,
        label: `${remainingSpots} :: ${name}` || '',
      };
    });

    sortAndAddDefaultSelection(newTeamSelectOptions, noTeamOption, setTeamSelectOptions, teamSelectOptions);

    if (userRow) {
      const currentSelectedOption = userRow.teamName
        ? getTeamOptionByName(userRow.teamName, newTeamSelectOptions) || noTeamOption
        : noTeamOption;
      setSelectedTeamOption(currentSelectedOption);
    }
  };

  const sortAndAddDefaultSelection = (
    options: OptionDefinition[],
    noOptionPlaceholder: OptionDefinition,
    setSelection: any,
    original: OptionDefinition[]
  ) => {
    options.sort((a, b) => (a.value! > b.value! ? 1 : -1));
    options.unshift(noOptionPlaceholder);

    if (!isEqual(options, original)) {
      setSelection(options);
    }
  };

  const setDefaultSelectedRoleOption = (userRow: UserRow) => {
    if (userRow.facilitator) {
      setSelectedRoleOption(roleOptions[UserRole.Facilitator]);
    } else if (userRow.support) {
      setSelectedRoleOption(roleOptions[UserRole.Support]);
    } else {
      setSelectedRoleOption(roleOptions[UserRole.Participant]);
    }
  };

  const handleCreateTeams = async () => {
    if (isNaN(numberOfTeamsToCreate) || !numberOfTeamsToCreate || numberOfTeamsToCreate < 1) {
      return;
    }
    if (!createTeamsPrefix) {
      return;
    }
    await teamApi.bulkCreateTeam(target.name, numberOfTeamsToCreate, createTeamsPrefix).then(() => {
      getEventByName(target.name);
    });
    setNumberOfTeamsToCreate(0);
    setCreateTeamsPrefix(null);
    setCreateTeamsVisible(false);
  };

  const handleCreateTeam = () => {
    setCreateTeamVisible(false);
    setConfirmCreateTeamVisible(true);
  };

  const handleConfirmCreateTeam = async () => {
    const owner = teamOwnerOption.value ? getUserById(teamOwnerOption?.value) || null : null;
    if (newTeamName) {
      await teamApi.createTeam(target.name, newTeamName, newPassword, owner).then(() => {
        getEventByName(target.name);
      });
      setConfirmCreateTeamVisible(false);
      setNewPassword(null);
      setTeamOwnerOption(noOwnerOption);
      setNewTeamName(null);
    }
  };

  const handleConfirmSaveTeamAssignment = async () => {
    if (selectedUser && selectedUser.userId && selectedUser.nickname && selectedTeamOption.value) {
      const teamName = selectedTeamOption.value === noTeamOption.value ? null : selectedTeamOption.value;
      await userApi.assignUserToTeam(target.name, teamName, selectedUser);

      void getEventByName(target.name);

      setSelectedUser(null);
      setSelectedTeamOption(noTeamOption);
      setConfirmAssignTeamVisible(false);
    }
  };

  const handleConfirmSaveRoleAssignment = async () => {
    const role = selectedRoleOption?.value as UserRole;

    if (selectedUser && selectedUser.userId && selectedUser.nickname && role) {
      await userApi.setUserRole(target.name, selectedUser.userId, selectedUser.nickname, role);

      void getEventByName(target.name);

      setSelectedUser(null);
      setConfirmAssignRoleVisible(false);
    }
  };

  const handleEnableUser = async () => {
    if (selectedUser && selectedUser.userId && selectedUser.nickname) {
      await userApi.enableUserAccount(target.name, selectedUser.userId, selectedUser.nickname);

      void getEventByName(target.name);

      setConfirmEnableUserVisible(false);
      setSelectedUser(null);
    }
  };

  const handleDisableUser = async () => {
    if (selectedUser && selectedUser.userId && selectedUser.nickname) {
      await userApi.disableUserAccount(target.name, selectedUser.userId, selectedUser.nickname);

      void getEventByName(target.name);

      setConfirmDisableUserVisible(false);
      setSelectedUser(null);
    }
  };

  const handleRenameTeamAlias = async () => {
    if (!_.isEmpty(newTeamAlias) && selectedTeam?.name) {
      await teamApi.changeTeamAlias(target.name, selectedTeam?.name, newTeamAlias || '').then(() => {
        getEventByName(target.name);
      });

      setSelectedTeam(null);
      setRenameTeamAliasVisible(false);
      setNewTeamAlias(null);
    }
  };

  const handleDisableTeam = async () => {
    if (selectedTeam && selectedTeam.name) {
      await teamApi.disableTeam(target.name, selectedTeam.name).then(() => {
        getEventByName(target.name);
      });

      setDisableTeamVisible(false);
      setSelectedTeam(null);
    }
  };

  const handleDeleteTeam = async () => {
    if (selectedTeam && selectedTeam.name) {
      await teamApi.deleteTeam(target.name, selectedTeam.name).then(() => {
        getEventByName(target.name);
      });
      setDeleteTeamVisible(false);
      setSelectedTeam(null);
    }
  };

  const checkEmail = async (userRow: UserRow, throwIfNotVerified = true, ignoreErrors = false) => {
    if (!userRow || !userRow.userId) {
      return;
    }

    try {
      let userEmail: CachedEmail | undefined = emailCache[userRow.userId];

      if (!userEmail || !userEmail.email || !userEmail.isVerified) {
        userEmail = await fetchUserEmail(userRow.userId);
      }

      if (!userEmail) {
        throw new Error('error with user email');
      }

      const { email, isVerified } = userEmail;

      if (!email) {
        addErrorFlashbar(t(i18nKeys.participants.errors.fetchEmail));
        throw new Error('error with user email');
      }

      if (throwIfNotVerified && !isVerified) {
        addErrorFlashbar(t(i18nKeys.participants.errors.emailVerification));
        throw new Error('error with user verification');
      }

      const newRow = _.cloneDeep(userRow);
      newRow.email = email;

      setSelectedUser(newRow);
    } catch (err: any) {
      if (ignoreErrors) {
        return;
      }

      hideAllModals();
      setSelectedUser(null);
    }
  };

  const handleRoleFilterChange = (selectedRole: OptionDefinition) => {
    setRoleFilter(selectedRole);
    let allUsers = _.cloneDeep(allUserRows);
    switch (selectedRole.value) {
      /**
       * roleOptions[0] is for all users
       */
      case roleFilterOptions[0].value:
        break;
      /**
       * roleOptions[1] is for facilitators
       */
      case roleFilterOptions[1].value:
        allUsers = allUsers.filter((userRow) => userRow.facilitator);
        break;
      /**
       * roleOptions[2] is for participants
       */
      case roleFilterOptions[2].value:
        allUsers = allUsers.filter((userRow) => !userRow.facilitator);
        break;
    }
    setUsers(allUsers);
  };

  const { items, actions, filteredItemsCount, collectionProps, filterProps, paginationProps } = useCollection(
    users || [],
    {
      filtering: {
        filteringFunction,
        empty: (
          <TableEmptyState
            title={t(i18nKeys.participants.labels.empty.participants.title)}
            subtitle={t(i18nKeys.participants.labels.empty.participants.subtitle)}
          />
        ),
        noMatch: (
          <TableEmptyState
            title={t(i18nKeys.tables.noMatch.title)}
            subtitle={t(i18nKeys.tables.noMatch.subtitle)}
            onClearFilter={() => actions.setFiltering('')}
          />
        ),
      },
      pagination: { pageSize: preferences.pageSize },
      sorting: {},
    }
  );

  const handleDownloadAction = (id: string) => {
    switch (id) {
      case DownloadParticipantActions.CHALLENGE_RESOLVER_PARTICIPANTS:
        downloadParticipantSolvedExport();
        break;

      case DownloadParticipantActions.ALL_PARTICIPANTS_EMAIL:
        downloadAllParticipantEmails();
        break;

      default:
        break;
    }
  };

  const canUserBeAssignedRole = (userRow: UserRow, role: UserRole) => {
    if (role === UserRole.Facilitator) {
      const allowlist = (target.facilitatorDomainAllowlist = []);

      if (!allowlist.length) {
        return true;
      }

      const endsWithSuffix = (value: string) => (suffix: string) => value.endsWith(suffix);
      const endsWithAtLeastOne = (value: string, list: string[]) => list.some(endsWithSuffix(value));

      if (userRow.email && !endsWithAtLeastOne(userRow.email, allowlist)) {
        return false;
      }
    }

    return true;
  };

  return (
    <React.Fragment>
      {selectedUser && (
        <React.Fragment>
          <ConfirmModal
            data-testid="disable-user-confirm-modal"
            visible={confirmDisableUserVisible}
            message={`${t(i18nKeys.participants.messages.disable)}${selectedUser?.nickname} <${selectedUser.email}>`}
            confirmBtnLabel={t(i18nKeys.participants.buttons.confirmDisbleUser)}
            onConfirm={() => handleDisableUser()}
            onCancel={() => {
              setSelectedUser(null);
              setConfirmDisableUserVisible(false);
            }}
          />
          <ConfirmModal
            data-testid="enable-user-confirm-modal"
            visible={confirmEnableUserVisible}
            message={`${t(i18nKeys.participants.messages.enable)}${selectedUser?.nickname} <${selectedUser.email}>`}
            confirmBtnLabel={t(i18nKeys.participants.buttons.confirmEnableUser)}
            onConfirm={() => handleEnableUser()}
            onCancel={() => {
              setSelectedUser(null);
              setConfirmEnableUserVisible(false);
            }}
          />
          {t(i18nKeys.participants.buttons.handleAssignment, {
            trailingMessage:
              selectedTeamOption.value !== TEAM_OPTION_VALUE_NONE
                ? t(i18nKeys.participants.buttons.assignToTeam, { teamName: selectedTeamOption.value })
                : t(i18nKeys.participants.buttons.unassign),
          })}
          <ConfirmModal
            data-testid="save-role-assignment-confirm-modal"
            visible={confirmAssignRoleVisible}
            message={t(i18nKeys.participants.messages.assignUserRole, {
              nickName: selectedUser.nickname,
              role: selectedRoleOption?.label,
            })}
            confirmBtnLabel={t(i18nKeys.participants.buttons.saveRoleAssignment)}
            onConfirm={() => handleConfirmSaveRoleAssignment()}
            onCancel={() => {
              setSelectedUser(null);
              setConfirmAssignRoleVisible(false);
            }}
          />
          <ConfirmModal
            data-testid="save-role-assignment-modal"
            visible={saveRoleAssignmentVisible}
            title={t(i18nKeys.participants.headers.roleAssignment)}
            disabled={!canUserBeAssignedRole(selectedUser, selectedRoleOption?.value as UserRole)}
            message={
              <div>
                <KeyValue label={t(i18nKeys.participants.headers.event)}>{`${target.title} (${target.name})`}</KeyValue>
                <KeyValue
                  label={t(i18nKeys.participants.headers.user)}
                >{`${selectedUser.nickname} (${selectedUser.email})`}</KeyValue>
                <KeyValue label={t(i18nKeys.participants.labels.selectRole)}>
                  <Select
                    options={Object.values(roleOptions)}
                    selectedOption={selectedRoleOption!}
                    onChange={({ detail }) => setSelectedRoleOption(detail.selectedOption)}
                    ariaLabelledby={
                      !canUserBeAssignedRole(selectedUser, selectedRoleOption?.value as UserRole)
                        ? 'role-cannot-promote'
                        : undefined
                    }
                  />
                </KeyValue>
                {!canUserBeAssignedRole(selectedUser, selectedRoleOption?.value as UserRole) && (
                  <Alert type="error">
                    <span id="role-cannot-promote">{t(i18nKeys.participants.errors.cannotPromote)}</span>
                  </Alert>
                )}
              </div>
            }
            confirmBtnLabel={t(i18nKeys.participants.buttons.saveRoleAssignment)}
            onConfirm={() => {
              setSaveRoleAssignmentVisible(false);
              setConfirmAssignRoleVisible(true);
            }}
            onCancel={() => {
              setSelectedUser(null);
              setSaveRoleAssignmentVisible(false);
            }}
          />
          <ConfirmModal
            data-testid="save-team-assignment-confirm-modal"
            visible={confirmAssignTeamVisible}
            message={
              selectedTeamOption.value !== TEAM_OPTION_VALUE_NONE
                ? t(i18nKeys.participants.messages.assignUserToTeam, {
                    nickName: selectedUser.nickname,
                    teamName: selectedTeamOption.value,
                  })
                : t(i18nKeys.participants.messages.unassignUserFromTeam, { nickname: selectedUser.nickname })
            }
            confirmBtnLabel={t(i18nKeys.participants.buttons.handleAssignment, {
              trailingMessage:
                selectedTeamOption.value !== TEAM_OPTION_VALUE_NONE
                  ? t(i18nKeys.participants.buttons.assignToTeam, { teamName: selectedTeamOption.value })
                  : t(i18nKeys.participants.buttons.unassign),
            })}
            onConfirm={() => handleConfirmSaveTeamAssignment()}
            onCancel={() => {
              setSelectedUser(null);
              setConfirmAssignTeamVisible(false);
            }}
          />
          <ConfirmModal
            data-testid="save-team-assignment-modal"
            visible={saveTeamAssignmentVisible}
            title={t(i18nKeys.participants.headers.teamAssignment)}
            message={
              <div>
                <KeyValue label={t(i18nKeys.participants.headers.event)}>{`${target.title} (${target.name})`}</KeyValue>
                <KeyValue
                  label={t(i18nKeys.participants.headers.user)}
                >{`${selectedUser.nickname} (${selectedUser.email})`}</KeyValue>
                <KeyValue label={t(i18nKeys.participants.labels.selectTeam)}>
                  <Select
                    options={teamSelectOptions}
                    selectedOption={selectedTeamOption}
                    onChange={({ detail }) => setSelectedTeamOption(detail.selectedOption)}
                  />
                </KeyValue>
              </div>
            }
            confirmBtnLabel={t(i18nKeys.participants.buttons.saveTeamAssignment)}
            disabled={
              selectedUser.teamName
                ? selectedUser.teamName === selectedTeamOption.value
                : selectedTeamOption.value === TEAM_OPTION_VALUE_NONE
            }
            onConfirm={() => {
              setSaveTeamAssignmentVisible(false);
              setConfirmAssignTeamVisible(true);
            }}
            onCancel={() => {
              setSelectedUser(null);
              setSaveTeamAssignmentVisible(false);
            }}
          />
          <Modal
            data-testid="user-details-modal"
            visible={userDetailsVisible}
            header={t(i18nKeys.participants.headers.userDetails)}
            onDismiss={() => {
              setSelectedUser(null);
              setUserDetailsVisible(false);
            }}
            footer={
              <Box float="right">
                <Button
                  variant="link"
                  onClick={() => {
                    setSelectedUser(null);
                    setUserDetailsVisible(false);
                  }}
                >
                  {t(i18nKeys.general.cancel)}
                </Button>
              </Box>
            }
          >
            <div>
              <KeyValue label={<strong>{t(i18nKeys.participants.headers.user)}</strong>}>
                {`${selectedUser.nickname} (${selectedUser.email})`}
              </KeyValue>
              <KeyValue label={<strong>{t(i18nKeys.participants.headers.event)}</strong>}>
                {`${target.title} (${target.name})`}
              </KeyValue>
              <KeyValue label={<strong>{t(i18nKeys.participants.headers.team)}</strong>}>
                {selectedUser.teamName}
                <Link
                  data-testid="reassign-team-link"
                  onClick={() => {
                    setUserDetailsVisible(false);
                    toggleAssignTeam(selectedUser);
                  }}
                >
                  <Icon name="edit" />
                </Link>
              </KeyValue>
              <KeyValue label={<strong>{t(i18nKeys.participants.headers.role)}</strong>}>
                {t(selectedUser.getRole)}
                <Link
                  data-testid="reassign-role-link"
                  onClick={() => {
                    setDefaultSelectedRoleOption(selectedUser);
                    setUserDetailsVisible(false);
                    setSaveRoleAssignmentVisible(true);
                  }}
                >
                  <Icon name="edit" />
                </Link>
              </KeyValue>
              <HorizontalRule evenMargins />
              <SpaceBetween direction="horizontal" size="s">
                {!selectedUser.disabled && (
                  <Button
                    data-testid="disable-user-button"
                    variant="inline-link"
                    onClick={() => {
                      setUserDetailsVisible(false);
                      toggleDisableUser(selectedUser);
                    }}
                  >
                    {t(i18nKeys.participants.labels.disable)}
                  </Button>
                )}
                {selectedUser.disabled && (
                  <Button
                    data-testid="enable-user-button"
                    variant="inline-link"
                    onClick={() => {
                      setUserDetailsVisible(false);
                      toggleEnableUser(selectedUser);
                    }}
                  >
                    {t(i18nKeys.participants.labels.enable)}
                  </Button>
                )}
              </SpaceBetween>
            </div>
          </Modal>
        </React.Fragment>
      )}
      {selectedTeam && (
        <React.Fragment>
          <Modal
            header={t(i18nKeys.participants.headers.teamDetails)}
            visible={teamDetailsVisible}
            onDismiss={() => {
              setSelectedTeam(null);
              setTeamDetailsVisible(false);
            }}
            footer={
              <Box float="right">
                <Button
                  variant="link"
                  onClick={() => {
                    setSelectedTeam(null);
                    setTeamDetailsVisible(false);
                  }}
                >
                  {t(i18nKeys.general.cancel)}
                </Button>
              </Box>
            }
          >
            <KeyValue
              label={<strong>{t(i18nKeys.participants.headers.event)}</strong>}
            >{`${target.title} (${target.name})`}</KeyValue>
            <KeyValue label={<strong>{t(i18nKeys.participants.headers.teamName)}</strong>}>
              {selectedTeam?.name}
            </KeyValue>
            <KeyValue label={<strong>{t(i18nKeys.participants.headers.teamAlias)}</strong>}>
              {selectedTeam?.alias}
            </KeyValue>
            <KeyValue label={<strong>{t(i18nKeys.participants.headers.members)}</strong>}>
              {selectedTeam?.members.map((member, i) => {
                return (
                  <div key={`team-member-${i}`}>
                    {member.nickname}
                    <Link
                      onFollow={() => {
                        if (member.userId) {
                          const userRow = getUserById(member.userId);
                          if (userRow) {
                            setSelectedUser(userRow);
                            setTeamDetailsVisible(false);
                            setSaveTeamAssignmentVisible(false);
                            setConfirmAssignTeamVisible(true);
                          }
                        }
                      }}
                    >
                      <Icon name="close" className="ml-5" />
                    </Link>
                  </div>
                );
              })}
            </KeyValue>
            <HorizontalRule evenMargins />
            <SpaceBetween direction="horizontal" size="s">
              <Link
                className="right-border pr-12"
                onFollow={() => {
                  setTeamDetailsVisible(false);
                  setRenameTeamAliasVisible(true);
                }}
              >
                {t(i18nKeys.participants.labels.changeTeamAlias)}
              </Link>
              <Link
                className="right-border pr-12"
                onFollow={() => {
                  setDisableTeamVisible(true);
                  setTeamDetailsVisible(false);
                }}
              >
                {t(i18nKeys.participants.labels.disableTeam)}
              </Link>
              <Link
                onFollow={() => {
                  setDisableTeamVisible(false);
                  setDeleteTeamVisible(true);
                }}
              >
                {t(i18nKeys.participants.labels.deleteTeam)}
              </Link>
            </SpaceBetween>
          </Modal>
          <ConfirmModal
            visible={renameTeamAliasVisible}
            title={t(i18nKeys.participants.headers.renameTeamAlias)}
            confirmBtnLabel={t(i18nKeys.general.submit)}
            message={
              <KeyValue label={t(i18nKeys.participants.labels.pleaseEnterNewTeamAlias)}>
                <Input
                  type="text"
                  value={newTeamAlias || ''}
                  onChange={({ detail }) => setNewTeamAlias(detail.value)}
                />
              </KeyValue>
            }
            onConfirm={() => handleRenameTeamAlias()}
            onCancel={() => {
              setNewTeamAlias(null);
              setSelectedTeam(null);
              setRenameTeamAliasVisible(false);
            }}
          />
          <ConfirmModal
            visible={disableTeamVisible}
            confirmBtnLabel={t(i18nKeys.participants.buttons.disableTeam)}
            message={t(i18nKeys.participants.messages.disableTeam, { teamName: selectedTeam.name })}
            onConfirm={() => handleDisableTeam()}
            onCancel={() => {
              setSelectedTeam(null);
              setDisableTeamVisible(false);
            }}
          />
          <ConfirmModal
            visible={deleteTeamVisible}
            confirmBtnLabel={t(i18nKeys.participants.buttons.deleteTeam)}
            message={t(i18nKeys.participants.messages.unassignTeam, { teamName: selectedTeam.name })}
            onConfirm={() => handleDeleteTeam()}
            onCancel={() => {
              setSelectedTeam(null);
              setDeleteTeamVisible(false);
            }}
          />
        </React.Fragment>
      )}
      <ConfirmModal
        visible={createTeamsVisible}
        confirmBtnLabel={t(i18nKeys.participants.buttons.createTeams)}
        title={t(i18nKeys.participants.headers.bulkCreateTeams)}
        message={
          <div>
            <KeyValue label={t(i18nKeys.participants.labels.howManyTeams)}>
              <Input
                type="number"
                value={numberOfTeamsToCreate.toString() || ''}
                onChange={({ detail }) => setNumberOfTeamsToCreate(Number(detail.value))}
              />
            </KeyValue>
            <KeyValue label={t(i18nKeys.participants.labels.teamPrefix)}>
              <Input
                type="text"
                value={createTeamsPrefix || ''}
                onChange={({ detail }) => setCreateTeamsPrefix(detail.value)}
              />
            </KeyValue>
          </div>
        }
        disabled={numberOfTeamsToCreate <= 0 || _.isEmpty(createTeamsPrefix)}
        onConfirm={() => handleCreateTeams()}
        onCancel={() => {
          setCreateTeamsVisible(false);
        }}
      />
      <ConfirmModal
        visible={createTeamVisible}
        title={t(i18nKeys.participants.headers.createTeam)}
        confirmBtnLabel={t(i18nKeys.participants.buttons.createTeam)}
        message={
          <div>
            <KeyValue label={t(i18nKeys.participants.headers.event)}>{`${target?.title} (${target?.name})`}</KeyValue>
            <KeyValue label={t(i18nKeys.participants.headers.teamName)}>
              <Input type="text" value={newTeamName || ''} onChange={({ detail }) => setNewTeamName(detail.value)} />
            </KeyValue>
            <KeyValue label={t(i18nKeys.participants.headers.teamPassword)}>
              <Input
                value={newPassword || ''}
                type={showPassword ? 'text' : 'password'}
                onChange={({ detail }) => setNewPassword(detail.value)}
              />
              <Link onFollow={() => setShowPassword(!showPassword)}>
                {showPassword
                  ? t(i18nKeys.participants.labels.hidePassword)
                  : t(i18nKeys.participants.labels.showPassword)}
              </Link>
            </KeyValue>
            <KeyValue label={t(i18nKeys.participants.headers.teamOwner)}>
              <Select
                selectedOption={teamOwnerOption}
                onChange={({ detail }) => setTeamOwnerOption(detail.selectedOption)}
                options={teamOwnerOptions}
              />
            </KeyValue>
          </div>
        }
        disabled={_.isEmpty(newTeamName)}
        onConfirm={() => handleCreateTeam()}
        onCancel={() => {
          setCreateTeamVisible(false);
        }}
      />
      <ConfirmModal
        visible={confirmCreateTeamVisible}
        confirmBtnLabel={t(i18nKeys.participants.buttons.confirmCreateTeam, {
          teamType: newPassword ? t(i18nKeys.participants.labels.private) : t(i18nKeys.participants.labels.public),
        })}
        message={t(i18nKeys.participants.messages.createTeam, {
          teamType: newPassword ? t(i18nKeys.participants.labels.private) : t(i18nKeys.participants.labels.public),
          teamName: newTeamName,
          trailingMessage:
            teamOwnerOption && teamOwnerOption.value !== TEAM_OPTION_VALUE_NONE
              ? t(i18nKeys.participants.messages.createTeamTrail, { teamOwner: teamOwnerOption.label })
              : '',
        })}
        onConfirm={() => handleConfirmCreateTeam()}
        onCancel={() => {
          setTeamOwnerOption(noOwnerOption);
          setNewPassword(null);
          setNewTeamName(null);
          setConfirmCreateTeamVisible(false);
        }}
      />
      <SpaceBetween direction="vertical" size="s">
        <ExpandableSection
          data-testid="participant-user-section"
          defaultExpanded
          variant="container"
          headerText={t(i18nKeys.participants.headers.participantsLabel)}
          headerCounter={`(${String(allUserRows.length)})`}
          headerActions={
            hasDownloadPermissions(user) ? (
              <ButtonDropdown
                key={'download-dropdown'}
                onItemClick={(e) => handleDownloadAction(e.detail.id)}
                items={[
                  {
                    id: DownloadParticipantActions.CHALLENGE_RESOLVER_PARTICIPANTS,
                    text: t(i18nKeys.participants.buttons.DownloadParticipantsWhoSolvedAChallenge) ?? '',
                  },
                  {
                    disabled: !users || users?.length === 0,
                    id: DownloadParticipantActions.ALL_PARTICIPANTS_EMAIL,
                    text: t(i18nKeys.participants.buttons.downloadAllParticipantEmails) ?? '',
                  },
                ]}
              >
                {t(i18nKeys.participants.labels.download)}
              </ButtonDropdown>
            ) : null
          }
        >
          <Table
            variant="borderless"
            {...collectionProps}
            pagination={<Pagination {...paginationProps} ariaLabels={paginationLabels(t)} />}
            items={items}
            filter={
              <SpaceBetween direction="horizontal" size="s">
                <TextFilter
                  className="text-filter"
                  filteringPlaceholder={t(i18nKeys.participants.labels.searchParticipants)}
                  {...filterProps}
                  countText={t(i18nKeys.tables.matchesCount, { count: filteredItemsCount })}
                  filteringAriaLabel={t(i18nKeys.participants.labels.userFilteringLabel)}
                />
                <Select
                  options={roleFilterOptions}
                  selectedOption={roleFilter}
                  onChange={({ detail }) => handleRoleFilterChange(detail.selectedOption)}
                />
              </SpaceBetween>
            }
            columnDefinitions={COLUMN_DEFINITIONS(toggleUserDetails)}
          />
        </ExpandableSection>

        <TeamTable
          target={target}
          teams={teamList}
          toggleTeamDetails={toggleTeamDetails}
          setCreateTeamsVisible={setCreateTeamsVisible}
          setCreateTeamVisible={setCreateTeamVisible}
        />
      </SpaceBetween>
    </React.Fragment>
  );
};
