import { TFunction } from 'i18next';
// api client
import { ApiClient } from './ApiClient';
import { v4 } from 'uuid';

import {
  IEventTemplate,
  EventTemplateCreateOptions,
  EventDurationType,
  EventLearningType,
  EventTemplateStatusType,
  EventTemplateReviewResponse,
  EventTemplateReview,
  EventTemplateRating,
  EventTemplateReport,
  EventTemplateReportResponse,
  EventTemplateListResponse,
  IGetEventTemplatesOptions,
  GetEventTemplateOffersResponse,
} from '@/src/types/EventTemplate';
import { ChallengeListItem } from '../types/Challenge';
import { getMockEventTemplates } from '../__mocks__/mock-event-templates';

// TODO: move to types
type EventTemplateOptions = {
  name: string;
  learningType: EventLearningType;
  duration: EventDurationType;
  price: number;
  challenges?: { id: string; rationale?: string }[];
  summary?: string;
  topics?: string[];
  recommendedTeamSize?: number;
  minParticipants?: number;
  maxParticipants?: number;
  rating?: number;
  status?: EventTemplateStatusType;
  awsServices?: string[];
  challengeList?: ChallengeListItem[];
};

// const saveSingleEventTemplate = (newEventTemplate: IEventTemplate) => {
//   const allEventTemplates = getAllEventTemplates();
//   allEventTemplates.push(newEventTemplate);
//   localStorage.setItem(EVENT_CATALOG_TEMPLATES_KEY, JSON.stringify(allEventTemplates));
//   return newEventTemplate;
// };

// const updateSingleEventTemplate = (id: string, updatedEventTemplate: Partial<EventTemplateOptions>) => {
//   const allEventTemplates = getAllEventTemplates();
//   const idx = allEventTemplates.findIndex((item) => item.id === id);
//   if (idx !== -1) {
//     allEventTemplates[idx] = {
//       ...allEventTemplates[idx],
//       ...updatedEventTemplate,
//     };
//     localStorage.setItem(EVENT_CATALOG_TEMPLATES_KEY, JSON.stringify(allEventTemplates));
//     return allEventTemplates[idx];
//   }
//   return undefined;
// };

// const deleteEventTemplate = (id: string) => {
//   const allEventTemplates = getAllEventTemplates();
//   const idx = allEventTemplates.findIndex((item) => item.id === id);
//   if (idx !== -1) {
//     allEventTemplates.splice(idx, 1);
//     localStorage.setItem(EVENT_CATALOG_TEMPLATES_KEY, JSON.stringify(allEventTemplates));
//     return allEventTemplates[idx];
//   }
//   return undefined;
// };

const generateReview = (): EventTemplateReview => {
  return {
    id: v4(),
    imgURL: 'https://static.thenounproject.com/png/363639-200.png',
    userName: 'test username',
    rating: Math.floor(Math.random() * 5) + 1,
    review: 'test review',
    date: new Date('1/1/2020').toLocaleDateString(),
    liked: true,
  };
};

const fetchEventTemplateReviews = async (
  count: number,
  pageNumber: number,
  itemsPerPage: number
): Promise<EventTemplateReviewResponse> => {
  const allData: EventTemplateReview[] = [];

  for (let i = 0; i < count; i++) {
    allData.push(generateReview());
  }

  const startIndex = (pageNumber - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const data = allData.slice(startIndex, endIndex);

  return new Promise((resolve) => {
    setTimeout(() => {
      resolve({
        totalData: count,
        pageNumber,
        itemsPerPage,
        data,
        allData,
      });
    }, 400);
  });
};

const fetchEventTemplateRatings = async (): Promise<EventTemplateRating> => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve({
        averageRating: Math.floor(Math.random() * 5) + 1,
        numberOfRatings: 99,
        fiveStarProportion: 99,
        fourStarProportion: 99,
        threeStarProportion: 99,
        twoStarProportion: 99,
        oneStarProportion: 99,
      });
    }, 400);
  });
};

export const fetchEventTemplateReports = (): Promise<EventTemplateReportResponse> => {
  return new Promise((resolve) => {
    setTimeout(() => {
      const data = generateReport(500);

      const response: EventTemplateReportResponse = {
        data,
        eventsUsed: data.length,
        revenueGenerated: 999,
        learnersParticipated: 999,
        labCostGenerated: 999,
      };

      resolve(response);
    }, 100);
  });
};

const generateReport = (rows: number): EventTemplateReport[] => {
  const data: EventTemplateReport[] = [];

  for (let i = 0; i < rows; i++) {
    const rowData: EventTemplateReport = {
      title: 'test title',
      owner: 'test owner',
      date: new Date('1/1/2020'),
      learners: 99,
      totalRevenue: '$9999',
    };

    data.push(rowData);
  }

  return data;
};

export const fetchTrendingEventTemplate = async (
  count: number,
  pageNumber: number,
  itemsPerPage: number
): Promise<EventTemplateListResponse> => {
  const allData = getMockEventTemplates();

  const startIndex = (pageNumber - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const data = allData.slice(startIndex, endIndex);

  return new Promise((resolve) => {
    setTimeout(() => {
      resolve({
        totalData: count,
        pageNumber,
        itemsPerPage,
        data,
        allData,
      });
    }, 400);
  });
};

export class EventTemplateAPI {
  constructor(private apiClient: ApiClient, private t: TFunction) {
    // do nothing
  }

  public async getEventTemplates(options?: IGetEventTemplatesOptions) {
    const response = (await this.apiClient.get({ path: '/catalog/templates', params: options as Record<string, any> })) as {
      templates: IEventTemplate[];
      nextToken?: string;
    };
    const templates = response.templates;
    return { data: templates, totalCount: templates.length };
  }

  public async getPublicEventTemplates(options?: Omit<IGetEventTemplatesOptions, 'statuses' | 'deleted'>) {
    const response = (await this.apiClient.get({ path: '/public/catalog/templates', params: options })) as {
      templates: IEventTemplate[];
      nextToken?: string;
    };
    const templates = response.templates;
    return { data: templates, totalCount: templates.length };
  }

  public async createNewEventTemplate(options: EventTemplateCreateOptions) {
    const response = (await this.apiClient.post({
      path: '/catalog/templates',
      body: options,
    })) as IEventTemplate;
    return response;
  }

  public async getEventTemplateById(id: string) {
    const response = (await this.apiClient.get({ path: `/catalog/templates/${id}` })) as IEventTemplate;
    return response;
  }

  public async getPublicEventTemplateById(id: string) {
    const response = (await this.apiClient.get({ path: `/public/catalog/templates/${id}` })) as IEventTemplate;
    return response;
  }

  public async updateEventTemplateById(id: string, options: Partial<EventTemplateOptions>) {
    const response = (await this.apiClient.put({ path: `/catalog/templates/${id}`, body: options })) as IEventTemplate;
    return response;
  }

  public async uploadEventTemplateImage(id: string, image: File) {
    const form = new FormData();
    form.append('image', image);
    const response = (await this.apiClient.post({ path: `/catalog/image/${id}`, body: form }, true)) as {
      presignedUrl: string;
    };
    return response.presignedUrl;
  }

  public async deleteEventTemplateById(id: string) {
    const response = (await this.apiClient.delete({ path: `/catalog/templates/${id}` })) as IEventTemplate;
    return response;
  }

  public async getPublicEventTemplateOffers() {
    const response = (await this.apiClient.get({ path: '/public/catalog/offers' })) as GetEventTemplateOffersResponse;
    return response;
  }

  public async getEventTemplateReports(id: string) {
    return fetchEventTemplateReports();
    const response = (await this.apiClient.get({
      path: `/catalog/templates/${id}/reports`,
    })) as EventTemplateReportResponse;
    return response;
  }

  public async getEventTemplateRating(id: string) {
    return fetchEventTemplateRatings();
    const response = (await this.apiClient.get({
      path: `/catalog/templates/${id}/rating`,
    })) as EventTemplateRating;
    return response;
  }

  public async getEventTemplateReviews(id: string, count: number, pageNumber: number, itemsPerPage: number) {
    return fetchEventTemplateReviews(count, pageNumber, itemsPerPage);
    const response = (await this.apiClient.get({
      path: `/catalog/templates/${id}/reviews`,
    })) as EventTemplateReviewResponse;
    return response;
  }
}
