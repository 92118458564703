/* eslint-disable @typescript-eslint/no-unsafe-argument */
import Amplify from 'aws-amplify';
import { BASE_PATH_PREFIX } from '../utils/env.utils';
import { config } from './app-config';
import { getSignOutUrl } from '../utils/hybrid-console/index';
import { RoutePath } from '../RoutePath';

const addQueryParams = (url: string, newParams: Record<string, string>) => {
  const urlObj = new URL(url);
  Object.entries(newParams).forEach(([key, value]) => {
    urlObj.searchParams.set(key, value);
  });
  return urlObj.toString();
};

/**
 * Create Gandalf signIn interceptor
 *
 * @param issuer Gandalf cognito auth domain
 * @param login Gandalf login domain
 */
const createUrlInterceptor =
  (issuer: string, login: string, logout: string, additionalParameters: Record<string, string> = {}) =>
  (url: string) => {
    const SELF = '_self';
    const loginRequestUrl = `https://${issuer}/oauth2/authorize`;
    const logoutRequestUrl = `https://${issuer}/logout`;
    let windowProxy;

    if (login && url.startsWith(loginRequestUrl)) {
      const signInUrl = url.replace(loginRequestUrl, `https://${login}/login`);
      windowProxy = window.open(addQueryParams(signInUrl, additionalParameters), SELF);
    } else if (logout && url.startsWith(logoutRequestUrl)) {
      const signOutUrl = url.replace(logoutRequestUrl, `https://${logout}/logout`);
      windowProxy = window.open(signOutUrl, SELF);
    } else {
      windowProxy = window.open(url, SELF);
    }
    return windowProxy ? Promise.resolve(windowProxy) : Promise.reject();
  };

const getBaseUrl = (basePathPrefix: string) => {
  let port: string | null = window.location.port;
  if (port === '443' || port === '80') {
    port = null;
  }

  // i.e. https://localhost:4201/v2
  return `${window.location.protocol}//${window.location.hostname}${port ? ':' + port : ''}${basePathPrefix}`;
};

export const getSignInUrl = (basePathPrefix: string): string => {
  return `${getBaseUrl(basePathPrefix)}${RoutePath.OAUTH2}`;
};

const defaultConfig = {
  Auth: {
    region: config.cognito.gandalfRegion,
    userPoolId: config.cognito.userPoolId,
    userPoolWebClientId: config.cognito.clientId,
    oauth: {
      domain: config.cognito.appWebDomain,
      scope: ['openid'],
      redirectSignIn: getSignInUrl(BASE_PATH_PREFIX),
      redirectSignOut: getSignOutUrl(BASE_PATH_PREFIX),
      responseType: 'code',
      urlOpener: createUrlInterceptor(
        config.cognito.appWebDomain,
        config.cognito.gandalfLogin,
        config.cognito.gandalfLogout
      ),
    },
  },
};

/**
 * Used to set additional query parameters on Gandalf sign-in.
 * See configureAmplify function below for more details.
 */
const getCustomConfig = (additionalParameters: Record<string, string>) => {
  return {
    Auth: {
      ...defaultConfig.Auth,
      oauth: {
        ...defaultConfig.Auth.oauth,
        urlOpener: createUrlInterceptor(
          config.cognito.appWebDomain,
          config.cognito.gandalfLogin,
          config.cognito.gandalfLogout,
          additionalParameters
        ),
      },
    },
  };
};

/**
 * Sets up Amplify to leverage Gandalf authentication.
 * Calling this function without parameters will default to the basic login/logout behavior.
 * Sometimes additional URL parameters are required to use special Gandalf features
 * (email verification for example).
 *
 * Note that most parts of the Jam system depend on the default sign-in configuration,
 * so if this function is used to configure a special sign-in, it should be re-configured back
 * to the default after the operation is complete.
 *
 * @param additionalParameters (optional) - extra query parameters to append to Gandalf's sign-in URL
 */
export const configureAmplify = (additionalParameters?: Record<string, string>) => {
  if (additionalParameters) {
    Amplify.configure(getCustomConfig(additionalParameters));
  } else {
    Amplify.configure(defaultConfig);
  }
};

export default defaultConfig;
