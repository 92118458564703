import React, { useEffect, useRef, useState } from 'react';
import Dropzone from 'dropzone';

import './Dropzone.scss';
import 'dropzone/dist/dropzone.css';
import { Flashbar, Icon } from '@amzn/awsui-components-react';
import { i18nKeys } from '@/src/utils/i18n.utils';
import { useTranslation } from 'react-i18next';

interface Props {
  uploadedFileCb: (file: File) => void;
}

const CustomDropzone: React.FC<Props> = ({ uploadedFileCb }) => {
  const dropzoneRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();
  const [active, setActive] = useState(false);

  useEffect(() => {
    const dropzone = new Dropzone(dropzoneRef.current?.firstChild as HTMLElement, {
      parallelUploads: 1,
      thumbnailHeight: 120,
      thumbnailWidth: 120,
      maxFiles: 1,
      maxFilesize: 1,
      filesizeBase: 1000,
      error: () => false,
      acceptedFiles: 'image/*',
    });

    dropzone.on('maxfilesexceeded', (file: Dropzone.DropzoneFile) => {
      dropzone.removeAllFiles();
      dropzone.addFile(file);
    });

    dropzone.on('addedfile', (file: Dropzone.DropzoneFile) => {
      if (!file.type.match(/image.*/)) {
        dropzone.removeFile(file);
        setActive(true);
        // alert('Invalid file format. Please upload an image.');
      }
    });

    dropzone.on('thumbnail', (file: Dropzone.DropzoneFile) => {
      uploadedFileCb(file);
    });
    // TODO: fix this logic
    // dropzone.uploadFiles = (files: Dropzone.DropzoneFile[]) => {};

    return () => {
      dropzone.destroy();
    };
  }, []);

  return (
    <div id="dropzone" ref={dropzoneRef}>
      <form className="dropzone" id="demo-upload" action="#">
        {active && (
          <Flashbar
            items={[
              {
                type: 'error',
                dismissible: true,
                content: t(i18nKeys.dropzone.invalidFormat),
                onDismiss: () => setActive(!active),
              },
            ]}
          />
        )}
        <div className="dz-message">
          <div className="icon">
            <Icon name={'upload-download'} />
          </div>
          <div className="drag-n-drop">
            {t(i18nKeys.dropzone.dragAndDrop)} <label>{t(i18nKeys.dropzone.browse)}</label>
          </div>
          <div className="types">{t(i18nKeys.dropzone.types)}</div>
        </div>
      </form>
    </div>
  );
};

export default CustomDropzone;
