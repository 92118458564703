import JamSpinner from '@/src/components/common/JamSpinner';
import { useLoading } from '@/src/hooks/store/useLoading';
import { useModalManager } from '@/src/hooks/useModalManager';
import { useSideNavBreadCrumbVisibility } from '@/src/hooks/useSideNavBreadCrumbVisibility';
import { useAuth } from '@/src/store/auth.context';
import { useJamLeaderboard } from '@/src/store/jam-leaderboard.context';
import { configureAwsRum } from '@/src/utils/awsrum.utils';
import localStorageTTL from '@/src/utils/localStorageTTL.utils';
import { gradientWithoutSidebar, jamChallengesRegex } from '@/src/utils/myjam.utils';
import { AppLayout, Flashbar, Link } from '@amzn/awsui-components-react';
import React, { Suspense, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { config } from '../../config/app-config';
import { useActiveRouteDetails } from '../../hooks/useActiveRouteDetails';
import { useComponentDidMountEffect } from '../../hooks/useComponentDidMountEffect';
import { useCookieConsent } from '../../store/cookie-consent.context';
import { useFlashbars } from '../../store/flashbar.context';
import { useLSE } from '../../store/lse.context';
import { useSplitPanel } from '../../store/split-panel.context';
import { useToolPanel } from '../../store/tool-panel.context';
import { useUser } from '../../store/user.context';
import { FlashbarItem } from '../../types/common';
import { i18nKeys, withI18NPrefix } from '../../utils/i18n.utils';
import { bannerFlashbar } from '../../utils/notification.utils';
import AbsLoader from '../common/AbsLoader';
import { LoadingBar } from '../common/LoadingBar';
import SplitPanelInterface from '../common/SplitPanelInterface';
import { ToolSets } from '../tools/ToolSets';
import AllJamRoutes from './AllJamRoutes';
import BreadCrumbs from './BreadCrumbs';
import { Footer } from './Footer/Footer';
import GlobalSideNav from './GlobalSideNav/GlobalSideNav';
import { Header, NewHeader } from './Header';

configureAwsRum();
localStorageTTL.init();

const TopHeader = () => {
  return <Suspense fallback={<LoadingBar />}>{config.isNewTopNavigationEnabled ? <NewHeader /> : <Header />}</Suspense>;
};
const App: React.FC = () => {
  const { t } = useTranslation();
  const { isHome } = useActiveRouteDetails();
  const location = useLocation();
  const { user, loading } = useUser();
  const { authClient } = useAuth();
  const { shortbread, initializeCookies } = useCookieConsent();
  const { showSplitPanel, toggleSplitPanel, splitPanelOpen } = useSplitPanel();
  const { isPresenterMode } = useJamLeaderboard();
  const { showToolPanel, toggleToolPanel, toolPanelOpen, toolPanelWidth, showSidenav, toggleSideNav } = useToolPanel();
  const { currentLSE, loadCurrentLSE } = useLSE();
  const { addFlashbar, removeFlashbar, flashbars } = useFlashbars();
  const [currentLSEText, setCurrentLSEText] = useState('');
  const { removeAllModals } = useModalManager();
  const { isSideNavVisible, isBreadCrumbVisible } = useSideNavBreadCrumbVisibility();
  const { loading: isLoading } = useLoading();

  useEffect(() => {
    removeAllModals();
  }, [location.pathname, location.search]);

  const ariaContent = {
    ariaLabel: t(i18nKeys.general.info),
    errorIconAriaLabel: t(i18nKeys.general.error),
    inProgressIconAriaLabel: t(i18nKeys.general.inProgress),
    infoIconAriaLabel: t(i18nKeys.general.info),
    notificationBarAriaLabel: t(i18nKeys.general.notifications),
    notificationBarText: t(i18nKeys.general.notifications),
    successIconAriaLabel: t(i18nKeys.general.success),
    warningIconAriaLabel: t(i18nKeys.general.warning),
  };

  // /** Setup interval to check for new LSE every five minutes */
  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     loadCurrentLSE();
  //   }, MILLIS_PER_FIVE_MINUTES);

  //   return () => clearInterval(interval);
  // }, []);

  useComponentDidMountEffect(async () => {
    if (await authClient.isSignedIn()) {
      await new Promise(() => loadCurrentLSE());
    }
  });

  /** Add banner flashbar if lse message doesn't equal current cached lse message */
  useEffect(() => {
    if (!!currentLSE?.text && currentLSE.text !== currentLSEText) {
      addFlashbar(
        bannerFlashbar(
          currentLSE?.text,
          currentLSE?.url ? (
            <Link href={currentLSE?.url} external color="inverted">
              {t(i18nKeys.general.learnMore)}
            </Link>
          ) : undefined
        )
      );
      setCurrentLSEText(currentLSE.text);
    } else {
      const flashbarId = flashbars.find((fb) => fb.header === currentLSEText)?.id;
      if (flashbarId) {
        removeFlashbar(flashbarId);
      }
      setCurrentLSEText('');
    }
  }, [currentLSE]);

  useEffect(() => {
    // check for the IDP query parameter on the app's every route change / state update
    void authClient.handleIdpQueryParam(user);
    if (shortbread === undefined) {
      initializeCookies();
    }
    // in EU geo locations, will show the cookie consent banner.
    // (will show it once per 365 days at most per domain)
    shortbread?.checkForCookieConsent();
    shortbread?.getConsentCookie();
  }, [shortbread, authClient, initializeCookies, user, showSplitPanel]);

  /**
   * Iterate through all flashbar items and handle translation of any i18n keys that might be present.
   */
  const getLocalizedFlashbarItems = (): FlashbarItem[] => {
    return (flashbars || [])
      .map((item) => {
        if (item.i18nKeys && item.i18nKeys.length > 0) {
          item = Object.assign({}, item);
          item.i18nKeys.forEach((key) => {
            const value = t(key);
            if (value) {
              if (typeof item.header === 'string') {
                item.header = item.header.replace(withI18NPrefix(key), value);
              }
              if (typeof item.content === 'string') {
                item.content = item.content.replace(withI18NPrefix(key), value);
              }
            }
          });
        }
        item.dismissLabel = t(i18nKeys.general.cancel);
        return item;
      })
      .slice()
      .reverse();
  };

  const applyGradientBg = useMemo(
    () =>
      gradientWithoutSidebar.test(location.pathname)
        ? gradientWithoutSidebar.test(location.pathname)
        : jamChallengesRegex.test(location.pathname),
    [location.pathname]
  );

  const getContent = () => {
    if (loading) {
      return <JamSpinner />;
    }
    return <AllJamRoutes />;
  };

  const getClassNameBasedOnLocation = useCallback(() => {
    const pathName = location.pathname.split('/').join('-');
    return pathName.slice(1);
  }, [location]);

  return (
    <Suspense fallback={<LoadingBar />}>
      <React.Fragment>
        {!isPresenterMode && <TopHeader />}
        <div className={getClassNameBasedOnLocation()} data-testid="app-layout-container">
          <AppLayout
            disableContentPaddings={isHome}
            navigationOpen={showSidenav}
            navigationHide={!isSideNavVisible}
            onNavigationChange={() => toggleSideNav(!showSidenav)}
            navigation={<GlobalSideNav />}
            notifications={
              !!user && <Flashbar stackItems i18nStrings={ariaContent} items={getLocalizedFlashbarItems()} />
            }
            breadcrumbs={!isBreadCrumbVisible ? null : <BreadCrumbs />}
            headerSelector={'#header'}
            footerSelector={'#footer'}
            toolsOpen={toolPanelOpen}
            toolsWidth={toolPanelWidth}
            content={getContent()}
            tools={<ToolSets />}
            toolsHide={!showToolPanel}
            onToolsChange={() => toggleToolPanel(!toolPanelOpen)}
            splitPanelOpen={splitPanelOpen}
            onSplitPanelToggle={() => toggleSplitPanel()}
            splitPanel={<SplitPanelInterface />}
            className={applyGradientBg ? 'gradient-bg' : ''}
            ariaLabels={{
              navigationClose: t(i18nKeys.general.navigationClose),
              tools: t(i18nKeys.general.toolsHelp),
              toolsClose: t(i18nKeys.general.toolsClose),
              toolsToggle: t(i18nKeys.general.toolsToggle),
              navigation: t(i18nKeys.general.navigation),
              navigationToggle: t(i18nKeys.general.navigationToggle),
              notifications: t(i18nKeys.general.notifications),
            }}
            maxContentWidth={isPresenterMode ? Number.MAX_VALUE : undefined}
          />
        </div>
        <Footer />
        {isLoading && <AbsLoader />}
      </React.Fragment>
    </Suspense>
  );
};

export default App;
