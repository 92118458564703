import { EventNotes } from '@/src/components/common/CampaignEventComponents/EventNotes';
import { EventType } from '@/src/constants/shared/event-type';
import { IEventTemplate } from '@/src/types/EventTemplate';
import { SpaceBetween } from '@amzn/awsui-components-react';
import React, { useEffect } from 'react';
import { useEditEvent } from '../../../store/edit-event.context';
import { Event } from '../../../types/Event';
import TargetSummaryDetails from '../../common/CampaignEventComponents/TargetSummaryDetails';
import Access from './Summary/Access';
import PreparationGuide from './Summary/PreparationGuide/PreparationGuide';
import TeamSettings from './Summary/TeamSettings';

interface EventDetailsSummaryProps {
  event: Event | undefined;
  eventTemplate?: IEventTemplate | undefined;
  handleCancelOrDeleteEvent: () => void;
}

const EventDetailsSummary: React.FC<EventDetailsSummaryProps> = ({
  event,
  eventTemplate,
  handleCancelOrDeleteEvent,
}) => {
  const { newEventMode, destroyEdits, editedEvent, editMode, handleUpdateEditEvent } = useEditEvent();

  useEffect(() => {
    if (newEventMode) {
      destroyEdits();
    }
  }, []);

  const eventToEdit = editedEvent || event;

  return (
    <SpaceBetween direction="vertical" size="m">
      <Access event={eventToEdit} onAddFlashbar={Error} />
      <TargetSummaryDetails
        target={eventToEdit}
        handleCancelOrDeleteEvent={handleCancelOrDeleteEvent}
        eventTemplate={eventTemplate}
      />
      {/* this code commented because it depends on discussion about showing options to user based on access and we will uncomment it */}
      {/* {(user?.isSuperAdmin || user?.isEventAdmin) && <EventDetailsNotes target={eventToEdit} />}
      {(user?.isSuperAdmin || user?.isEventAdmin) && <AgreementInfo target={eventToEdit} />}
      {(user?.isSuperAdmin || user?.isEventAdmin) && <PrivacyType target={eventToEdit} />} */}
      {/* <TargetDetailsUsagePlan target={eventToEdit} /> */}
      {/* {(user?.isSuperAdmin || user?.isEventAdmin) && <EventTimes target={eventToEdit} />}
      {(user?.isSuperAdmin || user?.isEventAdmin) && <Attendance target={eventToEdit} />}
      {(user?.isSuperAdmin || user?.isEventAdmin) && <DetailsEventType target={eventToEdit} />}
      {(user?.isSuperAdmin || user?.isEventAdmin) && <SupportAndCollaboration target={eventToEdit} />} */}
      {eventToEdit?.type !== EventType.SPL && <TeamSettings target={eventToEdit} />}
      {/* {(user?.isSuperAdmin || user?.isEventAdmin) && <OwnerPermissions target={eventToEdit} targetType="event" />}
      {(user?.isSuperAdmin || user?.isEventAdmin) && <Audit target={event} />} */}
      <PreparationGuide />
      <EventNotes target={event} editMode={editMode} handleUpdateEditEvent={handleUpdateEditEvent} />
    </SpaceBetween>
  );
};

export default EventDetailsSummary;
