import React from 'react';
import { Button, Header, Icon, Link, SpaceBetween, Table } from '@amzn/awsui-components-react';
import { ChangeWithRevisionNumber } from './Revisions';
import { TableHeader } from '../TableHeader';
import { i18nKeys } from '../../../utils/i18n.utils';
import moment from 'moment';
import { DiffChange } from '../../../types/Diff';
import { useTranslation } from 'react-i18next';

interface RevisionDetailsProps {
  revision: ChangeWithRevisionNumber;
  goBack: () => void;
}

export const RevisionDetails: React.FC<RevisionDetailsProps> = ({ revision, goBack }) => {
  const { t } = useTranslation();

  return (
    <SpaceBetween direction="vertical" size="s" className="mt-10">
      <Header
        variant="h2"
        actions={[
          <Button variant="inline-link" onClick={goBack} key="go-back">
            {t(i18nKeys.revisions.revisionDetails.labels.goBack)}
          </Button>,
        ]}>
        {t(i18nKeys.revisions.revisionDetails.title, { revisionNumber: revision.revisionNumber })}
      </Header>
      <Table
        header={<TableHeader title={t(i18nKeys.revisions.revisionDetails.headers.revisionInfo)} />}
        items={[revision]}
        columnDefinitions={[
          {
            id: 'revision-number',
            header: t(i18nKeys.revisions.revisionDetails.headers.revisionNumber),
            width: 130,
            cell: (item: ChangeWithRevisionNumber) => <div>{item?.revisionNumber}</div>,
          },
          {
            id: 'changeAppliedDate',
            header: t(i18nKeys.revisions.revisionDetails.headers.datePublished),
            cell: (item: ChangeWithRevisionNumber) => (
              <div className="aws-positive-green">
                <Icon name="status-positive" /> {moment(item?.changeAppliedDate).format('lll')}
              </div>
            ),
          },
          {
            id: 'changeRequestedDate',
            header: t(i18nKeys.revisions.revisionDetails.headers.dateRequested),
            cell: (item: ChangeWithRevisionNumber) => moment(item?.changeRequestedDate).format('lll'),
          },
          {
            id: 'changeRequestedBy',
            sortingField: 'changeRequestedBy',
            minWidth: 600,
            header: t(i18nKeys.revisions.revisionDetails.headers.author),
            cell: (item: ChangeWithRevisionNumber) => <div>{item?.changeRequestedBy}</div>,
          },
        ]}
      />
      <Table
        header={<TableHeader title={t(i18nKeys.revisions.revisionDetails.headers.revisionComparison)} />}
        items={revision.changes}
        columnDefinitions={[
          {
            id: 'change-type',
            sortingField: 'changeType',
            header: t(i18nKeys.revisions.revisionDetails.headers.changeType),
            cell: (item: DiffChange) => <div>{item.property}</div>,
          },
          {
            id: 'original-value',
            header: t(i18nKeys.revisions.revisionDetails.headers.originalValue),
            cell: (item: DiffChange) => (
              <div>{item?.previousValue ? item?.previousValue : t(i18nKeys.general.none)}</div>
            ),
          },
          {
            id: 'updated-value',
            header: t(i18nKeys.revisions.revisionDetails.headers.updatedValue),
            cell: (item: DiffChange) => <div>{item?.updatedValue ? item?.updatedValue : t(i18nKeys.general.none)}</div>,
          },
        ]}
      />
    </SpaceBetween>
  );
};
export default RevisionDetails;
