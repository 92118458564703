import { TypedJSON } from 'typedjson';

/**
 * Converts plain javascript object to instance of desired class
 *
 * @param object Javascript Plain Object
 * @param className Class to cast object to
 */
export const fromPlainObject = <T extends new () => any>(object: any, className: T) => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  const serializer: TypedJSON<any> = new TypedJSON(className);
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  return serializer.parse(object) as InstanceType<T>;
};
