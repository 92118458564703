/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
import {
  Box,
  Button,
  Container,
  Header,
  Link,
  SpaceBetween,
  TextContent,
} from '@amzn/awsui-components-react';
import React, { createRef, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useChallenges } from '../../../../store/challenge.context';
import { ChallengePropAction, useCreateChallenge } from '../../../../store/create-challenge.context';
import { Challenge, ChallengeReviewableSection } from '../../../../types/Challenge';
import {
  hasAwsJamResourceWithoutRegion,
  hasValidCfnTemplateResource,
  parseCfnToJson,
} from '../../../../utils/cfn.utils';
import { getEnvVar } from '../../../../utils/env-var.utils';
import { i18nKeys } from '../../../../utils/i18n.utils';
import { preProdLogger } from '../../../../utils/log.utils';
import JamCodeEditor from '../../challengesCommon/JamCodeEditor';
import { ChallengeReviewPopover } from '../../challengesCommon/ChallengeReviewPopover';
import { RoutePath } from '@/src/RoutePath';
import { KeyValue } from '@/src/components/common/KeyValue';
import * as awsui from '@amzn/awsui-design-tokens/polaris.js';
import CodeView from '@amzn/awsui-code-view/code-view';
import yamlHighlight from "@amzn/awsui-code-view/highlight/yaml";
import { useFlashbars } from '@/src/store/flashbar.context';
import { downloadAs } from '@/src/utils/download.utils';
import { config } from '@/src/config/app-config';

interface ChallengeCFNTemplateDetailProps {
  challenge: Challenge;
}

const ChallengeCFNTemplateDetail: React.FC<ChallengeCFNTemplateDetailProps> = ({ challenge }) => {
  const { t } = useTranslation();
  const { addSuccessFlashbar, addWarningFlashbar } = useFlashbars();
  const { getChallengeResources, getChallengeConfiguration, validateCfnTemplate } =
    useChallenges();
  const { editMode, editedChallenge, handleUpdateChallengeProp } = useCreateChallenge();
  const [templateScanResults, setTemplateScanResults] = useState('');
  const [disableValidation,setDisableValidation] = useState(true);
  const [fetchingTemplateValidation, fetchingTemplateValidationSet] = useState(false);
  const hiddenFileInput = createRef<HTMLInputElement>();

  const challengeResourceAccountName = getEnvVar('REACT_APP_CHALLENGE_RESOURCES_BUCKET_NAME');

  useEffect(() => {
    getChallengeConfiguration();
    void getChallengeResources(challenge.challengeId || '');
  }, []);

  useEffect(() => {
    setTemplateScanResults('');
  }, [editMode]);

  useEffect(() => {
    if(editedChallenge?.props.cfnTemplate?.length !==0){
    setDisableValidation(false);
    }
    else{
      setDisableValidation(true);
    }
  }, [editedChallenge?.props.cfnTemplate]);

  const handleFileUpload = (uploadedFile: File) => {
    // Handle file upload
    uploadedFile
      .text()
      .then((file) => {
        handleUpdateChallengeProp(ChallengePropAction.CFN_TEMPLATE, file);
      })
      .catch((err) => preProdLogger(err));
  };

  const handleUpdateCfnTemplate = (template: string) => {
    if (editMode) {
      handleUpdateChallengeProp(ChallengePropAction.CFN_TEMPLATE, template);
    }
  };

  const challengeResourceBucket = getEnvVar('REACT_APP_CHALLENGE_RESOURCES_BUCKET_NAME') || '';
  const cfnTemplateFileName = `aws-jam-${challenge.challengeId}-cfn-template.yaml`;

  const onValidateCFNTemplate = () => {
    fetchingTemplateValidationSet(true);
    validateCfnTemplate(challenge.props.cfnTemplate || '', true)
      .then((results) => {
        if (results) {
          const warnings = /^Warnings count:\s*(\d+)/m.exec(results);
          const failures = /^Failures count:\s*(\d+)/m.exec(results);
          const numberOfWarnings = (warnings && warnings?.length > 1) ? parseInt(warnings[1], 10) : 0;
          const numberOfFailures = (failures && failures?.length > 1) ? parseInt(failures[1], 10) : 0;
          if (numberOfWarnings + numberOfFailures > 0) {
            addWarningFlashbar(t(i18nKeys.challenges.subSections.cfnTemplate.validateTemplateWarning));
          }  else {
            addSuccessFlashbar(t(i18nKeys.success.requestSucceeded.challenges.validateTemplate));
          }
        }
        setTemplateScanResults(results);
      })
      .catch((err) => preProdLogger(err))
      .finally(() => fetchingTemplateValidationSet(false));
  };

  const hasInvalidCfnTemplateResource = () => {
    if (editedChallenge) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const cfnJson = parseCfnToJson(editedChallenge.props.cfnTemplate || '');
      return !hasValidCfnTemplateResource(cfnJson);
    }
  };

  const hasMissingResourceRegion = () => {
    if (editedChallenge) {
      return hasAwsJamResourceWithoutRegion(editedChallenge.props.cfnTemplate || '');
    }
  };

  return (
    <Container
      header={
        <>
          <Box float="left">
            <Header variant="h2" actions={<ChallengeReviewPopover section={ChallengeReviewableSection.CFN_TEMPLATE} />}>
              {t(i18nKeys.challenges.challengeDetails.headings.cloudformationTemplate)}
            </Header>
          </Box>
          <Box float='right'>
            <Button
              disabled={disableValidation}
              data-testid='challenge-cnftemplate-validate-cfn-template'
              iconName={!!templateScanResults.length ? "status-positive" : 'status-info'}
              loading={fetchingTemplateValidation}
              onClick={onValidateCFNTemplate}>
                {t(i18nKeys.challenges.subSections.cfnTemplate.validateTemplate)}
            </Button>
          </Box>
        </>
      }>
      <Box margin={{bottom:"m"}}>
        <TextContent>
          {t(i18nKeys.challenges.subSections.cfnTemplate.infoFlashbar)}
        </TextContent>
      </Box>
      <SpaceBetween size="s">
      {editMode && <div
          style={{
            backgroundColor: awsui.colorBackgroundCellShaded,
            padding: '10px',
            borderRadius: '8px'
          }}>
          <KeyValue
            className="primary-text"
            label={<TextContent><p><strong>{t(i18nKeys.challenges.subSections.cfnTemplate.toolsAndResources)}</strong></p></TextContent>}>
            <SpaceBetween size="xs" direction="vertical" >
              <Button variant="link"
                data-testid='challenge-cnftemplate-show-snippet'
                href={RoutePath.CHALLENGE_SNIPPETS}
                iconAlign='left'
                iconName='external'
                target='_blank'>
                  {t(i18nKeys.challenges.challengeDetails.buttons.showCfnSnippets)}
                </Button>
              <Button variant="link"
                data-testid='challenge-cnftemplate-provide-ssh-access'
                href={RoutePath.SSH_ACCESS}
                iconAlign='left'
                iconName='external'
                target='_blank'>
                  {t(i18nKeys.challenges.challengeDetails.buttons.provideSshAccess)}
                </Button>
              <Button variant="link"
                data-testid='challenge-cnftemplate-using-challenge-resource'
                href={`${RoutePath.CHALLENGE_RESOURCES}${challenge.id}`}
                iconAlign='left'
                iconName='external'
                target='_blank'>
                  {t(i18nKeys.challenges.challengeDetails.buttons.usingChallengeResources)}
              </Button>
            </SpaceBetween>
          </KeyValue>
        </div>}
        {editMode && (<TextContent>
                <p><strong>{t(i18nKeys.challenges.subSections.cfnTemplate.format.title)}{' '}</strong>{t(i18nKeys.challenges.subSections.cfnTemplate.format.part1)}{' '}<a href="https://github.com/awslabs/aws-cfn-template-flip">aws-cfn-template-flip</a>{t(i18nKeys.challenges.subSections.cfnTemplate.format.part2)}{' '}<a href="https://us-west-2.console.aws.amazon.com/cloudformation/designer/home?region=us-west-2">CloudFormation Template Designer</a>{t(i18nKeys.challenges.subSections.cfnTemplate.format.part3)}</p> 
              </TextContent>)}
        {editMode && (
          <Box padding={'l'} textAlign="center">
            <div
              style={{
                padding: '20px',
                border: '2px solid #AAB7B8',
                borderStyle: 'dashed',
              }}>
              <SpaceBetween size="s" direction="vertical" alignItems="center">
                <input
                  ref={hiddenFileInput}
                  id="chooseFileInput"
                  type="file"
                  hidden
                  multiple={false}
                  accept="text/yaml"
                  // @ts-ignore
                  onChange={(event) => handleFileUpload(event.target.files[0])}
                />
                <Button
                  id='challenge-cnftemplate-upload'
                  data-testid='challenge-cnftemplate-upload'
                  variant="link"
                  iconName="upload"
                  formAction="none"
                  onClick={() => {
                    // @ts-ignore
                    hiddenFileInput.current.value = null; // allow for re-choosing the same file name
                    // @ts-ignore
                    hiddenFileInput.current.click();
                  }} />
                  <SpaceBetween size={'xxs'} direction="horizontal" alignItems="center">
                  <p>{t(i18nKeys.challenges.subSections.cfnTemplate.dropYamlFile)}</p>
                  <Link onFollow={() =>  {
                     // @ts-ignore
                     hiddenFileInput.current.value = null; // allow for re-choosing the same file name
                     // @ts-ignore
                     hiddenFileInput.current.click();
                  }}>
                    {t(i18nKeys.challenges.subSections.cfnTemplate.browseFiles)}
                    </Link>
                  </SpaceBetween>
                {/* {editedChallenge?.hasCfnTemplate && (
                  <Button
                    iconName="status-negative"
                    formAction="none"
                    onClick={() => {
                      handleUpdateChallengeProp(ChallengePropAction.CFN_TEMPLATE, null);
                    }}>
                    {t(i18nKeys.general.remove)}
                  </Button>
                )} */}
              </SpaceBetween>
            </div>
          </Box>
        )}
        {challenge.hasCfnTemplate && !editMode && (
          <Button
            variant="link"
            data-testid="challenge-cfn-template-download"
            onClick={() => downloadAs(challenge.props.cfnTemplate || '', cfnTemplateFileName, 'text/yaml')}
            iconAlign='left'
            iconName='file'>
            {cfnTemplateFileName}
          </Button>
        )}
        {editMode && (
          <JamCodeEditor
            language="yaml"
            value={editedChallenge?.props.cfnTemplate || ''}
            setValue={(e) => {
              handleUpdateCfnTemplate(e);
            }}
          />
        )}
        {!editMode && (        
          <CodeView
            highlight={yamlHighlight}
            content={challenge.props.cfnTemplate || ''}
          />
        
        )}
        {templateScanResults.length > 0 && (
          <Container
            header={<Header>{t(i18nKeys.challenges.challengeDetails.headings.templateSecurityFindings)}</Header>}>
            <CodeView
              highlight={yamlHighlight}
              content={templateScanResults}
            />
          </Container>
        )}

        {!editMode && challenge.hasCfnTemplate && (
          <div>
            {(hasMissingResourceRegion() || hasInvalidCfnTemplateResource()) && (
              <div style={{ fontSize: '1rem' }}>
                <div className="aws-panel aws-panel-info mb-3">
                  <span className="fa fa-info-circle" />
                  {hasMissingResourceRegion() && (
                    <Box>
                      {t(i18nKeys.challenges.challengeDetails.text.missingResourcePartOne)}{' '}
                      <strong>{challengeResourceAccountName}</strong>{' '}
                      {t(i18nKeys.challenges.challengeDetails.text.missingResourcePartTwo)}
                    </Box>
                  )}
                  {hasInvalidCfnTemplateResource() && (
                    <span style={{ display: hasMissingResourceRegion() ? 'block' : 'inline' }}>
                      {hasMissingResourceRegion() && <br />}
                      {t(i18nKeys.challenges.challengeDetails.text.nestedCfnTemplate)}
                      <strong>{`${challengeResourceBucket} ${t(
                        i18nKeys.challenges.challengeDetails.text.bucket
                      )}`}</strong>
                      .
                    </span>
                  )}

                  <div>
                    <br />
                    {t(i18nKeys.challenges.challengeDetails.text.mayDelayApproval)}
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
      </SpaceBetween>
    </Container>
  );
};

export default ChallengeCFNTemplateDetail;
