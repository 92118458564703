import NiceModal from '@ebay/nice-modal-react';
import { useContext } from 'react';

export const useModalManager = () => {
  const niceModalContext = useContext(NiceModal.NiceModalContext);

  /** This only hides modals managed by NiceModal */
  const hideAllModals = () => {
    Object.keys(niceModalContext).forEach((key) => {
      NiceModal.hide(key);
    });
  };

  /** This only removes modals managed by NiceModal */
  const removeAllModals = () => {
    Object.keys(niceModalContext).forEach((key) => {
      NiceModal.remove(key);
    });
  };

  return { hideAllModals, removeAllModals };
};
