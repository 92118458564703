import { Box, Button, Icon, Link } from '@amzn/awsui-components-react';
import { useTranslation } from 'react-i18next';
import { useEvents } from '../../../store/events.context';
import { UserRow } from '../../../types/UserRow';
import { i18nKeys } from '../../../utils/i18n.utils';
import { getTableFilterMatcher, TableFilterMatcher } from '../../../utils/table.utils';
import { CopyToClipboard } from '../CopyToClipboard';

export const filteringFunction = (item: UserRow, filteringText: string): boolean => {
  const matcher: TableFilterMatcher = getTableFilterMatcher(filteringText);
  return (
    matcher.filterEmpty ||
    matcher.isMatch(item.email) ||
    matcher.isMatch(item.nickname) ||
    matcher.isMatch(item.teamName)
  );
};

export const COLUMN_DEFINITIONS = (toggleUserDetails: (user: UserRow) => void) => {
  const { emailCache, fetchUserEmail } = useEvents();
  const { t } = useTranslation();

  return [
    {
      id: 'nickname',
      sortingField: 'nickname',
      header: t(i18nKeys.participants.headers.displayName),
      cell: (item: UserRow) => (
        <Button variant="inline-link" onClick={() => toggleUserDetails(item)}>
          {item.disabled && <Icon name="lock-private" variant="warning" className="mr-5" />}
          {item?.nickname}
        </Button>
      ),
      minWidth: 260,
      allowLineWrap: true,
    },
    {
      id: 'facilitator',
      sortingField: 'facilitator',
      header: t(i18nKeys.participants.headers.role),
      cell: (item: UserRow) => <div>{t(item?.getRole)}</div>,
      minWidth: 180,
      allowLineWrap: false,
    },
    {
      id: 'team-name',
      sortingField: 'teamDisplayName',
      header: t(i18nKeys.participants.headers.team),
      cell: (item: UserRow) => (
        <div>{item.teamName ? <Box>{item?.teamDisplayName}</Box> : t(i18nKeys.general.unassigned)}</div>
      ),
      minWidth: 156,
      allowLineWrap: false,
    },
    {
      id: 'email',
      sortingField: 'email',
      header: t(i18nKeys.participants.headers.email),
      cell: (item: UserRow) => (
        <div>
          {item.userId && !emailCache[item.userId]?.email && (
            <Link onFollow={() => void fetchUserEmail(item.userId || '')}>
              {t(i18nKeys.participants.labels.showEmail)}
            </Link>
          )}
          {item.userId && emailCache[item.userId]?.email && (
            <Link>
              {emailCache[item?.userId].isVerified && (
                <Icon className="mr-5" variant="success" name="status-positive" />
              )}
              {emailCache[item?.userId].email}
              {emailCache[item?.userId].email && (
                <CopyToClipboard value={emailCache[item.userId || ''].email} variant="inline-icon" />
              )}
            </Link>
          )}
        </div>
      ),
      minWidth: 215,
      allowLineWrap: false,
    },
  ];
};
