import React, { useEffect, useRef, useState } from 'react';
import { Redirect, Route, Switch, useHistory, useLocation, useParams } from 'react-router-dom';
import { Box } from '@amzn/awsui-components-react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { useFlashbars } from '@/src/store/flashbar.context';
import { RoutePath } from '@/src/RoutePath';
import { getErrorMessage, isSBTSError } from '@/src/utils/errors.utils';
import {
  JAM_CHALLENGE_DETAILS_ROUTES,
  JAM_CHALLENGE_TASK_ROUTES,
  JAM_EVENT_DETAILS_ROUTES,
  JAM_FACILITATOR_DETAILS_ROUTES,
  jamEventRoute,
} from '@/src/routes';
import { FlashbarType, errorFlashbar } from '@/src/utils/notification.utils';
import { i18nKeys } from '@/src/utils/i18n.utils';
import JamEventOnboarding from '../JamEventOnboarding/JamEventOnboarding';
import JoinTeam from '../JoinTeam/JoinTeam';
import JamChallenges from '../JamChallenges/JamChallenges';
import JamChallengeDetails from '../JamChallengeDetails/JamChallengeDetails';
import JamTaskInfo from '../JamChallengeDetails/JamTask/JamTaskInfo';
import JamRecentFeedback from '../JamRecentFeedback/JamRecentFeedback';
import JamFeedback from '../JamFeedback/JamFeedback';
import JamReport from '../JamReport/JamReport';
import JamTeam from '../JamTeam/JamTeam';
import JamTeamChat from '../JamTeamChat/JamTeamChat';
import JamTeamInfo from '../JamTeamInfo/JamTeamInfo';
import JamIssue from '../JamIssue/JamIssue';
import JamFacilitatorNotes from '../JamFacilitatorNotes/JamFacilitatorNotes';
import FacilitatorMessaging from '../FacilitatorMessaging/FacilitatorMessaging';
import Leaderboard from '../Leaderboard/Leaderboard';
import JamMessages from '../JamMessages/JamMessages';
import FacilitatorSupportChats from '../FacilitatorSupportChats/FacilitatorSupportChats';
import FacilitatorJamSettings from '../FacilitatorJamSettings/FacilitatorJamSettings';
import JamSpinner from '@/src/components/common/JamSpinner';
import FacilitatorParticipants from '../FacilitatorParticipants/FacilitatorParticipants';
import FacilitatorNotifications from '../FacilitatorNotifications/FacilitatorNotifications';
import { useCampaigns } from '@/src/store/campaigns.context';
import { useJamChat } from '@/src/store/jam-chat.context';
import { useJamEventDetails } from '@/src/store/jam-event-details.context';
import { useUser } from '@/src/store/user.context';
import { useWebsocket } from '@/src/store/websocket.context';
import { useJamChallenge } from '@/src/store/jam-challenge.context';
import { useJamChallengeDetails } from '@/src/store/jam-challenge-details.context';
import JamChallengeOutputProperties from '../JamChallengeOutputProperties/JamChallengeOutputProperties';
import JamChallengeWiki from '../JamChallengeWiki/JamChallengeWiki';
import JamWelcomeModal from '../../ui/molecules/JamWelcomeModal/JamWelcomeModal';
import { didWelcomeModalShow } from '@/src/utils/jam-event.utils';
import JamEventFeedbackModal from '../../ui/molecules/JamEventFeedbackModal/JamEventFeedbackModal';
import { localLogger, preProdLogger } from '@/src/utils/log.utils';
import { isProfileEmpty } from '@/src/components/game/Account/JamProfileCheckGuard';
import localStorageTTL from '@/src/utils/localStorageTTL.utils';
import { JamConstants } from '@/src/constants/shared/jam-constants';
import { isValidRegistrationIdShape } from '@/src/utils/validation.utils';

const DONT_SHOW_EVENT_ONBOARDING =
  (localStorageTTL.getItem(JamConstants.DONT_SHOW_JAM_EVENT_ONBOARDING_POPUP_KEY) as string) === 'true';


const JamEvent = () => {
  const { t } = useTranslation();
  const { eventId }: { eventId: string } = useParams();
  const { loadEventDetails, event, isFetchingEvent, loadEventMessages, eventName, eventSlug } = useJamEventDetails();
  const { loadJamChallengeData, isFetchingJamChallenge, loadJamChallengeFeedbackData, isFetchingJamChallengeFeedback } =
    useJamChallenge();
  const { isFetchingTaskClue } = useJamChallengeDetails();
  const history = useHistory();
  const location = useLocation();
  const { addFlashbar, removeFlashbar, addErrorFlashbar } = useFlashbars();
  const { loadSupportChats, loadTeamChat } = useJamChat();
  const { destroyConnection } = useWebsocket();
  const onboardChecked = useRef(false);
  const [showFeedbackModal, setShowFeedbackModal] = useState(false);
  const onboardingCompleted = event ? event.isOnboardingComplete() : false;
  const endDate = event?.eventEndDate;
  const { pathname } = location;
  const { isFetchingCampaign, getCampaignEventDetails } = useCampaigns();
  const { profile, loading } = useUser();
  const isCampaign = event?.type === 'CAMPAIGN_GROUP';

  const registrationIdIsValid = (): boolean => {
    const params = new URLSearchParams(location.search);
    const registrationId = params.get("registration_id");
    preProdLogger(`request params have registration_id: ${registrationId ? "true" : "false"}`);
    preProdLogger(`Is valid registration id shape: ${isValidRegistrationIdShape(registrationId) ? "true" : "false"}`);
    
    // registrationId being null is valid for backward compatability.
    if (registrationId && !isValidRegistrationIdShape(registrationId)) {
      // If registrationId is present but malformed redirect to 403
      history.replace(`${RoutePath.JAM_403}?from=game`);
      return false;
    }
    return true;
  }

  const getEvent = async () => {
    try {
      if (!isCampaign || registrationIdIsValid()) {
        await loadEventDetails(eventName || eventId, true, true);
      } 
    } catch (e: any) {
      if (e.status === 403) {
        if (isSBTSError(e)) {
          history.replace(`${RoutePath.JAM_403}?from=sb_subscription`);
        } else {
          history.replace(`${RoutePath.JAM_403}?from=game`);
        }
      } else {
        const errMessage = <div dangerouslySetInnerHTML={{ __html: getErrorMessage(e) }} />;
        addFlashbar(errorFlashbar(t(i18nKeys.general.error), errMessage));
        gotoChallenge();
      }
    }
  };

  // Get a campaign event details
  const getCampaign = async () => {
    try {
      await getCampaignEventDetails(eventName || eventId);
    } catch (e: any) {
      addErrorFlashbar(getErrorMessage(e));
    }
  };

  const gotoChallenge = () => {
    history.replace({
      pathname: JAM_EVENT_DETAILS_ROUTES.Challenges.resolve(eventSlug),
      search: location.search
    });
  };

  useEffect(() => {
    // Don't bother trying to call the event API if the profile is empty, since the JamProfileCheckGuard component
    // will redirect the user to the profile create page. Calling the event API with an empty profile creates
    // weird behavior if the `getEvent` call returns 403 but the user has already been redirected to the
    // profile create page, as it then takes them to the 403 page from the profile create page.
    if (!loading && !isProfileEmpty(profile)) {
      void getEvent();
    }
  }, [profile, loading, eventName, eventId]);

  // Fetch the campaign event details if the
  // event is apart of a campaign
  useEffect(() => {
    if (isCampaign) {
      void getCampaign();
    }
  }, [isCampaign, eventName]);

  // if onboarding is not complete navigate user to onboarding screen
  useEffect(() => {
    if (onboardChecked.current || pathname.includes('onboarding') || !event) {
      return;
    }
    onboardChecked.current = true;
    if (!onboardingCompleted) {
      history.replace({
        pathname: JAM_EVENT_DETAILS_ROUTES.Onboarding.resolve(eventSlug || eventId),
        search: location.search
      });
    }
  }, [onboardingCompleted, eventName]);

  useEffect(() => {
    // after mounting if onboarding gets finished.. move user to challenges screen asap.
    if (onboardingCompleted && (pathname.includes('onboarding') || pathname === jamEventRoute.resolvePath(eventSlug))) {
      gotoChallenge();
    }
  }, [onboardingCompleted, eventSlug]);

  useEffect(() => {
    if (pathname === jamEventRoute.resolvePath(eventSlug) && onboardingCompleted) {
      gotoChallenge();
    }
  }, [pathname, onboardingCompleted, eventSlug]);

  useEffect(() => {
    let timeout: number;
    if (pathname.includes('onboarding') && !onboardingCompleted && !event?.ended) {
      addFlashbar({
        id: 'onboarding-info',
        i18nKeys: [''],
        header: '',
        content: t(i18nKeys.myJams.joinAnEvent.registeredToEvent),
        dismissible: false,
        type: FlashbarType.SUCCESS,
      });
    } else {
      removeFlashbar('onboarding-info');
    }

    const endDateMoment = moment(endDate);

    // check for event end and trigger event feedback modal IF event ends within 24 hours
    if (onboardingCompleted && !event?.ended && endDateMoment.isBefore(moment().add(24, 'hours'))) {
      const waitTimeMS = endDateMoment.diff(moment(), 'milliseconds');
      localLogger('feeback modal timeout started', waitTimeMS / 1000);
      timeout = setTimeout(() => {
        localLogger('logger came here to show feedback modal');
        setShowFeedbackModal(true);
      }, waitTimeMS);
    }

    return () => {
      removeFlashbar('onboarding-info');
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [onboardingCompleted, endDate]);

  // accomodate new API
  useEffect(() => {
    if (onboardingCompleted) {
      void loadSupportChats(eventName);
      void loadTeamChat(eventName);
      void loadEventMessages();
      void loadJamChallengeData({ id: eventName });
      void loadJamChallengeFeedbackData({ id: eventName });
    }
  }, [onboardingCompleted, eventName]);

  useEffect(() => {
    return () => destroyConnection();
  }, [eventName]);

  if (
    isFetchingEvent ||
    isFetchingJamChallenge ||
    isFetchingJamChallengeFeedback ||
    isFetchingTaskClue ||
    (isCampaign && isFetchingCampaign)
  ) {
    return (
      <Box>
        <JamSpinner />
      </Box>
    );
  }
  return (
    <>
      <Switch>
        <Route exact path={JAM_EVENT_DETAILS_ROUTES.JoinTeam.wildcard()} component={JoinTeam} />
        <Route exact path={JAM_EVENT_DETAILS_ROUTES.Onboarding.wildcard()} component={JamEventOnboarding} />
        <Route path={JAM_EVENT_DETAILS_ROUTES.LeaderBoard.wildcard()} component={Leaderboard} />
        <Route exact path={JAM_CHALLENGE_TASK_ROUTES.wildcard()} component={JamTaskInfo} />
        <Route path={JAM_CHALLENGE_DETAILS_ROUTES.Overview.wildcard()} component={JamChallengeDetails} />
        <Route path={JAM_CHALLENGE_DETAILS_ROUTES.RecentFeedback.wildcard()} component={JamRecentFeedback} />
        <Route path={JAM_CHALLENGE_DETAILS_ROUTES.Issue.wildcard()} component={JamIssue} />
        <Route path={JAM_CHALLENGE_DETAILS_ROUTES.FacilitatorNotes.wildcard()} component={JamFacilitatorNotes} />
        <Route path={JAM_CHALLENGE_DETAILS_ROUTES.Wiki.wildcard()} component={JamChallengeWiki} />
        <Route
          path={JAM_CHALLENGE_DETAILS_ROUTES.OutputProperties.wildcard()}
          component={JamChallengeOutputProperties}
        />

        <Route exact path={JAM_EVENT_DETAILS_ROUTES.Challenges.wildcard()} component={JamChallenges} />
        <Route
          exact
          path={JAM_EVENT_DETAILS_ROUTES.Facilitator.wildcard()}
          component={() => <Redirect to={JAM_FACILITATOR_DETAILS_ROUTES.JamSettings.resolve(eventSlug)} />}
        />
        <Route exact path={JAM_FACILITATOR_DETAILS_ROUTES.JamSettings.wildcard()} component={FacilitatorJamSettings} />
        <Route exact path={JAM_FACILITATOR_DETAILS_ROUTES.Messaging.wildcard()} component={FacilitatorMessaging} />
        <Route
          exact
          path={JAM_FACILITATOR_DETAILS_ROUTES.Participants.wildcard()}
          component={FacilitatorParticipants}
        />
        <Route
          exact
          path={JAM_FACILITATOR_DETAILS_ROUTES.SupportChats.wildcard()}
          component={FacilitatorSupportChats}
        />
        <Route
          exact
          path={JAM_FACILITATOR_DETAILS_ROUTES.Notifications.wildcard()}
          component={FacilitatorNotifications}
        />
        <Route exact path={JAM_EVENT_DETAILS_ROUTES.Messages.wildcard()} component={JamMessages} />
        <Route exact path={JAM_EVENT_DETAILS_ROUTES.TeamChat.wildcard()} component={JamTeamChat} />
        <Route exact path={JAM_EVENT_DETAILS_ROUTES.TeamInfo.wildcard()} component={JamTeamInfo} />
        <Route exact path={JAM_EVENT_DETAILS_ROUTES.Team.wildcard()} component={JamTeam} />
        <Route path={JAM_EVENT_DETAILS_ROUTES.Feedback.wildcard()} component={JamFeedback} />
        <Route exact path={JAM_EVENT_DETAILS_ROUTES.Report.wildcard()} component={JamReport} />
        <Route exact path={RoutePath.JAM_EVENT}>
          {event ? (
            <Redirect to={JAM_EVENT_DETAILS_ROUTES.Challenges.resolve(event?.eventName || '')} />
          ) : (
            <Redirect to={JAM_EVENT_DETAILS_ROUTES.Challenges.resolve(eventId)} />
          )}
        </Route>
      </Switch>
      {!DONT_SHOW_EVENT_ONBOARDING &&
        onboardingCompleted &&
        !event?.ended &&
        !isCampaign &&
        !didWelcomeModalShow(eventName) && <JamWelcomeModal eventId={eventName} />}
      {showFeedbackModal && <JamEventFeedbackModal onDismiss={() => setShowFeedbackModal(false)} />}
    </>
  );
};

export default JamEvent;
