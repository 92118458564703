/* eslint-disable @typescript-eslint/ban-ts-comment */
import {
  Box,
  Button,
  ExpandableSection,
  FormField,
  Grid,
  Icon,
  Input,
  Link,
  RadioGroup,
  Select,
  SelectProps,
  SpaceBetween,
  Spinner,
  TextContent,
} from '@amzn/awsui-components-react';
import CodeView from "@amzn/awsui-code-view/code-view";
import javascriptHighlight from "@amzn/awsui-code-view/highlight/javascript";
import pythonHighlight from "@amzn/awsui-code-view/highlight/python";
import { Textarea } from '@amzn/awsui-components-react/uxdg';
import React, { createRef, useEffect, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useChallenges } from '../../../../store/challenge.context';
import {
  ChallengePropAction,
  ChallengeTaskFields,
  useCreateChallenge,
} from '../../../../store/create-challenge.context';
import { ChallengeTask, ChallengeTaskValidationType } from '../../../../types/Challenge';
import { i18nKeys } from '../../../../utils/i18n.utils';
import { preProdLogger } from '../../../../utils/log.utils';
import { safeString } from '../../../../utils/string.utils';
import { Column } from '../../../common/Column';
import { Columns } from '../../../common/Columns';
import { ConfirmModal } from '../../../common/ConfirmModal';
import { KeyValue } from '../../../common/KeyValue';
import { Markdown } from '../../../common/Markdown';
import JamCodeEditor from '../../challengesCommon/JamCodeEditor';
import ChallengeCluesDetail from './Clues';
import MarkDownPreviewEditor from '@/src/components/common/MarkdownPreviewEditor/MarkDownPreviewEditor';

interface ChallengeTaskDetailProps {
  task: ChallengeTask;
  getTaskLabel?: (taskId: string) => string;
  removeTask: (challengeId: string, challengeVersion: number, taskId: string) => Promise<void>;
  updateDebounceDelay?: number;
}

const ChallengeTaskDetail: React.FC<ChallengeTaskDetailProps> = ({ task, removeTask, updateDebounceDelay = 500 }) => {
  const { t } = useTranslation();
  const { editMode, editedChallenge, handleUpdateChallengeTask, challengeTaskValidator } = useCreateChallenge();
  const { challengeConfig, getChallengeConfiguration } = useChallenges();
  const hiddenFileInput = createRef<HTMLInputElement>();

  const [challengeTask, setChallengeTask] = useState<ChallengeTask | undefined>(task);
  const [supportedRuntimeOptions, setSupportedRuntimeOptions] = useState<SelectProps.Option[]>([]);

  const [showSampleFunction, setShowSampleFunction] = useState(false);
  const [visibleConfirmSample, setVisibleConfirmSample] = useState(false);
  const [visibleRemoveTaskConfirm, visibleRemoveTaskConfirmSet] = useState(false);

  const field = i18nKeys.challenges.subSections.tasks;
  const [weightedScoreErrorText, setWeightedScoreErrorText] = useState<string>('');
  const [titleErrorText, setTitleErrorText] = useState<string>('');
  const [globalStaticAnswerErrorText, setGlobalStaticAnswerErrorText] = useState<string>('');
  const [taskValidationFunctionErrorText, setTaskValidationFunctionErrorText] = useState<string>('');
  const [isLoading, isLoadingSet] = useState(false);

  useEffect(() => {
    // reset error fields when editMode is changed
    if (!editMode) {
      setWeightedScoreErrorText('');
      setTitleErrorText('');
      setGlobalStaticAnswerErrorText('');
      setTaskValidationFunctionErrorText('');
    }
  }, [editMode]);


  useEffect(() => {
    if (!editMode && challengeTask) {
      challengeTask.decodeForMarkdown();
    }
  }, [editMode]);

  useEffect(() => {
    getChallengeConfiguration();
  }, []);

  useEffect(() => {
    if (editedChallenge) {
      setChallengeTask(editedChallenge.props.tasks.find((ct) => ct.id === task.id));
    }
  }, [editedChallenge]);

  useEffect(() => {
    const runtimes: SelectProps.Option[] = [];
    if (challengeConfig) {
      challengeConfig.supportedLambdaRuntimes.forEach((runtime) => runtimes.push({ label: runtime, value: runtime }));
    }
    setSupportedRuntimeOptions(runtimes);
  }, [challengeConfig]);


  if (!task) {
    return null;
  }

  const taskValidator = useMemo(() => challengeTaskValidator(
    task,
    new Map<ChallengeTaskFields, (error: string) => void>([
      [ChallengeTaskFields.WEIGHTED_SCORE, (error: string) => setWeightedScoreErrorText(error)],
      [ChallengeTaskFields.TASK_TITLE, (error: string) => setTitleErrorText(error)],
      [ChallengeTaskFields.GLOBAL_STATIC_ANSWER, (error: string) => setGlobalStaticAnswerErrorText(error)],
      [ChallengeTaskFields.VALIDATION_FUNCTION, (error: string) => setTaskValidationFunctionErrorText(error)],
    ])
  ), [task]);

  const handleFileUpload = (uploadedFile: File) => {
    // Handle file upload
    uploadedFile
      .text()
      .then((file) => {
        handleUpdateChallengeTask(ChallengePropAction.TASK_VALIDATION_LAMDA, task.taskNumber, file);
      })
      .catch((err) => preProdLogger(err));
  };

  const getCodeLanguage = () => {
    const currentRunTime = challengeTask?.validationFunctionRuntime;
    if (currentRunTime) {
      if (currentRunTime.includes('nodejs')) {
        return 'javascript';
      }
      if (currentRunTime.includes('python')) {
        return 'python';
      }
    }
    // default to javascript
    return 'javascript';
  };

  const getRuntimeFileType = () => {
    const currentRunTime = challengeTask?.validationFunctionRuntime;
    if (currentRunTime) {
      if (currentRunTime.includes('nodejs')) {
        return 'js';
      }
      if (currentRunTime.includes('python')) {
        return 'py';
      }
    }
    // default to javascript
    return 'js';
  };

  const getCodeHighlights = () => {
    const currentRunTime = challengeTask?.validationFunctionRuntime;
    if (currentRunTime) {
      if (currentRunTime.includes('nodejs')) {
        return javascriptHighlight;
      }
      if (currentRunTime.includes('python')) {
        return pythonHighlight;
      }
    }
    // default to javascript
    return javascriptHighlight;
  };

  const title = task.title ? task.title : t(i18nKeys.challenges.challengeDetails.text.challengeTaskEmptyTitle);

  const taskValidationFunctionFileName = challengeTask?.validationFunction
    ? `${editedChallenge?.challengeId}-task-${challengeTask.taskNumber}-validation-function.${getRuntimeFileType()}`
    : '';

  const getValidationType = () => {
    switch (task.validationType) {
      case ChallengeTaskValidationType.GLOBAL_STATIC_ANSWER:
        return t(
          i18nKeys.challenges.subSections.tasks.subSections.fields.validationType.types.globalStaticAnswer.label
        );
      case ChallengeTaskValidationType.CFN_OUTPUT_PARAMETER:
        return t(i18nKeys.challenges.subSections.tasks.subSections.fields.validationType.types.dynamicAnswer.label);
      case ChallengeTaskValidationType.LAMBDA_FUNCTION:
        return t(i18nKeys.challenges.subSections.tasks.subSections.fields.validationType.types.lambdaFunction.label);
      case ChallengeTaskValidationType.LAMBDA_FUNCTION_WITH_INPUT:
        return t(
          i18nKeys.challenges.subSections.tasks.subSections.fields.validationType.types.lambdaFunctionInput.label
        );
    }
  };

  return (
    <div style={{ marginBottom: '1.5rem' }}>
      <ExpandableSection
        variant="container"
        headerActions={
          editMode && task.taskNumber > 1 ? (
            <Button variant='icon' iconName='close'
              ariaLabel='Remove Task'
              onClick={() => visibleRemoveTaskConfirmSet(true)} />
          ) : null
        }
        headerText={`${t(i18nKeys.challenges.challengeDetails.headings.task, { count: task.taskNumber })}: ${title}`}>
        <SpaceBetween size={'s'} direction="vertical">
          <div className="task-section">
            <Columns columns={2} variant="default">
              <Column size={'s'}>
                <KeyValue
                  className="primary-text"
                  label={
                    <TextContent>
                      <p>
                        <strong>{t(i18nKeys.challenges.challengeDetails.titles.weightedScore)}</strong>
                      </p>
                    </TextContent>
                  }>
                  {!editMode ? (
                    `${task.scorePercent}%`
                  ) : (
                    <FormField
                      constraintText={t(field.subSections.fields.weightedScore.constraint)}
                      errorText={weightedScoreErrorText}>
                      <Input
                        value={challengeTask?.scorePercent.toString() || ''}
                        data-testid="challenge-task__weighted-score-input"
                        onBlur={() => {
                          taskValidator.isValidField(ChallengeTaskFields.WEIGHTED_SCORE);
                        }}
                        onChange={({ detail }) =>
                          handleUpdateChallengeTask(
                            ChallengePropAction.TASK_WEIGHTED_SCORE,
                            challengeTask?.taskNumber || 0,
                            detail.value
                          )
                        }
                        placeholder={t(
                          i18nKeys.challenges.subSections.tasks.subSections.fields.weightedScore.placeholder
                        )}
                      />
                    </FormField>
                  )}
                </KeyValue>
              </Column>
              <Column size={'s'}>
                <KeyValue
                  className="primary-text"
                  label={
                    <TextContent>
                      <p>
                        <strong>{t(i18nKeys.challenges.challengeDetails.titles.validationType)}</strong>
                      </p>
                    </TextContent>
                  }>
                  {!editMode ? (
                    getValidationType()
                  ) : (
                    <RadioGroup
                      items={[
                        {
                          value: ChallengeTaskValidationType.LAMBDA_FUNCTION,
                          label: t(
                            i18nKeys.challenges.subSections.tasks.subSections.fields.validationType.types.lambdaFunction
                              .label
                          ),
                          description: t(
                            i18nKeys.challenges.subSections.tasks.subSections.fields.validationType.types.lambdaFunction
                              .description
                          ),
                        },
                        {
                          value: ChallengeTaskValidationType.LAMBDA_FUNCTION_WITH_INPUT,
                          label: t(
                            i18nKeys.challenges.subSections.tasks.subSections.fields.validationType.types
                              .lambdaFunctionInput.label
                          ),
                          description: t(
                            i18nKeys.challenges.subSections.tasks.subSections.fields.validationType.types
                              .lambdaFunctionInput.description
                          ),
                        },
                        {
                          value: ChallengeTaskValidationType.CFN_OUTPUT_PARAMETER,
                          label: t(
                            i18nKeys.challenges.subSections.tasks.subSections.fields.validationType.types.dynamicAnswer
                              .label
                          ),
                          description: t(
                            i18nKeys.challenges.subSections.tasks.subSections.fields.validationType.types.dynamicAnswer
                              .description
                          ),
                        },
                        {
                          value: ChallengeTaskValidationType.GLOBAL_STATIC_ANSWER,
                          label: t(
                            i18nKeys.challenges.subSections.tasks.subSections.fields.validationType.types
                              .globalStaticAnswer.label
                          ),
                          description: t(
                            i18nKeys.challenges.subSections.tasks.subSections.fields.validationType.types
                              .globalStaticAnswer.description
                          ),
                        },
                      ]}
                      value={challengeTask?.validationType || ''}
                      onChange={({ detail }) =>
                        handleUpdateChallengeTask(
                          ChallengePropAction.TASK_VALIDATION_TYPE,
                          challengeTask?.taskNumber || 0,
                          detail.value
                        )
                      }
                    />
                  )}
                </KeyValue>
              </Column>
            </Columns>
            <div>
              <ExpandableSection
                variant="container"
                className="tasks-container"
                headerText={t(i18nKeys.challenges.challengeDetails.headings.content)}>
                <div>
                  <KeyValue
                    className="primary-text"
                    label={
                      <TextContent>
                        <p>
                          <strong>{t(i18nKeys.challenges.challengeDetails.titles.title)}</strong>
                        </p>
                      </TextContent>
                    }>
                    {!editMode ? (
                      title
                    ) : (
                      <FormField
                        constraintText={t(field.subSections.fields.title.constraint)}
                        errorText={titleErrorText}>
                        <Input
                          onBlur={() => {
                            taskValidator.isValidField(ChallengeTaskFields.TASK_TITLE);
                          }}
                          data-testid="challenge-task__title-input"
                          onChange={({ detail }) =>
                            handleUpdateChallengeTask(
                              ChallengePropAction.TASK_TITLE,
                              challengeTask?.taskNumber || 0,
                              detail.value
                            )
                          }
                          value={challengeTask?.title || ''}
                        />
                      </FormField>
                    )}
                  </KeyValue>
                  <Box>
                    {!editMode ? (
                      <Markdown content={task.content || ''} />
                    ) : (
                      <KeyValue
                        className="primary-text"
                        label={
                          <TextContent>
                            <p>
                              <strong>{t(i18nKeys.challenges.challengeDetails.headings.content)}</strong>
                            </p>
                          </TextContent>
                        }>
                        <Box margin={{ top: 's' }}>
                            <MarkDownPreviewEditor
                              data-testid="challenge-task__content-editor"
                              updateDebounceDelay={updateDebounceDelay}
                              text={task.content}
                              onTextChanged={(text) => handleUpdateChallengeTask(
                                ChallengePropAction.TASK_CONTENT as string,
                                challengeTask?.taskNumber || 0,
                                text
                              )}
                            />
                        </Box>
                      </KeyValue>
                    )}
                  </Box>
                </div>
              </ExpandableSection>
            </div>
            <ChallengeCluesDetail taskId={task.id || ''} taskNumber={task.taskNumber} />
            {challengeTask && (
              <div className="dynamicValidationArea">
                {challengeTask.validationType !== ChallengeTaskValidationType.GLOBAL_STATIC_ANSWER && (
                  <Box margin={{ top: 'l' }}>
                    <ExpandableSection
                      variant="container"
                      headerText={t(i18nKeys.challenges.challengeDetails.headings.validationFunction)}>
                      {editMode && (
                        <SpaceBetween size={'s'}>
                          <Grid gridDefinition={[{ colspan: 4 }]}>
                            {challengeConfig && (
                              <Select
                                selectedOption={
                                  supportedRuntimeOptions.find(
                                    (runtime) => runtime.value === challengeTask.validationFunctionRuntime
                                  ) || null
                                }
                                options={supportedRuntimeOptions}
                                data-testid="challenge-task__runtime"
                                onChange={({ detail }) =>
                                  handleUpdateChallengeTask(
                                    ChallengePropAction.TASK_VALIDATION_RUNTIME,
                                    task.taskNumber,
                                    detail.selectedOption.value
                                  )
                                }
                              />
                            )}
                          </Grid>
                          <SpaceBetween size="s">
                            {challengeTask.validationFunction && (
                              <Box textAlign="center">
                                <Icon name="file" />
                                <Link href="#" variant="primary">
                                  {taskValidationFunctionFileName}
                                </Link>
                              </Box>
                            )}
                            <Box className="gray-box" padding={'l'} textAlign="center">
                              <Button
                                id="challenge-clues-upload"
                                data-testid="challenge-clues-upload"
                                variant="link"
                                iconName="upload"
                                formAction="none"
                                onClick={() => {
                                  // @ts-ignore
                                  hiddenFileInput.current.value = null; // allow for re-choosing the same file name
                                  // @ts-ignore
                                  hiddenFileInput.current.click();
                                }}>
                                {t(i18nKeys.challenges.challengeDetails.text.uploadFileOrDrop)}
                              </Button>
                              <input
                                ref={hiddenFileInput}
                                id="chooseFileInput"
                                type="file"
                                hidden
                                multiple={false}
                                accept="application/js"
                                // @ts-ignore
                                onChange={(event) => handleFileUpload(event.target.files[0])}
                              />
                            </Box>
                          </SpaceBetween>
                          <SpaceBetween size="m" direction="horizontal">
                            {showSampleFunction ? (
                              <Button
                                id="challenge-clues-hide-sample"
                                data-testid="challenge-clues-hide-sample"
                                onClick={() => setShowSampleFunction(false)}>
                                {t(i18nKeys.challenges.challengeDetails.buttons.hideSampleFunction)}
                              </Button>
                            ) : (
                              <Button
                                id="challenge-clues-show-sample"
                                data-testid="challenge-clues-show-sample"
                                onClick={() => setShowSampleFunction(true)}>
                                {t(i18nKeys.challenges.challengeDetails.buttons.showSampleFunction)}
                              </Button>
                            )}
                            <Button
                              id="challenge-clues-use-sample"
                              data-testid="challenge-clues-use-sample"
                              onClick={() => setVisibleConfirmSample(true)}>
                              {t(i18nKeys.challenges.challengeDetails.buttons.useSampleFunction)}
                            </Button>
                          </SpaceBetween>
                          {showSampleFunction && challengeConfig && (
                            <JamCodeEditor
                              language={getCodeLanguage()}
                              value={
                                getCodeLanguage() === 'python'
                                  ? safeString(challengeConfig.pythonSampleLambdaCode)
                                  : safeString(challengeConfig.nodeSampleLambdaCode)
                              }
                              setValue={() => preProdLogger('none')}
                            />
                          )}
                          <FormField errorText={taskValidationFunctionErrorText}>
                            <JamCodeEditor
                              language={getCodeLanguage()}
                              value={challengeTask.validationFunction || ''}
                              data-testid='challenge-task__lambda-validation-editor'
                              setValue={(e) => {
                                handleUpdateChallengeTask(
                                  ChallengePropAction.TASK_VALIDATION_LAMDA,
                                  task.taskNumber,
                                  e
                                );
                              }}
                            />
                          </FormField>
                        </SpaceBetween>
                      )}
                      {!editMode && !!challengeTask.validationFunction && (
                        <CodeView
                          content={challengeTask.validationFunction}
                          highlight={getCodeHighlights()} />
                      )}
                    </ExpandableSection>
                  </Box>
                )}
                {challengeTask.validationType === ChallengeTaskValidationType.GLOBAL_STATIC_ANSWER && (
                  <Box margin={{ top: 'l' }}>
                    <ExpandableSection
                      variant="container"
                      headerText={t(i18nKeys.challenges.challengeDetails.headings.globalStaticAnswer)}>
                      {!editMode ? (
                        challengeTask.globalStaticAnswer
                      ) : (
                        <FormField errorText={globalStaticAnswerErrorText}>
                          <Textarea
                            onBlur={() => {
                              taskValidator.isValidField(ChallengeTaskFields.GLOBAL_STATIC_ANSWER);
                            }}
                            value={challengeTask.globalStaticAnswer || ''}
                            onChange={({ detail }) => {
                              handleUpdateChallengeTask(
                                ChallengePropAction.TASK_GLOBAL_STATIC_ANSWER,
                                task.taskNumber,
                                detail.value
                              );
                            }}
                          />
                        </FormField>
                      )}
                    </ExpandableSection>
                  </Box>
                )}
              </div>
            )}
          </div>
        </SpaceBetween>
      </ExpandableSection>
      {challengeTask && challengeConfig && (
        <ConfirmModal
          visible={visibleConfirmSample}
          message={t(i18nKeys.challenges.challengeDetails.messages.useSampleFunction)}
          confirmBtnLabel={t(i18nKeys.challenges.challengeDetails.yesLabels.continue)}
          onConfirm={() => {
            if (getCodeLanguage() === 'javascript') {
              challengeTask.validationFunction = challengeConfig.nodeSampleLambdaCode;
            } else {
              challengeTask.validationFunction = challengeConfig.pythonSampleLambdaCode;
            }
          }}
          onCancel={() => setVisibleConfirmSample(false)}
        />
      )}
      {challengeTask && challengeConfig && (
        <ConfirmModal
          visible={visibleRemoveTaskConfirm}
          title={t(i18nKeys.challenges.challengeDetails.messages.removeTaskTitle)}
          message={<>{t(i18nKeys.challenges.challengeDetails.messages.removeTaskContent)} <Spinner variant='disabled' /></>}
          data-testid="challenge-task__remove-confirm-modal"
          confirmBtnLabel={t(i18nKeys.challenges.challengeDetails.yesLabels.continue)}
          disabled={isLoading}
          onConfirm={() => {
            isLoadingSet(true);
            removeTask(editedChallenge?.challengeId || '', editedChallenge?.version || 0, task.id || '')
              .catch(err => preProdLogger(err))
              .finally(() => {
                isLoadingSet(false);
                visibleRemoveTaskConfirmSet(false);
              });
          }}
          onCancel={() => visibleRemoveTaskConfirmSet(false)}
        />
      )}
    </div>
  );
};

export default ChallengeTaskDetail;
