import { EditEventActions, HandleUpdateEditEvent } from '@/src/store/edit-event.context';
import { i18nKeys } from '@/src/utils/i18n.utils';
import { ExpandableSection, FormField, Textarea } from '@amzn/awsui-components-react';
import { useTranslation } from 'react-i18next';
import { Event } from '../../../types/Event';

interface EventNotesProps {
  target: Event | undefined;
  editMode: boolean;
  handleUpdateEditEvent: HandleUpdateEditEvent;
}

export const EventNotes: React.FC<EventNotesProps> = (props) => {
  const { t } = useTranslation();
  const isEvent = props.target instanceof Event;

  if (!isEvent || !props.target?.notes) {
    return null;
  }

  const getContent = () => {
    if (props.editMode) {
      return (
        <FormField i18nStrings={{ errorIconAriaLabel: t(i18nKeys.general.error) }}>
          <Textarea
            value={props.target?.notes || ''}
            ariaLabel={t(i18nKeys.events.eventDetails.labels.eventNote)}
            onChange={({ detail }) => props.handleUpdateEditEvent(EditEventActions.NOTES, detail.value)}
            data-testid="event-notes-input"
          />
        </FormField>
      );
    }

    return <>{(props.target as Event).notes}</>;
  };

  return (
    <ExpandableSection
      defaultExpanded
      variant="container"
      headerText={t(i18nKeys.events.eventDetails.headers.notes.title)}
    >
      {getContent()}
    </ExpandableSection>
  );
};
