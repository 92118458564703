import React, {useState} from 'react';
import {Box, ColumnLayout, Container} from '@amzn/awsui-components-react';
import {SpaceBetween} from '@amzn/awsui-components-react/uxdg';
import {useTranslation} from 'react-i18next';
import {i18nKeys} from '@/src/utils/i18n.utils';
import {useHistory} from 'react-router';
import {useJamChallengeDetails} from '@/src/store/jam-challenge-details.context';
import {JamTask, JamTaskStatus} from '@/src/types/JamChallengeDetails';
import {JAM_CHALLENGE_TASK_ROUTES} from '@/src/routes';
import TaskStatus from '../JamTask/TaskStatus';
import {useFlashbars} from '@/src/store/flashbar.context';
import {useJamEventDetails} from '@/src/store/jam-event-details.context';

const TaskDetails: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { eventName, event } = useJamEventDetails();
  const { selectedJamChallenge, challengeProgress, jamChallengeId } = useJamChallengeDetails();
  const { addErrorFlashbar } = useFlashbars();
  const [selectedTask, setSelectedTask] = useState<JamTask>();
  const TotalEarnedPoints: number = selectedJamChallenge?.score || 0;
  const TotalPoints: number = selectedJamChallenge?.possiblePoints || 0;

  const handleNavigation = (task: JamTask) => {
    setSelectedTask(task);
    // if challenge is not started and user is not facilitator throw error
    if (!challengeProgress?.started && !event?.facilitator) {
      addErrorFlashbar(t(i18nKeys.myJams.challenges.errors.startJamChallenge));
      return;
    }
    if (!task.locked) {
      history.push(JAM_CHALLENGE_TASK_ROUTES.resolve([eventName, jamChallengeId, task.id]));
    }
  };

  return (
    <SpaceBetween direction="vertical" size="l">
      <ColumnLayout columns={2}>
        {Array.isArray(selectedJamChallenge?.tasks) &&
          selectedJamChallenge?.tasks
            ?.sort((a, b) => (a.taskNumber > b.taskNumber ? 1 : a.taskNumber < b.taskNumber ? -1 : 0))
            .map((task, index) => (
              <div
                key={task.id}
                id={`task${index + 1}`}
                data-testid={`task${index + 1}`}
                onClick={() => handleNavigation(task)}>
                <Container className={`task-card-container dark-bg ${selectedTask?.id === task.id ? 'solved' : ''}`}>
                  <Box padding={'xs'}>
                    <SpaceBetween direction="vertical" size="m">
                      <Box variant="div">
                        {t(i18nKeys.myJams.challenges.details.task.title)} {task?.taskNumber}
                        <Box variant="span" margin={{ left: 's' }}>
                          <TaskStatus
                            taskCompleted={task.status === JamTaskStatus.COMPLETE}
                            taskLocked={task.locked}
                            status={event?.eventStatus}
                            challengeStatus={challengeProgress?.started}
                          />
                        </Box>
                      </Box>
                      <Box variant="h2" color="text-status-info">
                        {task.title ?? t(i18nKeys.myJams.challenges.details.task.noTitle)}
                      </Box>
                      <ColumnLayout borders="horizontal" columns={2} className="task-card-container">
                        <Box>{t(i18nKeys.myJams.challenges.details.task.status)}</Box>
                        <Box className="global-value" float="right">
                          {task.status}
                        </Box>
                        <Box>{t(i18nKeys.myJams.challenges.details.task.possiblePoints)}</Box>
                        <Box className="global-value" float="right">
                          {task.pointsPossible}
                        </Box>
                        <Box>{t(i18nKeys.myJams.challenges.details.task.clueDeductionPoints)}</Box>
                        <Box className="global-value" float="right">
                          {task.cluesPenalty}
                        </Box>
                        <Box>{t(i18nKeys.myJams.challenges.details.task.totalEarned)}</Box>
                        <Box className="global-value" float="right">
                          {task.cluesPenalty < 0 ? '--' : task.score}
                        </Box>
                      </ColumnLayout>
                    </SpaceBetween>
                  </Box>
                </Container>
              </div>
            ))}
      </ColumnLayout>
      <Box margin={{ bottom: 'xl' }}>
        {selectedJamChallenge?.tasks && selectedJamChallenge?.tasks.length > 0 && (
          <Container className="dark-bg">
            <Box variant="h2" textAlign="center">
              {t(i18nKeys.myJams.challenges.details.task.totalPointsEarned)}:
              <Box
                variant="span"
                margin={{ left: 'xs' }}
                fontSize="heading-l"
                fontWeight="bold"
                className="global-value">
                {TotalEarnedPoints}/{TotalPoints}
              </Box>
            </Box>
          </Container>
        )}
      </Box>
    </SpaceBetween>
  );
};

export default TaskDetails;
