import { EventReportContainer } from '@/src/components/reports/EventReportContainer';
import { useUser } from '@/src/store/user.context';
import { Report } from '@/src/types/Report';
import { i18nKeys } from '@/src/utils/i18n.utils';
import { Box, Button, ColumnLayout, Container, ContentLayout, Header, Select } from '@amzn/awsui-components-react';
import { OptionDefinition } from '@amzn/awsui-components-react/polaris/internal/components/option/interfaces';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

const ReportContainer = () => {
  const { t } = useTranslation();
  const { user } = useUser();
  const [selectedOption, setSelectedOption] = useState<OptionDefinition | null>(null);

  const dropdownOptions = useMemo(() => {
    // Default reports accessible to any event owners
    const options = [
      { label: t(i18nKeys.reports.allEvents), value: Report.ALL_EVENTS },
      { label: t(i18nKeys.reports.eventReport), value: Report.EVENT_REPORT },
    ];

    // Only accessible to Jam Admins
    if (user?.isSuperAdmin) {
      options.push(
        { label: t(i18nKeys.reports.costExplorer), value: Report.COST_EXPLORER },
        { label: t(i18nKeys.reports.dailyEventReportEmail), value: Report.DAILY_EVENT_REPORT_EMAIL },
        { label: t(i18nKeys.reports.registrationDashboard), value: Report.REGISTRATION_DASHBOARD },
        { label: t(i18nKeys.reports.eventsDashboard), value: Report.EVENTS_DASHBOARD },
        { label: t(i18nKeys.reports.labAccountUsageAnalysis), value: Report.LAB_ACCOUNT_USAGE_ANALYSIS }
      );
    }

    return options;
  }, [user]);

  // Only All Events Report, Event Report, and Cost Explorer Report can be downloaded as JSON
  const exportToJsonEnabled = [Report.ALL_EVENTS, Report.EVENT_REPORT, Report.COST_EXPLORER].includes(
    selectedOption?.value as Report
  );

  const getContent = () => {
    if (selectedOption?.value === Report.EVENT_REPORT) {
      return <EventReportContainer />;
    }

    return null;
  };

  return (
    <ContentLayout disableOverlap header={<Header>{t(i18nKeys.reports.title)}</Header>}>
      <Container
        header={
          <Header
            actions={
              <Button iconName="download" disabled={!exportToJsonEnabled} data-testid="export-report-to-json-button">
                {t(i18nKeys.reports.exportToJson)}
              </Button>
            }
          >
            {t(i18nKeys.reports.chooseReport)}
          </Header>
        }
      >
        <ColumnLayout columns={1} borders="horizontal">
          <Box float="left">
            <Select
              data-testid="select-report-dropdown"
              selectedOption={selectedOption}
              placeholder={t(i18nKeys.reports.selectReport)}
              ariaLabel={t(i18nKeys.reports.chooseReport)}
              options={dropdownOptions}
              onChange={({ detail }) => {
                setSelectedOption(detail.selectedOption);
              }}
            />
          </Box>
          <Box>{getContent()}</Box>
        </ColumnLayout>
      </Container>
    </ContentLayout>
  );
};

export default ReportContainer;
