import React from 'react';
import { Button, Icon, SpaceBetween } from '@amzn/awsui-components-react';
import { Rating } from 'react-simple-star-rating';
import { roundFloat } from '@/src/utils/number.utils';
import { compareValues, getStabilityLabel } from '@/src/components/common/Challenges/browse-challenges-list-config';
import { Challenge, ChallengeListItem } from '@/src/types/Challenge';
import { TFunctionProvider } from '@/src/components/common/TFunctionProvider';
import { i18nKeys } from '@/src/utils/i18n.utils';
import DifficultyIndicator from '@/src/components/challenges/challengesCommon/difficultyIndiciator/DifficultyIndicator';
import { TFunction } from 'react-i18next';
import { ChallengeSet } from '@/src/types/ChallengeSet';
import { PropertyFilterProperty } from '@amzn/awsui-collection-hooks';

export const getMatchesCountText = (count: number) => {
  return count === 1 ? '1 match' : `${count} matches`;
};

export const columnDefinitions = (
  toggleChallengeDetailsInfo: (challenge: ChallengeListItem) => void,
  t: TFunction<'translation', undefined>
) => {
  return [
    {
      id: 'title',
      sortingField: 'title',
      sortingComparator: (a: ChallengeListItem, b: ChallengeListItem) => {
        return compareValues(a?.props.title || null, b?.props.title || null);
      },
      maxWidth: 200,
      header: t(i18nKeys.eventTemplates.step2.customTable.challenges.title),
      cell: (item: ChallengeListItem) => (
        <div>
          {toggleChallengeDetailsInfo && (
            <Button
              onClick={() => toggleChallengeDetailsInfo(item)}
              variant='inline-link'  
              id={`challenge-${item?.props.title}`}
              data-testid={`challenge-${item?.props.title}`}
            >
                {item?.props.title || 'Untitled Challenge'}
            </Button>
          )}
        </div>
      ),
    },
    {
      id: 'topic',
      sortingField: 'jamType',
      sortingComparator: (a: ChallengeListItem, b: ChallengeListItem) => {
        return compareValues(a?.props.jamType || null, b?.props.jamType || null);
      },
      header: t(i18nKeys.challenges.browseChallenges.headers.topic),
      cell: (item: ChallengeListItem) => (
        <div>
          {item?.props.jamType || (
            <TFunctionProvider>{(translate) => translate(i18nKeys.general.nA)}</TFunctionProvider>
          )}
        </div>
      ),
    },
    {
      id: 'category',
      sortingField: 'category',
      sortingComparator: (a: ChallengeListItem, b: ChallengeListItem) => {
        return compareValues(a?.props.category || null, b?.props.category || null);
      },
      header: t(i18nKeys.challenges.browseChallenges.headers.category),
      cell: (item: ChallengeListItem) => (
        <div>
          {item?.props.category || (
            <TFunctionProvider>{(translate) => translate(i18nKeys.general.nA)}</TFunctionProvider>
          )}
        </div>
      ),
    },
    {
      id: 'difficulty',
      sortingField: 'difficulty',
      sortingComparator: (a: ChallengeListItem, b: ChallengeListItem) => {
        return compareValues(a?.props.difficulty || null, b?.props.difficulty || null);
      },
      header: t(i18nKeys.challenges.browseChallenges.headers.difficulty),
      cell: (item: ChallengeListItem) => <DifficultyIndicator challenge={item as Challenge} enableBlock={false} />,
    },
    {
      id: 'stability',
      sortingField: 'stability',
      sortingComparator: (a: ChallengeListItem, b: ChallengeListItem) => {
        return compareValues(a?.stability || null, b?.stability || null);
      },
      header: t(i18nKeys.challenges.browseChallenges.headers.stability),
      cell: (item: ChallengeListItem) => <div>{getStabilityLabel(item?.stabilityLabel)}</div>,
    },
    {
      id: 'rating',
      sortingField: 'rating',
      sortingComparator: (a: ChallengeListItem, b: ChallengeListItem) => {
        return compareValues(a.globalStatistics.rating, b.globalStatistics.rating);
      },
      header: t(i18nKeys.challenges.browseChallenges.headers.rating),
      cell: (e: ChallengeListItem) => (
        <div>
          {e?.globalStatistics?.rating && (
            <SpaceBetween direction="horizontal" size={'xxxs'}>
              <Rating readonly initialValue={e.globalStatistics.rating} size={15} iconsCount={5} allowFraction />
              <div className="default-text">{roundFloat(e.globalStatistics.rating).toFixed(2)}</div>
            </SpaceBetween>
          )}
        </div>
      ),
    },
  ];
};

export const challengeSetColumnDefinitions = (
  toggleChallengeSetDetailsInfo: (challenge: ChallengeSet) => void,
  challengeListItemMap: { [id: string]: ChallengeListItem },
  handleSelectedItemsChange: (selectedItems: ChallengeSet) => void,
  t: TFunction<'translation', undefined>
) => {
  return [
    // {
    //   id: 'select',
    //   header: 'select',
    //   cell: (item: ChallengeSet) => (
    //     <Button variant="primary" className="success" onClick={() => handleSelectedItemsChange(item)}>
    //       <SpaceBetween size="s" direction="horizontal">
    //         <Icon name={item.isSelected ? 'remove' : 'add-plus'} />
    //         {t(i18nKeys.general.select)}
    //       </SpaceBetween>
    //     </Button>
    //   ),
    // },
    {
      id: 'title',
      header: 'Challenge Set Title',
      cell: (item: ChallengeSet) => (
        <div>
          {toggleChallengeSetDetailsInfo && (
            <Button variant="inline-link" onClick={() => toggleChallengeSetDetailsInfo(item)}>
              {item.title}
            </Button>
          )}
        </div>
      ),
      sortingField: 'title',
    },
    {
      id: 'topics',
      header: 'Topics',
      cell: (e: ChallengeSet) => e.topics(challengeListItemMap),
      sortingField: 'topics',
    },
    {
      id: 'challenges',
      header: 'Challenges',
      cell: (e: ChallengeSet) => e.numChallenges,
      sortingField: 'challenges',
    },
    // {
    //   id: 'rating',
    //   header: 'Rating',
    //   cell: (e: ChallengeSet) => (
    //     <div>
    //       <SpaceBetween direction="horizontal" size={'xxxs'}>
    //         <Rating readonly initialValue={e.rating(challengeListItemMap)} size={15} iconsCount={5} allowFraction />
    //         <div className="default-text">{roundFloat(e.rating(challengeListItemMap)).toFixed(2)}</div>
    //       </SpaceBetween>
    //     </div>
    //   ),
    //   sortingField: 'rating',
    // },
    {
      id: 'healthStatus',
      header: 'Health status',
      cell: (e: ChallengeSet) => (
        <div style={{ display: 'flex', color: e.healthStatus(challengeListItemMap) ? '#037F0C' : '#ff1e1e' }}>
          <Icon name={e.healthStatus(challengeListItemMap) ? 'status-positive' : 'status-warning'} />
          <span style={{ marginLeft: '0.5rem' }}>
            {e.healthStatus(challengeListItemMap)
              ? t(i18nKeys.eventTemplates.step2.customTable.healthStatus.healthy)
              : t(i18nKeys.eventTemplates.step2.customTable.healthStatus.unStable)}
          </span>
        </div>
      ),
      sortingField: 'healthStatus',
    },
  ];
};

export const filteringProperties: PropertyFilterProperty[] = [
  {
    key: 'title',
    operators: [
      {
        operator: '=',
        match: (itemValue: unknown, tokenValue: string) => tokenValue.split(',').includes(itemValue as string),
      },
      {
        operator: '!=',
        match: (itemValue: unknown, tokenValue: string) => !tokenValue.split(',').includes(itemValue as string),
      },
    ],
    groupValuesLabel: 'Title',
    propertyLabel: 'Title',
  },
  {
    key: 'category',
    groupValuesLabel: 'Category',
    propertyLabel: 'Category',
  },
  {
    key: 'difficulty',
    groupValuesLabel: 'Difficulty',
    propertyLabel: 'Difficulty',
  },
  {
    key: 'stability',
    groupValuesLabel: 'Stability',
    propertyLabel: 'Stability',
  },
  {
    key: 'rating',
    groupValuesLabel: 'Rating',
    propertyLabel: 'Rating',
  },
];

export const challengeSetsFilteringProperties: PropertyFilterProperty[] = [
  {
    key: 'title',
    operators: [
      {
        operator: '=',
        match: (itemValue: unknown, tokenValue: string) => tokenValue.split(',').includes(itemValue as string),
      },
      {
        operator: '!=',
        match: (itemValue: unknown, tokenValue: string) => !tokenValue.split(',').includes(itemValue as string),
      },
    ],
    groupValuesLabel: 'Challenge Set Title',
    propertyLabel: 'Challenge Set Title',
  },
  {
    key: 'numChallenges',
    groupValuesLabel: 'Challenges',
    propertyLabel: 'Challenges',
  },
];

export const paginationLabels = {
  nextPageLabel: 'Next page',
  pageLabel: (pageNumber: number) => `Go to page ${pageNumber}`,
  previousPageLabel: 'Previous page',
};

const pageSizePreference = {
  title: 'Select page size',
  options: [
    { value: 10, label: '10 resources' },
    { value: 20, label: '20 resources' },
  ],
};

const visibleContentPreference = (
  toggleChallengeDetailsInfo: (challenge: ChallengeListItem) => void,
  t: TFunction<'translation', undefined>
) => {
  return {
    title: 'Select visible content',
    options: [
      {
        label: 'Main properties',
        options: columnDefinitions(toggleChallengeDetailsInfo, t).map(({ id, header }) => ({
          id,
          label: header,
          editable: id !== 'id',
        })),
      },
    ],
  };
};

const visibleContentPreferenceForChallengeSet = (
  toggleChallengeSetDetailsInfo: (challengeSet: ChallengeSet) => void,
  challengeListItemMap: { [id: string]: ChallengeListItem },
  handleSelectedItemsChange: (selectedItem: ChallengeSet) => void,
  t: TFunction<'translation', undefined>
) => {
  return {
    title: 'Select visible content',
    options: [
      {
        label: 'Main properties',
        options: challengeSetColumnDefinitions(
          toggleChallengeSetDetailsInfo,
          challengeListItemMap,
          handleSelectedItemsChange,
          t
        ).map(({ id, header }) => ({
          id,
          label: header,
          editable: id !== 'id',
        })),
      },
    ],
  };
};

export const visibleContentForChallenge = ['title', 'topic', 'category', 'difficulty', 'stability', 'rating'];
export const visibleContentForChallengeDetail = ['title', 'topic', 'category', 'difficulty', 'stability'];
export const visibleContentForChallengeSet = ['select', 'title', 'topics', 'challenges', 'healthStatus'];
export const collectionPreferencesProps = (
  toggleChallengeDetailsInfo: (challenge: ChallengeListItem) => void,
  t: TFunction<'translation', undefined>
) => {
  return {
    pageSizePreference,
    visibleContentPreference: visibleContentPreference(toggleChallengeDetailsInfo, t),
    cancelLabel: 'Cancel',
    confirmLabel: 'Confirm',
    title: 'Preferences',
  };
};

export const collectionPreferencesPropsForChallengeSet = (
  toggleChallengeSetDetailsInfo: (challengeSet: ChallengeSet) => void,
  challengeListItemMap: { [id: string]: ChallengeListItem },
  handleSelectedItemsChange: (selectedItem: ChallengeSet) => void,
  t: TFunction<'translation', undefined>
) => {
  return {
    pageSizePreference,
    visibleContentPreference: visibleContentPreferenceForChallengeSet(
      toggleChallengeSetDetailsInfo,
      challengeListItemMap,
      handleSelectedItemsChange,
      t
    ),
    cancelLabel: 'Cancel',
    confirmLabel: 'Confirm',
    title: 'Preferences',
  };
};
