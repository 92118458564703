import React, { useState, useEffect } from 'react';
import { Box, Tabs } from '@amzn/awsui-components-react';
import { useApi } from '../../../../store/api.context';
import { Challenge, ChallengeDeploymentStatistics } from '../../../../types/Challenge';
import { safeString } from '../../../../utils/string.utils';
import ChallengeDeploymentStatisticsDetail from './deploymentStatistics/DeploymentStatistics';
import ChallengeRecentEventsDetail from './recentEvents/RecentEvents';
import ChallengeStatisticSummarysDetail from './StatisticsSummary';
import { i18nKeys } from '@/src/utils/i18n.utils';
import { useTranslation } from 'react-i18next';
import { preProdLogger } from '@/src/utils/log.utils';


interface ChallengeStatsDetailsProps {
  challenge: Challenge;
}

const ChallengeStatsDetails: React.FC<ChallengeStatsDetailsProps> = ({ challenge }) => {
  const { challengesApi } = useApi();
  const { t } = useTranslation();


  const [deploymentStatistics, setDeploymentStatistics] = useState<ChallengeDeploymentStatistics>();

  useEffect(() => {
    challengesApi.getChallengeDeploymentStatistics(safeString(challenge.challengeId), false).then((r) => {
      if (r.statistics) {
        setDeploymentStatistics(r.statistics);
      }
    }).catch((err) => {
      preProdLogger("Error loading challenges stats", err);
    });
  }, []);

  return (
    <Box margin={'xxl'}>
      <Tabs tabs={[
        {
          id:'summary',
          label:t(i18nKeys.challenges.challengeStats.summary),
          content: <ChallengeStatisticSummarysDetail challenge={challenge} challengeDeploymentStatistics={deploymentStatistics} />
        },
        {
          id:'deployment-statistics',
          label:t(i18nKeys.challenges.challengeStats.deploymentStats),
          content: <ChallengeDeploymentStatisticsDetail challengeDeploymentStatistics={deploymentStatistics} />
        },
        {
          id:'recent-upcoming-events',
          label:t(i18nKeys.challenges.challengeStats.recentEvents),
          content: <ChallengeRecentEventsDetail challenge={challenge} />
         }
      ]} />
    </Box>
  );
};

export default ChallengeStatsDetails;
