import { MyJamFooter } from '@/src/components/event-templates-catalog/molecules/MyJamFooter/MyJamFooter';
import { RoleSpoofingModal } from '@/src/components/spoofed-groups/RoleSpoofingModal';
import { useJamLeaderboard } from '@/src/store/jam-leaderboard.context';
import { useUser } from '@/src/store/user.context';
import { isDev, isPreProd } from '@/src/utils/env.utils';
import { jamChallengesRegex, jamChallengeTaskRegex, jamChatRegex } from '@/src/utils/myjam.utils';
import { Badge, Button, Link } from '@amzn/awsui-components-react';
import * as React from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { config } from '../../../config/app-config';
import { useCookieConsent } from '../../../store/cookie-consent.context';
import { privacy, siteTerms } from '../../../utils/jam-urls';
import { i18nKeys } from '../../../utils/i18n.utils';
import styles from './Footer.module.scss';

export const Footer: React.FC = () => {
  const { t } = useTranslation();
  const { customizeCookies } = useCookieConsent();
  const { isLoggedIn } = useUser();
  const { mouseMoved } = useJamLeaderboard();
  const [showRoleSpoofingModal, setShowRoleSpoofingModal] = useState<boolean>(false);
  const location = useLocation();
  const { pathname } = location;

  if (!mouseMoved) {
    return null;
  }

  if (jamChallengesRegex.test(pathname) || jamChallengeTaskRegex.test(pathname) || jamChatRegex.test(pathname)) {
    return <MyJamFooter />;
  }

  return (
    <footer id={styles.footer} style={{ position: 'fixed', width: '100%', zIndex: '1000' }}>
      <div className={styles.container}>
        <span className={styles.footerItem}>
          {isPreProd() && <Badge className="inline">{config.stageName.toUpperCase()}</Badge>}
        </span>
        <span className={styles.footerItem} data-testid="footer-privacy">
          <Link className="mr-8" href={privacy} target="_blank" rel="noopener noreferrer">
            {t(i18nKeys.footer.privacy)}
          </Link>
        </span>
        <span className={styles.footerItem} data-testid="footer-terms">
          <Link href={siteTerms} target="_blank" rel="noopener noreferrer">
            {t(i18nKeys.footer.siteTerms)}
          </Link>
        </span>
        <span className={styles.footerItem} data-testid="footer-cookies">
          <Link onFollow={customizeCookies}>{t(i18nKeys.footer.cookie)}</Link>
        </span>
        <span className={styles.footerItem} data-testid="footer-copyright">
          <span className={styles.copyright}>
            {t(i18nKeys.footer.copyright, { name: `©${new Date().getFullYear()} Amazon.com` })}
          </span>
        </span>
      </div>
      <div className={styles.footerEnd}>
        {isDev() && isLoggedIn && (
          <Button
            className={styles.roleSpoofing}
            iconName='settings'
            variant='inline-icon'
            onClick={() => setShowRoleSpoofingModal(true)}
            data-testid="footer-role-spoofing"
           />
        )}
        {showRoleSpoofingModal && <RoleSpoofingModal handleCloseModal={() => setShowRoleSpoofingModal(false)} />}
        <div id="shortbread-container" />
      </div>
    </footer>
  );
};
