import React from 'react';
import { Challenge, ChallengeListItem } from '@/src/types/Challenge';
import { Button, Container, Icon, SpaceBetween } from '@amzn/awsui-components-react';
import { Link as ReactRouterLink } from 'react-router-dom';

import './ChallengeOrderingItem.scss';
import DifficultyIndicator from '@/src/components/challenges/challengesCommon/difficultyIndiciator/DifficultyIndicator';
import { CHALLENGE_DETAILS_ROUTES } from '@/src/routes';
import { i18nKeys } from '@/src/utils/i18n.utils';
import { useTranslation } from 'react-i18next';

export interface IChallengeOrderingItemProps {
  item: ChallengeListItem;
  editMode?: boolean;
  isPrimary?: boolean;
  handleRemove?: (challengeId: string, isPrimary: boolean) => void;
}

export const ChallengeOrderingItem: React.FC<IChallengeOrderingItemProps> = ({
  item,
  editMode,
  isPrimary,
  handleRemove,
}) => {
  const { t } = useTranslation();
  const handleRemoveChallenge = (challengeId: string) => {
    if (handleRemove) handleRemove(challengeId, !!isPrimary);
  };
  return (
    <div className="challenge-ordering-listitem" key={item.id}>
      <Container data-item={JSON.stringify(item)} data-challenge="challenge-item" disableContentPaddings>
        <div className="content">
          <div className="position">
            <Icon name="drag-indicator" />
          </div>
          <div className="description">
            <h3>{item.props.title}</h3>
            <div className="meta">
              <div className="meta-item">
                <span>
                  <b>Difficulty:</b>
                </span>
                <DifficultyIndicator challenge={item as Challenge} enableBlock={false} />
              </div>
              <div className="meta-item">
                <span>
                  <b>Topic:</b>
                </span>
                <span>{item.props.category}</span>
              </div>
              {!editMode ? (
                <ReactRouterLink
                  target="_blank"
                  to={CHALLENGE_DETAILS_ROUTES.Summary.resolve(item.challengeId ?? '')}
                  style={{ textDecoration: 'none', color: '#0982f3' }}
                  key={item.id}>
                  <SpaceBetween direction="horizontal" size="xs">
                    Challenge details
                    <div style={{ marginLeft: '0.1rem' }}>
                      <Icon name="external" />
                    </div>
                  </SpaceBetween>
                </ReactRouterLink>
              ) : (
                <Button
                  onClick={() => handleRemoveChallenge(item.id ?? '')}
                  variant='inline-link'
                  id={`remove-${item.id}`}
                  iconName="remove"
                  data-testid={`remove-${item.id}`}>
                  <SpaceBetween direction="horizontal" size="xs">
                    {t(i18nKeys.newEvent.buttons.remove)}
                  </SpaceBetween>
                </Button>
              )}
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};
