import React, { useState, useCallback, useMemo } from 'react';
import { Button, Header, SpaceBetween, Badge, ButtonDropdown, ButtonDropdownProps } from '@amzn/awsui-components-react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams, useRouteMatch } from 'react-router-dom';
import { EVENT_CATALOG_TEMPLATE_DETAILS_ROUTES } from '@/src/routes';
import { i18nKeys } from '@/src/utils/i18n.utils';
import { RoutePath } from '@/src/RoutePath';
import { EventTemplateStatusType } from '@/src/types/EventTemplate';
import { ConfirmModal } from '@/src/components/common/ConfirmModal';
import { CancelableEventHandler } from '@amzn/awsui-components-react/polaris/internal/events';

interface IEventTemplateDetailHeaderProps {
  status: EventTemplateStatusType | undefined;
  onUpdateEventTemplateStatus: (newStatus: EventTemplateStatusType) => Promise<void>;
  heading: string;
  eventTemplateEditMode: boolean;
  setEventTemplateEditMode: (enableEditMode: boolean) => void;
  onSaveEventTemplate: () => void;
  handleCancel: () => void;
  deleteEventTemplate: () => void;
}

const getColorForStatus = (status: EventTemplateStatusType) => {
  switch (status) {
    case EventTemplateStatusType.DRAFT: {
      return 'grey';
    }
    case EventTemplateStatusType.PUBLISHED: {
      return 'green';
    }
    case EventTemplateStatusType.DEFECTIVE: {
      return 'red';
    }
    case EventTemplateStatusType.PRIVATE: {
      return 'blue';
    }
  }
};

const convertToCapitalCase = (text: string) => {
  return text.charAt(0).toUpperCase() + text.slice(1);
};
const EventTemplateDetailHeader: React.FC<IEventTemplateDetailHeaderProps> = ({
  status,
  onUpdateEventTemplateStatus,
  heading,
  eventTemplateEditMode,
  setEventTemplateEditMode,
  handleCancel,
  onSaveEventTemplate,
  deleteEventTemplate,
}) => {
  const navigate = useHistory();
  const [showDeleteConfirm, setShowDeteleConfirm] = useState(false);
  const { t } = useTranslation();
  const { eventTemplateId }: { eventTemplateId: string } = useParams();
  const feedbackMatch = useRouteMatch(EVENT_CATALOG_TEMPLATE_DETAILS_ROUTES.Feedback.wildcard());
  const reportingMatch = useRouteMatch(EVENT_CATALOG_TEMPLATE_DETAILS_ROUTES.Reporting.wildcard());
  const ChallengesMatch = useRouteMatch(EVENT_CATALOG_TEMPLATE_DETAILS_ROUTES.Challenges.wildcard());

  const handleNaviagtion = () => {
    setEventTemplateEditMode(true);
    navigate.replace(
      `${RoutePath.EVENT_CATALOG_TEMPLATES}/${eventTemplateId}/edit/${
        ChallengesMatch && ChallengesMatch.isExact ? 'challenges' : 'general'
      }`
    );
  };

  const handleDeleteEventCatalogTemplateClick = useCallback(() => {
    setShowDeteleConfirm(true);
  }, []);

  const handleDeleteConfirm = useCallback(() => {
    setShowDeteleConfirm(false);
    deleteEventTemplate();
  }, [deleteEventTemplate]);

  const handleDeleteCancel = useCallback(() => {
    setShowDeteleConfirm(false);
  }, []);

  const handleUpdateEventTemplateStatus = async (newStatus: EventTemplateStatusType) => {
    await onUpdateEventTemplateStatus(newStatus);
  };

  const handleActionItemClick = useCallback<CancelableEventHandler<ButtonDropdownProps.ItemClickDetails>>((e) => {
    switch (e.detail.id) {
      case 'delete': {
        handleDeleteEventCatalogTemplateClick();
        return;
      }
      case 'private': {
        void handleUpdateEventTemplateStatus(EventTemplateStatusType.PRIVATE);
        return;
      }
      case 'publish': {
        void handleUpdateEventTemplateStatus(EventTemplateStatusType.PUBLISHED);
        return;
      }
    }
  }, []);

  const actionMenuItems = useMemo(() => {
    const menuItems = [];
    if (status === EventTemplateStatusType.PRIVATE) {
      menuItems.push({ text: t(i18nKeys.eventTemplates.makeEventPublic) ?? '', id: 'publish', disabled: false });
    }
    if (status === EventTemplateStatusType.PUBLISHED || status === EventTemplateStatusType.DEFECTIVE) {
      menuItems.push({ text: t(i18nKeys.eventTemplates.makeEventPrivate) ?? '', id: 'private', disabled: false });
    }
    menuItems.push({
      text: t(i18nKeys.eventTemplates.deleteEventCatalogTemplate.button) ?? '',
      id: 'delete',
      disabled: false,
    });
    return menuItems;
  }, [t, status]);

  return (
    <SpaceBetween size="m">
      {eventTemplateEditMode}
      <Header
        variant="h1"
        actions={
          <SpaceBetween direction="horizontal" size="l">
            {!eventTemplateEditMode ? (
              <SpaceBetween direction="horizontal" size="l">
                {!feedbackMatch && !reportingMatch && (
                  <Button variant="normal" disabled={status === EventTemplateStatusType.PUBLISHED} data-testid="edit" onClick={handleNaviagtion}>
                    {t(i18nKeys.general.edit)}
                  </Button>
                )}
                <ButtonDropdown items={actionMenuItems} onItemClick={handleActionItemClick}>
                  {t(i18nKeys.eventTemplates.actionText)}
                </ButtonDropdown>
              </SpaceBetween>
            ) : (
              <SpaceBetween direction="horizontal" size="l">
                <Button variant="normal" id="cancel" data-testid="cancel" onClick={handleCancel}>{t(i18nKeys.general.cancel)}</Button>
                <Button variant="primary" id="save" data-testid="save" onClick={onSaveEventTemplate}>{t(i18nKeys.general.save)}</Button>
              </SpaceBetween>
            )}
          </SpaceBetween>
        }>
        <div>
          <div>{heading}</div>
          {status && <Badge color={getColorForStatus(status)}>{convertToCapitalCase(status)}</Badge>}
        </div>
      </Header>
      <ConfirmModal
        visible={showDeleteConfirm}
        title={t(i18nKeys.eventTemplates.deleteEventCatalogTemplate.modalTitle)}
        confirmBtnLabel={t(i18nKeys.eventTemplates.deleteEventCatalogTemplate.modalConfirm)}
        onConfirm={handleDeleteConfirm}
        onCancel={handleDeleteCancel}
      />
    </SpaceBetween>
  );
};

export default EventTemplateDetailHeader;
