import React, { useEffect, useMemo, useState } from 'react';
import { LANGUAGE_SELECT_OPTIONS } from '@/src/utils/locale.utils';
import {
  Box,
  Button,
  Checkbox,
  Container,
  ContentLayout,
  FormField,
  Header,
  Input,
  Link,
  Select,
  SpaceBetween,
} from '@amzn/awsui-components-react';
import './JamProfileCreate.scss';
import { OptionDefinition } from '@amzn/awsui-components-react/polaris/internal/components/option/interfaces';
import { useTranslation } from 'react-i18next';
import { i18nKeys } from '@/src/utils/i18n.utils';
import { COUNTRIES } from '@/src/constants/countries';
import { createJamProfileValidator } from '@/src/utils/create-jam-profile.validation.utils';
import { CreateJamProfileFields } from '@/src/types/CreateJamProfile';
import { localLogger } from '@/src/utils/log.utils';
import { useDebounce } from '@/src/hooks/useDebounce';
import { delay } from '@/src/utils/delay';
import { learnerTermsAndConditionsLink, privacyNoticeLink } from './jam-profile.config';
import { useUser } from '@/src/store/user.context';

const JamProfileCreate = () => {
  const { t } = useTranslation();
  const { user } = useUser();
  const [language, setLanguage] = useState<OptionDefinition | null>(null);
  const [hasSubscribed, setHasSubscribed] = useState(false);
  const [email, setEmail] = useState<string>(user?.email || '');
  const [displayName, setDisplayName] = useState<string>('');
  const [country, setCountry] = useState<OptionDefinition | null>(null);
  const displayNameSearch = useDebounce<string>(displayName, 300);
  const countries = COUNTRIES.map((countryEl) => ({ value: countryEl.code, label: t(countryEl.name) }));

  const [emailError, setEmailError] = useState('');
  const [languageError, setLanguageError] = useState('');
  const [displayNameError, setDisplayNameError] = useState('');
  const [displayNameUniqueError, setDisplayNameUniqueError] = useState('');
  const [countryError, setCountryError] = useState('');

  const validator = useMemo(
    () =>
      createJamProfileValidator(
        email,
        displayName,
        country?.value,
        language?.value,
        t(i18nKeys.createJamProfile.form.validation.emailError),
        t(i18nKeys.createJamProfile.form.validation.displayNameError),
        t(i18nKeys.createJamProfile.form.validation.countryError),
        t(i18nKeys.createJamProfile.form.validation.languageError),
        new Map<CreateJamProfileFields, (error: string) => void>([
          [CreateJamProfileFields.EMAIL, (error: string) => setEmailError(error)],
          [CreateJamProfileFields.DISPLAY_NAME, (error: string) => setDisplayNameError(error)],
          [CreateJamProfileFields.COUNTRY, (error: string) => setCountryError(error)],
          [CreateJamProfileFields.LANGUAGE, (error: string) => setLanguageError(error)],
        ])
      ),
    [email, country, displayName, language]
  );

  const checkDisplayNameUnique = async () => {
    await delay(300);
    if (displayNameSearch.length <= 3) {
      setDisplayNameUniqueError(t(i18nKeys.createJamProfile.form.validation.displayNameUniqueError));
      return;
    } else {
      setDisplayNameUniqueError('');
    }
  };

  const onSubmit = () => {
    const isValid = validator.isValidSection(true);
    if (isValid && !displayNameUniqueError) {
      localLogger('valid', isValid);
      return;
    }
    localLogger('invalid', isValid);
  };

  useEffect(() => {
    if (!displayNameSearch) {
      return;
    }
    void checkDisplayNameUnique();
  }, [displayNameSearch]);

  return (
    <ContentLayout
      header={<Header description={t(i18nKeys.createJamProfile.caption)}>{t(i18nKeys.createJamProfile.title)}</Header>}>
      <div className="jam-profile-create-container">
        <Container header={<Header variant="h3">{t(i18nKeys.createJamProfile.profileDetails)}</Header>}>
          <form onSubmit={(e) => e.preventDefault()}>
            <SpaceBetween size="l">
              <FormField
                label={`${t(i18nKeys.createJamProfile.form.preferredLanguage)}*`}
                description={`${t(i18nKeys.createJamProfile.form.preferredLanguageDesc)}*`}
                errorText={languageError}>
                <Select
                  id="preferredLanguageSelectBox"
                  placeholder={t(i18nKeys.createJamProfile.form.select)}
                  selectedOption={language}
                  options={LANGUAGE_SELECT_OPTIONS}
                  onChange={({ detail }) => setLanguage(detail.selectedOption)}
                  onBlur={() => validator.isValidField(CreateJamProfileFields.LANGUAGE)}
                />
              </FormField>
              <FormField label={`${t(i18nKeys.createJamProfile.form.email)}`} errorText={emailError}>
                <Input
                  value={email}
                  onChange={({ detail }) => setEmail(detail.value)}
                  placeholder={t(i18nKeys.createJamProfile.form.email)}
                  onBlur={() => validator.isValidField(CreateJamProfileFields.EMAIL)}
                  disabled
                />
              </FormField>
              <FormField
                label={`${t(i18nKeys.createJamProfile.form.displayName)}*`}
                description={t(i18nKeys.createJamProfile.form.displayNameDesc)}
                errorText={displayNameUniqueError || displayNameError}>
                <Input
                  id="displayNameTextBox"
                  value={displayName}
                  onChange={({ detail }) => setDisplayName(detail.value)}
                  placeholder={t(i18nKeys.createJamProfile.form.displayNamePlaceholder)}
                  onBlur={() => validator.isValidField(CreateJamProfileFields.DISPLAY_NAME)}
                />
              </FormField>
              <FormField label={`${t(i18nKeys.createJamProfile.form.country)}*`} errorText={countryError}>
                <Select
                  id="countrySelectBox"
                  placeholder={t(i18nKeys.createJamProfile.form.select)}
                  selectedOption={country}
                  onChange={({ detail }) => setCountry(detail.selectedOption)}
                  options={countries}
                  onBlur={() => validator.isValidField(CreateJamProfileFields.COUNTRY)}
                />
              </FormField>
              <FormField>
                <Checkbox checked={hasSubscribed} onChange={() => setHasSubscribed((prev) => !prev)}>
                  <Box color="text-label">{t(i18nKeys.createJamProfile.form.subscribeDesc)}</Box>
                </Checkbox>
              </FormField>
            </SpaceBetween>
          </form>
        </Container>
        <div className="final-note">
          <Box variant="p" fontSize="body-s" margin={{ top: 'l', bottom: 'm' }}>
            {t(i18nKeys.createJamProfile.termsDesc)}
            <Link fontSize="inherit" href={learnerTermsAndConditionsLink} target="blank">
              {t(i18nKeys.createJamProfile.termLink)}
            </Link>
            .
            <br />
            {t(i18nKeys.createJamProfile.privacyDesc)}
            <Link fontSize="inherit" href={privacyNoticeLink} target="blank">
              {t(i18nKeys.createJamProfile.privacyLink)}
            </Link>
            .
          </Box>
          <Box float="right">
            <Button variant="primary" id="createJamProfileButton" data-testid="createJamProfileButton" onClick={onSubmit}>{t(i18nKeys.createJamProfile.form.submitButton)}</Button>
          </Box>
        </div>
      </div>
    </ContentLayout>
  );
};

export default JamProfileCreate;
