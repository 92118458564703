import { ApiClient } from './ApiClient';
import { i18nKeys } from '../utils/i18n.utils';
import { CognitoUserModel } from '../types/cognito';
import { TermsAndConditionsStatus } from '../types/TermsAndConditionsStatus';
import { CognitoUser } from '../types/User';
import { downloadObjectAsJson } from '../utils/download.utils';
import { TFunction } from 'i18next';

export interface ListUserResponse {
  userResponses?: CognitoUser[];
  nextToken: string;
}

/**
 * Class for constructing a client to make account API calls
 */
export class AccountAPI {
  constructor(private apiClient: ApiClient, private t: TFunction) {
    // do nothing
  }

  /**
   * Sets MFA to enable/disable
   *
   * @param enabled
   * @public
   */
  public async setMFAEnabled(enabled: boolean): Promise<void> {
    if (enabled) {
      return (await this.apiClient.post({
        path: `/admin/account/enableMFA`,
        failMessage: this.t(i18nKeys.errors.requestFailed.setMFAEnabled),
      })) as Promise<void>;
    } else {
      return (await this.apiClient.post({
        path: `/admin/account/disableMFA`,
        failMessage: this.t(i18nKeys.errors.requestFailed.setMFADisabled),
      })) as Promise<void>;
    }
  }

  /**
   * Get Cognito User information of the current user
   *
   * @public
   */
  public async getUserAccountInfo(): Promise<CognitoUserModel> {
    return (await this.apiClient.get({
      path: `/admin/account/user`,
      failMessage: this.t(i18nKeys.errors.requestFailed.getUserAccountInfo),
    })) as Promise<CognitoUserModel>;
  }

  /**
   * Update Cognito User phone number of current user
   *
   * @param phoneNumber
   * @public
   */
  public async updateUser(phoneNumber: string): Promise<{ status: 'ok' | 'phoneVerificationSent' }> {
    return (await this.apiClient.post({
      path: `/admin/account/user`,
      body: { phoneNumber },
      failMessage: this.t(i18nKeys.errors.requestFailed.updateUser),
      successMessage: this.t(i18nKeys.success.requestSucceeded.updateUser),
    })) as Promise<{ status: 'ok' | 'phoneVerificationSent' }>;
  }

  /** Get List of users */
  public async listUsers(includeGroups = false, nextToken?: string) {
    const response = (await this.apiClient.get({
      path: `/admin/users`,
      params: {
        includeGroups,
        ...(nextToken ? { nextToken } : {}),
      },
      failMessage: this.t(i18nKeys.errors.requestFailed.user.listUsers),
    })) as ListUserResponse;

    return response;
  }

  public async createUsers(
    emails: string[]
  ): Promise<{ successfulUsers?: string[]; alreadyExistsUsers?: string[]; otherErrorsUsers?: string[] }> {
    return (await this.apiClient.post({
      path: `/admin/users`,
      body: {
        emails,
      },
    })) as Promise<{ successfulUsers?: string[]; alreadyExistsUsers?: string[]; otherErrorsUsers?: string[] }>;
  }

  public async deleteUser(username: string): Promise<void> {
    return (await this.apiClient.delete({
      path: `/admin/users/${username}`,
      successMessage: this.t(i18nKeys.success.requestSucceeded.user.deleteUser),
      failMessage: this.t(i18nKeys.errors.requestFailed.user.deleteUser),
    })) as Promise<void>;
  }

  public async resendInvite(username: string): Promise<void> {
    return (await this.apiClient.post({
      path: `/admin/users/${username}/resend`,
      successMessage: this.t(i18nKeys.success.requestSucceeded.user.resendInvite),
      failMessage: this.t(i18nKeys.errors.requestFailed.user.resendInvite),
    })) as Promise<void>;
  }

  public async removeUserFromGroup(email: string, group: string): Promise<void> {
    await this.apiClient.post({
      path: `/admin/users/groups/remove`,
      body: {
        email,
        group,
      },
      successMessage: this.t(i18nKeys.success.requestSucceeded.user.removeUserFromGroup),
      failMessage: this.t(i18nKeys.errors.requestFailed.user.removeUserFromGroup),
    });
  }

  public async addUserToGroup(email: string, group: string): Promise<void> {
    await this.apiClient.post({
      path: `/admin/users/groups/add`,
      body: {
        email,
        group,
      },
      successMessage: this.t(i18nKeys.success.requestSucceeded.user.addUserToGroup),
      failMessage: this.t(i18nKeys.errors.requestFailed.user.addUserToGroup),
    });
  }

  /**
   * Verify Cognito User phone number with verification code
   *
   * @param verificationCode
   * @public
   */
  public async verifyPhoneNumber(verificationCode: string): Promise<void> {
    return (await this.apiClient.post({
      path: `/admin/account/verifyPhoneNumber`,
      body: { verificationCode },
      failMessage: this.t(i18nKeys.errors.requestFailed.verifyPhoneNumber),
      successMessage: this.t(i18nKeys.success.requestSucceeded.verifyPhoneNumber),
    })) as Promise<void>;
  }

  async getConsoleUserTermStatus(): Promise<TermsAndConditionsStatus> {
    return (await this.apiClient.get({
      path: '/admin/terms/status',
      failMessage: this.t(i18nKeys.errors.requestFailed.getUserTermStatus),
    })) as Promise<TermsAndConditionsStatus>;
  }

  async acceptTermsAndConditions(): Promise<void> {
    (await this.apiClient.post({
      path: '/admin/terms/accept',
      failMessage: this.t(i18nKeys.errors.requestFailed.acceptTermsAndConditions),
    })) as Promise<void>;
  }

  async getLastFinishedTutorialDate(silent = true): Promise<{ lastFinishedTutorialOn: string }> {
    return (await this.apiClient.get({
      path: '/admin/challenge/tutorial',
      failMessage: this.t(i18nKeys.errors.requestFailed.user.getLastFinishedTutorialDate),
      silent,
    })) as Promise<{ lastFinishedTutorialOn: string }>;
  }

  async updateLastFinishedTutorialDate(silent = true): Promise<void> {
    return (await this.apiClient.post({
      path: '/admin/challenge/tutorial',
      failMessage: this.t(i18nKeys.errors.requestFailed.user.updateLastFinishedTutorialDate),
      silent,
    })) as Promise<void>;
  }

  async makeDSARRequest(email: string, silent = false): Promise<void> {
    const response = (await this.apiClient.get({
      path: '/admin/gdpr/dsar',
      params: {
        email,
      },
      successMessage: this.t(i18nKeys.success.requestSucceeded.account.makeDSARRequest),
      failMessage: this.t(i18nKeys.errors.requestFailed.user.makeDSARRequest),
      silent,
    })) as unknown;

    if (response) {
      downloadObjectAsJson(response, `aws-jam-personal-data-${Date.now()}`);
    }
  }

  async makeRTFRequest(email: string, silent = false): Promise<void> {
    return (await this.apiClient.delete({
      path: '/admin/gdpr/rtf',
      params: {
        email,
      },
      successMessage: this.t(i18nKeys.success.requestSucceeded.account.makeRTFRequest),
      failMessage: this.t(i18nKeys.errors.requestFailed.user.makeRTFRequest),
      silent,
    })) as Promise<void>;
  }
}
