/* eslint-disable @typescript-eslint/no-unsafe-call */
import { useCollection } from '@amzn/awsui-collection-hooks';
import { Button, Checkbox, Pagination, SpaceBetween, Table, TextFilter } from '@amzn/awsui-components-react';
import React, { useEffect, useMemo } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useApi } from '../../../../store/api.context';
import { Challenge, ChallengeIssue } from '../../../../types/Challenge';
import { i18nKeys } from '../../../../utils/i18n.utils';
import { safeString } from '../../../../utils/string.utils';
import { paginationLabels } from '../../../../utils/table.utils';
import { TableEmptyState } from '../../../common/TableEmptyState';
import { TableHeader } from '../../../common/TableHeader';
import { getColumnDefinitions, filteringFunction } from './issue-list-config';
import NewIssue from './NewIssue';
import JamSpinner from '@/src/components/common/JamSpinner';
import { preProdLogger } from '@/src/utils/log.utils';

interface ChallengeIssuesDetailProps {
  challenge: Challenge;
}

const ChallengeIssuesDetail: React.FC<ChallengeIssuesDetailProps> = ({ challenge }) => {
  const { t } = useTranslation();
  const { challengeIssuesApi } = useApi();
  const [challengeIssues, setChallengeIssues] = useState<ChallengeIssue[] | undefined>();
  const [newIssueActive, setNewIssueActive] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);

  const [showResolvedIssues, setShowResolvedIssues] = useState(false);

  const challengeId = useMemo(() => challenge.id, [challenge.id]);

  useEffect(() => {
    setLoading(true);
    challengeIssuesApi.getChallengeIssuesByChallengeId(safeString(challengeId), showResolvedIssues)
      .then((res) => setChallengeIssues(res))
      .catch((err) => preProdLogger(err))
      .finally(() => setLoading(false));
  }, [showResolvedIssues, challengeId]);

  const [preferences] = useState({
    pageSize: 10,
    visibleColumns: ['title', 'createdDate', 'openedBy', 'status', 'severity', 'comments'],
  });

  const { items, actions, filteredItemsCount, collectionProps, filterProps, paginationProps } = useCollection(
    challengeIssues || [],
    {
      filtering: {
        filteringFunction,
        empty: <TableEmptyState title={t(i18nKeys.challenges.challengeDetails.table.emptyIssuesState)} />,
        noMatch: (
          <TableEmptyState
            title={t(i18nKeys.tables.noMatch.title)}
            subtitle={t(i18nKeys.tables.noMatch.subtitle)}
            onClearFilter={() => actions.setFiltering('')}
          />
        ),
      },
      pagination: { pageSize: preferences.pageSize },
      sorting: {},
    }
  );

  const columnDefinitions = useMemo(() => {
    return getColumnDefinitions();
  }, []);

  const toggleNewIssues = () => setNewIssueActive(!newIssueActive);

  if (loading) {
    return <JamSpinner />;
  }

  if (newIssueActive) {
    return <NewIssue challenge={challenge} toggleNewIssues={toggleNewIssues} />;
  }

  return (
    <Table
      {...collectionProps}
      variant="container"
      resizableColumns
      header={
        <TableHeader
          totalItems={challengeIssues ? challengeIssues.length : 0}
          title={t(i18nKeys.challenges.challengeDetails.headings.issues)}
          toggleTools={() => alert('challenge list tools not implemented yet')}
          actionButtons={
            <SpaceBetween direction="horizontal" size="s">
              <Checkbox onChange={({ detail }) => setShowResolvedIssues(detail.checked)} checked={showResolvedIssues}>
                {t(i18nKeys.challenges.challengeDetails.table.showResolvedIssues)}
              </Checkbox>
              <Button id='challenge-issue-upload' data-testid='challenge-issue-upload' variant="primary" onClick={toggleNewIssues}>
                {t(i18nKeys.challenges.challengeDetails.table.button.openNewIssue)}
              </Button>
            </SpaceBetween>
          }
        />
      }
      columnDefinitions={columnDefinitions}
      items={items}
      loadingText={t(i18nKeys.challenges.challengeDetails.table.loadingIssues)}
      pagination={<Pagination {...paginationProps} ariaLabels={paginationLabels(t)} />}
      filter={
        <TextFilter
          {...filterProps}
          countText={t(i18nKeys.tables.matchesCount, { count: filteredItemsCount })}
          filteringAriaLabel={t(i18nKeys.challenges.filteringLabel)}
        />
      }
    />
  );
};

export default ChallengeIssuesDetail;
