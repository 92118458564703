export const getErrorMessage = (e: any): string => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call
  let message: string = e?.responseData?.errorMessage || e.message || e.toString();
  message = message.replace('Error:', '');
  return message;
};

export const getErrorCode = (e: any): number => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call
  const errCode: number = e?.responseData?.errorMessage || e.code || 0;
  return errCode;
};


const SBTS_ERROR_CODES = [410, 1021];

export const isSBTSError = (e: { responseData? : { errorMessage: string; errorCode: number; }; code?: number }) => {
  const errorCode = e?.responseData?.errorCode || e?.code || 0;
  return SBTS_ERROR_CODES.includes(errorCode);
};