import React, { useEffect, useMemo } from 'react';
import { Button, ContentLayout, Header, SpaceBetween } from '@amzn/awsui-components-react';
import ChallengeDetails from '../../ui/organisms/MyJams/JamChallenges/ChallengeDetails';
import GlobalInfo from '../../ui/molecules/MyJams/JamChallenges/GlobalInfo';
import { i18nKeys } from '@/src/utils/i18n.utils';
import { useTranslation } from 'react-i18next';
import { useJamChallenge } from '@/src/store/jam-challenge.context';
import './JamChallenges.scss';
import { LoadingBar } from '../../common/LoadingBar';
import { useFlashbars } from '@/src/store/flashbar.context';
import { FlashbarType } from '@/src/utils/notification.utils';
import { useCampaigns } from '@/src/store/campaigns.context';
import { isDateInThePast } from '@/src/utils/event-campaign-common.utils';
import { getErrorMessage } from '@/src/utils/errors.utils';
import JamCampaignRules from '../../ui/molecules/JamCampaignRules/JamCampaignRules';
import { useJamEventDetails } from '@/src/store/jam-event-details.context';
import { useUser } from '@/src/store/user.context';

const JamChallenges = () => {
  const { t } = useTranslation();
  const { addFlashbar, removeFlashbar, addErrorFlashbar, flashbars } = useFlashbars();
  const {
    startCampaignAttempt,
    campaignAttempt,
    handleShowCampaignRules,
    getCampaignEventDetails,
    showCampaignRules,
    campaignStarted,
    handleIsFetchingCampaign,
  } = useCampaigns();
  const { isFetchingJamChallenge, jamChallengeData, loadJamChallengeData } = useJamChallenge();
  const { user } = useUser();
  const { event } = useJamEventDetails();

  const isCampaign = jamChallengeData?.type === 'CAMPAIGN_GROUP';
  const eventName = jamChallengeData?.eventName;
  const isExpired = isDateInThePast(campaignAttempt?.deadline || '');
  const isSupportUser = !!event?.supportUser;
  const isAdmin = !!(user?.isSuperAdmin || user?.isEventAdmin);

  const shouldShowJamCampaignRules = useMemo(() => {
    if (!isCampaign) {
      return false;
    }

    if (isSupportUser || isAdmin) {
      return false;
    }

    if (event?.ended) {
      return false;
    }

    return showCampaignRules || !campaignStarted || event?.notStarted;
  }, [showCampaignRules, campaignStarted, event, user]);

  // Opens for the campaign rules page
  const handleOpenRules = () => {
    handleShowCampaignRules(true);
    removeFlashbar('campaign-attempt-warning');
  };

  // Closes the campaign rules page
  const handleCloseRules = () => {
    handleShowCampaignRules(false);
  };

  // Handles starting a new campaign attempt
  const handleCampaignAttempt = async () => {
    try {
      if (event && eventName && !event.ended) {
        handleIsFetchingCampaign(true);
        await startCampaignAttempt(eventName, false);
        removeFlashbar('campaign-attempt-warning');
        // Fetch the campaign attempt
        await getCampaignEventDetails(event?.eventName, false, true);

        // Reload the challenge data so that the challenges unlock after
        // creating a new campaign attempt
        await loadJamChallengeData({ id: eventName, showLoader: false });
      }
    } catch (err) {
      addErrorFlashbar(getErrorMessage(err));
    } finally {
      handleIsFetchingCampaign(false);
    }
  };

  // Creates the button for the campaign attempt warning banner.
  // Only render the button if a user is allowed to create a new campaign attempt.
  const addFlashbarAction = (): JSX.Element | undefined => {
    if (isCampaign && campaignAttempt && campaignAttempt.allowedAttempts && campaignAttempt.attemptNumber) {
      if (campaignAttempt.allowedAttempts > campaignAttempt.attemptNumber) {
        return <Button onClick={() => void handleCampaignAttempt()}>Start new attempt</Button>;
      }
    }
  };

  if (isFetchingJamChallenge) {
    return <LoadingBar />;
  }

  // Determine if the campaign attempt flashbar has rendered
  const campaignAttemptFlashbardHasRendered =
    flashbars.find((flashbar) => flashbar.id === 'campaign-attempt-warning') !== undefined;
  useEffect(() => {
    if (
      isCampaign &&
      campaignAttempt &&
      campaignAttempt.attemptNumber > 0 &&
      isExpired &&
      !campaignAttemptFlashbardHasRendered &&
      !shouldShowJamCampaignRules &&
      !event?.ended
    ) {
      addFlashbar({
        content: t(i18nKeys.campaigns.headers.campaignDetails.descriptions.deadlineForAttemptPassed, {
          allowedAttempts: campaignAttempt.allowedAttempts,
          currentAttemptNumber: campaignAttempt.attemptNumber,
        }),
        type: FlashbarType.WARNING,
        action: addFlashbarAction(),
        dismissible: false,
        i18nKeys: [],
        id: 'campaign-attempt-warning',
      });
    }
  }, [campaignAttempt, showCampaignRules, shouldShowJamCampaignRules, event]);

  return (
    <React.Fragment>
      {shouldShowJamCampaignRules ? (
        <JamCampaignRules event={event} campaignAttempt={campaignAttempt} handleCloseRules={handleCloseRules} />
      ) : (
        <ContentLayout
          header={
            <Header
              variant="h1"
              description={t(i18nKeys.myJams.challenges.subtitle)}
              actions={
                isCampaign && !isSupportUser && !isAdmin && !event?.ended ? (
                  <Button onClick={handleOpenRules} variant="primary" data-testid="openCampaignRulesButton">
                    {t(i18nKeys.campaigns.rules.title)}
                  </Button>
                ) : null
              }>
              {t(i18nKeys.myJams.challenges.title)}
            </Header>
          }>
          <SpaceBetween direction="vertical" size="xxl">
            <GlobalInfo />
            <ChallengeDetails />
          </SpaceBetween>
        </ContentLayout>
      )}
    </React.Fragment>
  );
};
export default JamChallenges;
