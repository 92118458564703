import { create } from 'zustand';
import { devtools, persist } from 'zustand/middleware';
import { StateCreator } from 'zustand/vanilla';

export const createStore = <T extends object>(name: string | null, initializer: StateCreator<T>) => {
  if (name) {
    return create<T>()(devtools(persist(initializer, { name })));
  }

  return create<T>()(devtools(initializer));
};
