import { useCollection } from '@amzn/awsui-collection-hooks';
import {
  Button,
  CollectionPreferencesProps,
  ExpandableSection,
  Pagination,
  SpaceBetween,
  Table,
  TextFilter,
} from '@amzn/awsui-components-react';
import React, { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { Event } from '../../../types/Event';
import { Team } from '../../../types/Team';
import { i18nKeys } from '../../../utils/i18n.utils';
import { paginationLabels } from '../../../utils/table.utils';
import { TimezoneFormat } from '../CommonModel';
import { TableEmptyState } from '../TableEmptyState';
import { COLUMN_DEFINITIONS, filteringFunction } from './table-teams-config';

interface TeamTableProps {
  target: Event;
  teams: Team[];
  toggleTeamDetails: (teamName: string) => void;
  setCreateTeamsVisible: Dispatch<SetStateAction<boolean>>;
  setCreateTeamVisible: Dispatch<SetStateAction<boolean>>;
}

export const TeamTable: React.FC<TeamTableProps> = ({
  target,
  teams,
  toggleTeamDetails,
  setCreateTeamsVisible,
  setCreateTeamVisible,
}) => {
  const { t } = useTranslation();

  const preferences: CollectionPreferencesProps.Preferences = { pageSize: 10, custom: TimezoneFormat.LOCAL };

  const { items, actions, filteredItemsCount, collectionProps, filterProps, paginationProps } = useCollection(
    teams || [],
    {
      filtering: {
        filteringFunction,
        empty: (
          <TableEmptyState
            title={t(i18nKeys.participants.labels.empty.teams.title)}
            subtitle={t(i18nKeys.participants.labels.empty.teams.subtitle)}
          />
        ),
        noMatch: (
          <TableEmptyState
            title={t(i18nKeys.tables.noMatch.title)}
            subtitle={t(i18nKeys.tables.noMatch.subtitle)}
            onClearFilter={() => actions.setFiltering('')}
          />
        ),
      },
      pagination: { pageSize: preferences.pageSize },
      sorting: {},
    }
  );

  return (
    <ExpandableSection
      data-testid="participant-group-section"
      defaultExpanded
      variant="container"
      headerText={t(i18nKeys.participants.headers.teams)}
      headerCounter={`(${String(teams.length)})`}
      headerActions={
        <SpaceBetween key="space-between" direction="horizontal" size="s">
          {!target?.isCampaignEvent && (
            <Button key="bulk-create-team" onClick={() => setCreateTeamsVisible(true)}>
              {t(i18nKeys.participants.buttons.bulkCreateTeam)}
            </Button>
          )}
          {!target?.isCampaignEvent && (
            <Button key="create-team" variant="primary" onClick={() => setCreateTeamVisible(true)}>
              {t(i18nKeys.participants.buttons.createTeam)}
            </Button>
          )}
        </SpaceBetween>
      }
    >
      <Table
        variant="borderless"
        className="mb-12"
        {...collectionProps}
        pagination={<Pagination {...paginationProps} ariaLabels={paginationLabels(t)} />}
        items={items}
        filter={
          <SpaceBetween direction="horizontal" size="s">
            <TextFilter
              className="text-filter"
              filteringPlaceholder={t(i18nKeys.participants.labels.searchTeams)}
              {...filterProps}
              countText={t(i18nKeys.tables.matchesCount, { count: filteredItemsCount })}
              filteringAriaLabel={t(i18nKeys.events.filteringLabel)}
            />
          </SpaceBetween>
        }
        columnDefinitions={COLUMN_DEFINITIONS(target, toggleTeamDetails)}
      />
    </ExpandableSection>
  );
};
