import { Box, Container, Header } from '@amzn/awsui-components-react';
import { Alert } from '@amzn/awsui-components-react';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Challenge } from '../../../../types/Challenge';
import { i18nKeys } from '../../../../utils/i18n.utils';

interface ChallengeFacilitatorNotesDetailProps {
  challenge: Challenge;
}

const ChallengeFacilitatorNotesDetail: React.FC<ChallengeFacilitatorNotesDetailProps> = ({ challenge }) => {
  const { t } = useTranslation();

  return (
    <Container
      header={<Header variant="h2">{t(i18nKeys.challenges.challengeDetails.headings.facilitatorNotes)}</Header>}>
      <Box>
        {challenge.props.facilitatorNotes ? (
          <div
            className="awsui-util-formatted-text"
            style={{ whiteSpace: 'pre-wrap' }}
          >
            {challenge.props.facilitatorNotes}
          </div>
        ) : (
          <Alert type="info">
            {t(i18nKeys.challenges.challengeDetails.field.facilitatorNotes.emptyState)}
          </Alert>
        )}
      </Box>
    </Container>
  );
};

export default ChallengeFacilitatorNotesDetail;
