import { createStore } from '@/src/hooks/store/createStore';

interface LoadingState {
  loading: boolean;
  setLoading: (loading: boolean) => void;
}

export const useLoading = createStore<LoadingState>(null, (set) => ({
  loading: false,
  setLoading: (loading) => set({ loading }),
}));
