import { EventReport } from '@/src/components/events/eventDetailsSections/Report/EventReport';
import { useEventsApi } from '@/src/hooks/api/useEventsApi';
import { TinyEvent } from '@/src/types/Event';
import { createDateRangePickerI18nStrings, getDateRange, isValidRange } from '@/src/utils/date-range-picker.utils';
import { i18nKeys } from '@/src/utils/i18n.utils';
import {
  ColumnLayout,
  DateRangePicker,
  DateRangePickerProps,
  Select,
  SpaceBetween,
  Spinner,
} from '@amzn/awsui-components-react';
import { OptionDefinition } from '@amzn/awsui-components-react/polaris/internal/components/option/interfaces';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

const relativeOptions: DateRangePickerProps.RelativeOption[] = [
  {
    key: 'previous-7-days',
    amount: 7,
    unit: 'day',
    type: 'relative',
  },
  {
    key: 'previous-1-month',
    amount: 1,
    unit: 'month',
    type: 'relative',
  },
  {
    key: 'previous-3-months',
    amount: 3,
    unit: 'month',
    type: 'relative',
  },
  {
    key: 'previous-6-months',
    amount: 6,
    unit: 'month',
    type: 'relative',
  },
  {
    key: 'previous-1-year',
    amount: 1,
    unit: 'year',
    type: 'relative',
  },
];

export const EventReportContainer: React.FC = () => {
  const { t } = useTranslation();
  const i18nStrings = useMemo(() => createDateRangePickerI18nStrings(t), [t]);
  const [dateRange, setDateRange] = useState<DateRangePickerProps.Value | null>(relativeOptions[1]);
  const [events, setEvents] = useState<TinyEvent[]>([]);
  const [selectedOption, setSelectedOption] = useState<OptionDefinition | null>(null);
  const [loading, setLoading] = useState(false);
  const eventsApi = useEventsApi();

  const loadEvents = async () => {
    if (!dateRange) {
      return;
    }

    setLoading(true);
    try {
      const range = getDateRange(dateRange);

      const newEvents = await eventsApi.getTinyEvents({
        dateRangeStart: range.startDate,
        dateRangeEnd: range.endDate,
      });

      setEvents(newEvents);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    void loadEvents();
  }, [dateRange]);

  const selectOptions = useMemo<OptionDefinition[]>(() => {
    return events.map((event) => ({ value: event.name, label: event.title || event.name }));
  }, [events]);

  return (
    <ColumnLayout>
      <SpaceBetween direction="horizontal" size="s" alignItems="center">
        <DateRangePicker
          isValidRange={(range) => isValidRange(range, t)}
          value={dateRange}
          onChange={({ detail }) => setDateRange(detail.value)}
          relativeOptions={relativeOptions}
          placeholder={t(i18nKeys.eventReportContainer.dateRangePickerPlaceholder)}
          i18nStrings={i18nStrings}
          data-testid="report-data-range-picker"
        />
        <Select
          placeholder={t(i18nKeys.eventReportContainer.eventSelectPlaceholder)}
          selectedOption={selectedOption}
          options={selectOptions}
          disabled={!selectOptions.length}
          onChange={({ detail }) => setSelectedOption(detail.selectedOption)}
          data-testid="report-select-event"
        />
        {loading && <Spinner size="big" />}
      </SpaceBetween>
      {!!selectedOption?.value && <EventReport eventName={selectedOption?.value} />}
    </ColumnLayout>
  );
};
