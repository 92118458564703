import React, { Dispatch, SetStateAction, useCallback, useMemo, MutableRefObject } from 'react';
import { useTranslation } from 'react-i18next';
import {
  TableProps,
  Button,
  PropertyFilterProps,
  CollectionPreferencesProps,
  SpaceBetween,
  Toggle,
  Popover,
  Link,
  Icon,
} from '@amzn/awsui-components-react';
import { PropertyFilterProperty } from '@amzn/awsui-collection-hooks';

// components
import { CustomTable } from '@/src/components/event-templates/CustomTable';
import { LoadingBar } from '@/src/components/common/LoadingBar';

import {
  challengeSetColumnDefinitions,
  collectionPreferencesPropsForChallengeSet,
  paginationLabels,
  visibleContentForChallengeSet,
} from './table-config';
import { ChallengeSet } from '@/src/types/ChallengeSet';
import { i18nKeys } from '@/src/utils/i18n.utils';
import { ChallengeListItem } from '@/src/types/Challenge';
import { SelectionType } from '../../../EventTemplateModel';

const { common } = i18nKeys.eventTemplates.tabs;
const { headerRightContentButton } = common;
interface IChallengesSetTableProps {
  data?: ChallengeSet[];
  propertyFilterRef: MutableRefObject<HTMLDivElement | null>;
  paginationRef: MutableRefObject<HTMLDivElement | null>;
  collectionPrefRef: MutableRefObject<HTMLDivElement | null>;
  challengeListItemMap: { [id: string]: ChallengeListItem };
  loading: boolean;
  showPreferences?: boolean;
  selectedItems?: ChallengeSet[];
  filterProps: PropertyFilterProperty[];
  preferences: CollectionPreferencesProps.Preferences;
  setPreferences?: Dispatch<CollectionPreferencesProps.Preferences>;
  challengeSetToggleEnable?: boolean;
  isChallengeSetToggleEnable?: Dispatch<SetStateAction<boolean>>;
  onSelectedItemsChange: (selectedChallenges: ChallengeSet[]) => void;
  onPropertyFilterPropsChange?: (propertyFilterProps: PropertyFilterProps) => void;
  onAllPageItemsChange?: (items: ChallengeSet[]) => void;
  onFilteredCountChange?: (count: number) => void;
  onToggleDetails: (challenge: ChallengeSet) => void;
  addChallenges?: () => void;
}

export const ChallengeSetTable: React.FC<IChallengesSetTableProps> = ({
  data,
  challengeListItemMap,
  loading,
  selectedItems,
  filterProps,
  preferences,
  setPreferences,
  showPreferences,
  challengeSetToggleEnable,
  isChallengeSetToggleEnable,
  onSelectedItemsChange,
  onPropertyFilterPropsChange,
  onAllPageItemsChange,
  onFilteredCountChange,
  onToggleDetails,
  addChallenges,
  propertyFilterRef,
  paginationRef,
  collectionPrefRef,
}) => {
  const { t } = useTranslation();

  const handleSelectedItemsChange = useCallback((item: ChallengeSet) => {
    if (selectedItems) {
      const selectedChallengeSetIndex = selectedItems.findIndex(
        (selectedItem: ChallengeSet) => selectedItem.id === item.id
      );
      if (selectedChallengeSetIndex !== -1) selectedItems.splice(selectedChallengeSetIndex, 1);
      else selectedItems.push(item);
      onSelectedItemsChange(selectedItems);
    }
  }, []);

  const props = useMemo(() => {
    return {
      visibleContent: visibleContentForChallengeSet,
      data: data || [],
      columnDefinitions: challengeSetColumnDefinitions(
        onToggleDetails,
        challengeListItemMap,
        handleSelectedItemsChange,
        t
      ) as TableProps.ColumnDefinition<ChallengeSet>[],
      headerTitle: t(i18nKeys.eventTemplates.tabs.challengeSetProps.headerTitle),
      filterPlaceholder: t(i18nKeys.eventTemplates.tabs.challengeSetProps.filterPlaceholder),
      collectionPreferencesProps: collectionPreferencesPropsForChallengeSet(
        onToggleDetails,
        challengeListItemMap,
        handleSelectedItemsChange,
        t
      ),
      headerRightContent: (
        <SpaceBetween direction="horizontal" size="l">
          <div style={{ marginTop: '0.5rem', display: 'flex' }}>
            <Toggle
              checked={!!challengeSetToggleEnable}
              onChange={({ detail }) => isChallengeSetToggleEnable && isChallengeSetToggleEnable(detail.checked)}>
              {t(i18nKeys.eventTemplates.table.header.actionToggleChallenges)}
            </Toggle>
            <div style={{ marginLeft: '0.3rem' }}>
              <Popover content={t(i18nKeys.eventTemplates.table.header.popoverContent)} triggerType="custom">
                <Link>
                  <Icon name="status-info" />
                </Link>
              </Popover>
            </div>
          </div>          
          <Button variant="primary" disabled={selectedItems?.length === 0} id="add-challenge-set-button" data-testid="add-challenge-set-button" onClick={addChallenges}>
            {t(headerRightContentButton)}
          </Button>
        </SpaceBetween>
      ),
      paginationLabels,
      selectionType: SelectionType.single,
    };
  }, [
    t,
    data,
    isChallengeSetToggleEnable,
    addChallenges,
    onToggleDetails,
    challengeListItemMap,
    handleSelectedItemsChange,
  ]);

  if (loading) {
    return <LoadingBar />;
  }

  return (
    <>
      <CustomTable
        {...props}
        noAction={showPreferences ? false : true}
        variant="borderless"
        propertyFilterRef={propertyFilterRef}
        paginationRef={paginationRef}
        collectionPrefRef={collectionPrefRef}
        filteringProperties={filterProps}
        selectedChallegeItems={selectedItems}
        preferences={preferences}
        setPreferences={(updatedPreferences) => setPreferences && setPreferences({ ...updatedPreferences })}
        propertyFilterPropsCb={onPropertyFilterPropsChange}
        allPageItemsCb={onAllPageItemsChange}
        selectedItemsCb={onSelectedItemsChange}
        filteredCountCb={onFilteredCountChange}
      />
    </>
  );
};
