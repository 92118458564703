import MarkDownPreviewEditor from "@/src/components/common/MarkdownPreviewEditor/MarkDownPreviewEditor";
import { useApi } from "@/src/store/api.context";
import { i18nKeys } from "@/src/utils/i18n.utils";
import { preProdLogger } from "@/src/utils/log.utils";
import { Alert, Box, Button, Header, Modal, SpaceBetween } from "@amzn/awsui-components-react";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";

const ResolveChallengeIssueModal = ({ issueId, visible, onDismiss, triggerReload }: { issueId: string; visible: boolean; onDismiss: () => void; triggerReload: () => void; }) => {
  const [comment, commentSet] = useState('');
  const [waiting, waitingSet] = useState(false);
  const [hasError, hasErrorSet] = useState(false);
  const { challengeIssuesApi } = useApi();
  const { t } = useTranslation();
  const submitHandler = useCallback(async () => {
    waitingSet(true);
    try {
      await challengeIssuesApi.updateChallengeIssueStatus(issueId, { status: "RESOLVED", resolutionComment: comment });
      waitingSet(false);
      onDismiss();
      triggerReload();
    } catch (e) {
      waitingSet(false);
      preProdLogger(e);
      hasErrorSet(true);
    }
  }, [challengeIssuesApi, issueId, comment, onDismiss, triggerReload]);

  return (
    <Modal
      header={<Header>{t(i18nKeys.challenges.issues.resolutionCommentHeader)}</Header>}
      size="large"
      onDismiss={onDismiss}
      data-testid="challenge-issue__resolve-challenge-modal"
      footer={<Box float="right">
        <SpaceBetween size="xxs" direction="horizontal">
          <Button
            onClick={onDismiss}
            data-testid="challenge-issue__resolve-challenge-cancel-btn"
          >{t(i18nKeys.general.cancel)}</Button>
          <Button
            loading={waiting}
            onClick={submitHandler}
            data-testid="challenge-issue__resolve-challenge-submit-btn"
          >{t(i18nKeys.general.submit)}</Button>
        </SpaceBetween>
      </Box>}
      visible={visible}>
        {hasError && <Alert type="error" dismissible onDismiss={() => hasErrorSet(false)}>
          {t(i18nKeys.challenges.issues.resolveModalError)}
        </Alert>}
        <Box variant="p" padding={{bottom: 's'}}>
          {t(i18nKeys.challenges.issues.instructionsContent)}
        </Box>
        <MarkDownPreviewEditor text={comment} onTextChanged={commentSet} />
    </Modal>
  );
};

export default ResolveChallengeIssueModal;