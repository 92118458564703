import { HOURS_PER_DAY, MINUTES_PER_HOUR, SECONDS_PER_MINUTE } from '../../../constants/shared/time';

import {
  Alert,
  Container,
  ExpandableSection,
  FormField,
  Grid,
  Header,
  SpaceBetween,
} from '@amzn/awsui-components-react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EditCampaignActions, useEditCampaign } from '../../../store/edit-campaign.context';
import { Campaign } from '../../../types/Campaign';
import { i18nKeys } from '../../../utils/i18n.utils';
import { getDuration } from '../../../utils/time.utils';
import { InputWithIcon } from '../../common/InputWithIcon/InputWithIcon';

interface CampaignTimeLimitProps {
  campaign: Campaign | undefined;
}

const CampaignTimeLimit: React.FC<CampaignTimeLimitProps> = ({ campaign }) => {
  const { t } = useTranslation();
  const { campaignEditMode, editedCampaign, handleUpdateEditCampaign, newCampaignMode, newEditedCampaign } =
    useEditCampaign();
  const [days, setDays] = useState(0);
  const [hours, setHours] = useState(1);

  useEffect(() => {
    if (campaignEditMode || newCampaignMode) {
      const totalHours =
        editedCampaign?.campaignSettings.sessionDurationLimitHours ||
        newEditedCampaign?.campaignSettings?.sessionDurationLimitHours ||
        0;

      const newDays = Math.floor(totalHours / HOURS_PER_DAY);
      const newHours = totalHours % HOURS_PER_DAY;
      setDays(newDays);
      setHours(newHours);
    }
  }, [campaignEditMode, newCampaignMode]);

  useEffect(() => {
    const totalHours = days * HOURS_PER_DAY + hours;

    handleUpdateEditCampaign(EditCampaignActions.TIME_LIMIT, totalHours);
  }, [days, hours]);

  const renderContent = () => {
    if (!campaignEditMode && !newCampaignMode) {
      return (
        <div className="section-first-row">
          <Grid gridDefinition={[{ colspan: 3 }, { colspan: 6 }]}>
            <div className="secondary-text">{t(i18nKeys.campaigns.labels.campaignDetails.timeLimit)}</div>
            <div>
              {campaign?.campaignSettings.sessionDurationLimitHours &&
                getDuration(
                  campaign?.campaignSettings.sessionDurationLimitHours * MINUTES_PER_HOUR * SECONDS_PER_MINUTE
                )}
            </div>
          </Grid>
        </div>
      );
    }

    const totalHours = days * HOURS_PER_DAY + hours;

    return (
      <FormField
        label={<strong>{t(i18nKeys.campaigns.labels.campaignDetails.timeLimit)}</strong>}
        data-testid="time-inputs"
      >
        <SpaceBetween direction="vertical" size="s">
          <SpaceBetween direction="horizontal" size="s">
            <InputWithIcon
              value={String(days)}
              type="number"
              disabled={false}
              icon={<span>{t(i18nKeys.campaigns.labels.campaignDetails.days)}</span>}
              onChange={(value) => setDays(Number(value.replaceAll('-', '')))}
              direction="horizontal"
            />
            <InputWithIcon
              value={String(hours)}
              type="number"
              disabled={false}
              icon={<span>{t(i18nKeys.campaigns.labels.campaignDetails.hours)}</span>}
              onChange={(value) => setHours(Number(value.replaceAll('-', '')))}
              direction="horizontal"
            />
          </SpaceBetween>
          {totalHours === 0 && <Alert type="error">{t(i18nKeys.campaigns.errors.minSessionTimeLimit)}</Alert>}
        </SpaceBetween>
      </FormField>
    );
  };

  const headerElement = (
    <Header variant="h2" description={t(i18nKeys.campaigns.headers.campaignDetails.descriptions.timeLimit)}>
      {t(i18nKeys.campaigns.headers.campaignDetails.timeLimit)}
    </Header>
  );

  return (
    <React.Fragment>
      {!newCampaignMode && (
        <ExpandableSection variant="container" header={headerElement}>
          {renderContent()}
        </ExpandableSection>
      )}
      {newCampaignMode && <Container header={headerElement}>{renderContent()}</Container>}
    </React.Fragment>
  );
};

export default CampaignTimeLimit;
