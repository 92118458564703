import { isPreProd } from './env.utils';
export interface SpoofedGroup {
  value: string;
  label: string;
}
export const SPOOFED_GROUP_KEY = '__jam_groups__';
export const getSpoofedGroups = (): string[] => {
  const spoofedGroups = localStorage.getItem(SPOOFED_GROUP_KEY);

  if (isPreProd() && spoofedGroups && spoofedGroups !== 'undefined') {
    return JSON.parse(localStorage.getItem(SPOOFED_GROUP_KEY) || '[]') as string[];
  }
  return [];
};
