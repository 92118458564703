import React, { ReactElement } from 'react';
import { i18nKeys } from '@/src/utils/i18n.utils';
import { useTranslation } from 'react-i18next';
import ChallengeDiffTable from '../challengeRevisionHistory/ChallengeDiff/ChallengeDiffTable';
import { Box, Container, Grid, Header, Select, SpaceBetween } from '@amzn/awsui-components-react';
import { Challenge } from '@/src/types/Challenge';
import { DiffChange } from '@/src/types/Diff';
import { KeyValue } from '@/src/components/common/KeyValue';
import { OptionDefinition } from '@amzn/awsui-components-react/polaris/internal/components/option/interfaces';
import { selectedRevisionDataProps } from '../challengeRevisionHistory/RevisionHistory';
import { getReviewStatus } from '../revision-list-config';
import { CopyToClipboard } from '@/src/components/common/CopyToClipboard';
import { getRevisionFormattedDate } from '@/src/utils/time.utils';
import JamSpinner from '@/src/components/common/JamSpinner';

interface RevisionHistoryDetailsProps {
  overviewDetailDiffs: DiffChange[],
  settingsDiffs: DiffChange[],
  learningOutcomeDiffs: DiffChange[],
  tasksDiffs: DiffChange[][],
  cfnTemplateDiffs: DiffChange[],
  iamPolicyDiffs: DiffChange[],
  wikiDiffs: DiffChange[],
  leftRevision: Challenge | null,
  containsDiff: boolean,
  selectedLeftRevision: OptionDefinition | null,
  selectedRightRevision: OptionDefinition | null,
  selectRevisionOptions: OptionDefinition[],
  handleLeftRevisionSelection: (details: any) => void,
  handleRightRevisionSelection: (details: any) => void,
  selectedLeftRevisionData: selectedRevisionDataProps,
  selectedRightRevisionData: selectedRevisionDataProps,
  navigationElement?: React.JSX.Element
}

const SelectContainer = ({ label, children }: { label: string; children: ReactElement }) => {
  return (
    <KeyValue
      label={
        <Box fontWeight="bold" margin={{ bottom: 'xxs' }}>
          {label}
        </Box>
      }>
      {children}
    </KeyValue>
  );
};

const RevisionOwnerAndDateContainer = ({ owner, createdAt }: { owner: string; createdAt: number }) => {
  return (
    <SpaceBetween direction="horizontal" size="l" alignItems="center">
      <Box variant="span">{getRevisionFormattedDate(new Date(createdAt))}</Box>
      <Box variant="span">
        <Box variant="span" color="text-status-info">
          {owner}
        </Box>
        <CopyToClipboard icon value={owner} />
      </Box>
    </SpaceBetween>
  );
};

const RevisionHistoryDetails: React.FC<RevisionHistoryDetailsProps> = ({
  overviewDetailDiffs,
  settingsDiffs,
  learningOutcomeDiffs,
  tasksDiffs,
  cfnTemplateDiffs,
  iamPolicyDiffs,
  wikiDiffs,
  leftRevision,
  containsDiff,
  selectedLeftRevision,
  selectedRightRevision,
  selectRevisionOptions,
  handleLeftRevisionSelection,
  handleRightRevisionSelection,
  selectedLeftRevisionData,
  selectedRightRevisionData,
  navigationElement
}) => {
  const { t } = useTranslation();

  if (!leftRevision) {
    return <JamSpinner />;
  }

  return (
    <SpaceBetween direction="vertical" size="m">
      {leftRevision && (
        <Container
          header={
            <Grid
              gridDefinition={[
                { colspan: 9 },
                { colspan: 3 }
              ]}
            >
              <Header>{t(i18nKeys.challenges.challengeDetails.headings.revisionDiffLabel)}</Header>
              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                {navigationElement}
              </div>
            </Grid>
          }
        >
          <SpaceBetween direction="vertical" size="s">
            <Grid gridDefinition={[{ colspan: 6 }, { colspan: 6 }]}>
              <SpaceBetween direction="vertical" size="s">
                <SelectContainer label={t(i18nKeys.challenges.challengeDetails.titles.left)}>
                  <Select
                    selectedOption={selectedLeftRevision}
                    options={selectRevisionOptions}
                    onChange={({ detail }) => handleLeftRevisionSelection(detail)}
                  />
                </SelectContainer>
                {getReviewStatus(selectedLeftRevisionData.status)}
                <RevisionOwnerAndDateContainer
                  owner={selectedLeftRevisionData.owner}
                  createdAt={selectedLeftRevisionData?.createdAt}
                />
              </SpaceBetween>
              <SpaceBetween direction="vertical" size="s">
                <SelectContainer label={t(i18nKeys.challenges.challengeDetails.titles.right)}>
                  <Select
                    selectedOption={selectedRightRevision}
                    options={selectRevisionOptions}
                    onChange={({ detail }) => handleRightRevisionSelection(detail)}
                  />
                </SelectContainer>
                {getReviewStatus(selectedRightRevisionData.status)}
                <RevisionOwnerAndDateContainer
                  owner={selectedRightRevisionData.owner}
                  createdAt={selectedRightRevisionData?.createdAt}
                />
              </SpaceBetween>
            </Grid>
          </SpaceBetween>
        </Container>
      )}
      {overviewDetailDiffs.length > 0 && (
        <ChallengeDiffTable
          tableHeader={t(i18nKeys.challenges.challengeDetails.headings.details)}
          challengeDiffs={overviewDetailDiffs}
        />
      )}
      {settingsDiffs.length > 0 && (
        <ChallengeDiffTable
          tableHeader={t(i18nKeys.challenges.challengeDetails.headings.detailsSetting)}
          challengeDiffs={settingsDiffs}
        />
      )}
      {learningOutcomeDiffs.length > 0 && (
        <ChallengeDiffTable
          tableHeader={t(i18nKeys.challenges.challengeDetails.headings.learningOutcome)}
          challengeDiffs={learningOutcomeDiffs}
        />
      )}
      {tasksDiffs.map(
        (taskDiff, i) =>
          taskDiff.length > 0 && (
            <ChallengeDiffTable
              key={i}
              tableHeader={t(i18nKeys.challenges.challengeDetails.headings.task, { count: i + 1 })}
              challengeDiffs={taskDiff}
            />
          )
      )}
      {cfnTemplateDiffs.length > 0 && (
        <ChallengeDiffTable
          tableHeader={t(i18nKeys.challenges.challengeDetails.headings.cloudformationTemplate)}
          challengeDiffs={cfnTemplateDiffs}
        />
      )}
      {iamPolicyDiffs.length > 0 && (
        <ChallengeDiffTable
          tableHeader={t(i18nKeys.challenges.challengeDetails.headings.teamIAMPolicy)}
          challengeDiffs={iamPolicyDiffs}
        />
      )}
      {wikiDiffs.length > 0 && (
        <ChallengeDiffTable
          tableHeader={t(i18nKeys.challenges.challengeDetails.headings.wiki)}
          challengeDiffs={wikiDiffs}
        />
      )}
      {leftRevision && !containsDiff && (
        <Container>{t(i18nKeys.challenges.challengeDetails.messages.noDifferenceRevision)}</Container>
      )}
    </SpaceBetween>
  );
};

export default RevisionHistoryDetails;
