import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import i18n from '@/src/i18n';
import { generateFormatForCurrency, i18nKeys } from '@/src/utils/i18n.utils';
import { Moment } from 'moment';
import {
  Box,
  Button,
  Container,
  DatePicker,
  Form,
  FormField,
  Input,
  Select,
  SpaceBetween,
  Spinner,
  TextContent,
  TimeInput,
} from '@amzn/awsui-components-react';
import { OptionDefinition } from '@amzn/awsui-components-react/polaris/internal/components/option/interfaces';
import { KeyValue } from '@/src/components/common/KeyValue';
import { MeridiemOptions } from '@/src/constants/DateTimeConstants';
import { EditEventActions, useEditEvent } from '@/src/store/edit-event.context';
import { eventCatalogTemplateDetailsValidator } from '@/src/utils/event-template.validation.utils';
import { EventCatalogTemplateDetailsFields, EventLearningType } from '@/src/types/EventTemplate';
import { ChallengeDescriptor } from '@/src/types/Challenge';
import { useEditEventTemplate } from '@/src/store/edit-event-template.context';
import { useApi } from '@/src/store/api.context';
import { fromPlainObject } from '@/src/utils/mapper.utils';
import {
  BackupChallengeConfig,
  Event,
  EventPermission,
  EventPermissionType,
  JamEventRequest,
  TeamAssignmentType,
} from '@/src/types/Event';
import { RoutePath } from '@/src/RoutePath';
import { preProdLogger } from '@/src/utils/log.utils';
import { getLanguageCodeSafe } from '@/src/utils/locale.utils';
import {
  convert24To12HourTime,
  getBrowserTimezoneName,
  getTimeForPolarisTimePicker,
  getTimeInBrowserLocalTime,
  getTimezonesWithUTCOffsetAsOptionList,
  polarisDateTimeToMoment,
} from '@/src/utils/event-time.utils';
import { useUser } from '@/src/store/user.context';
import './PlanEvent.scss';
import { useAuth } from '@/src/store/auth.context';
import { EventAudienceType, EventType } from '@/src/constants/shared';
import { isDateNotInThePast } from '@/src/utils/common.utils';
import { EventPrivacyType } from '@/src/types/EventPrivacyType';
import { useEvents } from '@/src/store/events.context';
import { customEventTrigger } from '@/src/components/analytics/createEventTrigger';
import { EVENT_DETAILS_ROUTES } from '@/src/routes';

interface IHandleOnChange {
  detail: { value: string };
}

interface IBooking {
  input: string;
  total: number;
  date: string;
  time: string;
  timeZone: OptionDefinition | null;
  timeFormat: OptionDefinition;
}

interface PlanEventProps {
  onAddFlashbar: (error: string) => void;
}

const PlanEvent: React.FC<PlanEventProps> = ({ onAddFlashbar }) => {
  const history = useHistory();
  const { t } = useTranslation();
  const { eventsApi } = useApi();
  const { user } = useUser();
  const { authClient } = useAuth();
  const { event } = useEvents();
  const { destroyEdits, newEditedEvent, handleUpdateEditEvent, initializeNewEvent } = useEditEvent();
  const { eventTemplate, getEventDurationInfo } = useEditEventTemplate();
  const [headCountError, setHeadCountError] = useState('');
  const [startDateError, setStartDateError] = useState('');
  const [startTimeError, setStartTimeError] = useState('');
  const [loading, setLoading] = useState<boolean>(false);
  const languageCode: string = getLanguageCodeSafe(i18n.language);
  const hours = getEventDurationInfo()?.hours;
  const challengesLimit = getEventDurationInfo()?.minChallenges;
  const primaryChallenges = eventTemplate?.challengeList ? eventTemplate?.challengeList?.slice(0, challengesLimit) : [];
  const backupChallenges = eventTemplate?.challengeList ? eventTemplate?.challengeList?.slice(challengesLimit) : [];
  const timezoneOptions = getTimezonesWithUTCOffsetAsOptionList();

  useEffect(() => {
    setBooking({ ...booking, total: eventTemplate?.price ?? 150 });
  }, [eventTemplate]);

  useEffect(() => {
    if (!newEditedEvent) {
      initializeNewEvent();
    }
  }, [newEditedEvent]);

  useEffect(() => {
    if (event) {
      const newBooking = { ...booking };
      newBooking.input = `${event.maxExpectedParticipants}`;

      const { startDate, startTime, timeFormat } = getSelectedStartDateAndTime();
      newBooking.date = startDate;
      newBooking.time = startTime;
      newBooking.timeFormat = timeFormat;
      newBooking.timeZone = getTimeZone() ?? null;

      setBooking(newBooking);
    }
  }, [event]);

  const getTimeZone = () => {
    const defaultTimezone = event?.timezone ?? getBrowserTimezoneName();

    const selectedTimezone = timezoneOptions.find((e) => e.value === defaultTimezone);
    return selectedTimezone;
  };

  const [booking, setBooking] = useState<IBooking>({
    input: '',
    total: 0,
    date: '',
    time: '',
    timeZone: getTimeZone() ?? null,
    timeFormat: MeridiemOptions.AM,
  });
  const instantQuotePrice = Number(booking.input) ? booking.total * Number(booking.input) : 0;

  const getSelectedStartDateAndTime = () => {
    let startDate = '';
    let timeFormat = MeridiemOptions.AM;
    let startTime = '';
    if (event?.startDate) {
      // Polaris DatePicker component does not properly parse full startDate with time
      // Spliting here fixes default selection for DatePicker
      startDate = event?.startDate.split('T')[0];
      const startTimeFromEvent = getTimeInBrowserLocalTime(event?.startDate, {
        includeDate: false,
        includeTime: true,
      });
      const dateSelectorTime = getTimeForPolarisTimePicker(event?.startDate);

      if (startTimeFromEvent && dateSelectorTime) {
        const convertedTimeWithMeridian = convert24To12HourTime(startTimeFromEvent, true);
        if (convertedTimeWithMeridian.includes('PM')) {
          timeFormat = MeridiemOptions.PM;
        } else {
          timeFormat = MeridiemOptions.AM;
        }
        startTime = convert24To12HourTime(startTimeFromEvent, false);
      }
    }
    return { startDate, timeFormat, startTime };
  };

  const handleInput = ({ detail }: IHandleOnChange) => {
    setBooking({ ...booking, input: detail.value });
  };

  const handleTimezoneSelection = (optionSelection: OptionDefinition) => {
    setBooking({ ...booking, timeZone: optionSelection });
    handleUpdateEditEvent(EditEventActions.TIMEZONE, optionSelection.value);
  };

  const validator = useMemo(() => {
    return eventCatalogTemplateDetailsValidator(
      booking.input,
      eventTemplate?.minParticipants || 2,
      eventTemplate?.maxParticipants || 200,
      {
        required: t(i18nKeys.eventTemplates.catalogDetails.headCount.error),
        minimum: t(i18nKeys.eventTemplates.catalogDetails.headCount.minimumError),
        maximum: t(i18nKeys.eventTemplates.catalogDetails.headCount.maximumError),
      },
      booking.date,
      {
        required: t(i18nKeys.eventTemplates.catalogDetails.startDate.error),
        minimum: t(i18nKeys.eventTemplates.catalogDetails.startDate.minimum),
      },
      booking.time,
      {
        required: t(i18nKeys.eventTemplates.catalogDetails.startTime.error),
        minimum: t(i18nKeys.eventTemplates.catalogDetails.startTime.minimum),
      },
      booking.timeFormat?.value || 'AM',
      booking.timeZone?.value || getBrowserTimezoneName() || '',
      user?.isOnlyBasicUser ? 12 : 0,
      new Map<EventCatalogTemplateDetailsFields, (error: string) => void>([
        [EventCatalogTemplateDetailsFields.HEAD_COUNT, (error: string) => setHeadCountError(error)],
        [EventCatalogTemplateDetailsFields.START_DATE, (error: string) => setStartDateError(error)],
        [EventCatalogTemplateDetailsFields.START_TIME, (error: string) => setStartTimeError(error)],
      ])
    );
  }, [
    user,
    booking.input,
    booking.date,
    booking.time,
    booking.timeFormat,
    booking.timeZone,
    eventCatalogTemplateDetailsValidator,
  ]);

  const handleSignInClick = useCallback(() => {
    void authClient.signIn(history.location);
  }, [authClient, history]);

  const handleCreateEvent = () => {
    onAddFlashbar('');
    const result = validator.isValidSection(true);
    if (!result) return;
    if (!user) {
      handleSignInClick();
      return;
    }
    customEventTrigger('click', 'Go To Checkout', window.location.href, 'Go To Checkout', {});
    const startDateTime: Moment | string = polarisDateTimeToMoment(
      booking.date,
      `${booking.time} ${booking.timeFormat.value}`,
      booking.timeZone?.value ?? ''
    ).format();

    const endDateTime: Moment | string = polarisDateTimeToMoment(
      booking.date,
      `${booking.time} ${booking.timeFormat.value}`,
      booking.timeZone?.value ?? ''
    )
      .add(hours, 'hours')
      .format();

    if (newEditedEvent) {
      const newCreatorEmail: string = user?.email || '';
      newEditedEvent.title = eventTemplate?.name ?? '';
      newEditedEvent.createdDate = Date.now();
      newEditedEvent.startDate = startDateTime.toString();
      newEditedEvent.endDate = endDateTime.toString();
      newEditedEvent.timezone = booking.timeZone?.value ?? '';
      newEditedEvent.minExpectedParticipants = Number(booking.input);
      newEditedEvent.maxExpectedParticipants = Number(booking.input);
      // Set audienceType as skill-builder for subscribed user & federate user only
      newEditedEvent.audienceType = user.isAmazonian
        ? EventAudienceType.INTERNAL
        : EventAudienceType.SKILL_BUILDER_EVENT;
      newEditedEvent.teamAssignmentType = TeamAssignmentType.SELF_FORMING;
      newEditedEvent.formTeamsMinsBeforeEventStart = 90;
      newEditedEvent.catalogId = eventTemplate?.id ?? '';
      newEditedEvent.eventPrivacyType = EventPrivacyType.PRIVATE_INVITE;
      newEditedEvent.autoUnlockChallengesOffsetMinutes = 0;

      if (eventTemplate?.learningType === EventLearningType.INDIVIDUAL) {
        newEditedEvent.type = EventType.SPL;
        newEditedEvent.maxTeamSize = 1;
        newEditedEvent.collaborationOptions.teamChatEnabled = false;
        newEditedEvent.codeWhispererDisabled = true;
      }

      newEditedEvent.challengeDescriptors = primaryChallenges.map((challenge) =>
        ChallengeDescriptor.fromChallenge(challenge)
      );
      newEditedEvent.eventPermissions = [
        fromPlainObject(
          {
            email: newCreatorEmail,
            eventPermissionType: EventPermissionType.OWNER,
          },
          EventPermission
        ) as EventPermission,
      ];
      if (!newEditedEvent.backupChallengeConfig) {
        newEditedEvent.backupChallengeConfig = new BackupChallengeConfig();
      }
      newEditedEvent.backupChallengeConfig.generalBackups = backupChallenges.map((challenge) =>
        ChallengeDescriptor.fromChallenge(challenge)
      );

      setLoading(true);
      const jamEventRequest = fromPlainObject(newEditedEvent, JamEventRequest) as JamEventRequest;
      eventsApi
        .catalogEventRequest(jamEventRequest)
        .then((_res: Event) => {
          if (user.isAmazonian || user.isSubscribedUser) {
            history.replace(EVENT_DETAILS_ROUTES.Summary.resolve(_res.name));
          } else {
            history.replace(RoutePath.EVENTS);
          }
          destroyEdits();
          setLoading(false);
        })
        .catch((err) => {
          onAddFlashbar(err.message as unknown as string);
          setLoading(false);
          preProdLogger(err);
        });
    }
  };

  // identity user as subscribed or jam-admin user
  const isSubscribedOrAdminUser = user?.isSubscribedUser || user?.isSuperAdmin;
  // identity user as subscribed or faderate user
  const isSubscribedOrFederateUser = user?.isSubscribedUser || user?.isAmazonian;

  if (!user?.canScheduleAnEvent) return <UnsubcribedUserWidget />;

  return (
    <Form>
      <Container>
        <SpaceBetween direction="vertical" size="s">
          <Box>
            <Box variant="h1" tagOverride='h2'>{t(i18nKeys.eventTemplates.catalogDetails.planEvent.title)}</Box>
            <Box variant="h3" data-testid="pricePerPerson">
              <span style={{ textDecoration: isSubscribedOrAdminUser ? 'line-through' : undefined }}>
                {t(i18nKeys.catalog.pricePerPerson, { price: generateFormatForCurrency('$', booking.total) })}
              </span>
            </Box>
            <Box data-classname="free-subscription" color="text-status-success" variant='h4'>
              {t(i18nKeys.eventTemplates.catalogDetails.planEvent.caption)}
            </Box>
          </Box>

          <SpaceBetween direction="vertical" size="xxxs">
            <Box className="booking-container" padding={{ horizontal: 's' }}>
              <Box className="justify-between">
                <div>
                  <Box variant="h4">{t(i18nKeys.eventTemplates.catalogDetails.headCount.title)}</Box>
                  <Box color="text-status-inactive" fontSize="body-s">
                    {t(i18nKeys.eventTemplates.catalogDetails.headCount.subtitle, {
                      min: eventTemplate?.minParticipants || '2',
                      max: eventTemplate?.maxParticipants || '200',
                    })}
                  </Box>
                </div>
                <FormField i18nStrings={{ errorIconAriaLabel: t(i18nKeys.general.error) }} errorText={headCountError}>
                  <Input
                    onChange={handleInput}
                    value={booking.input}
                    ariaLabel="participants-input"
                    data-testid="num-participants-input"
                    className="participants-input"
                    id="num-participants-input"
                    onBlur={() => validator.isValidField(EventCatalogTemplateDetailsFields.HEAD_COUNT)}
                  />
                </FormField>
              </Box>
            </Box>
            <Box className="booking-container" padding={{ horizontal: 's' }}>
              <Box variant="h4">{t(i18nKeys.eventTemplates.catalogDetails.dateAndTime.title)}</Box>
              <SpaceBetween direction="vertical" size="s">
                <Box variant="div">
                  <FormField
                    label={t(i18nKeys.eventTemplates.catalogDetails.startDate.title)}
                    i18nStrings={{ errorIconAriaLabel: t(i18nKeys.general.error) }}
                    errorText={startDateError}>
                    <DatePicker
                      id="start-date-input-box"
                      data-testid="start-date-input-box"
                      onChange={({ detail }) => setBooking({ ...booking, date: detail.value })}
                      value={booking.date}
                      openCalendarAriaLabel={(selectedDate) =>
                        t(i18nKeys.general.chooseDate) +
                        (selectedDate ? t(i18nKeys.general.selectedDate, { selectedDate }) : '')
                      }
                      placeholder={t(i18nKeys.eventTemplates.catalogDetails.dateFormat)}
                      locale={languageCode}
                      previousMonthAriaLabel={t(i18nKeys.events.fields.filters.labels.previousMonth)}
                      nextMonthAriaLabel={t(i18nKeys.events.fields.filters.labels.nextMonth)}
                      todayAriaLabel={t(i18nKeys.events.fields.filters.labels.today)}
                      onBlur={() =>
                        validator.isValidField(EventCatalogTemplateDetailsFields.START_DATE) &&
                        validator.isValidField(EventCatalogTemplateDetailsFields.START_TIME)
                      }
                      isDateEnabled={isDateNotInThePast}
                    />
                  </FormField>
                  <div style={{ display: 'inline-flex', margin: '5px 0' }}>
                    <FormField
                      i18nStrings={{ errorIconAriaLabel: t(i18nKeys.general.error) }}
                      errorText={startTimeError}>
                      <TimeInput
                        data-testid="startTimeInput"
                        value={booking.time}
                        placeholder="hh:mm"
                        format="hh:mm"
                        onChange={({ detail }) => setBooking({ ...booking, time: detail.value })}
                        onBlur={() =>
                          validator.isValidField(EventCatalogTemplateDetailsFields.START_DATE) &&
                          validator.isValidField(EventCatalogTemplateDetailsFields.START_TIME)
                        }
                      />
                    </FormField>
                    <div style={{ width: '10px' }} />
                    <FormField i18nStrings={{ errorIconAriaLabel: t(i18nKeys.general.error) }} errorText="">
                      <Select
                        data-testid="selectAmPm"
                        className="inline-select"
                        options={[MeridiemOptions.AM, MeridiemOptions.PM]}
                        onChange={({ detail }) => setBooking({ ...booking, timeFormat: detail.selectedOption })}
                        onBlur={() =>
                          validator.isValidField(EventCatalogTemplateDetailsFields.START_DATE) &&
                          validator.isValidField(EventCatalogTemplateDetailsFields.START_TIME)
                        }
                        selectedOption={booking.timeFormat}
                      />
                    </FormField>
                  </div>
                  {user?.isOnlyBasicUser && (
                    <p style={{ color: '#8e9aa5', fontSize: '12px', lineHeight: '16px', letterSpacing: 'normal' }}>
                      {t(i18nKeys.eventTemplates.catalogDetails.startTime.minimum)}
                    </p>
                  )}
                </Box>
                <Box variant="div">
                  <KeyValue label={<b>{t(i18nKeys.events.eventDetails.labels.timezone)}</b>}>
                    <Select
                      data-testid="selectTimeZone"
                      selectedOption={booking.timeZone}
                      options={timezoneOptions}
                      onChange={({ detail }) => handleTimezoneSelection(detail.selectedOption)}
                    />
                  </KeyValue>
                </Box>
              </SpaceBetween>
            </Box>
          </SpaceBetween>
          <Box className="justify-between">
            <Box variant="h3">{t(i18nKeys.eventTemplates.catalogDetails.planEvent.instantQuote)}</Box>
            <Box variant="h3" data-testid="instantQuote">
              {isSubscribedOrAdminUser
                ? t(i18nKeys.catalog.free)
                : instantQuotePrice
                ? `$${(instantQuotePrice * 1.0).toLocaleString('en-US')}`
                : ''}
            </Box>
          </Box>
          {!isSubscribedOrAdminUser && (
            <Box fontSize="body-s" textAlign="center" data-classname="free-perks">
              {t(i18nKeys.eventTemplates.catalogDetails.planEvent.freePerk)}
            </Box>
          )}
            <Button variant="primary" fullWidth loading={loading}
              id="checkout-button"
              data-testid="checkout-button"
              loadingText={t(i18nKeys.general.loading)}
              onClick={handleCreateEvent}>
              {isSubscribedOrAdminUser ? (
                t(i18nKeys.eventTemplates.catalogDetails.submitButton.confirm)
              ) : (
                t(i18nKeys.eventTemplates.catalogDetails.submitButton.title)
              )}
            </Button>
          <Box variant="small" fontWeight="light" color="text-label">
            {isSubscribedOrFederateUser
              ? t(i18nKeys.eventTemplates.catalogDetails.bookingTextForSBTSUser.description)
              : t(i18nKeys.eventTemplates.catalogDetails.bookingText.description)}
          </Box>
        </SpaceBetween>
      </Container>
    </Form>
  );
};

const UnsubcribedUserWidget = () => {
  const { t } = useTranslation();
  const { authClient } = useAuth();
  const { isLoggedIn } = useUser();
  return (
    <Container>
      <SpaceBetween direction="vertical" size="m">
        <TextContent>
          <h3 className="inline">{t(i18nKeys.eventTemplates.unsubscribedEventWidget.content)}</h3> <a href="https://aws.amazon.com/training/digital/team-subscription/">{t(i18nKeys.general.learnMore)}</a>
        </TextContent>
        <Box>
          <Button href="https://skillbuilder.aws/subscriptions">{t(i18nKeys.eventTemplates.unsubscribedEventWidget.actionButtonLabel)}</Button>
        </Box>
        { !isLoggedIn && (<Box>{t(i18nKeys.eventTemplates.unsubscribedEventWidget.postscriptLine)} <Button variant='inline-link' onClick={() => void authClient.signIn()}>{t(i18nKeys.account.login.button)}</Button></Box>)}
      </SpaceBetween>
    </Container>
  );
};

export default PlanEvent;
