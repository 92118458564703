import React, { Dispatch, MutableRefObject, SetStateAction, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  TableProps,
  Button,
  PropertyFilterProps,
  CollectionPreferencesProps,
  SpaceBetween,
  Toggle,
  Popover,
  Link,
  Icon,
  Box,
} from '@amzn/awsui-components-react';
import { PropertyFilterProperty } from '@amzn/awsui-collection-hooks';

import { LoadingBar } from '@/src/components/common/LoadingBar';

// utils
import { i18nKeys } from '@/src/utils/i18n.utils';

import {
  collectionPreferencesProps,
  columnDefinitions,
  paginationLabels,
  visibleContentForChallenge,
} from './table-config';

import type { ChallengeListItem } from '@/src/types/Challenge';
import { SelectionType } from '../../../EventTemplateModel';
import { CustomChallengesTable } from '../../../CustomTable/CustomChallengesTable';

interface IChallengesTableProps {
  data?: ChallengeListItem[];
  loading: boolean;
  editMode?: boolean;
  propertyFilterRef: MutableRefObject<HTMLDivElement | null>;
  paginationRef: MutableRefObject<HTMLDivElement | null>;
  collectionPrefRef: MutableRefObject<HTMLDivElement | null>;
  showPreferences?: boolean;
  selectedItems: ChallengeListItem[];
  filterProps: PropertyFilterProperty[];
  preferences: CollectionPreferencesProps.Preferences;
  setPreferences?: Dispatch<CollectionPreferencesProps.Preferences>;
  challengeToggleEnable?: boolean;
  isChallengeToggleEnable?: Dispatch<SetStateAction<boolean>>;
  onSelectedItemsChange: (selectedChallenges: ChallengeListItem[]) => void;
  onPropertyFilterPropsChange?: (propertyFilterProps: PropertyFilterProps) => void;
  onAllPageItemsChange?: (items: ChallengeListItem[]) => void;
  onFilteredCountChange?: (count: number) => void;
  onToggleDetails: (challenge: ChallengeListItem) => void;
  addChallenges?: () => void;
  currPageIndex: number;
  numOfPages: number;
  challengesCount: number;
  onPageClick: (currentPageIndex: number) => void;
  // handleToggleChange: (checked: boolean) => void;
  setFilterValues: (details: PropertyFilterProps.Query, toggleChallengeEnable: boolean) => void;
  filterQuery: PropertyFilterProps.Query;
}

const { challengeProps, common } = i18nKeys.eventTemplates.tabs;
const { headerTitle, filterPlaceholder } = challengeProps;
const { headerRightContentButton } = common;

export const ChallengesTable: React.FC<IChallengesTableProps> = ({
  data,
  editMode,
  loading,
  selectedItems,
  filterProps,
  preferences,
  setPreferences,
  showPreferences,
  challengeToggleEnable,
  isChallengeToggleEnable,
  onSelectedItemsChange,
  onPropertyFilterPropsChange,
  onAllPageItemsChange,
  onFilteredCountChange,
  onToggleDetails,
  addChallenges,
  propertyFilterRef,
  paginationRef,
  collectionPrefRef,
  currPageIndex,
  numOfPages,
  challengesCount,
  onPageClick,
  setFilterValues,
  filterQuery,
}) => {
  const { t } = useTranslation();

  const props = useMemo(() => {
    return {
      visibleContent: visibleContentForChallenge,
      data: data || [],
      columnDefinitions: columnDefinitions(onToggleDetails, t) as TableProps.ColumnDefinition<ChallengeListItem>[],
      headerTitle: t(headerTitle),
      filterPlaceholder: t(filterPlaceholder),
      collectionPreferencesProps: collectionPreferencesProps(onToggleDetails, t),
      headerRightContent: (
        <Box float="right">
          <SpaceBetween direction="horizontal" size="l">
            {!editMode && (
              <div style={{ marginTop: '0.5rem', display: 'flex' }}>
                <Toggle
                  checked={!!challengeToggleEnable}
                  onChange={({ detail }) => isChallengeToggleEnable && isChallengeToggleEnable(detail.checked)}>
                  {t(i18nKeys.eventTemplates.table.header.actionToggleChallenges)}
                </Toggle>
                <div style={{ marginLeft: '0.3rem' }}>
                  <Popover content={t(i18nKeys.eventTemplates.table.header.popoverContent)} triggerType="custom">
                    <Link ariaLabel={t(i18nKeys.general.info)}>
                      <Icon name="status-info" alt={t(i18nKeys.general.info)} />
                    </Link>
                  </Popover>
                </div>
              </div>
            )}
            <Button variant="primary" id="add-challenges-button" data-testid="add-challenges-button" onClick={addChallenges}>{t(headerRightContentButton)}</Button>
          </SpaceBetween>
        </Box>
      ),
      paginationLabels,
      selectionType: SelectionType.multi,
    };
  }, [t, data, onToggleDetails, isChallengeToggleEnable, addChallenges, challengeToggleEnable]);

  const handleSelectedItemsChange = useCallback(
    (items: ChallengeListItem[]) => {
      onSelectedItemsChange(items);
    },
    [onSelectedItemsChange]
  );

  if (loading) {
    return <LoadingBar />;
  }

  return (
    <>
      <CustomChallengesTable
        {...props}
        variant="borderless"
        propertyFilterRef={propertyFilterRef}
        paginationRef={paginationRef}
        collectionPrefRef={collectionPrefRef}
        noAction={showPreferences ? false : true}
        filteringProperties={filterProps}
        selectedChallengeItems={selectedItems}
        preferences={preferences}
        setPreferences={(updatedPreferences) => setPreferences && setPreferences({ ...updatedPreferences })}
        propertyFilterPropsCb={onPropertyFilterPropsChange}
        allPageItemsCb={onAllPageItemsChange}
        selectedItemsCb={handleSelectedItemsChange}
        filteredCountCb={onFilteredCountChange}
        currPageIndex={currPageIndex}
        numOfPages={numOfPages}
        challengesCount={challengesCount}
        onPageClick={onPageClick}
        setFilterValues={setFilterValues}
        filterQuery={filterQuery}
      />
    </>
  );
};
