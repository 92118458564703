import {
  Container,
  FormField,
  Header,
  TextContent,
  Button,
  Box,
  SpaceBetween,
} from '@amzn/awsui-components-react';

import { useTranslation } from 'react-i18next';
import { Markdown } from '../../../common/Markdown';
import { ChallengePropAction, useCreateChallenge } from '../../../../store/create-challenge.context';
import { Challenge, ChallengeReviewableSection, ChallengeUtils } from '../../../../types/Challenge';
import { i18nKeys } from '../../../../utils/i18n.utils';
import { ChallengeReviewPopover } from '../../challengesCommon/ChallengeReviewPopover';
import Flashbar, { FlashbarProps } from '@amzn/awsui-components-react/polaris/flashbar';
import './NextSteps.scss';
import React, { useState } from 'react';
import { BORKMarkdownEditor, BORKMarkdownRenderer } from '@/src/components/ui/molecules/Challenge';
import MarkDownPreviewEditor from '@/src/components/common/MarkdownPreviewEditor/MarkDownPreviewEditor';

interface ChallengeNextStepsDetailProps {
  challenge: Challenge;
}

const ChallengeNextStepsDetail: React.FC<ChallengeNextStepsDetailProps> = ({ challenge }) => {
  const { t } = useTranslation();
  const { editMode, editedChallenge, handleUpdateChallengeProp } = useCreateChallenge();
  const [, setOverwriteTemplate] = useState(false);
  const updateTemplate = () => {
    const template: string =
      '- [Link to some documentation](https://docs.aws.amazon.com/)\n' +
      '- [Link to some blog post](https://aws.amazon.com/blogs/aws/)\n' +
      '- [Link to some github repo](https://github.com/aws)\n';
    handleUpdateChallengeProp(ChallengePropAction.NEXT_STEPS, template);
    setOverwriteTemplate(false);
  };
  const addContentTemplate = () => {
    if (challenge.props.nextSteps) {
      setOverwriteTemplate(true);
    } else {
      updateTemplate();
    }
  };
  const items: FlashbarProps.MessageDefinition[] = [
    {
      type: 'info',
      content: (
        <>
          {t(i18nKeys.challenges.challengeDetails.field.markdownPreferred)} {t(i18nKeys.challenges.challengeDetails.field.nextSteps.overrideWarning)}{' '}
          <Box float="right">
            <Button
              data-testid='challenge-new-step-add-content-template'
              onClick={() => addContentTemplate()}
            >
              {t(i18nKeys.challenges.challengeDetails.field.nextSteps.addContentTemplateBtn)}
            </Button>
          </Box>
        </>
      ),
      id: 'info',
    },
  ];

  const handleUpdateNextStep = (details: string) => {
    handleUpdateChallengeProp(ChallengePropAction.NEXT_STEPS, details);
  };

  if (!editMode) {
    return (
      <Container
        header={
          <Header
            description={t(i18nKeys.challenges.subSections.nextSteps.description)}
            variant="h2"
            actions={<ChallengeReviewPopover section={ChallengeReviewableSection.NEXT_STEPS} />}>
            {t(i18nKeys.challenges.challengeDetails.headings.nextSteps)}
          </Header>
        }>
        <TextContent>
          {!editMode && challenge.props.nextSteps && (
            <Markdown content={challenge.props.nextSteps} />
          )}
          {!editMode &&
            !challenge.props.nextSteps &&
            t(i18nKeys.challenges.challengeDetails.field.nextSteps.emptyState)}
        </TextContent>
      </Container>
    );
  }
  return (
    <Container
      header={
        <Header
          description={t(i18nKeys.challenges.subSections.nextSteps.description)}
          variant="h2"
          actions={<ChallengeReviewPopover section={ChallengeReviewableSection.NEXT_STEPS} />}>
          {t(i18nKeys.challenges.challengeDetails.headings.nextSteps)}
        </Header>
      }>
      {ChallengeUtils.isIndividualLearningType(challenge) ? (
        <Box>
          {!editMode ? (
            <BORKMarkdownRenderer markdown={challenge.props.nextSteps || ''} />
          ) : (
            <FormField stretch>
              <BORKMarkdownEditor
                onChange={(value) => handleUpdateChallengeProp(ChallengePropAction.NEXT_STEPS, value)}
                value={editedChallenge?.props.nextSteps || ''}
              />
            </FormField>
          )}
        </Box>
      ) : (
        <div className="challenge-next-step">
          <SpaceBetween size="s">
            <Flashbar items={items} />
            <MarkDownPreviewEditor onTextChanged={(text) => handleUpdateNextStep(text)} text={challenge.props.nextSteps || ''} />
          </SpaceBetween>
        </div>
      )}
    </Container>
  );
};

export default ChallengeNextStepsDetail;
