import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, TableProps } from '@amzn/awsui-components-react';

// context
import { useEventTemplate } from '@/src/store/event-template.context';
// utils
import { i18nKeys } from '@/src/utils/i18n.utils';

// components
import { CustomTableServerSide } from '../CustomTable';
import {
  columnDefinitions,
  filteringProperties,
  collectionPreferencesProps,
  paginationLabels,
} from '../CustomTable/table-config';

// types
import type { IEventTemplate } from '@/src/types/EventTemplate';
import JamSpinner from '../../common/JamSpinner';
import { ConfirmModal } from '../../common/ConfirmModal';
import { useHistory } from 'react-router-dom';
import { createEventTemplateRoute } from '@/src/routes';

const EventTemplateList = () => {
  const {
    loading,
    eventTemplates,
    pageOptions,
    onPageChange,
    onPageSizeChange,
    onFilterChange,
    onSortChange,
    fetchEventTemplates,
    resetEventTemplates,
    onDeleteEventCatalogTemplate,
    totalCount
  } = useEventTemplate();
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [selectedECTId, setSelectedECTId] = useState<string>();
  const navigate = useHistory();
  const { t } = useTranslation();

  const onDeleteClicked = useCallback((item: IEventTemplate) => {
    setSelectedECTId(item.id);
    setShowDeleteConfirm(true);
  }, []);

  const handleDeleteConfirm = useCallback(async () => {
    setShowDeleteConfirm(false);
    if (selectedECTId) {
      await onDeleteEventCatalogTemplate(selectedECTId);
      setSelectedECTId(undefined);
    }
  }, [onDeleteEventCatalogTemplate, selectedECTId]);

  const handleDeleteCancel = useCallback(() => {
    setShowDeleteConfirm(false);
    setSelectedECTId(undefined);
  }, []);

  const props = useMemo(() => {
    const start = (pageOptions.currentPageNumber - 1) * pageOptions.limit;
    const end = start + pageOptions.limit;
    const currEventTemplates = eventTemplates.slice(start, end);

    return {
      visibleContent: ['name', 'challenges', 'durations', 'author', 'status', 'createdDate', 'lastUpdated', 'actions'],
      data: currEventTemplates,
      columnDefinitions: columnDefinitions(
        t,
        onDeleteClicked
      ) as unknown as TableProps.ColumnDefinition<IEventTemplate>[],
      headerTitle: t(i18nKeys.eventTemplates.title),
      filterPlaceholder: t(i18nKeys.eventTemplates.table.placeholder),
      collectionPreferencesProps: collectionPreferencesProps(t),
      headerRightContent: (
        <Button
          variant="primary"
          wrapText={false}
          className="event-actions"
          id="create-event-template"
          data-testid="create-event-template"
          onClick={() => navigate.push(createEventTemplateRoute.path)}
        >
          {t(i18nKeys.eventTemplates.createEventTemplate)}
        </Button>
      ),
      paginationLabels: paginationLabels(t),
      page: pageOptions,
      totalCount
    };
  }, [pageOptions, totalCount, eventTemplates, t]);

  useEffect(() => {
    fetchEventTemplates();
  }, [pageOptions.query]);

  useEffect(() => {
    return () => {
      resetEventTemplates();
    };
  }, []);

  if (loading) {
    return <JamSpinner />;
  }

  return (
    <div className="event-template-container">
      <CustomTableServerSide
        {...props}
        filteringProperties={filteringProperties(t)}
        pageChangeCb={onPageChange}
        pageSizeChangeCb={onPageSizeChange}
        onFilterCb={onFilterChange}
        onSortingChangeCb={onSortChange}
      />
      <ConfirmModal
        visible={showDeleteConfirm}
        title={t(i18nKeys.eventTemplates.deleteEventCatalogTemplate.modalTitle)}
        confirmBtnLabel={t(i18nKeys.eventTemplates.deleteEventCatalogTemplate.modalConfirm)}
        onConfirm={handleDeleteConfirm}
        onCancel={handleDeleteCancel}
      />
    </div>
  );
};

export default EventTemplateList;
