import React, { MutableRefObject, useEffect, useMemo } from 'react';

import { PropertyFilterProperty, useCollection } from '@amzn/awsui-collection-hooks';
import {
  Box,
  Button,
  CollectionPreferences,
  CollectionPreferencesProps,
  Header,
  Pagination,
  PaginationProps,
  PropertyFilter,
  PropertyFilterProps,
  Table,
  TableProps,
} from '@amzn/awsui-components-react';

import './CustomTable.scss';
import { EventTemplateReport, IEventTemplatePage, SelectionType } from '@/src/types/EventTemplate';

import { useTranslation } from 'react-i18next';
import { ChallengeListItem } from '@/src/types/Challenge';
import { ChallengeSet } from '@/src/types/ChallengeSet';
import ReactDOM from 'react-dom';
import { i18nKeys } from '@/src/utils/i18n.utils';

const EmptyState = ({ title, subtitle, action }: { title: string; subtitle: string; action: React.ReactNode }) => {
  return (
    <Box textAlign="center" color="inherit">
      <Box variant="strong" textAlign="center" color="inherit">
        {title}
      </Box>
      <Box variant="p" padding={{ bottom: 's' }} color="inherit">
        {subtitle}
      </Box>
      {action}
    </Box>
  );
};

interface IProps<T> {
  data: T[];
  columnDefinitions: TableProps.ColumnDefinition<T>[];
  headerTitle: string;
  filterPlaceholder: string;
  collectionPreferencesProps: CollectionPreferencesProps;
  paginationLabels: PaginationProps.Labels;
  filteringProperties?: PropertyFilterProperty[];
  propertyFilterRef?: MutableRefObject<HTMLDivElement | null>;
  paginationRef?: MutableRefObject<HTMLDivElement | null>;
  collectionPrefRef?: MutableRefObject<HTMLDivElement | null>;
  headerRightContent?: React.ReactNode;
  noAction?: boolean;
  selectionType?: SelectionType;
  variant?: TableProps.Variant;
  page?: IEventTemplatePage;
  selectedChallengeItems?: T[];
  preferences?: CollectionPreferencesProps.Preferences;
  selectedItemsCb?: (selectedItems: T[]) => void;
  filteredCountCb?: (filteredCount: number) => void;
  propertyFilterPropsCb?: (propertyFilterProps: PropertyFilterProps) => void;
  allPageItemsCb?: (items: T[]) => void;
  setPreferences?: (preferences: CollectionPreferencesProps.Preferences | undefined) => void;
  currPageIndex: number;
  //   setCurrPageIndex: (index: number) => void;
  numOfPages: number;
  challengesCount: number;
  //   setNumOfPages: (page: number) => void;
  onPageClick: (currentPageIndex: number) => void;
  setFilterValues: (detail: PropertyFilterProps.Query, toggleChallengeEnable: boolean) => void;
  filterQuery: PropertyFilterProps.Query;
}

export const CustomChallengesTable = <T extends ChallengeListItem | ChallengeSet | EventTemplateReport>({
  data,
  columnDefinitions,
  headerTitle,
  filterPlaceholder,
  collectionPreferencesProps,
  paginationLabels,
  selectionType = undefined,
  filteringProperties,
  propertyFilterRef,
  paginationRef,
  collectionPrefRef,
  headerRightContent,
  noAction = false,
  selectedChallengeItems,
  preferences,
  challengesCount,
  selectedItemsCb = () => {
    // do nothing
  },
  filteredCountCb = () => {
    // do nothing
  },
  propertyFilterPropsCb = () => {
    // do nothing
  },
  allPageItemsCb = () => {
    // do nothing
  },
  setPreferences = () => {
    // do nothing
  },
  variant = 'full-page',
  currPageIndex,
  numOfPages,
  onPageClick,
  setFilterValues,
  filterQuery,
}: IProps<T>) => {
  const { t } = useTranslation();

  const { allPageItems, items, actions, collectionProps, propertyFilterProps, filteredItemsCount } = useCollection(
    data,
    {
      filtering: {
        empty: (
          <EmptyState
            title={t(i18nKeys.eventTemplates.customTable.empty.noTitle, { headerTitle })}
            subtitle={t(i18nKeys.eventTemplates.customTable.empty.noSubTitle, { headerTitle })}
            action={<Button>{t(i18nKeys.eventTemplates.customTable.empty.createTitle, { headerTitle })}</Button>}
          />
        ),
        noMatch: (
          <EmptyState
            title={t(i18nKeys.eventTemplates.customTable.noMatch.title)}
            subtitle={t(i18nKeys.eventTemplates.customTable.noMatch.subTitle)}
            action={
              <Button onClick={() => actions.setFiltering('')}>
                {t(i18nKeys.eventTemplates.customTable.noMatch.clearFilter)}
              </Button>
            }
          />
        ),
      },
      pagination: { pageSize: preferences ? preferences.pageSize : 10 },
      sorting: {},
      selection: { defaultSelectedItems: selectedChallengeItems, keepSelection: true },
      propertyFiltering: {
        filteringProperties: filteringProperties ?? [],
      },
    }
  );
  const { selectedItems } = collectionProps;

  useEffect(() => {
    if (filteredCountCb) {
      filteredCountCb(filteredItemsCount as number);
    }
  }, [filteredItemsCount]);

  useEffect(() => {
    if (selectedItemsCb) {
      selectedItemsCb(selectedItems as T[]);
    }
  }, [selectedItems]);

  useEffect(() => {
    if (allPageItemsCb) {
      allPageItemsCb(allPageItems as T[]);
    }
  }, [allPageItems, allPageItemsCb]);

  useEffect(() => {
    if (propertyFilterPropsCb) {
      propertyFilterPropsCb(propertyFilterProps as PropertyFilterProps);
    }
  }, [propertyFilterProps, propertyFilterPropsCb]);

  const propertyFilterI18NStrings = useMemo(() => {
    return {
      filteringAriaLabel: t(i18nKeys.eventTemplates.customTable.filter.filteringAriaLabel),
      dismissAriaLabel: t(i18nKeys.eventTemplates.customTable.filter.dismissAriaLabel),
      filteringPlaceholder: filterPlaceholder,
      groupValuesText: t(i18nKeys.eventTemplates.customTable.filter.groupValuesText),
      groupPropertiesText: t(i18nKeys.eventTemplates.customTable.filter.groupPropertiesText),
      operatorsText: t(i18nKeys.eventTemplates.customTable.filter.operatorsText),
      operationAndText: t(i18nKeys.eventTemplates.customTable.filter.operationAndText),
      operationOrText: t(i18nKeys.eventTemplates.customTable.filter.operationOrText),
      operatorLessText: t(i18nKeys.eventTemplates.customTable.filter.operatorLessText),
      operatorLessOrEqualText: t(i18nKeys.eventTemplates.customTable.filter.operatorLessOrEqualText),
      operatorGreaterText: t(i18nKeys.eventTemplates.customTable.filter.operatorGreaterText),
      operatorGreaterOrEqualText: t(i18nKeys.eventTemplates.customTable.filter.operatorGreaterOrEqualText),
      operatorContainsText: t(i18nKeys.eventTemplates.customTable.filter.operatorContainsText),
      operatorDoesNotContainText: t(i18nKeys.eventTemplates.customTable.filter.operatorDoesNotContainText),
      operatorEqualsText: t(i18nKeys.eventTemplates.customTable.filter.operatorEqualsText),
      operatorDoesNotEqualText: t(i18nKeys.eventTemplates.customTable.filter.operatorDoesNotEqualText),
      editTokenHeader: t(i18nKeys.eventTemplates.customTable.filter.editTokenHeader),
      propertyText: t(i18nKeys.eventTemplates.customTable.filter.propertyText),
      operatorText: t(i18nKeys.eventTemplates.customTable.filter.operatorText),
      valueText: t(i18nKeys.eventTemplates.customTable.filter.valueText),
      cancelActionText: t(i18nKeys.eventTemplates.customTable.filter.cancelActionText),
      applyActionText: t(i18nKeys.eventTemplates.customTable.filter.applyActionText),
      allPropertiesLabel: t(i18nKeys.eventTemplates.customTable.filter.allPropertiesLabel),
      tokenLimitShowMore: t(i18nKeys.eventTemplates.customTable.filter.tokenLimitShowMore),
      tokenLimitShowFewer: t(i18nKeys.eventTemplates.customTable.filter.tokenLimitShowFewer),
      clearFiltersText: t(i18nKeys.eventTemplates.customTable.filter.clearFiltersText),
    };
  }, [t]);

  const filterCommonProps = {
    ...propertyFilterProps,
    query: filterQuery,
    i18nStrings: propertyFilterI18NStrings,
    onChange: ({ detail }: {detail : PropertyFilterProps.Query}) => setFilterValues(detail, false)
  };
  return (
    <Table
      {...collectionProps}
      trackBy={'challengeId'}
      variant={variant}
      selectedItems={selectedItems}
      selectionType={selectionType}
      header={
        noAction ? undefined : (
          <div className="table-header">
            <Header counter={`(${challengesCount})`}>{headerTitle}</Header>
            {headerRightContent && <div style={{ width: '45%' }}>{headerRightContent}</div>}
          </div>
        )
      }
      columnDefinitions={columnDefinitions}
      visibleColumns={preferences ? preferences.visibleContent : []}
      items={items}
      ariaLabels={{
        selectionGroupLabel: t(i18nKeys.challenges.selectionGroupLabel),
      }}
      pagination={
        paginationRef && paginationRef.current ? (
          ReactDOM.createPortal(
            <Pagination
              currentPageIndex={currPageIndex}
              onChange={({ detail }) => void onPageClick(detail.currentPageIndex)}
              pagesCount={numOfPages}
              ariaLabels={paginationLabels}
            />,
            paginationRef.current
          )
        ) : noAction ? undefined : (
          <Pagination
            currentPageIndex={currPageIndex}
            onChange={({ detail }) => void onPageClick(detail.currentPageIndex)}
            pagesCount={numOfPages}
            ariaLabels={paginationLabels}
          />
        )
      }
      filter={
        propertyFilterRef && propertyFilterRef.current ? (
          ReactDOM.createPortal(
            <PropertyFilter
              hideOperations
              {...filterCommonProps}
            />,
            propertyFilterRef.current
          )
        ) : noAction ? undefined : (
          <PropertyFilter
            {...filterCommonProps} />
        )
      }
      preferences={
        collectionPrefRef && collectionPrefRef.current ? (
          ReactDOM.createPortal(
            <CollectionPreferences
              {...collectionPreferencesProps}
              preferences={preferences}
              onConfirm={({ detail }) => {
                setPreferences(detail as typeof preferences);
              }}
            />,
            collectionPrefRef.current
          )
        ) : noAction ? undefined : (
          <CollectionPreferences
            {...collectionPreferencesProps}
            preferences={preferences}
            onConfirm={({ detail }) => {
              setPreferences(detail as typeof preferences);
            }}
          />
        )
      }
    />
  );
};
