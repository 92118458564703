import '@amzn/awsui-global-styles/polaris.css';
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { LoadingBar } from './components/common/LoadingBar';
import App from './components/root/App';
import './i18n';
import './index.css';
import reportWebVitals from './reportWebVitals';

import { ApiClientProvider } from './store/api.context';
import { FlashbarProvider } from './store/flashbar.context';
import { ThemeProvider } from './store/theme.context';
import { UserProvider } from './store/user.context';

import Amplify from 'aws-amplify';
import amplifyConfig from './config/amplifyConfig';

import { AuthClientProvider } from './store/auth.context';
import { ChallengesProvider } from './store/challenge.context';
import { CookieConsentProvider } from './store/cookie-consent.context';
import { EditEventProvider } from './store/edit-event.context';
import { EventsProvider } from './store/events.context';
import { SplitPanelProvider } from './store/split-panel.context';
import { ToolPanelProvider } from './store/tool-panel.context';
import { UsagePlanProvider } from './store/usage-plan/usage-plan.context';

import { BASE_PATH_PREFIX } from './utils/env.utils';

import { CreateEventTemplateProvider } from '@/src/store/create-event-template.context';
import { EditEventTemplateProvider } from '@/src/store/edit-event-template.context';
import { HelmetProvider } from 'react-helmet-async';
import { ChallengeTutorial } from './components/challenges/ChallengeTutorial';
import { CampaignsProvider } from './store/campaigns.context';
import { ChallengeSetProvider } from './store/challenge-set.context';
import { CreateChallengeProvider } from './store/create-challenge.context';
import { EditCampaignProvider } from './store/edit-campaign.context';
import { EventTemplateChallengesProvider } from './store/event-template-challenges.context';
import { EventTemplateProvider } from './store/event-template.context';
import { JamChallengeRecentFeedbackProvider } from './store/jam-challenge-recent-feedback';
import { JamChallengeProvider } from './store/jam-challenge.context';
import { JamDashboardProvider } from './store/jam-dashboard.context';
import { JamFeedbackProvider } from './store/jam-feedback';
import { JamMyTeamProvider } from './store/jam-myteam.context';
import { LSEProvider } from './store/lse.context';
import { WebsocketProvider } from './store/websocket.context';

import { IconDefinition, library } from '@fortawesome/fontawesome-svg-core';
import { faCaretDown, faUser } from '@fortawesome/free-solid-svg-icons';

import { JamReportProvider } from '@/src/store/jam-report.context';
import { EventTemplateOffersProvider } from './store/event-template-offers.context';
import { HeaderProvider } from './store/header.context';
import { JamChallengeDetailsProvider } from './store/jam-challenge-details.context';
import { JamChatProvider } from './store/jam-chat.context';
import { JamEventDetailsProvider } from './store/jam-event-details.context';
import { JamFacilitatorProvider } from './store/jam-facilitator.context';
import { JamLeaderboardProvider } from './store/jam-leaderboard.context';
import { PublicEventsProvider } from './store/public-events.context';

import NiceModal from '@ebay/nice-modal-react';

library.add(faCaretDown as IconDefinition);
library.add(faUser as IconDefinition);

Amplify.configure(amplifyConfig);

// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
global.fetch = require('node-fetch');

const Root = () => (
  <React.StrictMode>
    <BrowserRouter basename={BASE_PATH_PREFIX}>
      <div className="awsui">
        <Suspense fallback={<LoadingBar />}>
          <FlashbarProvider>
            <AuthClientProvider>
              <ApiClientProvider>
                <UserProvider>
                  {/*
                    TODO: figure out how to wrap a provider around a specific route in routes.ts or MainContent.tsx
                          because this EventsProvider is only needed by EventList and EventDetails
                  */}
                  <CookieConsentProvider>
                    <LSEProvider>
                      <PublicEventsProvider>
                        <ChallengesProvider>
                          <ChallengeSetProvider>
                            <SplitPanelProvider>
                              <ToolPanelProvider>
                                <EventsProvider>
                                  <EventTemplateOffersProvider>
                                    <EventTemplateProvider>
                                      <CreateEventTemplateProvider>
                                        <EditEventTemplateProvider>
                                          <EventTemplateChallengesProvider>
                                            <CampaignsProvider>
                                              <EditEventProvider>
                                                <EditCampaignProvider>
                                                  <UsagePlanProvider>
                                                    <CreateChallengeProvider>
                                                      <JamEventDetailsProvider>
                                                        <JamDashboardProvider>
                                                          <JamLeaderboardProvider>
                                                            <JamChallengeProvider>
                                                              <JamChallengeDetailsProvider>
                                                                <JamFeedbackProvider>
                                                                  <JamFacilitatorProvider>
                                                                    <JamReportProvider>
                                                                      <JamChallengeRecentFeedbackProvider>
                                                                        <ChallengeTutorial>
                                                                          <JamMyTeamProvider>
                                                                            <JamChatProvider>
                                                                              <WebsocketProvider>
                                                                                <HelmetProvider>
                                                                                  <ThemeProvider>
                                                                                    <HeaderProvider>
                                                                                      <NiceModal.Provider>
                                                                                        <App />
                                                                                      </NiceModal.Provider>
                                                                                    </HeaderProvider>
                                                                                  </ThemeProvider>
                                                                                </HelmetProvider>
                                                                              </WebsocketProvider>
                                                                            </JamChatProvider>
                                                                          </JamMyTeamProvider>
                                                                        </ChallengeTutorial>
                                                                      </JamChallengeRecentFeedbackProvider>
                                                                    </JamReportProvider>
                                                                  </JamFacilitatorProvider>
                                                                </JamFeedbackProvider>
                                                              </JamChallengeDetailsProvider>
                                                            </JamChallengeProvider>
                                                          </JamLeaderboardProvider>
                                                        </JamDashboardProvider>
                                                      </JamEventDetailsProvider>
                                                    </CreateChallengeProvider>
                                                  </UsagePlanProvider>
                                                </EditCampaignProvider>
                                              </EditEventProvider>
                                            </CampaignsProvider>
                                          </EventTemplateChallengesProvider>
                                        </EditEventTemplateProvider>
                                      </CreateEventTemplateProvider>
                                    </EventTemplateProvider>
                                  </EventTemplateOffersProvider>
                                </EventsProvider>
                              </ToolPanelProvider>
                            </SplitPanelProvider>
                          </ChallengeSetProvider>
                        </ChallengesProvider>
                      </PublicEventsProvider>
                    </LSEProvider>
                  </CookieConsentProvider>
                </UserProvider>
              </ApiClientProvider>
            </AuthClientProvider>
          </FlashbarProvider>
        </Suspense>
      </div>
    </BrowserRouter>
  </React.StrictMode>
);

ReactDOM.render(<Root />, document.getElementById('root'));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
