import { useTheme } from '@/src/store/theme.context';
import localStorageTTLUtils from '@/src/utils/localStorageTTL.utils';
import { CodeEditor, CodeEditorProps } from '@amzn/awsui-components-react';
import { Mode } from '@amzn/awsui-global-styles';
import 'ace-builds/css/ace.css';
import 'ace-builds/css/theme/dawn.css';
import 'ace-builds/css/theme/tomorrow_night_bright.css';
import ace from 'ace-builds/src-noconflict/ace';
import React, { useState } from 'react';
import 'react-ace-builds/webpack-resolver-min';
import { useTranslation } from 'react-i18next';
import { i18nKeys } from '../../../utils/i18n.utils';

interface JamCodeEditorProps {
  language: CodeEditorProps.Language;
  value: string;
  setValue: (e: string) => void;
  editorContentHeight?: number;
  ['data-testid']?: string;
}

const EDIT_PREFS_KEY = 'jam-code-editor-preferences';

const JamCodeEditor: React.FC<JamCodeEditorProps> = ({ language, value, setValue, editorContentHeight, ...rest }) => {
  const { t } = useTranslation();
  const { themeMode } = useTheme();
  const storagePreferences =
    (localStorageTTLUtils.getItem(EDIT_PREFS_KEY) as Partial<CodeEditorProps.Preferences>) || {};
  const initialTheme = themeMode === Mode.Dark ? 'tomorrow_night_bright' : 'dawn';
  const [preferences, setPreferences] = useState<CodeEditorProps.Preferences | undefined>({
    wrapLines: true,
    theme: initialTheme,
    ...storagePreferences,
  });
  const [loading] = useState(false);
  ace.config.set('useStrictCSP', true);
  ace.config.set('loadWorkerFromBlob', false);
  ace.config.set('basePath', 'https://unpkg.com/ace-builds@1.4.6/src-noconflict');

  return (
    <CodeEditor
      ace={ace as unknown}
      language={language}
      value={value}
      onChange={(e) => setValue(e.detail.value)}
      preferences={preferences}
      onPreferencesChange={(e) => {
        localStorageTTLUtils.setItem(EDIT_PREFS_KEY, e.detail, 30, 'days');
        setPreferences(e.detail);
      }}
      loading={loading}
      editorContentHeight={editorContentHeight}
      data-testid={rest['data-testid']}
      themes={{ dark: ['tomorrow_night_bright'], light: ['dawn'] }}
      i18nStrings={{
        loadingState: t(i18nKeys.codeEditor.loadingState),
        errorState: t(i18nKeys.codeEditor.errorState),
        errorStateRecovery: t(i18nKeys.codeEditor.errorStateRecovery),
        editorGroupAriaLabel: t(i18nKeys.codeEditor.editorGroupAriraLabel),
        statusBarGroupAriaLabel: t(i18nKeys.codeEditor.statusBarGroupAriaLabel),
        cursorPosition: (row, column) => `Ln ${row}, Col ${column}`,
        errorsTab: t(i18nKeys.codeEditor.errorsTab),
        warningsTab: t(i18nKeys.codeEditor.warningsTab),
        preferencesButtonAriaLabel: t(i18nKeys.codeEditor.preferencesLabel),
        paneCloseButtonAriaLabel: t(i18nKeys.codeEditor.closeLabel),
        preferencesModalHeader: t(i18nKeys.codeEditor.preferencesLabel),
        preferencesModalCancel: t(i18nKeys.codeEditor.cancelLabel),
        preferencesModalConfirm: t(i18nKeys.codeEditor.confirmLabel),
        preferencesModalWrapLines: t(i18nKeys.codeEditor.warpLinesLabel),
        preferencesModalTheme: t(i18nKeys.codeEditor.themeLabel),
        preferencesModalLightThemes: t(i18nKeys.codeEditor.lightThemesLabel),
        preferencesModalDarkThemes: t(i18nKeys.codeEditor.darkThemesLabel),
      }}
    />
  );
};

export default JamCodeEditor;
