import { Button, Grid, SpaceBetween, TextContent } from '@amzn/awsui-components-react';
import React, { useCallback, useEffect } from 'react';
import { useState } from 'react';
import JamCodeEditor from '../../challenges/challengesCommon/JamCodeEditor';
import { Markdown } from '../Markdown';
import './MarkDownPreviewEditor.scss';
import { useTranslation } from 'react-i18next';
import { i18nKeys } from '@/src/utils/i18n.utils';
import { debounce } from 'lodash';

const IconImg = (): JSX.Element => (
  <svg
    style={{ display: 'flex' }}
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="14"
    viewBox="0 0 16 16"
    fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.41421 8.00008L5.20711 5.20718L3.79289 3.79297L0.292893 7.29297C-0.0976311 7.68349 -0.0976311 8.31666 0.292893 8.70718L3.79289 12.2072L5.20711 10.793L2.41421 8.00008ZM5.54611 11.3179L8.60611 3.91787L10.4543 4.68213L7.39432 12.0821L5.54611 11.3179ZM15.7071 7.29297L12.2071 3.79297L10.7929 5.20718L13.5858 8.00008L10.7929 10.793L12.2071 12.2072L15.7071 8.70718C16.0976 8.31666 16.0976 7.68349 15.7071 7.29297Z"
      fill="#0972D3"
    />
  </svg>
);

interface IMarkdownPreviewEditor {
  text?: string | null;
  onTextChanged: (text: string) => void;
  editorContentHeight?: number;
  activeTabIndex?: number;
  updateDebounceDelay?: number;
  ['data-testid']?: string;
}

const MarkDownPreviewEditor: React.FC<IMarkdownPreviewEditor> = ({
  text,
  onTextChanged,
  editorContentHeight,
  activeTabIndex,
  updateDebounceDelay = 500,
  ...rest
}) => {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState(activeTabIndex || 1);
  const [textBuffer, setTextBuffer] = useState(text || '');

  const debounceUpdate = useCallback(debounce((text: string) => onTextChanged(text), updateDebounceDelay), [onTextChanged]);

  useEffect(() => {
    debounceUpdate(textBuffer);
  }, [textBuffer]);

  return (
    <div className="markdown-preview-editor" data-testid={rest['data-testid'] || 'markdown-preview-editor'}>
      <SpaceBetween size="s">
        <div className="main-container">
          <div className="button-container">
            <SpaceBetween direction="horizontal" size="xs">
              <Button
                variant={activeTab === 1 ? 'normal' : 'link'}
                onClick={() => setActiveTab(1)}>
                <SpaceBetween direction="horizontal" size="xxs" alignItems="center">
                  <IconImg /> {t(i18nKeys.events.eventDetails.buttons.markdown)}
                </SpaceBetween>
              </Button>
              <Button
                variant={activeTab === 2 ? 'normal' : 'link'}
                onClick={() => setActiveTab(2)}>
                <SpaceBetween direction="horizontal" size="xxs" alignItems="center">
                  <IconImg />
                  <img src="/assets/eye-blue-icon.png" alt="" />
                  {t(i18nKeys.events.eventDetails.buttons.markdownPreview)}
                </SpaceBetween>
              </Button>
              <Button
                variant={activeTab === 3 ? 'normal' : 'link'}
                onClick={() => setActiveTab(3)}>
                <SpaceBetween direction="horizontal" size="xxs" alignItems="center">
                  <img src="/assets/eye-blue-icon.png" alt="" />
                  {t(i18nKeys.events.eventDetails.buttons.preview)}
                </SpaceBetween>
              </Button>
            </SpaceBetween>
          </div>
          <div style={{ padding: '2rem' }}>
              <Grid gridDefinition={(activeTab === 1 || activeTab === 3) ? [{ colspan: 12 }] : [{ colspan: 6 }, { colspan: 6 }]}>
                { activeTab < 3 && <JamCodeEditor
                  language="markdown"
                  value={textBuffer}
                  setValue={(text) => setTextBuffer(text)}
                  editorContentHeight={editorContentHeight}
                /> }
                { activeTab > 1  && <TextContent>
                  <Markdown content={textBuffer} />
                </TextContent>}
              </Grid>
          </div>
        </div>
      </SpaceBetween>
    </div>
  );
};

export default MarkDownPreviewEditor;
