import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Challenge, ChallengeReviewableSection, ChallengeTask } from '../../../../types/Challenge';
import { i18nKeys } from '../../../../utils/i18n.utils';
import ChallengeTaskDetail from './Task';
import { Box, Button, Header } from '@amzn/awsui-components-react';
import { ChallengeReviewPopover } from '../../challengesCommon/ChallengeReviewPopover';
import { useCreateChallenge } from '../../../../store/create-challenge.context';
import JamSpinner from '@/src/components/common/JamSpinner';
import { preProdLogger } from '@/src/utils/log.utils';

interface ChallengeTasksDetailProps {
  challenge: Challenge;
}

const ChallengeTasksDetail: React.FC<ChallengeTasksDetailProps> = ({ challenge }) => {
  const { t } = useTranslation();
  const { editMode, editedChallenge, addChallengeTask, removeChallengeTask } = useCreateChallenge();
  const [tasks, tasksSet] = useState<ChallengeTask[]>([]);
  const [showSpinner, showSpinnerSet] = useState(false);

  useEffect(() => {
    if (challenge?.props?.tasks?.length) {
      tasksSet(challenge.props.tasks.filter(f => !!f));
    }
  }, [JSON.stringify(challenge.props.tasks)]);

  const getTaskLabel = (taskId: string): string => {
    const task: ChallengeTask | undefined = challenge.props.tasks.find(
      (challengeTask: ChallengeTask) => challengeTask.id === taskId
    );
    return task ? task.label : t(i18nKeys.challenges.challengeDetails.text.unknownTask);
  };

  const addChallengeHandler = useCallback(() => {
    showSpinnerSet(true);
    addChallengeTask(editedChallenge?.challengeId || '', editedChallenge?.version || 0)
      .catch((err) => preProdLogger(err))
      .finally(() => showSpinnerSet(false));
  }, [addChallengeTask]);

  if (showSpinner) {
    return <JamSpinner />;
  }

  return (
    <Box>
      <Header variant="h2" actions={<ChallengeReviewPopover section={ChallengeReviewableSection.TASKS} />}>
        {t(i18nKeys.challenges.challengeDetails.headings.tasks)}
      </Header>
      <Box margin={{ top: 'l' }}>
        {!!tasks.length ? (
          tasks.map((task, i) => (
              <ChallengeTaskDetail
                key={i}
                task={task}
                getTaskLabel={getTaskLabel}
                removeTask={removeChallengeTask}
              />
            )
          )
        ) : (
          <div>{t(i18nKeys.challenges.challengeDetails.text.noTasks)}</div>
        )}
      </Box>
      {editMode && (
        <Button
          id="challenge-tasks-add"
          data-testid="challenge-tasks-add"
          onClick={addChallengeHandler}
          iconName="add-plus">
          {t(i18nKeys.challenges.subSections.tasks.addTask)}
        </Button>
      )}
    </Box>
  );
};

export default ChallengeTasksDetail;
