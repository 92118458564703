import {
  Alert,
  Box,
  Button,
  ColumnLayout,
  CopyToClipboard as CloudscapeCopyToClipboard,
  ExpandableSection,
  Grid,
  Header,
  Icon,
  Input,
  Link,
  SpaceBetween,
} from '@amzn/awsui-components-react';
import _ from 'lodash';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { useComponentDidMountEffect } from '../../../../hooks/useComponentDidMountEffect';
import { CAMPAIGN_DETAILS_ROUTES, EVENT_DETAILS_ROUTES } from '../../../../routes';
import { useApi } from '../../../../store/api.context';
import { useCampaigns } from '../../../../store/campaigns.context';
import { Campaign, CampaignGroup } from '../../../../types/Campaign';
import { getTimeInBrowserLocalTime, getUTCOffset } from '../../../../utils/event-time.utils';
import { i18nKeys } from '../../../../utils/i18n.utils';
import { preProdLogger } from '../../../../utils/log.utils';
import { isEmailListValid, splitDelimitedEmails } from '../../../../utils/string.utils';
import { ConfirmModal } from '../../../common/ConfirmModal';
import { CopyToClipboard } from '../../../common/CopyToClipboard';
import { KeyValue } from '../../../common/KeyValue';
import { LoadingBar } from '../../../common/LoadingBar';
import NewGroupDetails from './NewGroupDetails';
import { ParticipantList } from './ParticipantsList';

interface GroupDetailsProps {
  campaign: Campaign;
}

const GroupDetails: React.FC<GroupDetailsProps> = ({ campaign }) => {
  const { t } = useTranslation();
  const { getCampaignGroupById, campaignGroup } = useCampaigns();
  const params: { 0: string; 1: string } = useParams();
  const [emails, setEmails] = useState('');
  const { campaignApi } = useApi();
  const [loading, setLoading] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [editedCampaignGroup, setEditedCampaignGroup] = useState<CampaignGroup | undefined>();
  const [confirmDeleteGroupModalVisible, setConfirmDeleteGroupModalVisible] = useState(false);
  const history = useHistory();

  useComponentDidMountEffect(async () => {
    if (params[1]) {
      await getCampaignGroupById(params[1]);
    }
  });

  const handleEditUpdate = (newEditsCampaignGroup: CampaignGroup) => {
    setEditedCampaignGroup(newEditsCampaignGroup);
  };

  const isValidEmailsInput = () => {
    const emailList = splitDelimitedEmails(emails);
    return isEmailListValid(emailList);
  };

  const handleDeleteGroup = () => {
    if (campaign.id && campaignGroup?.id) {
      setLoading(true);
      campaignApi
        .deleteCampaignGroup(campaign.id, campaignGroup.id)
        .then(() => {
          setLoading(false);
          setConfirmDeleteGroupModalVisible(false);
          if (campaign.id) {
            history.push(CAMPAIGN_DETAILS_ROUTES.Groups.resolve(campaign.id));
          }
        })
        .catch(() => {
          setLoading(false);
          setConfirmDeleteGroupModalVisible(false);
        });
    }
  };

  const handleSaveGroup = () => {
    if (campaign.id && editedCampaignGroup?.id) {
      setLoading(true);
      campaignApi
        .updateCampaignGroup(editedCampaignGroup)
        .then(async () => {
          if (campaignGroup?.id) {
            await getCampaignGroupById(campaignGroup.id);
            setEditMode(false);
            setEditedCampaignGroup(undefined);
            setLoading(false);
          }
        })
        .catch((err) => {
          preProdLogger('Error updating campaign group', err.message);
          setLoading(false);
        });
    }
  };

  const handleAddParticipants = () => {
    if (campaign.id && campaignGroup?.id) {
      const newParticipantEmails = splitDelimitedEmails(emails);
      setLoading(true);
      campaignApi
        .createCampaignGroupParticipants(campaign.id, campaignGroup.id, newParticipantEmails)
        .then(() => {
          if (campaignGroup.id) {
            getCampaignGroupById(campaignGroup.id)
              .then(() => {
                setEmails('');
                setLoading(false);
              })
              .catch((err) => {
                preProdLogger('Error getting campaign group by id', err.message);
                setLoading(false);
              });
          }
        })
        .catch((err) => {
          preProdLogger('Error creating campaign group participants', err.message);
          setLoading(false);
        });
    }
  };

  return (
    <>
      <ConfirmModal
        visible={confirmDeleteGroupModalVisible}
        title={t(i18nKeys.campaigns.modals.deleteGroup.title)}
        confirmBtnLabel={t(i18nKeys.campaigns.modals.deleteGroup.confirmButton)}
        onCancel={() => setConfirmDeleteGroupModalVisible(false)}
        onConfirm={() => handleDeleteGroup()}
      />
      {!loading && (
        <SpaceBetween direction="vertical" size="s">
          <Header
            variant="h2"
            actions={
              <SpaceBetween direction="horizontal" size="s">
                <div className="mt-4">
                  <Link href={CAMPAIGN_DETAILS_ROUTES.Groups.resolve(campaign?.id || '')}>
                    {t(i18nKeys.campaigns.labels.groups.goBackToGroups)}
                  </Link>
                </div>
                <Button variant="normal" onClick={() => setConfirmDeleteGroupModalVisible(true)}>
                  {t(i18nKeys.general.delete)}
                </Button>
              </SpaceBetween>
            }>
            {t(i18nKeys.campaigns.headers.groups.group)}
          </Header>
          {campaignGroup?.isAfterEnd && (
            <Alert type="warning">{t(i18nKeys.campaigns.messages.groups.campaignGroupHasEnded)}</Alert>
          )}
          <ExpandableSection
            variant="container"
            headerActions={<SpaceBetween key="space-between-details-actions" direction="horizontal" size="s">
              {!editMode && (
                <Button
                  variant="normal"
                  onClick={(e) => {
                    e.stopPropagation();
                    setEditedCampaignGroup(_.clone(campaignGroup));
                    setEditMode(true);
                  }}>
                  {t(i18nKeys.general.edit)}
                </Button>
              )}
              {editMode && (
                <>
                  <Button
                    data-testid="group-details__cancel-btn"
                    onClick={(e) => {
                      e.stopPropagation();
                      setEditedCampaignGroup(undefined);
                      setEditMode(false);
                    }}
                    variant="link">
                    {t(i18nKeys.general.cancel)}
                  </Button>
                  <Button
                    onClick={(e) => {
                      e.stopPropagation();
                      handleSaveGroup();
                    }}>
                    {t(i18nKeys.general.save)}
                  </Button>
                </>
              )}
            </SpaceBetween>}
            headerText={t(i18nKeys.campaigns.headers.groups.details)
            }>
            {!editMode && (
              <ColumnLayout columns={2}>
                <KeyValue label={<strong>{t(i18nKeys.campaigns.labels.groups.title)}</strong>}>
                  {campaignGroup?.title}
                </KeyValue>
                <div />
                <SpaceBetween direction="vertical" size="s">
                  {campaignGroup?.startDate && (
                    <KeyValue label={<strong>{t(i18nKeys.campaigns.labels.groups.startTime)}</strong>}>
                      {getTimeInBrowserLocalTime(campaignGroup?.startDate, { includeDate: true, includeTime: true })}
                      <div>{`UTC: ${getUTCOffset(campaignGroup?.startDate)}`}</div>
                    </KeyValue>
                  )}
                  {campaignGroup?.minExpectedParticipants && campaignGroup.maxExpectedParticipants && (
                    <KeyValue
                      label={<strong>{t(i18nKeys.campaigns.labels.groups.numberOfExpectedParticipants)}</strong>}>
                      {t(i18nKeys.campaigns.messages.groups.numberOfParticipants, {
                        minExpectedParticipants: campaignGroup?.minExpectedParticipants,
                        maxExpectedParticipants: campaignGroup?.maxExpectedParticipants,
                      })}
                    </KeyValue>
                  )}
                  <KeyValue label={<strong>{t(i18nKeys.campaigns.labels.groups.associatedEvents)}</strong>}>
                    <SpaceBetween direction="vertical" size="s">
                      {campaignGroup?.eventName && (
                        <Link href={EVENT_DETAILS_ROUTES.Summary.resolve(campaignGroup.eventName)} external>
                          {t(i18nKeys.campaigns.buttons.goToLiveEvent)}
                        </Link>
                      )}
                      {campaignGroup?.testCloneEventName && (
                        <Link href={EVENT_DETAILS_ROUTES.Summary.resolve(campaignGroup.testCloneEventName)} external>
                          {t(i18nKeys.campaigns.buttons.goToTestEvent)}
                        </Link>
                      )}
                    </SpaceBetween>
                  </KeyValue>
                </SpaceBetween>
                <SpaceBetween direction="vertical" size="s">
                  {campaignGroup?.endDate && (
                    <KeyValue label={<strong>{t(i18nKeys.campaigns.labels.groups.endTime)}</strong>}>
                      {getTimeInBrowserLocalTime(campaignGroup?.endDate, { includeDate: true, includeTime: true })}
                      <div>{`UTC: ${getUTCOffset(campaignGroup?.endDate)}`}</div>
                    </KeyValue>
                  )}
                  <KeyValue label={<strong>{t(i18nKeys.campaigns.labels.groups.registrationStatus)}</strong>}>
                    {!campaignGroup?.closedForNewRegistrations && !campaignGroup?.isAfterEnd ? (
                      <span>
                        <Icon name="unlocked" /> {t(i18nKeys.general.open)}
                      </span>
                    ) : (
                      <span>
                        <Icon name="lock-private" /> {t(i18nKeys.general.locked)}
                      </span>
                    )}
                  </KeyValue>
                  <KeyValue label={<strong>{t(i18nKeys.campaigns.labels.groups.labs)}</strong>}>
                    <Link href={`/labs?event-name=${campaignGroup?.eventName}`} external>
                      {t(i18nKeys.campaigns.buttons.goToLabDashboard)}
                    </Link>
                  </KeyValue>
                </SpaceBetween>
              </ColumnLayout>
            )}
            {editMode && (
              <NewGroupDetails
                campaign={campaign}
                editedCampaignGroup={editedCampaignGroup}
                handleEditedCampaignChange={handleEditUpdate}
                editMode={editMode}
              />
            )}
          </ExpandableSection>
          <ExpandableSection
            variant="container"
            headerText={t(i18nKeys.campaigns.headers.groups.participants)}>
            <SpaceBetween direction="vertical" size="s">
              <KeyValue label={t(i18nKeys.campaigns.labels.groups.addEmails)}>
                <SpaceBetween direction="vertical" size="s">
                  <SpaceBetween direction="horizontal" size="s">
                    <Input
                      type="text"
                      className="text-input inline"
                      placeholder="user@email.com"
                      value={emails}
                      onChange={({ detail }) => setEmails(detail.value)}
                    />
                    <Button
                      className="inline"
                      variant="primary"
                      disabled={emails.length < 1 || (emails.length > 0 && !isValidEmailsInput())}
                      loading={loading}
                      onClick={() => handleAddParticipants()}>
                      {t(i18nKeys.campaigns.buttons.addParticipants)}
                    </Button>
                  </SpaceBetween>
                  {emails.length > 0 && !isValidEmailsInput() && (
                    <SpaceBetween direction="horizontal" size="s" className="warning">
                      <Icon name="status-warning" />
                      {t(i18nKeys.campaigns.errors.emailFormatInvalid)}
                    </SpaceBetween>
                  )}
                </SpaceBetween>
              </KeyValue>
              {campaignGroup && <ParticipantList campaign={campaign} campaignGroup={campaignGroup} />}
            </SpaceBetween>
          </ExpandableSection>
          <ExpandableSection
            variant="container"
            headerText={t(i18nKeys.campaigns.headers.groups.publicInviteUrl)}>
            {!campaignGroup?.isAfterEnd && campaignGroup?.publicCode && (
              <SpaceBetween direction="vertical" size="s">
                <div>{t(i18nKeys.campaigns.headers.groups.descriptions.publicInviteUrl)}</div>
                <KeyValue label={<strong>{t(i18nKeys.campaigns.labels.groups.shareThisUrl)}</strong>}>
                  <Box padding={{top: 's'}}>
                    <SpaceBetween direction='horizontal' size="m">
                      <Box display='inline-block' padding={{top: 'xs'}}>
                        <Link href={campaignGroup.getInviteUrl()} external>
                          {campaignGroup.getInviteUrl()}
                        </Link>
                      </Box>
                      <CloudscapeCopyToClipboard
                        copyButtonText={t(i18nKeys.clipboard.buttonLabel)}
                        textToCopy={campaignGroup.getInviteUrl()}
                        copySuccessText={t(i18nKeys.general.success)}
                        copyErrorText={t(i18nKeys.general.error)}
                      />
                    </SpaceBetween>
                  </Box>
                </KeyValue>
              </SpaceBetween>
            )}
            {campaignGroup?.isAfterEnd && <Alert>{t(i18nKeys.campaigns.messages.groups.campaignGroupEndedUrl)}</Alert>}
          </ExpandableSection>
          <ExpandableSection
            variant="container"
            headerText={t(i18nKeys.campaigns.headers.groups.audit)}>
            <div className="section-first-row">
              <Grid gridDefinition={[{ colspan: 3 }, { colspan: 6 }]}>
                <div className="secondary-text">{t(i18nKeys.campaigns.labels.groups.createdBy)}</div>
                {campaignGroup?.createdBy && (
                  <SpaceBetween direction="horizontal" size="m">
                    <div style={{ marginTop: '-4px' }}>
                      <Link href={`mailto:${campaignGroup.createdBy}`}>{campaignGroup?.createdBy}</Link>
                      <CopyToClipboard icon value={campaignGroup?.createdBy} />
                    </div>
                    {campaignGroup.createdDate &&
                      getTimeInBrowserLocalTime(new Date(campaignGroup.createdDate).toString(), {
                        includeDate: true,
                        includeTime: true,
                      })}
                  </SpaceBetween>
                )}
              </Grid>
            </div>
            <div className="grey-section-divider-top">
              <Grid gridDefinition={[{ colspan: 3 }, { colspan: 6 }]}>
                <div className="secondary-text">{t(i18nKeys.campaigns.labels.groups.lastUpdatedBy)}</div>
                {campaignGroup?.lastUpdatedBy && (
                  <SpaceBetween direction="horizontal" size="m">
                    <div style={{ marginTop: '-4px' }}>
                      <Link href={`mailto:${campaignGroup.lastUpdatedBy}`}>{campaignGroup?.lastUpdatedBy}</Link>
                      <CopyToClipboard icon value={campaignGroup?.lastUpdatedBy} />
                    </div>
                    {campaignGroup.lastUpdatedDate &&
                      getTimeInBrowserLocalTime(new Date(campaignGroup.lastUpdatedDate).toString(), {
                        includeDate: true,
                        includeTime: true,
                      })}
                  </SpaceBetween>
                )}
              </Grid>
            </div>
          </ExpandableSection>
        </SpaceBetween>
      )}
      {loading && <LoadingBar />}
    </>
  );
};

export default GroupDetails;
